import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {Table} from 'primeng/table';
import Utils from '../../../../utils/utils';

@Component({
  selector: 'app-form-item-structure-selection-dialog',
  templateUrl: './form-item-structure-selection-dialog.component.html',
  styleUrls: ['./form-item-structure-selection-dialog.component.css']
})
export class FormItemStructureSelectionDialogComponent extends BaseListComponent implements OnInit {

  @Output() onSubmited = new EventEmitter<any>();
  @Input('formItems') formItems;

  selectedObject;

  tfFilterMap = {
    label: null,
    formTypeEncoded: null,
  };

  exportCols = ['label', 'formTypeEncoded'];

  @ViewChild('dt1', {static: true}) dataTable: Table;

  visible: boolean =  false;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  selectFormItem(obj: any) {
    this.selectedObject = obj;
    this.visible = false;
    this.onSubmited.emit(JSON.stringify(this.selectedObject, Utils.getCircularReplacer()));
  }

  showDlg() {
    this.visible = true;
    this.clearFilters();
  }

}
