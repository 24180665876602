import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {DynamicHeader} from '../../../../../../model/dynamic-header';
import {DynamicHeaderService} from '../../../../../../service/dynamic-header.service';
import {ProgrammeDocumentType} from "../../../../../../model/programme-document-type";

@Component({
    selector: 'app-dynamic-header-csc1390selection-dialog',
    templateUrl: './dynamic-header-csc1390selection-dialog.component.html',
    styleUrls: ['./dynamic-header-csc1390selection-dialog.component.css']
})
export class DynamicHeaderCsc1390selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public dynamicHeaders: DynamicHeader[];
    @Output() onSelected = new EventEmitter<DynamicHeader>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'dftCode': null, 'dftDescription': null};

    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'dynamic-header';

    constructor(private dynamicHeaderService: DynamicHeaderService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        // this.getDynamicHeaders();
    }


    selectDft(obj: any) {
        this.selectDftCustom(obj);
    }

    showDlg(fdtCode: string, pdtId: number) {
        this.clearFilters();
        this.tfFilterMap['dftCode'] = fdtCode;
        this.getDynamicHeaders(pdtId);
        // this.visible = true;
    }

    getDynamicHeaders(pdtId: number): void {
        this.getDynamicHeaderCustom(pdtId);
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getDynamicHeaderCustom(pdtId: number) {
        this.dynamicHeaderService
            .getByPdtIdNotInProgrammeDocumentForm(pdtId)
            .subscribe(dynamicHeaders => {
                this.dynamicHeaders = dynamicHeaders;
                this.dynamicHeaders = this.dynamicHeaders.sort((a, b) => (a.dftCode > b.dftCode ? 1 : -1));
                this.visible = true;
                this.filterTable();
            }, error => this.errorMessage = <any> error);
    }

    selectDftCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
