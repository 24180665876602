import { Component, OnInit } from '@angular/core';
import {Subscription} from 'rxjs';
import {PendingRequestService} from '../../core/pending-request.service';


@Component({
  selector: 'app-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.css']
})
export class SpinnerComponent {

  private sub1: Subscription;

  show = false;

  constructor(private pendingRequestService: PendingRequestService) {
    this.sub1 = this.pendingRequestService.pendingRequests$.subscribe(r => {
        this.show = r;
      },
      err => {

      });
  }

}





