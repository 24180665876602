import { AuthService } from '../core/auth.service';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PendingRequestService} from '../core/pending-request.service';
import {tap} from 'rxjs/operators';

@Injectable()
export class PendingInterceptor implements HttpInterceptor {

  private pendingRequests = 0;

  private showLoading = false;

  constructor(private pendingRequestService: PendingRequestService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    console.log('intercepttttttttttttttttttt');

    console.log('intercept ' + req.url);

    // 01.03.2022. Nenad, dodao sam req.url.indexOf('tskNotificationsNonSeen') === -1
    // da se ne vidi loading screen prilikom osvjezavanja notifikacija
    if(req.url.indexOf('assets') === -1 && req.url.indexOf('refreshToken') === -1  && req.url.indexOf('tskNotificationsNonSeen') === -1 && req.url.indexOf('tskNotificationsNotificationsNonSeen') === -1) {

      this.pendingRequests++;
      this.turnOnModal();
      console.log('pendingRequests ', this.pendingRequests);

      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {

        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          this.pendingRequests--;
          console.log('pendingRequests ', req, this.pendingRequests);
          this.turnOffModal();
        }
      }, (err: any) => {
        this.pendingRequests--;
        console.log('pendingRequests ', this.pendingRequests);
        this.turnOffModal();
        if (err instanceof HttpErrorResponse) {

          if (err.status === 401) {

          }
        }
      })
      );
    }
    else {
      return next.handle(req);
    }

  }

  private turnOnModal() {
    if (!this.showLoading) {
      this.showLoading = true;
      // document.getElementsByTagName('body').spin('modal', '#FFFFFF', 'rgba(51, 51, 51, 0.1)');
      // document.getElementsByClassName('splash-loader1')[0].style.display = 'block';
      this.pendingRequestService.updatePendingRequests(true);
      console.log("Turned on modal");
    }
    this.showLoading = true;
  }

  private turnOffModal() {
    // this.pendingRequests--;
    if (this.pendingRequests <= 0) {
      if (this.showLoading) {
        // document.getElementsByTagName('body').spin('modal', '#FFFFFF', 'rgba(51, 51, 51, 0.1)');
        // document.getElementsByClassName('splash-loader1')[0].style.display = 'none';
        this.pendingRequestService.updatePendingRequests(false);
      }
      this.showLoading = false;
    }
    console.log('Turned off modal');
  }

}
