import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ClassificationDetail} from '../model/classification-detail';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {SelectItem, TreeNode} from 'primeng/api';
import {ClassificationHeader} from '../model/classification-header';
import {ProjectReportPeriod} from "../model/project-report-period";


@Injectable()
export class ClassificationDetailService {

    private baseApiUrl = globals.BASE_API_URL;
    private classificationDetailsUrl = this.baseApiUrl + 'classificationDetails';  // URL to web api
    private orgUniteUrl = this.baseApiUrl + 'organizationunit';  // URL to web api
    private classificationDetailTreeUrl = this.baseApiUrl + 'classificationDetailTree';
    private classificationDetailPaternTreeUrl = this.baseApiUrl + 'classificationDetailPaternTree';
    private classificationHeadersUrl = this.baseApiUrl + 'classificationHeaders';

    constructor(private httpClient: HttpClient) {
    }

    getAllClassificationDetails(): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetails(cldClhId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldClhId?cldClhId=' + cldClhId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    classificationDetailsLoadQuery(): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsLoadQuery').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetail(id: number): Observable<ClassificationDetail> {
        return this.httpClient.get<ResponseWrapper>(`${this.classificationDetailsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.classificationDetailsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(cld: ClassificationDetail): Observable<ClassificationDetail> {
        return this.httpClient
            .post<ResponseWrapper>(this.classificationDetailsUrl, JSON.stringify(cld)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(classificationDetail: ClassificationDetail): Observable<ClassificationDetail> {
        const url = `${this.classificationDetailsUrl}/${classificationDetail.cldId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(classificationDetail)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.classificationDetailsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(classificationDetail) {

        if (classificationDetail.cldSysCreateDate) {
            classificationDetail.cldSysCreateDate = new Date(classificationDetail.cldSysCreateDate);
        }
        if (classificationDetail.cldSysUpdateDate) {
            classificationDetail.cldSysUpdateDate = new Date(classificationDetail.cldSysUpdateDate);
        }
        if (classificationDetail.cldSysSyncTst) {
            classificationDetail.cldSysSyncTst = new Date(classificationDetail.cldSysSyncTst);
        }
        return classificationDetail;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start
    getClassificationDetailsByCasId(casId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCasId?casId=' + casId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCltId(cldCltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldCltId?cldCltId=' + cldCltId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsByCltIdLevel2(cldCltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCltIdLevel2?cldCltId=' + cldCltId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCltIdAndMsrId(cldCltId: number, msrId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldCltId?cldCltId=' + cldCltId + '&msrId=' + msrId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCltIdAndPclId(cldCltId: number, pclId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCltIdAndPclId?cldCltId=' + cldCltId + '&pclId=' + pclId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getMeasureReportingSectorsByMsrIdAndCldId(msrId, cldId): Observable<SelectItem[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'measureReportingSectorsSelectItem?msrId=' + msrId + '&cldId=' + cldId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCltIdSpecificLevel(cldCltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldCltIdSpecificLevel?cldCltId=' + cldCltId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getByCstIdAndOruId(cstId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCstIdAndOruId?cstId=' + cstId.toString()).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByOruAndType(oruId: number, cltType: string): Observable<ClassificationDetail[]> {
        const params = new HttpParams().set('oruId', oruId + '').set('cltType', cltType);
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + '/loadByOruIdAndType', {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsItems(): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + '/items').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    // getClassificationOrgUniteItem(): Observable<CaseType> {
    //   return this.httpClient.get<ResponseWrapper>(this.orgUniteUrl).pipe(
    //     map(rw => {
    //       return rw.data;
    //     }),
    //     retry(3),
    //     catchError(this.handleError)
    //   );
    // }

    getClassificationDetailTree(clhId: number): Observable<TreeNode[]> {
        const params = new HttpParams().set('clhId', clhId.toString());
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailTreeUrl, {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailPaternTree(clhId: number, patern: string): Observable<TreeNode[]> {
        const params = new HttpParams().set('clhId', clhId.toString()).set('patern', patern);
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailPaternTreeUrl, {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailPceTree(casId: number): Observable<TreeNode[]> {
        const params = new HttpParams().set('casId', casId.toString());
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailPceTree', {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsByCldLevel0Id(cldId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCldLevel0Id?cldId=' + cldId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsFindByCasId(casId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsFindByCasIdCost?casId=' + casId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCldLevel0IdByCasId(casId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsByCldLevel0IdByCasId?casId=' + casId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsFindCountries(): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + 'FindCountries').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // getForFieldType(cltFieldId: number): Observable<ClassificationDetailsFieldType[]> {
    //   return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + '/fieldType/' + cltFieldId).pipe(
    //     map(rw => {
    //       return rw.data;
    //     }),
    //     retry(3),
    //     catchError(this.handleError)
    //   );
    // }

    getForCldIds(cldIds: number[]): Observable<ClassificationDetail[]> {
        let params = new HttpParams();
        params = params.append('cldIds', cldIds.toString());
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl, {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationHeadersForClassificationTypeAndOru(cltId: number, oruId: number): Observable<ClassificationHeader[]> {
        const params = new HttpParams().set('cltId', cltId.toString()).set('oruId', oruId.toString());
        return this.httpClient.get<ResponseWrapper>(this.classificationHeadersUrl + '/forTypeAndOru', {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getClassificationDetailsByCldLevelFix(): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + 'ByCldLevelFix').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // poziv liste samo za dijalog Sifre Kvalifikacije na formi detalja Zaposleni ili Kandidati gdje
    // se vraca lista bez uslova where cld_level = 0
    getClassificationDetailsByCltIdWithoutCldLevel(cldCltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldCltIdWithoutCldLevel?cldCltId=' + cldCltId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsByWorkingEnvironmentDetailIdAndCltId(wedId: number, cltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.classificationDetailsUrl}/ByWorkingEnvironmentDetailAndCltId/${wedId}/${cltId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getClassificationDetailsByCltIdAllCldLeveles(cldCltId: number): Observable<ClassificationDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.baseApiUrl + 'classificationDetailsCldCltId/allCldLevels?cldCltId=' + cldCltId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getDefaultClassificationDetail(cldClhId: number): Observable<ClassificationDetail> {
        return this.httpClient.get<ResponseWrapper>(this.classificationDetailsUrl + 'ByDefault?cldClhId=' + cldClhId).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }


    getClassificationDetailDynamicDialog(sqlQuery): Observable<ClassificationDetail[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.classificationDetailsUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }




    // custom-end

}



