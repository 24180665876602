import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Form} from '../../../../../../model/form';
import {FormService} from '../../../../../../service/form.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-form-csc3780selection-dialog',
    templateUrl: './form-csc3780selection-dialog.component.html',
    styleUrls: ['./form-csc3780selection-dialog.component.css']
})
export class FormCsc3780selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public forms: Form[];

    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSelected = new EventEmitter<Form>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'forName': null, 'forDesc': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'form';

    constructor(private formService: FormService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getForms();
    }


    selectFor(obj: any) {
        this.selectForCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getForms(): void {
        this.getFormsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getFormsCustom() {
        this.formService
            .getForms()
            .subscribe(forms => this.forms = forms, error => this.errorMessage = <any> error);
    }

    selectForCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
