import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Sro} from '../../../../../../model/sro';
import {SroService} from '../../../../../../service/sro.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {ProjectSro} from '../../../../../../model/project-sro';
import {ProjectSroService} from '../../../../../../service/project-sro.service';

@Component({
    selector: 'app-project-sro-csc1100selection-dialog',
    templateUrl: './project-sro-csc1100selection-dialog.component.html',
    styleUrls: ['./project-sro-csc1100selection-dialog.component.css']
})
export class ProjectSroCsc1100selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public projectSros: ProjectSro[];
    @Output() onSelected = new EventEmitter<Sro>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'sro.sroCode': null, 'sro.sroName': null, 'pjsNo': null};

    exportCols = ['sro.sroCode', 'sro.sroName', 'pjsNo'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'project-sro-csc1100selection-dialog';

    constructor(private projectSroService: ProjectSroService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
      //  this.getSros();
    }


    selectProjectSro(obj: any) {
        this.selectProjectSroCustom(obj);
    }

    showDlg() {
        this.visible = true;
        this.clearFilters();
    }

    getProjectSros(): void {
        this.getProjectSrosCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProjectSrosCustom() {
        this.projectSroService
            .getProjectSros()
            .subscribe(projectSros => {
                this.projectSros = projectSros;
                this.projectSros.sort((pso1, pso2) => {
                   return pso1.sro.sroCode.localeCompare(pso2.sro.sroCode);
                });
            }, error => this.errorMessage = <any> error);
    }

    selectProjectSroCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    showDlgByProjectHeaderId(projectHeaderId: number) {
        this.clearFilters();
        this.getProjectSrosByProjectHeaderId(projectHeaderId);
    }

    getProjectSrosByProjectHeaderId(projectHeaderId: number) {
        this.projectSroService
            .getProjectSrosByProjectHeaderId(projectHeaderId)
            .subscribe(projectSros => {
                this.projectSros = projectSros;
                // this.projectSros.sort((pso1, pso2) => {
                //     return pso1.sro.sroCode.localeCompare(pso2.sro.sroCode);
                // });
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }


    // custom-end

}
