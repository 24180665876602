import {Component, Inject, OnInit} from '@angular/core';
import {Account} from '../account';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth.service';
import {LoginDTO} from '../../bo/model/sys/login-dto';
import {MessageService} from '../../core/message.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: 'login1.component.html',
  styleUrls: ['login.component.css'],
})

export class LoginComponent implements OnInit {
  public account: LoginDTO;
  public errorMessage: string;

  /*
  constructor(private _authService: LoginService, private _globalEventsManager: GlobalEventsManager) {
  } */

  constructor(private _authService: AuthService, private _router: Router, private _messageService: MessageService,
              @Inject(DOCUMENT) private document: Document) {
    this.document.body.classList.add('login-body');
  }

  ngOnInit() {
    /*this._authService.logout();*/
    this.account = new LoginDTO;
  }

  login() {
    console.log('onSubmit');

    /*if(!(this.account.accUsername === 'admin' && this.account.accPassword === 'password')) {
      this.errorMessage = 'Invalid username or password!';
    }
    else {
      this._authService.loginDummy();
      this._router.navigate(['home']);
    }*/

    this._authService.login(this.account).subscribe(
      res => {
        console.log('success');
      },
      err => {
        // this.errorMessage = err;
        console.log(err);
        // this._messageService.showFailMsg('LOGIN_ERROR');
        if (err.status === 504) {
          this._messageService.showFailMsg('SERVICE_UNAVAILABLE');
          // } else if (err.status == 401) {
          //   if (err.error && err.error.error && err.error.error === 'ACCOUNT_DISABLED') {
          //     this._messageService.showFailMsg('ACCOUNT_DISABLED');
          //     break;
          //   } else if (err.error && err.error.error && err.error.error === 'PASSWORD_EXPIRED') {
          //     this._messageService.showFailMsg('PASSWORD_EXPIRED');
          //   } else {
          //     this._messageService.showFailMsg('LOGIN_BAD_CREDENTIALS');
          //   }
          // }
        // } else if (err.status === 401) {
          //         //   if (err.error && err.error.error ) {
          //         //     switch (err.error.error) {
          //         //       case 'ACCOUNT_DISABLED' : {
          //         //         this._messageService.showFailMsg('ACCOUNT_DISABLED');
          //         //         break;
          //         //       }
          //         //       case 'PASSWORD_EXPIRED' : {
          //         //         this._messageService.showFailMsg('PASSWORD_EXPIRED');
          //         //         break;
          //         //       }
          //         //       default: {
          //         //         this._messageService.showFailMsg('LOGIN_BAD_CREDENTIALS');
          //         //         break;
          //         //       }
          //         //     }
          //         //
          //         //   }
          //         // }

        } else if (err.status === 401 || err.status === 500) {
          if (err.error && err.error.error ) {
            switch (err.error.error) {
              case 'ACCOUNT_DISABLED' : {
                this._messageService.showFailMsg('ACCOUNT_DISABLED');
                break;
              }
              case 'PASSWORD_EXPIRED' : {
                this._messageService.showFailMsg('PASSWORD_EXPIRED');
                break;
              }
              default: {
                this._messageService.showFailMsg('LOGIN_BAD_CREDENTIALS');
                break;
              }
            }

          } else if (err.error) {
            if (err.error instanceof ErrorEvent) {
              switch (err.error.message.trim()) {
                case 'ACCOUNT_DISABLED' : {
                  this._messageService.showFailMsg('ACCOUNT_DISABLED');
                  break;
                }
                case 'PASSWORD_EXPIRED' : {
                  this._messageService.showFailMsg('PASSWORD_EXPIRED');
                  break;
                }
                default: {
                  this._messageService.showFailMsg('LOGIN_BAD_CREDENTIALS');
                  break;
                }
              }
            } else {
              this._messageService.showFailMsg('LOGIN_BAD_CREDENTIALS');
            }
          }
        }
      }
    );

  }


  /*
  login() {
    console.log('onSubmit');
    this._authService.invokeLoginOp(this.account.accUsername, this.account.accPassword).subscribe(
      res => {
        console.log('success');
      },
      err => {
        this.errorMessage = err;
      }
    );
  }*/

}
