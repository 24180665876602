import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProgrammeDocumentType} from '../model/programme-document-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ProgrammeDocumentTypeService {

    private baseApiUrl = globals.BASE_API_URL;
    private programmeDocumentTypesUrl = this.baseApiUrl + 'programmeDocumentTypes';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProgrammeDocumentTypes(): Observable<ProgrammeDocumentType[]> {
        return this.httpClient.get<ResponseWrapper>(this.programmeDocumentTypesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProgrammeDocumentType(id: number): Observable<ProgrammeDocumentType> {
        return this.httpClient.get<ResponseWrapper>(`${this.programmeDocumentTypesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.programmeDocumentTypesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(pdt: ProgrammeDocumentType): Observable<ProgrammeDocumentType> {
        return this.httpClient
            .post<ResponseWrapper>(this.programmeDocumentTypesUrl, JSON.stringify(pdt)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(programmeDocumentType: ProgrammeDocumentType): Observable<ProgrammeDocumentType> {
        const url = `${this.programmeDocumentTypesUrl}/${programmeDocumentType.pdtId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(programmeDocumentType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.programmeDocumentTypesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(programmeDocumentType) {

        if (programmeDocumentType.pdtSysSyncTst) {
            programmeDocumentType.pdtSysSyncTst = new Date(programmeDocumentType.pdtSysSyncTst);
        }
        if (programmeDocumentType.pdtSysUpdateDate) {
            programmeDocumentType.pdtSysUpdateDate = new Date(programmeDocumentType.pdtSysUpdateDate);
        }
        if (programmeDocumentType.pdtSysCreateDate) {
            programmeDocumentType.pdtSysCreateDate = new Date(programmeDocumentType.pdtSysCreateDate);
        }
        return programmeDocumentType;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getProgrammeDocumentTypesByPjhId(pjh: number): Observable<ProgrammeDocumentType[]> {
        const url = `${this.programmeDocumentTypesUrl}/ByPjhId/${pjh}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getProgrammeDocumentTypesByFdtId(fdtId: number): Observable<ProgrammeDocumentType> {
        const url = `${this.programmeDocumentTypesUrl}ByFdtId/${fdtId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // 24.02.2022 Nenad: provijera da li postoji slog po sifri fdtId i prhId
    // odnosno ne moze da se snimi duplikat
    createNonDuplicateByFdtIdAndPrhId(pdt: ProgrammeDocumentType): Observable<ProgrammeDocumentType> {
        return this.httpClient
            .post<ResponseWrapper>(this.programmeDocumentTypesUrl + '/NonDuplicateByFdtIdAndPrhId', JSON.stringify(pdt)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    // 24.02.2022 Nenad: provijera da li postoji slog po sifri fdtId i prhId prilikom azuriranja
    // odnosno ne moze da se snimi duplikat
    updateNonDuplicateByFdtIdAndPrhId(programmeDocumentType: ProgrammeDocumentType): Observable<ProgrammeDocumentType> {
        const url = `${this.programmeDocumentTypesUrl + '/NonDuplicateByFdtIdAndPrhId'}/${programmeDocumentType.pdtId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(programmeDocumentType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    // custom-end

}



