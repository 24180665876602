import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrganizationUnit} from '../model/organization-unit';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {SelectItem, TreeNode} from 'primeng/api';


@Injectable()
export class OrganizationUnitService {

  private baseApiUrl = globals.BASE_API_URL;
  private organizationUnitsUrl = this.baseApiUrl + 'organizationUnits';  // URL to web api
  private organizationUnitTreeUrl = this.baseApiUrl + 'organizationUnitTree';  // URL to web api
  private organizationUnitPatternTreeUrl = this.baseApiUrl + 'organizationUnitPatternTree';  // URL to web api

  constructor(private httpClient: HttpClient) {
  }

  getOrganizationUnits(): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getOrganizationUnit(id: number): Observable<OrganizationUnit> {
    return this.httpClient.get<ResponseWrapper>(`${this.organizationUnitsUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.organizationUnitsUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }


  create(oru: OrganizationUnit): Observable<OrganizationUnit> {
    return this.httpClient
      .post<ResponseWrapper>(this.organizationUnitsUrl, JSON.stringify(oru)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(organizationUnit: OrganizationUnit): Observable<OrganizationUnit> {
    const url = `${this.organizationUnitsUrl}/${organizationUnit.oruId}`;
    return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(organizationUnit)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.organizationUnitsUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(organizationUnit) {

    if (organizationUnit.oruDateFrom) {
      organizationUnit.oruDateFrom = new Date(organizationUnit.oruDateFrom);
    }
    if (organizationUnit.oruSysCreateDate) {
      organizationUnit.oruSysCreateDate = new Date(organizationUnit.oruSysCreateDate);
    }
    if (organizationUnit.oruSysUpdateDate) {
      organizationUnit.oruSysUpdateDate = new Date(organizationUnit.oruSysUpdateDate);
    }
    if (organizationUnit.oruDateTo) {
      organizationUnit.oruDateTo = new Date(organizationUnit.oruDateTo);
    }
    if (organizationUnit.oruSysSyncTst) {
      organizationUnit.oruSysSyncTst = new Date(organizationUnit.oruSysSyncTst);
    }
    return organizationUnit;
  }

  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }


  // custom-start


  loadOrganizationUnitSelectItems(): Observable<SelectItem[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + '/selectItems').pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getAllUnderChecked(oruId: number): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + '/allUnder/' + oruId).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getTopLevelOrganizationUnits(): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + '/topLevel').pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  loadOrganizationUnitsByAccId(accId: number): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + 'ByAccId/' + accId).pipe(
      map(rw => {
        console.log('DATATTATATTATATTATTATTATATTATAT ' , rw.data);
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  loadOrganizationUnitsByLevel(level: number): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + '/level/' + level).pipe(
      map(rw => {
        console.log('DATATTATATTATATTATTATTATATTATAT ' , rw.data);
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getOrganizationUnitsByCurrentOruId(): Observable<OrganizationUnit[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + '/current').pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  findTreeByCurrentOruId(): Observable<any[]> {
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitsUrl + 'Tree').pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getOrganizationUnitTree(oruId: number): Observable<TreeNode[]> {
    const params = new HttpParams().set('oruId', oruId.toString());
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitTreeUrl, {params: params}).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }


  getOrganizationPatternTree(oruLevel0Id: number, pattern: string): Observable<TreeNode[]> {
    const params = new HttpParams().set('oruLevel0Id', oruLevel0Id.toString()).set('pattern', pattern);
    return this.httpClient.get<ResponseWrapper>(this.organizationUnitPatternTreeUrl, {params: params}).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }


  // custom-end

}



