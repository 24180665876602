import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Table} from 'primeng/table';

import {VCostTypeList} from '../../../model/v-cost-type-list';
import {VCostTypeListService} from '../../../service/v-cost-type-list.service';
import {BaseListComponent} from '../../../base/base-list/base-list.component';

@Component({
    selector: 'app-sro-csc1177selection-dialog',
    templateUrl: './sro-csc1177selection-dialog.component.html',
    styleUrls: ['./sro-csc1177selection-dialog.component.css']
})
export class SroCsc1177selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public sros: VCostTypeList[];

    // @Input('fthId') fthId: number;

    @Output() onSelected = new EventEmitter<VCostTypeList>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'ctpOrder': null, 'ctpName': null};

    exportCols = ['ctpOrder','ctpName'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'v-cost-type-list';

    constructor(private ctpService: VCostTypeListService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
    }


    selectSro(obj: any) {
        this.selectCtpCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    showDlgByFthId(fthId: number) {
        this.clearFilters();
        this.getVCostTypeListByFthId(fthId);
    }

    getVCostTypeListByFthId(fthId: number) {
        this.ctpService.getVCostTypeListByFthId(fthId).subscribe(
            res => {
                this.sros = res;
                this.visible = true;
            },error => this.errorMessage = <any> error
        );
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    selectCtpCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
