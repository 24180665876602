import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ReportTemplateMapper} from '../model/report-template-mapper';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ReportTemplateMapperService {

    private baseApiUrl = globals.BASE_API_URL;
    private reportTemplateMappersUrl = this.baseApiUrl + 'reportTemplateMappers';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getReportTemplateMappers(): Observable<ReportTemplateMapper[]> {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplateMappersUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getReportTemplateMapper(id: number): Observable<ReportTemplateMapper> {
        return this.httpClient.get<ResponseWrapper>(`${this.reportTemplateMappersUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.reportTemplateMappersUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(rtm: ReportTemplateMapper): Observable<ReportTemplateMapper> {
        return this.httpClient
            .post<ResponseWrapper>(this.reportTemplateMappersUrl, JSON.stringify(rtm)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(reportTemplateMapper: ReportTemplateMapper): Observable<ReportTemplateMapper> {
        const url = `${this.reportTemplateMappersUrl}/${reportTemplateMapper.rtmId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(reportTemplateMapper)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.reportTemplateMappersUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(reportTemplateMapper) {

        if (reportTemplateMapper.rtmSysCreateDate) {
            reportTemplateMapper.rtmSysCreateDate = new Date(reportTemplateMapper.rtmSysCreateDate);
        }
        if (reportTemplateMapper.rtmSysSyncTst) {
            reportTemplateMapper.rtmSysSyncTst = new Date(reportTemplateMapper.rtmSysSyncTst);
        }
        if (reportTemplateMapper.rtmSysUpdateDate) {
            reportTemplateMapper.rtmSysUpdateDate = new Date(reportTemplateMapper.rtmSysUpdateDate);
        }
        if (reportTemplateMapper.rtmSysUpdateWho) {
            reportTemplateMapper.rtmSysUpdateWho = new Date(reportTemplateMapper.rtmSysUpdateWho);
        }
        return reportTemplateMapper;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    findAllMacroCodes(): Observable<string[]> {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplateMappersUrl + '/macrocodes').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    findAllMacroCodesPrefix(): Observable<string[]> {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplateMappersUrl + '/macrocodesPrefix').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // vracanje podataka za padajuci meni rtm_table
    private tableLogNameListUrl = this.baseApiUrl + 'getTableName';

    getReportTemplateMappersTableName(): Observable<ReportTemplateMapper> {
        return this.httpClient.get<ResponseWrapper>(this.tableLogNameListUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(10),
            catchError(this.handleError)
        );
    }

    // custom-end

}



