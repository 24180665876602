import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Sro} from '../../../../../../model/sro';
import {SroService} from '../../../../../../service/sro.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {CommonCodeService} from '../../../../../../../core/common-code.service';
import {environment} from '../../../../../../../../environments/environment';

@Component({
    selector: 'app-sro-csc1100selection-dialog',
    templateUrl: './sro-csc1100selection-dialog.component.html',
    styleUrls: ['./sro-csc1100selection-dialog.component.css']
})
export class SroCsc1100selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public sros: Sro[];
    @Output() onSelected = new EventEmitter<Sro>();
    public errorMessage: string;
    visible = false;

    commonCodeTypes: string[] = ['sro_group'];
    commonCodes: any = {};

    tfFilterMap = {'sroCode': null, 'sroName': null, 'sroGroupEncoded': null};

    exportCols = ['sroCode', 'sroName', 'sroGroupEncoded'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'sro';

    constructor(private sroService: SroService,
                private commonCodeService: CommonCodeService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getCommonCodes();
    }


    selectSro(obj: any) {
        this.selectSroCustom(obj);
    }

    showDlg() {
        this.getSros();
        this.clearFilters();
    }

    // showDlgByPjhId(pjhId: number) {
    //     this.clearFilters();
    //     this.getSrosByPjhId(pjhId);
    // }

    // getSrosByPjhId(pjhId: number) {
    //     this.sroService.getSroByPjhId(pjhId).subscribe(
    //         res => {
    //             this.sros = res.map(sro => {
    //                 try {
    //                     const ccd = this.commonCodes['sro_group'].find(sroGroup => sroGroup.value === sro.sroGroup);
    //                     sro.sroGroupEncoded = sro.sroGroup && ccd.label;
    //                 } catch(e) {};
    //                 return sro;
    //             });
    //             this.visible = true;
    //         },error => this.errorMessage = <any> error
    //     );
    // }

    // showDlgDynamic(sqlQuery) {
    //     this.getSrosDynamicDialog(sqlQuery);
    //     this.clearFilters();
    // }

    getSros(): void {
        this.getSrosCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getSrosCustom() {
        this.sroService
            .getSros()
            .subscribe(sros => {
                this.sros = sros.map(sro => {
                    try {
                        const ccd = this.commonCodes['sro_group'].find(sroGroup => sroGroup.value === sro.sroGroup);
                        sro.sroGroupEncoded = sro.sroGroup && ccd.label;
                    } catch(e) {};
                    return sro;
                });
                this.sros.sort((s1, s2) => {
                    return s1.sroCode.localeCompare(s2.sroCode);
                });
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    // getSrosDynamicDialog(sqlQuery) {
    //     this.sroService
    //         .getSrosDynamicDialog(sqlQuery)
    //         .subscribe(sros => {
    //             this.sros = sros;
    //             // this.sros.sort((s1, s2) => {
    //             //     return s1.sroCode.localeCompare(s2.sroCode);
    //             // });
    //             debugger;
    //             this.visible = true;
    //         }, error => this.errorMessage = <any> error);
    // }

    selectSroCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    getCommonCodes() {
        if (environment.SHOW_CONSOLE_MSG) {
            console.log(this.commonCodeTypes);
        }
        this.commonCodeTypes.map(t => this.commonCodeService.findByType(t).subscribe(
            result => {
                this.commonCodes[t] = result;
            }));
    }


    // custom-end

}
