import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {
    InputTextModule
} from 'primeng/inputtext';
import {
    TabViewModule
} from 'primeng/tabview';
import {
    ButtonModule
} from 'primeng/button';
import {
    PanelModule
} from 'primeng/panel';
import {
    ContextMenuModule
} from 'primeng/contextmenu';
import {
    RadioButtonModule
} from 'primeng/radiobutton';
import {
    ScrollPanelModule
} from 'primeng/scrollpanel';

import {SharedModule as PngSharedModule} from 'primeng/shared';
import {TranslateModule} from '@ngx-translate/core';
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {DataScrollerModule} from 'primeng/datascroller';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TreeModule} from 'primeng/tree';
import {InputTextareaModule} from 'primeng/inputtextarea';

import {InputSwitchModule} from 'primeng/inputswitch';
import {DataGridModule} from 'primeng/datagrid';
import {DataListModule} from 'primeng/datalist';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {FieldsetModule} from 'primeng/fieldset';
import {
    AccordionModule, MultiSelectModule, SelectButtonModule, TreeTableModule,
    TriStateCheckboxModule
} from 'primeng/primeng';
import {NumDirective} from './num.directive';
import {MyCurrencyFormatterDirective} from './currency.directive';
import {MyCurrencyPipe} from './my-currency.pipe';
import {MessagesModule} from 'primeng/messages';
import {MessageModule, KeyFilterModule} from 'primeng/primeng';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {JmbgValidatorDirective} from '../bo/validator/jmbg-validator.directive';
import {AddRowDirective} from "./add-row.directive";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        InputTextModule,
        // DataTableModule,
        PngSharedModule,
        TranslateModule,
        TabViewModule,
        PanelModule,
        ButtonModule,
        DropdownModule,
        AutoCompleteModule,
        DialogModule,
        CheckboxModule,
        CalendarModule,
        DataScrollerModule,
        TooltipModule,
        TreeModule,
        ContextMenuModule,
        OverlayPanelModule,
        InputTextareaModule,
        DataListModule,
        DataGridModule,
        InputSwitchModule,
        SplitButtonModule,
        TableModule,
        ScrollPanelModule,
        AccordionModule,
        SelectButtonModule,
        MultiSelectModule,
        TriStateCheckboxModule,
        FieldsetModule,
        MessagesModule,
        MessageModule,
        KeyFilterModule
    ],
    declarations: [
        NumDirective,
        MyCurrencyFormatterDirective,
        AddRowDirective,
        MyCurrencyPipe,
        MessageDialogComponent
    ],
  exports: [
    CommonModule,
    FormsModule,
    NumDirective,
    InputTextModule,
    // DataTableModule,
    PngSharedModule,
    TranslateModule,
    TabViewModule,
    PanelModule,
    ButtonModule,
    DropdownModule,
    AutoCompleteModule,
    DialogModule,
    CheckboxModule,
    CalendarModule,
    DataScrollerModule,
    TooltipModule,
    TreeModule,
    ContextMenuModule,
    OverlayPanelModule,
    InputTextareaModule,
    DataListModule,
    DataGridModule,
    InputSwitchModule,
    RadioButtonModule,
    SplitButtonModule,
    TableModule,
    ScrollPanelModule,
    AccordionModule,
    SelectButtonModule,
    MultiSelectModule,
    TriStateCheckboxModule,
    FieldsetModule,
    TreeTableModule,
    MessageDialogComponent,
    AddRowDirective
  ]
})
export class SharedModule {
}
