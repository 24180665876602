import {Injectable} from '@angular/core';
import {BrowserStorageOptions, BrowserStorageService} from 'angular-browser-storage';

const storageOptions: BrowserStorageOptions = {
    default: 'session',
    obfuscate: true,
    salt: 'by_Fx_fvnL7m',
};

@Injectable()
export class PersistanceService {

    constructor(private storage: BrowserStorageService) {
        this.storage.configure(storageOptions);
    }

    set(key: string, data: any, expired: number = 0): void {
        try {
            this.storage.set(key, data, 'session');
        } catch (e) {
            console.error('Error saving to sessionStorage', e);
        }
    }

    get(key: string) {
        try {
            if (this.storage.has(key)) {
                return this.storage.get(key, 'session');
            }
        } catch (e) {
            console.error('Error getting data from sessionStorage', e);
            return null;
        }
    }

    remove(key: string) {
        try {
            this.storage.remove(key, 'session');
        } catch (e) {
            console.error('Error removing data from localStorage', e);
            return null;
        }
    }

    clear() {
        this.storage.clear('session');
    }

    getObserver(key: string) {
        return this.storage.getObserver(key);
    }

    trigger(key: string) {
        this.storage.trigger(key);
    }
}
