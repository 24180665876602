import {ProjectHeader} from './project-header';
// import {ProjectStructureElement} from './project-structure-element';
import {Status} from './status';
import {Account} from './account';
import {ProjectReportPeriod} from "./project-report-period";


export class ProjectStructureElement {

    label?: string;

    pseSysUpdateDate?: Date;

    pseSysSyncTst?: Date;

    pseId: number;

    pseSysCreateDate?: Date;

    pseDescription?: string;

    pseType: string;

    pseCode: string;

    // projectHeader: ProjectHeader;
    // projectStructureElement: ProjectStructureElement;
    status: Status;
    accountSysCreateWho: Account;
    accountSysUpdateWho: Account;


    // custom-start

    children: ProjectStructureElement[];

    parentId?: number;

    psePjhId: number;

    pseSort?: number;

    projectReportPeriods: ProjectReportPeriod[];

// custom-end

    constructor() {
    }
}

