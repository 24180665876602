import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-login-layout',
  template: `<router-outlet></router-outlet>`,
  styleUrls: []
})
export class LoginLayoutComponent {}





