declare var Quill: any;

var Embed = Quill.import('blots/embed');
export class Span extends Embed {

  static create(value) {
    let node = super.create(value);
    console.log('value ', value);
    // node.setAttribute('class', 'complex');
    return value;
    /*let node = super.create(value);
    console.log('node' , node);
    // node.appendChild(document.createTextNode('milica'));
    node.appendChild(value);

    node.setAttribute('class', 'class-1');

    return node;*/
  }

  format(name, value) {
    console.log(name);
    console.log(value);
    super.format(name, value);
  }

  static value(domNode) {
    console.log('valueeeeeeeeeee', domNode);
    return domNode;
  }

  extend(target, base) {
    for (var prop in base) {
      target[prop] = base[prop];
    }
  }

}
