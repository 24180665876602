import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {Form} from '../model/form';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class FormService {

  private baseApiUrl = globals.BASE_API_URL;
  private formsUrl = this.baseApiUrl + 'forms';  // URL to web api

  constructor(private httpClient: HttpClient) {
  }

  getForms(): Observable<Form[]> {
    return this.httpClient.get<ResponseWrapper>(this.formsUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getForm(id: number): Observable<Form> {
    return this.httpClient.get<ResponseWrapper>(`${this.formsUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.formsUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  create(form: Form): Observable<Form> {
    return this.httpClient
      .post<ResponseWrapper>(this.formsUrl, JSON.stringify(form)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(form: Form): Observable<Form> {
    const url = `${this.formsUrl}/${form.forId}`;
    return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(form)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.formsUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(form) {

    if (form.forSysCreateDate) {
      form.forSysCreateDate = new Date(form.forSysCreateDate);
    }
    if (form.forSysSyncTst) {
      form.forSysSyncTst = new Date(form.forSysSyncTst);
    }
    if (form.forSysUpdateDate) {
      form.forSysUpdateDate = new Date(form.forSysUpdateDate);
    }
    return form;
  }

  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }


  // custom-start


  // custom-end

}



