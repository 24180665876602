import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {map, catchError, retry, first} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {timer} from 'rxjs/internal/observable/timer';
import {of} from 'rxjs/internal/observable/of';
import * as globals from '../globals/globals';
import Utils from '../utils/utils';


import {PersistanceService} from './persistance.service';

@Injectable()
export class Privileges {

    private baseApiUrl = globals.BASE_API_URL;

    constructor(
        private router: Router,
        private persistanceService: PersistanceService) {
    }

    admin(): boolean {
        let form = this.persistanceService.get('gir-forms')[this.router.url];
        let priv = this.persistanceService.get('hasAdmin');
        return priv === true && form === true;
    }


    create(): boolean {
        let form = this.persistanceService.get('gir-forms')[this.router.url];
        let priv = this.persistanceService.get('hasCreate');
        return priv === true && form === true;

    }

    update(): boolean {
        let form = this.persistanceService.get('gir-forms')[this.router.url];
        let priv = this.persistanceService.get('hasEdit');
        return priv === true && form === true;

    }

    seeAllOrg(): boolean {
        let form = this.persistanceService.get('gir-forms')[this.router.url];
        let priv = this.persistanceService.get('hasSeeAllOrg');
        return priv === true && form === true;

    }

    view(): boolean {
        let form = this.persistanceService.get('gir-forms')[this.router.url];
        let priv = this.persistanceService.get('hasView');
        return priv === true && form === true;

    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


}
