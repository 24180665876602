import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Account} from '../bo/model/account';
import {BaseListComponent} from '../bo/base/base-list/base-list.component';
import {AuthService} from '../core/auth.service';
import {AccountService} from '../bo/service/account.service';
import {MessageService} from '../core/message.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseDetailComponent} from '../bo/base/base-detail/base-detail.component';
import {Router} from '@angular/router';


@Component({
    selector: 'app-change-password',
    templateUrl: 'change-password.component.html',
    styleUrls: ['change-password.component.css']
})
export class ChangePasswordComponent extends BaseDetailComponent implements OnInit {

    public errorMessage;

    public selectedObj: any;

    selectedAccount: Account;

    visible = false;

    old_passwords: string;
    new_passwords: string;
    confirm_passwords: string;

    constructor(private authService: AuthService, private _accountService: AccountService,
                private _messageService: MessageService, private _translateService: TranslateService, private _router: Router) {
        super(null);
        this.authService.getUser()
            .subscribe(
                result => {
                    this.selectedAccount = result;
                }
            );
    }

    ngOnInit(): void {
    }

    showDlg() {
        this.visible = true;
    }

    onSubmit() {
        console.log('CHANGE PASSWORD');
        if (this.checkPasswordMatch(this.confirm_passwords, this.new_passwords)) {
            this.btnSaveDisabled = true;
            this._accountService.updatePasswordOnExp(this.selectedAccount, this.new_passwords).subscribe(res => {
                    this.selectedAccount = res;
                    this.btnSaveDisabled = false;
                    setTimeout(() => {
                        this.formChanged = false;
                    }, 500);
                    this._messageService.showSuccessSaveMsg();
                    this.visible = false;
                    this.authService.logout();
                    this._router.navigate(['login']);
                },
                error => {
                    this.errorMessage = <any> error;
                    if (this.errorMessage.includes('Password NOT matches')) {
                        this._translateService
                            .get('OLD_PASSWORD_NOT_GOOD').subscribe((res: string) => {
                                this.errorMessage = res.toString();
                            }
                        );
                        this._messageService.showFailMsgText(this.errorMessage);
                    } else if (this.errorMessage.includes('You cannot use old password')) {
                        this._translateService
                            .get('CANNOT_USE_OLD_PASSWORD').subscribe((res: string) => {
                                this.errorMessage = res.toString();
                            }
                        );
                        this._messageService.showFailMsgText(this.errorMessage);
                    } else if (this.errorMessage.includes('Password contains block from username, name or surname')) {
                        this._translateService
                            .get('CANNOT_USE_BLOCK_PASSWORD').subscribe((res: string) => {
                                this.errorMessage = res.toString();
                            }
                        );
                        this._messageService.showFailMsgText(this.errorMessage);
                    } else {
                        this._messageService.showFailMsgText(this.errorMessage);
                    }
                    this.btnSaveDisabled = false;
                });
        }
        this.visible = false;
    }

    checkPasswordMatch(password1: string, password2: string): boolean {
        if (password1 === password2) {
            return true;
        } else {
            this.errorMessage = 'Лозинке морају бити идентичне';
            this._messageService.PasswordNotMatchingMsg();
            return false;
        }
    }

}
