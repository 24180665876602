import {Status} from './status';
import {Account} from './account';


export class ReportTemplate {

    rptId: number;

    rptCode?: string;

    rptDescription?: string;

    rptCategory?: string;

    rptHeader?: string;

    rptSubject?: string;

    rptFooter?: string;

    rptVersion?: string;

    status: Status;

    rptFile?: string;

    accountSysCreateWho: Account;

    rptSysCreateDate?: Date;

    // rptSysCreateWho?: number;

    // rptSysUpdateWho?: number;

    accountSysUpdateWho: Account;

    rptSysUpdateDate?: Date;

    rptSysSyncTst?: Date;


    // rptIncludeSignatureTemplate?: boolean;

    // custom-start


// custom-end

    constructor() {
    }
}

