import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription, forkJoin} from 'rxjs';
import {TabControllerService} from '../../../../../../../../core/tab-controller.service';
import {BoInteractionService} from '../../../../../../../../shared/bo-interaction.service';
import {MessageService} from '../../../../../../../../core/message.service';
import {Router} from '@angular/router';
import {ComponentCanDeactivate} from '../../../../../../../../guards/pending-changes.guard';
import {environment} from '../../../../../../../../../environments/environment';
// tslint:disable-next-line:max-line-length
import {ReportTemplateCsc2760listComponent} from '../../../../../report-template/c/sc/report-template-csc2760list/report-template-csc2760list.component';
// tslint:disable-next-line:max-line-length
import {ReportTemplateCsc2770detailComponent} from '../../../../../report-template/c/sc/report-template-csc2770detail/report-template-csc2770detail.component';


@Component({
  selector: 'app-report-template-csc1920main',
  templateUrl: './report-template-csc1920main.component.html',
  styleUrls: ['./report-template-csc1920main.component.css'],
  providers: [TabControllerService, BoInteractionService]
})

export class ReportTemplateCsc1920mainComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  public tabIndex = 0;
  public tabIndex1 = 0;
  public tabIndex2 = 0;
  public tabIndex3 = 0;
  public tabIndex4 = 0;
  tab000 = true;
  tab001 = false;
  tab002 = false;
  tab003 = false;

  subscriptionTabIndex: Subscription;
  subscriptionTab001: Subscription;
  subscriptionTab002: Subscription;
  subscriptionTab003: Subscription;

  private componentChanged = false;
  @ViewChild('csc2760list', {static: false}) cmpCsc2760list: ReportTemplateCsc2760listComponent;
  @ViewChild('csc2770detail', {static: false}) cmpCsc2770detail: ReportTemplateCsc2770detailComponent;


  // private cmpRef: any;
  // private cmpRefChangeTracking = false;
  private cmpRefs = [];
  private cmpRefsChangeTracking = [];

  displaySaveDlg = false;

  private continueTabIndex = 0;
  private continueTabIndex1 = 0;
  private continueTabIndex2 = 0;
  private continueTabIndex3 = 0;
  private continueTabIndex4 = 0;

  private showDetailFormForcsc2760list = false;


  private continueRedirectLink;

  public actionMode: string;

  // "0" : {"cmpName" : 'cmpHrmmda1440list', changeTracking : false }, "1" : {"cmpName" : 'cmpHrmmda1450detail', changeTracking : true}
  private mapCmpRef = {
    'app-report-template-csc2760list': {'cmpName': 'cmpCsc2760list', changeTracking: false},
    'app-report-template-csc2770detail': {'cmpName': 'cmpCsc2770detail', changeTracking: false}
  };

  constructor(private tabControllerService: TabControllerService,
              private interactionService: BoInteractionService,
              private messageService: MessageService,
              private router: Router) {
    this.subscriptionTabIndex = tabControllerService.tabIndex$.subscribe(
      tabIndexV => {
        this.tabIndex = tabIndexV;
        setTimeout(() => {
            // this.assignCmpRef();
            this.deactivatePreviousAndActivateNew();
          }, 500
        );
      }
    );

    this.subscriptionTab001 = tabControllerService.tab001$.subscribe(
      r => {
        this.tab001 = r;
      }
    );

    this.subscriptionTab002 = tabControllerService.tab002$.subscribe(
      r => {
        this.tab002 = r;
      }
    );

    this.subscriptionTab003 = tabControllerService.tab003$.subscribe(
      r => {
        this.tab003 = r;
      }
    );
  }

  ngOnInit() {
  }

  onTabChange(event) {
    this.actionMode = 'tab';
    let oldTabIndex = this.tabIndex;
    this.tabIndex = event.index;
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('handleChangeeee ' + event.index);
    }
    // console.log('cmpRef');
    // console.log(this.cmpRef);

    // check if changed, if true, back to previous tab and show dialog
    if (this.cmpRefs) {
      for (let i = 0; i < this.cmpRefs.length; i++) {
        let cr = this.cmpRefs[i];
        if (typeof cr.isFormChanged === 'function') {
          this.componentChanged = cr.isFormChanged();
        }
        // console.log(this.componentChanged);
        if (this.componentChanged) {
          this.displaySaveDlg = true;
          setTimeout(() => {
            this.tabIndex = oldTabIndex;
          }, 200);
          break;
        } else {
          this.displaySaveDlg = false;
        }
      }
    }

    // if current component not changed, go to next tab
    if (this.displaySaveDlg === false) {
      setTimeout(() => {
        this.deactivatePreviousAndActivateNew();
      }, 200);
    }
  }

  onReportTemplateCsc2760listEvent(obj) {
    this.onReportTemplateCsc2760listEventCustom(obj);
  }

  onReportTemplateCsc2770detailEvent(obj) {
    this.onReportTemplateCsc2770detailEventCustom(obj);
  }


  /*
  save() {
    console.log('save');
    if(this.tabIndex == 1) {
      console.log(this.cmpRef.isFormChanged());
      if(this.cmpRef.isFormValid()) {
        this.cmpRef.onSubmit();
        this.displaySaveDlg = false;
      } else {
        console.log('not validddddddddddddd');
        this._messageService.addMessages([{severity:'error', summary: 'Error', detail:'Form data are not valid!!!'}])
      }
    }
  }
  */

  cancel() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('cancel');
    }
    this.displaySaveDlg = false;
  }

  continueAndChangeTab() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('continueAndChangeTab');
    }
    let observables = this.cmpRefs.map(c => {
      return c.refreshAndDeactivateTracking();
    });
    forkJoin(observables).subscribe(
      res => {
        this.cmpRefs = [];
        this.displaySaveDlg = false;
        if (environment.SHOW_CONSOLE_MSG) {
          console.log('TAB INDEX: ' + this.tabIndex + ' CONTINUE TAB: ' + this.continueTabIndex);
        }
        this.tabIndex = this.continueTabIndex;
      }
    );
  }

  continueAndRedirect() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('continueAndRedirect');
    }
    let observables = this.cmpRefs.map(c => {
      return c.refreshAndDeactivateTracking();
    });
    forkJoin(observables).subscribe(
      res => {
        this.cmpRefs = [];
        this.displaySaveDlg = false;
        if (this.continueRedirectLink) {
          this.router.navigate([this.continueRedirectLink]);
        }
      }
    );
  }

  setRedirectionUrl(url) {
    this.continueRedirectLink = url;
    this.actionMode = 'page';
  }

  showSaveDlg() {
    this.displaySaveDlg = true;
  }

  deactivatePreviousAndActivateNew() {
    if (this.cmpRefs) {
      for (let i = 0; i < this.cmpRefs.length; i++) {
        let cr = this.cmpRefs[i];
        if (this.cmpRefsChangeTracking[i]) {
          if (typeof cr.deactivateChangeTracking === 'function') {
            if (environment.SHOW_CONSOLE_MSG) {
              console.log('deactivateChangeTracking ? true');
            }
            cr.deactivateChangeTracking();
          }
        }
      }
    }

    this.assignCmpRef();

    if (this.cmpRefs) {
      for (let i = 0; i < this.cmpRefs.length; i++) {
        let cr = this.cmpRefs[i];
        if (this.cmpRefsChangeTracking[i]) {
          if (environment.SHOW_CONSOLE_MSG) {
            console.log('activateChangeTracking ? ');
          }
          if (typeof cr.activateChangeTracking === 'function') {
            if (environment.SHOW_CONSOLE_MSG) {
              console.log('activateChangeTracking ? true');
            }
            cr.activateChangeTracking();
          }
        }
      }
    }
  }

  assignCmpRef() {
    /*
      if(this.mapCmpRef) {
          let key = this.tabIndex;
          if(this.mapCmpRef[key]) {
              let cmpName = this.mapCmpRef[key].cmpName;
              this.cmpRef = this[cmpName];
              this.cmpRefChangeTracking = this.mapCmpRef[key].changeTracking;
          }
      } */

    this.cmpRefs = [];

    if (this.mapCmpRef) {
      let tagNames = [];
      let tabs = document.getElementsByClassName('ui-tabview-panel');
      for (let i = 0; i < tabs.length; i++) {
        let el1 = tabs[i];
        if (this.isVisible(el1)) {
          if (environment.SHOW_CONSOLE_MSG) {
            console.log('isVisible');
          }
          let ch = el1.children;
          for (let j = 0; j < ch.length; j++) {
            let c1 = ch[j];
            if (c1.tagName.toLowerCase().indexOf('app-') == 0) {
              if (environment.SHOW_CONSOLE_MSG) {
                console.log(c1.tagName);
              }
              tagNames.push(c1.tagName.toLowerCase());
            }
          }
        }
      }

      if (tagNames) {
        if (tagNames.length > 0) {
          let key = tagNames[0];
          if (this.mapCmpRef[key]) {
            let cmpName = this.mapCmpRef[key].cmpName;
            let cr = this[cmpName];
            this.cmpRefs.push(cr);
            this.cmpRefsChangeTracking.push(this.mapCmpRef[key].changeTracking);
          }
        }
      }
    }
  }

  isVisible(el) {
    return el.style.display !== 'none' && !el.hidden;
  }

  // @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('can deactivateeeeeeee');
    }
    let result = true;
    if (this.cmpRefs) {
      for (let i = 0; i < this.cmpRefs.length; i++) {
        let cr = this.cmpRefs[i];
        if (typeof cr.isFormChanged === 'function') {
          this.componentChanged = cr.isFormChanged();
          if (this.componentChanged) {
            result = false;
          } else {
            result = true;
          }
          break;
        }
      }
    }
    return result;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscriptionTabIndex.unsubscribe();
    this.subscriptionTab001.unsubscribe();
    this.subscriptionTab002.unsubscribe();
    this.subscriptionTab003.unsubscribe();
  }

  // custom-start

  onReportTemplateCsc2760listEventCustom(obj) {
    if (obj.actionType === 'new') {
      this.tab001 = true;
      this.tab002 = false;
      this.tabIndex = 1;
    } else if (obj.actionType !== 'delete') {
      this.tab001 = true;
      this.tab002 = true;
      this.tabIndex = 1;
    } else {
      this.tab002 = false;
      this.tab001 = false;
      this.tabIndex = 0;
    }

    setTimeout(() => {
      this.interactionService.setSelectedBo(obj);
    }, 0);
  }

  onReportTemplateCsc2770detailEventCustom(obj) {
    if (obj.actionType === 'save') {
      this.tab001 = true;
      this.tab002 = true;
      this.tabIndex = 1;
    }

    setTimeout(() => {
      this.interactionService.setBoSaved(obj);
    }, 0);
  }

  onReportTemplateEditorEvent(obj) {
    setTimeout(() => {
      this.interactionService.setBoSaved(obj);
    }, 0);
  }

  // custom-end

  /* custom-gen-methods
onReportTemplateCsc2760listEventCustom
onReportTemplateCsc2770detailEventCustom
custom-gen-methods */

}
