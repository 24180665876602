import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {ProgrammeHeader} from '../../../model/programme-header';
import {CostType} from '../../../model/cost-type';
import {Table} from 'primeng/table';
import {ProgrammeHeaderService} from '../../../service/programme-header.service';
import {CostTypeService} from '../../../service/cost-type.service';

@Component({
  selector: 'app-ctp-selection-dialog',
  templateUrl: './ctp-selection-dialog.component.html',
  styleUrls: ['./ctp-selection-dialog.component.css']
})
export class CtpSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  public selectedObj: any;
  public costTypes: CostType[];
  @Output() onSelected = new EventEmitter<ProgrammeHeader>();
  public errorMessage: string;
  visible = false;

  tfFilterMap = {
    'ctpCode': null,
    'ctpName': null,
    'ctpDescription': null,
    'ctpOrder': null,
    'ctpTotalDecoded': null,
    'ctpDetailsByPersonnelDecoded': null
  };

  exportCols = ['ctpCode', 'ctpName', 'ctpDescription', 'ctpOrder', 'ctpTotalDecoded', 'ctpDetailsByPersonnelDecoded'];

  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  private cmpDomainName = 'cost-type-selection-dialog';

  constructor(
      private costTypeService: CostTypeService
  ) {
    super();
  }

  ngOnInit(): void {
    // this.getCostTypes();
  }

  selectCtp(obj: any) {
    this.selectCtpCustom(obj);
  }

  showDlg() {
    // CLEAR-OVANJE SVIH FILTERA
    this.clearFilters();
    this.getCostTypes();
  }

  getCostTypes(): void {
    this.getCostTypesCustom();
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  getCostTypesCustom() {
    this.costTypeService
        .getCostTypes()
        .subscribe(costTypes => {
          this.costTypes = costTypes.map(ctp => {
            ctp.ctpTotalDecoded = ctp.ctpTotal ? 'Да' : 'Не';
            ctp.ctpDetailsByPersonnelDecoded = ctp.ctpDetailsByPersonnel ? 'Да' : 'Не';
            return ctp;
          });
          this.costTypes.sort((c1, c2) => {
            return c1.ctpCode.localeCompare(c2.ctpCode);
          });
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  selectCtpCustom(obj: any) {
    this.selectedObj = obj;
    this.visible = false;
    this.onSelected.emit(this.selectedObj);
  }

  onDestroyCustom() {
  }

  showDlgDynamic(sqlQuery) {
    this.getCostTypesDynamicDialog(sqlQuery);
    this.clearFilters();
  }

  getCostTypesDynamicDialog(sqlQuery) {
    this.costTypeService
        .getCostTypesDynamicDialog(sqlQuery)
        .subscribe(costTypes => {
          this.costTypes = costTypes.map(ctp => {
            ctp.ctpTotalDecoded = ctp.ctpTotal ? 'Да' : 'Не';
            ctp.ctpDetailsByPersonnelDecoded = ctp.ctpDetailsByPersonnel ? 'Да' : 'Не';
            return ctp;
          });
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }


  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }

}
