import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {FznDocumentType} from '../model/fzn-document-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class FznDocumentTypeService {

  private baseApiUrl = globals.BASE_API_URL;
  private fznDocumentTypesUrl = this.baseApiUrl + 'fznDocumentTypes';  // URL to web api

  constructor(private httpClient: HttpClient) { }

  getFznDocumentTypes(): Observable<FznDocumentType[]> {
	return this.httpClient.get<ResponseWrapper>(this.fznDocumentTypesUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getFznDocumentType(id: number): Observable<FznDocumentType> {
    return this.httpClient.get<ResponseWrapper>(`${this.fznDocumentTypesUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.fznDocumentTypesUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  create(fdt: FznDocumentType): Observable<FznDocumentType> {
	return this.httpClient
      .post<ResponseWrapper>(this.fznDocumentTypesUrl, JSON.stringify(fdt)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(fznDocumentType: FznDocumentType): Observable<FznDocumentType> {
    const url = `${this.fznDocumentTypesUrl}/${fznDocumentType.fdtId}`;
	return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(fznDocumentType)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }
  
  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.fznDocumentTypesUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(fznDocumentType) {
    
 if(fznDocumentType.fdtSysCreateDate) {
fznDocumentType.fdtSysCreateDate = new Date(fznDocumentType.fdtSysCreateDate);
}
 if(fznDocumentType.fdtSysUpdateDate) {
fznDocumentType.fdtSysUpdateDate = new Date(fznDocumentType.fdtSysUpdateDate);
}
 if(fznDocumentType.fdtSysSyncTst) {
fznDocumentType.fdtSysSyncTst = new Date(fznDocumentType.fdtSysSyncTst);
}
    return fznDocumentType;
  }

  private handleError (error: Response | any) {
     return Utils.handleError(error);
  }
  
  
  // custom-start
  
  


  
  // custom-end
  
}



