import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VProjectReportPeriod} from '../model/v-project-report-period';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class VProjectReportPeriodService {

    private baseApiUrl = globals.BASE_API_URL;
    private vProjectReportPeriodsUrl = this.baseApiUrl + 'vProjectReportPeriods';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVProjectReportPeriods(): Observable<VProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(this.vProjectReportPeriodsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVProjectReportPeriod(id: number): Observable<VProjectReportPeriod> {
        return this.httpClient.get<ResponseWrapper>(`${this.vProjectReportPeriodsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.vProjectReportPeriodsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(prp: VProjectReportPeriod): Observable<VProjectReportPeriod> {
        return this.httpClient
            .post<ResponseWrapper>(this.vProjectReportPeriodsUrl, JSON.stringify(prp)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(vProjectReportPeriod: VProjectReportPeriod): Observable<VProjectReportPeriod> {
        const url = `${this.vProjectReportPeriodsUrl}/${vProjectReportPeriod.prpId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(vProjectReportPeriod)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.vProjectReportPeriodsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vProjectReportPeriod) {

        if (vProjectReportPeriod.prpSysUpdateDate) {
            vProjectReportPeriod.prpSysUpdateDate = new Date(vProjectReportPeriod.prpSysUpdateDate);
        }
        if (vProjectReportPeriod.prpSysCreateDate) {
            vProjectReportPeriod.prpSysCreateDate = new Date(vProjectReportPeriod.prpSysCreateDate);
        }
        if (vProjectReportPeriod.prpSysSyncTst) {
            vProjectReportPeriod.prpSysSyncTst = new Date(vProjectReportPeriod.prpSysSyncTst);
        }
        if (vProjectReportPeriod.prpDeadline) {
            vProjectReportPeriod.prpDeadline = new Date(vProjectReportPeriod.prpDeadline);
        }
        if (vProjectReportPeriod.prpStartDate) {
            vProjectReportPeriod.prpStartDate = new Date(vProjectReportPeriod.prpStartDate);
        }
        if (vProjectReportPeriod.prpEndDate) {
            vProjectReportPeriod.prpEndDate = new Date(vProjectReportPeriod.prpEndDate);
        }
        return vProjectReportPeriod;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getVProjectReportPeriodsByProjectHeaderId(projectHeaderId: number): Observable<VProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vProjectReportPeriodsUrl}/byProjectHeaderId/${projectHeaderId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriodsCopyQfrQarByPjhIdAndFdtId(fthPjhId: number, fthFdtId: number): Observable<VProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vProjectReportPeriodsUrl}/copyQfrQarByPjhIdAndFdtId/${fthPjhId}/${fthFdtId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    generatePeriods(projectHeaderId: number) {
        return this.httpClient.get<ResponseWrapper>(this.vProjectReportPeriodsUrl + '/generatePeriods?projectHeaderId=' + projectHeaderId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriodsByPrpPjhIdAndPrpType(prpPjhId: number, prpType: string): Observable<VProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vProjectReportPeriodsUrl}/byProjectHeaderIdAndPrpType/${prpPjhId}/${prpType}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriodsByPrpPjhIdAndPrpTypeAndPrpStartIdAndPrpEndId(prpPjhId: number, prpType: string, prpStartId: number, prpEndId: number): Observable<VProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vProjectReportPeriodsUrl}/byProjectHeaderIdAndPrpTypeAndPrpStartIdAndPrpEndId/${prpPjhId}/${prpType}/${prpStartId}/${prpEndId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



