import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as globals from '../../globals/globals';
// import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
// import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';
// import {FinDimensionType} from '../model/fin-dimension-type';
// import {Status} from '../model/status';
// import {FinDimension} from '../model/fin-dimension';


@Injectable()
export class ChangePasswordService {

    private baseApiUrl = globals.BASE_API_URL;
    private forgotUrl =  this.baseApiUrl + 'forgot';  // URL to web api
    private resetUrl =  this.baseApiUrl + 'reset';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    checkExistenseAndRequestNewPassword(credential: string, adminReset: boolean): Observable<string> {


        return this.httpClient
            .post<ResponseWrapper>(this.forgotUrl + '?credential=' + credential + '&adminReset=' + adminReset , null).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    saveNewPassword(token: string, password: string): Observable<string>{
        return this.httpClient
            .put<ResponseWrapper>(this.resetUrl + '?token=' + token + '&newPassword=' + password, null ).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }
}

