import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Sro} from '../../../model/sro';
import {Table} from 'primeng/table';
import {SroService} from '../../../service/sro.service';
import {CommonCodeService} from '../../../../core/common-code.service';
import {environment} from '../../../../../environments/environment';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {MessageService} from '../../../../core/message.service';
import {VSroDialogList} from '../../../model/v-sro-dialog-list';

@Component({
  selector: 'app-sro-dynamic-selection-dialog',
  templateUrl: './sro-dynamic-selection-dialog.component.html',
  styleUrls: ['./sro-dynamic-selection-dialog.component.css']
})
export class SroDynamicSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  public selectedObj: any;
  public sros: VSroDialogList[];
  @Output() onSelected = new EventEmitter<Sro>();
  public errorMessage: string;
  visible = false;

  tfFilterMap = {'pjsNo': null, 'sroCode': null, 'sroName': null, 'sroGroup': null};

  exportCols = ['pjsNo', 'sroCode', 'sroName', 'sroGroup'];


  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  // custom-constructor-start
  private cmpDomainName = 'sro';

  constructor(private sroService: SroService,
              private messageService: MessageService) {
    super();
  }

  // custom-constructor-end

  ngOnInit(): void {
  }

  selectSro(obj: any) {
    this.selectSroCustom(obj);
  }

  showDlgByPjhIdAndFthId(pjhId: number, fthId: number) {
    this.clearFilters();
    this.getSrosByPjhIdAndFthId(pjhId, fthId);
  }

  getSrosByPjhIdAndFthId(pjhId: number, fthId: number) {
    this.sroService.getSroByPjhIdAndFthId(pjhId, fthId).subscribe(
        res => {
          this.sros = res;
          this.visible = true;
        },error => this.errorMessage = <any> error
    );
  }

  showDlgDynamic(sqlQuery) {
    this.getSrosDynamicDialog(sqlQuery);
    this.clearFilters();
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  // custom-start

  getSrosDynamicDialog(sqlQuery) {
    this.sroService
        .getSrosDynamicDialog(sqlQuery)
        .subscribe(sros => {
          this.sros = sros;
          // this.sros.sort((s1, s2) => {
          //     return s1.sroCode.localeCompare(s2.sroCode);
          // });
          debugger;
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  selectSroCustom(obj: any) {
    this.sroService.getSro(obj.sroId).subscribe(res => {
      this.selectedObj = res;
      this.visible = false;
      this.onSelected.emit(this.selectedObj);
    }, error => {
      this.messageService.showFailMsgText(error);
    });
  }

  onDestroyCustom() {
  }

  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }


  // custom-end

}
