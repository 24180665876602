import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountRole} from '../model/account-role';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';


@Injectable()
export class AccountRoleService {

  private baseApiUrl = globals.BASE_API_URL;
  private accountRolesUrl = this.baseApiUrl + 'accountRoles';  // URL to web api

  constructor(private httpClient: HttpClient) {
  }

  getAccountRoles(): Observable<AccountRole[]> {
    return this.httpClient.get<ResponseWrapper>(this.accountRolesUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getAccountRole(id: number): Observable<AccountRole> {
    return this.httpClient.get<ResponseWrapper>(`${this.accountRolesUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.accountRolesUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  create(acr: AccountRole): Observable<AccountRole> {
    return this.httpClient
      .post<ResponseWrapper>(this.accountRolesUrl, JSON.stringify(acr)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(accountRole: AccountRole): Observable<AccountRole> {
    const url = `${this.accountRolesUrl}/${accountRole.acrId}`;
    return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(accountRole)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.accountRolesUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(accountRole) {

    if (accountRole.acrDateFrom) {
      accountRole.acrDateFrom = new Date(accountRole.acrDateFrom);
    }
    if (accountRole.acrDateTo) {
      accountRole.acrDateTo = new Date(accountRole.acrDateTo);
    }
    if (accountRole.acrSysCreateDate) {
      accountRole.acrSysCreateDate = new Date(accountRole.acrSysCreateDate);
    }
    if (accountRole.acrSysSyncTst) {
      accountRole.acrSysSyncTst = new Date(accountRole.acrSysSyncTst);
    }
    if (accountRole.acrSysUpdateDate) {
      accountRole.acrSysUpdateDate = new Date(accountRole.acrSysUpdateDate);
    }
    return accountRole;
  }

  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }


  // custom-start


  getAccountRolesByAccountId(accId: number): Observable<AccountRole[]> {
    return this.httpClient.get<ResponseWrapper>(this.accountRolesUrl + '/findAllByAccId?accId=' + accId).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getAccountRolesByAccountIdAndLoggedUserOru(accId: number): Observable<AccountRole[]> {
    return this.httpClient.get<ResponseWrapper>(this.accountRolesUrl + '/findAllByAccIdAndLoggedUserOru?accId=' + accId).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }




  // custom-end

}



