import {Injectable} from '@angular/core';


import {CommonCode} from './common-code';
import * as globals from '../globals/globals';
import {CommonCodeVM} from './common-code-vm';
import {Observable} from 'rxjs';

import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, map, retry} from 'rxjs/operators';
import {ResponseWrapper} from '../bo/model/sys/response-wrapper';
import {throwError} from 'rxjs/internal/observable/throwError';
import {environment} from '../../environments/environment';

@Injectable()
export class CommonCodeService {
    private headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(globals.AUTH_TOKEN_NAME)
    });
    // private baseApiUrl = environment.BASE_API_URL;
    private baseApiUrl = globals.BASE_API_URL;
    private commonCodesUrl = this.baseApiUrl + 'commonCodes';
    private commonCodesByCcdTypeUrl = this.baseApiUrl + 'commonCodesByCcdType';
    private commonCodeDefaultByCcdTypeUrl = this.baseApiUrl + 'commonCodeDefaultByCcdType';

    constructor(private http: HttpClient) {
    }

    getCommonCodes(): Observable<CommonCode[]> {
        return this.http.get<ResponseWrapper>(this.commonCodesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getCommonCode(id: number): Observable<CommonCode> {
        console.log('************** getCommonCode' + id);

        console.log("COMMON CODES URL: ", this.commonCodesUrl);

        return this.http.get<ResponseWrapper>(`${this.commonCodesUrl}/${id}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );

    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.commonCodesUrl}/${id}`;
        return this.http.delete<ResponseWrapper>(url).pipe(
            catchError(this.handleError)
        );
    }

    create(ccd: CommonCode): Observable<CommonCode> {
        return this.http
            .post<ResponseWrapper>(this.commonCodesUrl, JSON.stringify(ccd)).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    update(commonCode: CommonCode): Observable<CommonCode> {
        const url = `${this.commonCodesUrl}/${commonCode.ccdId}`;
        return this.http
            .put<ResponseWrapper>(url, JSON.stringify(commonCode)).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    findByType(type: string): Observable<CommonCodeVM[]> {
        return this.http.get<ResponseWrapper>(`${this.commonCodesUrl}?type=${type}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

    getCommonCodesDistinct(): Observable<string[]> {
        console.log('getCommonCodesDistinct method');
        return this.http.get<ResponseWrapper>(this.commonCodesUrl + 'Distinct').pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

    private handleError(error: HttpErrorResponse | any) {
        // In a real world app, we might use a remote logging infrastructure

        let errMsg;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            errMsg = error.error.message;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            error.error.message = error.error;
        }
        // return an ErrorObservable with a user-facing error message
        return throwError(
            errMsg);
    }

    // Arbutina: poziv liste iz common_code tabele po filteru polja ccd_type
    getCommonCodesByCcdType(ccdType: string): Observable<CommonCode[]> {
        return this.http.get<ResponseWrapper>(`${this.commonCodesByCcdTypeUrl}/${ccdType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

    // po tipu dovlaci defaultni
    getDefaultCommonCodeByType(ccdType: string): Observable<CommonCode> {
        return this.http.get<ResponseWrapper>(`${this.commonCodeDefaultByCcdTypeUrl}/${ccdType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );

    }

    getDefaultCommonCodeByCcdCode(ccdCode: string): Observable<CommonCode> {
        return this.http.get<ResponseWrapper>(`${this.commonCodesUrl + 'GetCcdCode'}/${ccdCode}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );

    }

    // 17.08.2022 Nenad: poziv liste po filteru polja ccd_type i uslovu ccd_code = fcl_type_contract_change_log i fcl_type_other_change_log
    getCommonCodesByCcdTypeAndCcdCode(ccdType: string): Observable<CommonCode[]> {
        return this.http.get<ResponseWrapper>(`${this.commonCodesByCcdTypeUrl}AndCcdCode/${ccdType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

}



