import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {Message} from '../shared/message';
import {TranslateService} from '@ngx-translate/core';
@Injectable()
export class PendingRequestService {

  // Observable msg sources
  private pendingRequestSource = new Subject<boolean>();
  // Observable msg streams
  pendingRequests$ = this.pendingRequestSource.asObservable();


  constructor() {

  }

  updatePendingRequests(b) {
    this.pendingRequestSource.next(b);
  }

}
