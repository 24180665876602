import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {VDocumentTypeListFth} from '../../../../../../model/v-document-type-list-fth';
import {VDocumentTypeListFthService} from '../../../../../../service/v-document-type-list-fth.service';

@Component({
    selector: 'app-document-type-csc1511selection-dialog',
    templateUrl: './document-type-csc1511selection-dialog.component.html',
    styleUrls: ['./document-type-csc1511selection-dialog.component.css']
})
export class DocumentTypeCsc1511selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public documentTypes: VDocumentTypeListFth[];
    @Output() onSelected = new EventEmitter<VDocumentTypeListFth>();
    public errorMessage: string;
    visible = false;
    public pid: number;
    public fdtid: number;

    // @Input('selectedDocType') selectedDocType: string;

    tfFilterMap = {'dgrCode': null, 'dgrName': null, 'dctCode': null, 'dctDescription': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'v-document-type-list-fth';

    constructor(private documentTypeService: VDocumentTypeListFthService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getDocumentTypes();
    }


    selectDct(obj: any) {
        this.selectDctCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    showDlgByGoId(id: number) {
        this.clearFilters();
        this.visible = true;
        this.pid = id;
        this.getDocumentTypes();
    }

    showDlgByIdAnByFdtId(id: number, fdtid: number) {
        this.clearFilters();
        this.visible = true;
        this.pid = id;
        this.fdtid = fdtid;
        this.getDocumentTypes();
    }

    getDocumentTypes(): void {
        this.getDocumentTypesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getDocumentTypesCustom() {
        if (this.fdtid !== null && this.fdtid !== undefined) {
            if (this.pid !== null && this.pid !== undefined) {
                this.documentTypeService
                    .getDocumentTypesListFthForFthFdt(this.pid, this.fdtid)
                    .subscribe(documentTypes => this.documentTypes = documentTypes, error => this.errorMessage = <any> error);
            }
        } else {
            if (this.pid !== null && this.pid !== undefined) {
                this.documentTypeService
                    .getDocumentTypesListFthForFznth(this.pid)
                    .subscribe(documentTypes => this.documentTypes = documentTypes, error => this.errorMessage = <any> error);
            }
        }
    }

    selectDctCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
