import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {BoDialogsModule} from '../bo-dialogs/bo-dialogs.module';
import {BoSharedModule} from '../bo-shared/bo-shared.module';
import {SpinnerModule} from 'primeng/components/spinner/spinner';
import {FinRoutingModule} from './fin-routing.module';
// tslint:disable-next-line:max-line-length
import {ReportTemplateListCsc2760listComponent} from './report-template-list/c/sc/report-template-list-csc2760list/report-template-list-csc2760list.component';
// tslint:disable-next-line:max-line-length
import {ReportTemplateCsc1920mainComponent} from './bo-main/report_template/c/sc/report-template-csc1920main/report-template-csc1920main.component';
// tslint:disable-next-line:max-line-length
import {ReportTemplateCsc2770detailComponent} from './report-template/c/sc/report-template-csc2770detail/report-template-csc2770detail.component';
import {ReportTemplateEditorComponent} from './report-template/custom/report-template-editor/report-template-editor.component';
import {ReportTemplateCsc2760listComponent} from './report-template/c/sc/report-template-csc2760list/report-template-csc2760list.component';
import {ReportTemplateService} from '../../service/report-template.service';
import {ReportTemplateMapperService} from '../../service/report-template-mapper.service';
import {CaseHeaderReportComponent} from './report-template/custom/case-header-report/case-header-report.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FinRoutingModule,
        BoDialogsModule,
        BoSharedModule,
        SpinnerModule

    ],
    declarations: [
        ReportTemplateListCsc2760listComponent,
        ReportTemplateCsc1920mainComponent,
        ReportTemplateCsc2770detailComponent,
        ReportTemplateEditorComponent,
        ReportTemplateCsc2760listComponent,


    ],
    exports: [
        ReportTemplateCsc1920mainComponent,

    ],
    providers: [
        ReportTemplateService,
        ReportTemplateMapperService
    ]
})
export class FinModule {
}
