import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {ProjectSroPersonnelBudget} from '../../../model/project-sro-personnel-budget';
import {Table} from 'primeng/table';
import {ProjectSroPersonnelService} from '../../../service/project-sro-personnel.service';
import {FznTransactionDetailService} from '../../../service/fzn-transaction-detail.service';
import {ProjectReportPeriodService} from '../../../service/project-report-period.service';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'app-project-sro-personnel-budget-dialog',
    templateUrl: './project-sro-personnel-budget-dialog.component.html',
    styleUrls: ['./project-sro-personnel-budget-dialog.component.css']
})
export class ProjectSroPersonnelBudgetDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public projectSroPersonnelBudgets: ProjectSroPersonnelBudget[];
    @Output() onSelected = new EventEmitter<any>();
    public errorMessage: string;
    visible = false;

    tfFilterMap = {'sroCode': null, 'pspCode': null, 'pspNameSurname': null, 'prpStartCode': null, 'prpEndCode': null};

    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    private cmpDomainName = 'project-sro-personnel-budget';

    constructor(private projectSroPersonnelService: ProjectSroPersonnelService,
                private fznTransactionDetailService: FznTransactionDetailService,
                private projectReportPeriodService: ProjectReportPeriodService) {
        super();
    }

    ngOnInit(): void {}

    selectPsp(obj: any) {
        this.selectPspCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    showDlgProjectSroPersonnelBudgetByFthIdAndYfrFthId(fthId: number, yfrFthId: number) {
        if (fthId) {
            this.projectSroPersonnelService.getProjectSroPersonnelBudgetByFthIdAndYfrFthId(fthId, yfrFthId).subscribe(res => {
                this.projectSroPersonnelBudgets = res;
                this.visible = true;
            });
        } else {
            this.projectSroPersonnelBudgets = [];
            this.visible = true;
        }
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    selectPspCustom(obj: any) {
        forkJoin([
            this.projectSroPersonnelService.getProjectSroPersonnel(obj.pspId),
            this.fznTransactionDetailService.getFznTransactionDetail(obj.ftdId),
            this.projectReportPeriodService.getProjectReportPeriod(obj.prpStartId),
            this.projectReportPeriodService.getProjectReportPeriod(obj.prpEndId)
        ]).subscribe(([projectSroPersonnel, ftdBudget, projectBdgStartReportPeriod, projectBdgEndReportPeriod]) => {
            this.selectedObj = projectSroPersonnel;
            this.visible = false;
            this.onSelected.emit({
                'projectSroPersonnel': this.selectedObj,
                'ftdBudget': ftdBudget,
                'projectBdgStartReportPeriod': projectBdgStartReportPeriod,
                'projectBdgEndReportPeriod': projectBdgEndReportPeriod
            });
        });
    }

    onDestroyCustom() {
    }

    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

}
