declare var Quill: any;

var BlockEmbed = Quill.import('blots/block/embed');
export class TableBlot extends BlockEmbed {

  static create(value) {
    let node = super.create(value);
    console.log('value ', value);
    // node.setAttribute('class', 'complex');
    return value;
  }

  format(name, value) {
    console.log(name);
    console.log(value);
    super.format(name, value);
  }

  static value(domNode) {
    return domNode;
  }

  extend(target, base) {
    for (var prop in base) {
      target[prop] = base[prop];
    }
  }

  /*static get ATTRIBUTES() {
    return [ 'id'];
  }

  static formats(domNode) {
    return [ 'id'].reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }*/

 /* format(name, value) {
    if (['id'].indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }*/

  /*static create(value) {
    let node = super.create(value);
    console.log('tableeeeeeeeeeee value ', value);
    node.setAttribute('id', 'id123');
    console.log(node);

    return value;
    /!*let node = super.create(value);
    console.log('node' , node);
    // node.appendChild(document.createTextNode('milica'));
    node.appendChild(value);

    node.setAttribute('class', 'class-1');

    return node;*!/
  }

  format(name, value) {
    console.log(name);
    console.log(value);
    super.format(name, value);
  }

  static value(domNode) {
    console.log('valueeeeeeeeeee table', domNode);
    return domNode;
  }

  extend(target, base) {
    for (var prop in base) {
      target[prop] = base[prop];
    }
  }*/


}
