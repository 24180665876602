import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {FznDocumentType} from '../../../../../../model/fzn-document-type';
import {FznDocumentTypeService} from '../../../../../../service/fzn-document-type.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-fzn-document-type-csc1230selection-dialog',
    templateUrl: './fzn-document-type-csc1230selection-dialog.component.html',
    styleUrls: ['./fzn-document-type-csc1230selection-dialog.component.css']
})
export class FznDocumentTypeCsc1230selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public fznDocumentTypes: FznDocumentType[];
    @Output() onSelected = new EventEmitter<FznDocumentType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'fdtCode': null, 'fdtName': null};

    exportCols = ['fdtCode', 'fdtName'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'fzn-document-type';

    constructor(private fznDocumentTypeService: FznDocumentTypeService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getFznDocumentTypes();
    }


    selectFdt(obj: any) {
        this.selectFdtCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getFznDocumentTypes(): void {
        this.getFznDocumentTypesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getFznDocumentTypesCustom() {
        this.fznDocumentTypeService
            .getFznDocumentTypes()
            .subscribe(fznDocumentTypes => {
                this.fznDocumentTypes = fznDocumentTypes;
                this.fznDocumentTypes.sort((fdt1, fdt2) => {
                   return fdt1.fdtCode.localeCompare(fdt2.fdtCode);
                });
            }, error => this.errorMessage = <any> error);
    }

    selectFdtCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
