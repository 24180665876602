import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Location} from '../model/location';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';


@Injectable()
export class LocationService {

    private baseApiUrl = globals.BASE_API_URL;
    private locationsUrl = this.baseApiUrl + 'locations';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getLocations(): Observable<Location[]> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getLocation(id: number): Observable<Location> {
        return this.httpClient.get<ResponseWrapper>(`${this.locationsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.locationsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(loc: Location): Observable<Location> {
        return this.httpClient
            .post<ResponseWrapper>(this.locationsUrl, JSON.stringify(loc)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(location: Location): Observable<Location> {
        const url = `${this.locationsUrl}/${location.locId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(location)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.locationsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(location) {

        if (location.locSysCreateDate) {
            location.locSysCreateDate = new Date(location.locSysCreateDate);
        }
        if (location.locSysUpdateDate) {
            location.locSysUpdateDate = new Date(location.locSysUpdateDate);
        }
        if (location.locSysSyncTst) {
            location.locSysSyncTst = new Date(location.locSysSyncTst);
        }
        return location;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getStreetsByMnpId(mnpId: number): Observable<any[]> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl + 'StreetsByMnpId/' + mnpId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getByStreetAndMnp(addExRegNo: number, mnpExRegNo: number): Observable<Location[]> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl + 'ByStrAndMnp/' + addExRegNo + '/' + mnpExRegNo).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getMnpUnknownLocation(mnpId: number): Observable<Location> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl + 'MnpUnknownLocation/' + mnpId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getDstUnknownLocation(dstId: number): Observable<Location> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl + 'DstUnknownLocation/' + dstId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getStates(): Observable<Location[]> {
        return this.httpClient.get<ResponseWrapper>(this.locationsUrl + '/getStates').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



