import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Municipality} from '../../../../../../model/municipality';
import {MunicipalityService} from '../../../../../../service/municipality.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-municipality-csc1180selection-dialog',
    templateUrl: './municipality-csc1180selection-dialog.component.html',
    styleUrls: ['./municipality-csc1180selection-dialog.component.css']
})
export class MunicipalityCsc1180selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public municipalitys: Municipality[];
    @Output() onSelected = new EventEmitter<Municipality>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'mnpName': null, 'mnpCity': null, 'mnpPostalCode': null};


    @ViewChild('dt1', {static: false}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'municipality';

    constructor(private municipalityService: MunicipalityService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getMunicipalitys();
    }


    selectMnp(obj: any) {
        this.selectMnpCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getMunicipalitys(): void {
        this.getMunicipalitysCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getMunicipalitysCustom() {
        this.municipalityService
            .getMunicipalitys()
            .subscribe(municipalitys => this.municipalitys = municipalitys, error => this.errorMessage = <any> error);
    }

    selectMnpCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
