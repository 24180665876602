import {GanttChartHeader} from './gantt-chart-header';
import {ProjectReportPeriod} from './project-report-period';
import {ProjectStructureElement} from './project-structure-element';
import {ProjectSroPersonnel} from './project-sro-personnel';
import {Account} from './account';


export class GanttChartDetail {

    gcdSysUpdateDate?: Date;

    gcdStaId?: number;

    gcdId: number;

    gcdType?: string;

    gcdSysCreateDate?: Date;

    gcdSysSyncTst?: Date;

    gcdWorkingTime?: number;

    ganttChartHeader: GanttChartHeader;
    projectReportPeriod: ProjectReportPeriod;
    projectStructureElement: ProjectStructureElement;
    projectSroPersonnel: ProjectSroPersonnel;
    accountSysCreateWho: Account;
    accountSysUpdateWho: Account;


    // custom-start

// custom-end

    constructor() {
    }
}

