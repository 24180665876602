import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {VFznDocumentType} from '../../../../../../model/v-fzn-document-type';
import {VFznDocumentTypeService} from '../../../../../../service/v-fzn-document-type.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import Utils from '../../../../../../../utils/utils';
import {ProgrammeDocumentTypeService} from '../../../../../../service/programme-document-type.service';
import {ProgrammeDocumentType} from '../../../../../../model/programme-document-type';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {MessageService} from '../../../../../../../core/message.service';

@Component({
    selector: 'app-v-fzn-document-type-csc1340selection-dialog',
    templateUrl: './v-fzn-document-type-csc1340selection-dialog.component.html',
    styleUrls: ['./v-fzn-document-type-csc1340selection-dialog.component.css']
})
export class VFznDocumentTypeCsc1340selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public vFznDocumentTypes: VFznDocumentType[];
    @Output() onSelected = new EventEmitter<VFznDocumentType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {
        'fdtCode': null,
        'fdtName': null,
        'fdtDescription': null,
        'ccdFdtFinAdmType': null,
        'ccdFdtPlanRealType': null,
        'ccdFdtPeriodType': null
    };


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'v-fzn-document-type';

    public selectedProgrammeDocumentType: ProgrammeDocumentType;

    constructor(private vFznDocumentTypeService: VFznDocumentTypeService,
                private programmeDocumentTypeService: ProgrammeDocumentTypeService,
                private messageService: MessageService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getVFznDocumentTypes();
    }


    selectFdt(obj: any) {
        this.selectFdtCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    getVFznDocumentTypes(): void {
        this.getVFznDocumentTypesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getVFznDocumentTypesCustom() {
        this.vFznDocumentTypeService
            .getVFznDocumentTypes()
            .subscribe(vFznDocumentTypes => this.vFznDocumentTypes = vFznDocumentTypes, error => this.errorMessage = <any> error);
    }

    selectFdtCustom(obj: any) {
        // this.selectedObj = obj;
        // this.programmeDocumentTypeService.getProgrammeDocumentTypesByFdtId(this.selectedObj.fdtId).subscribe(res => {
        //         this.selectedProgrammeDocumentType = res;
        //         // this._interactionService.setBoSaved(io);
        //         // this.selectedObj = obj;
        //         this.visible = false;
        //         this.onSelected.emit(this.selectedObj);
        //     },
        //     error => {
        //         this.errorMessage = <any> error;
        //         if ('FZN_DOCUMENT_TYPE_EXISTS' === this.errorMessage) {
        //             this.visible = false;
        //             this.messageService.showFailMsgText('У конфигурацији је већ унет одабран документ');
        //         } else {
        //             this.messageService.showFailMsgText(this.errorMessage);
        //         }
        //     });
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }



    // custom-end

}
