import {Input, Renderer, ElementRef, Directive, HostListener} from "@angular/core";
@Directive({
  selector: '[formatNum]'
})
export class NumDirective {
  private _defaultColor = '#e6e6ff';

  constructor(private el: ElementRef, private renderer: Renderer) { }

  @Input() set defaultColor(colorName: string){
    this._defaultColor = colorName || this._defaultColor;
  }

  @Input('formatNum') myNum: string;

  /*@HostListener('mouseenter') onMouseEnter() {
    this.highlight('yellow');
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
  }*/

  @HostListener('keypress', ['$event']) onKeyDown(event: KeyboardEvent) {
    //this.highlight(this.highlightColor || this._defaultColor);
    // console.log("keycode: " + event.which);
    // console.log(this.myNum);
    if (this.myNum === 'int') {
      if (!this.isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }
    } else {
      if (!this.isKeyPressedNumeric(event) && !this.isKeyPressedCommaOrDot(event)) {
        if (event.preventDefault) event.preventDefault();
      }
    }
  }

  private isCharNumeric(charStr):boolean {
    return !!/\d/.test(charStr);
  }

  private isKeyPressedNumeric(event):boolean {
    var charCode = this.getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  private isKeyPressedCommaOrDot(event):boolean {
    var charCode = this.getCharCodeFromEvent(event);
    var charStr = String.fromCharCode(charCode);
    return charStr === '.';
    // return charStr == '.' || charStr == ',';
  }

  private getCharCodeFromEvent(event):any {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
  }

  /*private highlight(color: string) {
    this.renderer.setElementStyle(this.el.nativeElement, 'backgroundColor', color);
  }*/
}
