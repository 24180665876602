import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VFznTransactionHeader} from '../model/v-fzn-transaction-header';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class VFznTransactionHeaderService {

    private baseApiUrl = globals.BASE_API_URL;
    private vFznTransactionHeadersUrl = this.baseApiUrl + 'vFznTransactionHeaders';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVFznTransactionHeaders(): Observable<VFznTransactionHeader[]> {
        return this.httpClient.get<ResponseWrapper>(this.vFznTransactionHeadersUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVFznTransactionHeader(id: number): Observable<VFznTransactionHeader> {
        return this.httpClient.get<ResponseWrapper>(`${this.vFznTransactionHeadersUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.vFznTransactionHeadersUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(fth: VFznTransactionHeader): Observable<VFznTransactionHeader> {
        return this.httpClient
            .post<ResponseWrapper>(this.vFznTransactionHeadersUrl, JSON.stringify(fth)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(vFznTransactionHeader: VFznTransactionHeader): Observable<VFznTransactionHeader> {
        const url = `${this.vFznTransactionHeadersUrl}/${vFznTransactionHeader.fthId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(vFznTransactionHeader)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.vFznTransactionHeadersUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vFznTransactionHeader) {

        if (vFznTransactionHeader.fthSysUpdateDate) {
            vFznTransactionHeader.fthSysUpdateDate = new Date(vFznTransactionHeader.fthSysUpdateDate);
        }
        if (vFznTransactionHeader.fthSysCreateDate) {
            vFznTransactionHeader.fthSysCreateDate = new Date(vFznTransactionHeader.fthSysCreateDate);
        }
        if (vFznTransactionHeader.fthSysSyncTst) {
            vFznTransactionHeader.fthSysSyncTst = new Date(vFznTransactionHeader.fthSysSyncTst);
        }
        if (vFznTransactionHeader.fthDateOfApproval) {
            vFznTransactionHeader.fthDateOfApproval = new Date(vFznTransactionHeader.fthDateOfApproval);
        }
        if (vFznTransactionHeader.fthDate) {
            vFznTransactionHeader.fthDate = new Date(vFznTransactionHeader.fthDate);
        }
        return vFznTransactionHeader;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getVFznTransactionHeaderLazy(request: any): Observable<any> {
        const url = `${this.vFznTransactionHeadersUrl}/lazy`;
        return this.httpClient.post<ResponseWrapper>(url, request).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



