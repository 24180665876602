import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {BoSharedModule} from '../bo-shared/bo-shared.module';
import {AppModule} from '../../../app.module';
import {DistrictCsc5460selectionDialogComponent} from './district/c/sc/district-csc5460selection-dialog/district-csc5460selection-dialog.component';
import {OrganizationUnitGbo2650selectionDialogComponent} from './organization-unit/g/bo/organization-unit-gbo2650selection-dialog/organization-unit-gbo2650selection-dialog.component';
import {LocationService} from '../../service/location.service';
import {MunicipalityCsc1180selectionDialogComponent} from './municipality/c/sc/municipality-csc1180selection-dialog/municipality-csc1180selection-dialog.component';
import {ClassificationDetailCsc1190selectionDialogComponent} from './classification-detail/c/sc/classification-detail-csc1190selection-dialog/classification-detail-csc1190selection-dialog.component';
import {FormCsc3780selectionDialogComponent} from './form/c/sc/form-csc3780selection-dialog/form-csc3780selection-dialog.component';
import {ExaminationTypeCsc1240selectionDialogComponent} from './examination-type/c/sc/examination-type-csc1240selection-dialog/examination-type-csc1240selection-dialog.component';
import { ClassificationDetailCsc1410selectionDialogComponent } from './classification-detail/c/sc/classification-detail-csc1410selection-dialog/classification-detail-csc1410selection-dialog.component';
import { DocumentTypeCsc1510selectionDialogComponent } from './document-type/c/sc/document-type-csc1510selection-dialog/document-type-csc1510selection-dialog.component';
import { ReportTemplateCsc1520selectionDialogComponent } from './report-template/c/sc/report-template-csc1520selection-dialog/report-template-csc1520selection-dialog.component';
import { AccountCsc1550selectionDialogComponent } from './account/c/sc/account-csc1550selection-dialog/account-csc1550selection-dialog.component';
import {AccountService} from '../../service/account.service';
import {CustomClassificationDetailCsc2190selectionDialogComponent} from './classification-detail/c/sc/custom-classsification-detail-csc2190selection-dialog/custom-classification-detail-csc2190selection-dialog.component';
import {ClassificationDetailCsc1410selectionDialogUniversalComponent} from './classification-detail/c/sc/classification-detail-csc1410selection-dialog-universal/classification-detail-csc1410selection-dialog-universal.component';
import {ClassificationDetailService} from '../../service/classification-detail.service';
import { ItemCsc1790selectionDialogComponent } from './item/c/sc/item-csc1790selection-dialog/item-csc1790selection-dialog.component';
import { GovernmentInstitutionCsc1830selectionDialogComponent } from './government-institution/c/sc/government-institution-csc1830selection-dialog/government-institution-csc1830selection-dialog.component';
import { MeasuringPointCsc2010selectionDialogComponent } from './measuring-point/c/sc/measuring-point-csc2010selection-dialog/measuring-point-csc2010selection-dialog.component';
import {WindowRef} from '../../../core/window-ref';
import {AccountGenericlogSelectionDialogComponent} from './account/c/sc/account-custom-genericlog-selection-dialog/account-genericlog-selection-dialog.component';
import { ProgrammeHeaderCsc1030selectionDialogComponent } from './programme-header/c/sc/programme-header-csc1030selection-dialog/programme-header-csc1030selection-dialog.component';
import { AccountCsc1060selectionDialogComponent } from './account/c/sc/account-csc1060selection-dialog/account-csc1060selection-dialog.component';
import { CtpSelectionDialogComponent } from './ctp-selection-dialog/ctp-selection-dialog.component';
import { SroCsc1100selectionDialogComponent } from './sro/c/sc/sro-csc1100selection-dialog/sro-csc1100selection-dialog.component';
import { ProjectStructureElementDialogComponent } from './project-structure-element-dialog/project-structure-element-dialog.component';
import {ProjectSroCsc1100selectionDialogComponent} from './project-sro/c/sc/project-sro-csc1100selection-dialog/project-sro-csc1100selection-dialog.component';
import { ProjectHeaderCsc1250selectionDialogComponent } from './project-header/c/sc/project-header-csc1250selection-dialog/project-header-csc1250selection-dialog.component';
import { FznDocumentTypeCsc1230selectionDialogComponent } from './fzn-document-type/c/sc/fzn-document-type-csc1230selection-dialog/fzn-document-type-csc1230selection-dialog.component';
import { ProjectReportPeriodCsc1240selectionDialogComponent } from './project-report-period/c/sc/project-report-period-csc1240selection-dialog/project-report-period-csc1240selection-dialog.component';
import { PjhCldSelectionDialogComponent } from './pjh-cld-selection-dialog/pjh-cld-selection-dialog.component';
import { DynamicFormsRowGeneratorDialogComponent } from './dynamic-forms-row-generator-dialog/dynamic-forms-row-generator-dialog.component';
import { FormItemDialogComponent } from './form-item-dialog/form-item-dialog.component';
import { FormItemStructureDialogComponent } from './form-item-structure-dialog/form-item-structure-dialog.component';
import { CommonCodeSelectionDialogComponent } from './common-code-selection-dialog/common-code-selection-dialog.component';
import { FormItemStructureSelectionDialogComponent } from './form-item-structure-selection-dialog/form-item-structure-selection-dialog.component';
import { VFznDocumentTypeCsc1340selectionDialogComponent } from './v-fzn-document-type/c/sc/v-fzn-document-type-csc1340selection-dialog/v-fzn-document-type-csc1340selection-dialog.component';
import { DynamicDataDictSelectionDialogComponent } from './dynamic-data-dict-selection-dialog/dynamic-data-dict-selection-dialog.component';
import { ProgrammeCostTypeCsc1400selectionDialogComponent } from './programme-cost-type/c/sc/programme-cost-type-csc1400selection-dialog/programme-cost-type-csc1400selection-dialog.component';
import {ProgrammeCostTypeService} from '../../service/programme-cost-type.service';
import {DynamicHeaderService} from '../../service/dynamic-header.service';
import {DynamicHeaderCsc1390selectionDialogComponent} from './dynamic-form-template/c/sc/dynamic-header-csc1390selection-dialog/dynamic-header-csc1390selection-dialog.component';
import { ProjectSroPersonnelCsc1430selectionDialogComponent } from './project-sro-personnel/c/sc/project-sro-personnel-csc1430selection-dialog/project-sro-personnel-csc1430selection-dialog.component';
import {ProjectSroPersonnelService} from '../../service/project-sro-personnel.service';
import { DocumentTypeCsc1480selectionDialogComponent } from './document-type/c/sc/document-type-csc1480selection-dialog/document-type-csc1480selection-dialog.component';
import { DocumentGroupCsc1520selectionDialogComponent } from './document-group/c/sc/document-group-csc1520selection-dialog/document-group-csc1520selection-dialog.component';
import { GanttChartDetailDialogComponent } from './gantt-chart-detail-dialog/gantt-chart-detail-dialog.component';
import { ReportTemplateCsc1540selectionDialogComponent } from './report-template/c/sc/report-template-csc1540selection-dialog/report-template-csc1540selection-dialog.component';
import { ProgrammeDocumentTypeCsc1550selectionDialogComponent } from './programme-document-type/c/sc/programme-document-type-csc1550selection-dialog/programme-document-type-csc1550selection-dialog.component';
import { ProjectReportPeriodQuartalSelectionDialogComponent } from './project-report-period-quartal-selection-dialog/project-report-period-quartal-selection-dialog.component';
import { DynamicDataDictUpdateDialogComponent } from './dynamic-data-dict-update-dialog/dynamic-data-dict-update-dialog.component';
import { ProjectStructureElementSelectionDialogComponent } from './project-structure-element-selection-dialog/project-structure-element-selection-dialog.component';
import {CustomClassificationDetailCsc2170selectionDialogComponent} from './classification-detail/c/sc/custom-classsification-detail-csc2170selection-dialog/custom-classification-detail-csc2170selection-dialog.component';
import {DocumentTypeCsc1511selectionDialogComponent} from './document-type/c/sc/document-type-csc1511selection-dialog/document-type-csc1511selection-dialog.component';
import {SroCsc1122selectionDialogComponent} from './sro/c/sc/sro-csc1122selection-dialog/sro-csc1122selection-dialog.component';
import {SroCsc1177selectionDialogComponent} from './cost-type-list-dialog/sro-csc1177selection-dialog.component';
import { SroDynamicSelectionDialogComponent } from './sro-dynamic-selection-dialog/sro-dynamic-selection-dialog.component';
import { CtpDynamicSelectionDialogComponent } from './ctp-dynamic-selection-dialog/ctp-dynamic-selection-dialog.component';
import {FznTransactionHeaderCsc8920selectionDialogComponent} from './fzn-transaction-header/c/sc/fzn-transaction-header-csc8920selection-dialog/fzn-transaction-header-csc8920selection-dialog.component';
import {ProjectHeaderCsc1251selectionCustomDialogComponent} from './project-header/c/sc/project-header-csc1251selection-custom-dialog/project-header-csc1251selection-custom-dialog.component';
import {ProjectReportPeriodMonthSelectionCsc1240selectionDialogComponent} from './project-report-period-month-slection/c/sc/project-report-period-month-selection-csc1240selection-dialog/project-report-period-month-selection-csc1240selection-dialog.component';
import {CustomClassificationDetailCscdynamicDialogComponent} from "./classification-detail/c/sc/custom-classification-detail-cscdynamic-dialog/custom-classification-detail-cscdynamic-dialog.component";
import {YfrBudgetBalanceDialogComponent} from "./yfr-budget-balance-dialog/yfr-budget-balance-dialog.component";
import {ProjectSroPersonnelBudgetDialogComponent} from "./project-sro-personnel-budget-dialog/project-sro-personnel-budget-dialog.component";

// ${import}


@NgModule({
    declarations: [
        DistrictCsc5460selectionDialogComponent,
        OrganizationUnitGbo2650selectionDialogComponent,
        MunicipalityCsc1180selectionDialogComponent,
        ClassificationDetailCsc1190selectionDialogComponent,
        FormCsc3780selectionDialogComponent,
        ExaminationTypeCsc1240selectionDialogComponent,
        ClassificationDetailCsc1410selectionDialogComponent,
        DocumentTypeCsc1510selectionDialogComponent,
        DocumentTypeCsc1511selectionDialogComponent,
        ReportTemplateCsc1520selectionDialogComponent,
        AccountCsc1550selectionDialogComponent,
        AccountGenericlogSelectionDialogComponent,
        CustomClassificationDetailCsc2190selectionDialogComponent,
        ClassificationDetailCsc1410selectionDialogUniversalComponent,
        ItemCsc1790selectionDialogComponent,
        GovernmentInstitutionCsc1830selectionDialogComponent,
        MeasuringPointCsc2010selectionDialogComponent,
        ProgrammeHeaderCsc1030selectionDialogComponent,
        AccountCsc1060selectionDialogComponent,
        CtpSelectionDialogComponent,
        SroCsc1100selectionDialogComponent,
        ProjectStructureElementDialogComponent,
        ProjectSroCsc1100selectionDialogComponent,
        ProjectHeaderCsc1250selectionDialogComponent,
        FznDocumentTypeCsc1230selectionDialogComponent,
        ProjectReportPeriodCsc1240selectionDialogComponent,
        PjhCldSelectionDialogComponent,
        DynamicFormsRowGeneratorDialogComponent,
        FormItemDialogComponent,
        FormItemStructureDialogComponent,
        CommonCodeSelectionDialogComponent,
        FormItemStructureSelectionDialogComponent,
        VFznDocumentTypeCsc1340selectionDialogComponent,
        DynamicDataDictSelectionDialogComponent,
        ProgrammeCostTypeCsc1400selectionDialogComponent,
        DynamicHeaderCsc1390selectionDialogComponent,
        ProjectSroPersonnelCsc1430selectionDialogComponent,
        DocumentTypeCsc1480selectionDialogComponent,
        DocumentGroupCsc1520selectionDialogComponent,
        GanttChartDetailDialogComponent,
        ReportTemplateCsc1540selectionDialogComponent,
        ProgrammeDocumentTypeCsc1550selectionDialogComponent,
        ProjectReportPeriodQuartalSelectionDialogComponent,
        DynamicDataDictUpdateDialogComponent,
        ProjectStructureElementSelectionDialogComponent,
        CustomClassificationDetailCsc2170selectionDialogComponent,
        SroCsc1122selectionDialogComponent,
        SroCsc1177selectionDialogComponent,
        SroDynamicSelectionDialogComponent,
        CtpDynamicSelectionDialogComponent,
        FznTransactionHeaderCsc8920selectionDialogComponent,
        ProjectHeaderCsc1251selectionCustomDialogComponent,
        ProjectReportPeriodMonthSelectionCsc1240selectionDialogComponent,
        CustomClassificationDetailCscdynamicDialogComponent,
        YfrBudgetBalanceDialogComponent,
        ProjectSroPersonnelBudgetDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        BoSharedModule,
    ],
    providers: [
        LocationService,
        AccountService,
        ClassificationDetailService,
        WindowRef,
        DynamicHeaderService,
        ProgrammeCostTypeService,
        ProjectSroPersonnelService
    ],
    exports: [
        DistrictCsc5460selectionDialogComponent,
        OrganizationUnitGbo2650selectionDialogComponent,
        MunicipalityCsc1180selectionDialogComponent,
        ClassificationDetailCsc1190selectionDialogComponent,
        FormCsc3780selectionDialogComponent,
        ExaminationTypeCsc1240selectionDialogComponent,
        ClassificationDetailCsc1410selectionDialogComponent,
        DocumentTypeCsc1510selectionDialogComponent,
        DocumentTypeCsc1511selectionDialogComponent,
        ReportTemplateCsc1520selectionDialogComponent,
        AccountCsc1550selectionDialogComponent,
        CustomClassificationDetailCsc2190selectionDialogComponent,
        ClassificationDetailCsc1410selectionDialogUniversalComponent,
        ItemCsc1790selectionDialogComponent,
        GovernmentInstitutionCsc1830selectionDialogComponent,
        MeasuringPointCsc2010selectionDialogComponent,
        AccountGenericlogSelectionDialogComponent,
        ProgrammeHeaderCsc1030selectionDialogComponent,
        AccountCsc1060selectionDialogComponent,
        CtpSelectionDialogComponent,
        SroCsc1100selectionDialogComponent,
        ProjectStructureElementDialogComponent,
        ProjectSroCsc1100selectionDialogComponent,
        ProjectHeaderCsc1250selectionDialogComponent,
        FznDocumentTypeCsc1230selectionDialogComponent,
        ProjectReportPeriodCsc1240selectionDialogComponent,
        PjhCldSelectionDialogComponent,
        DynamicFormsRowGeneratorDialogComponent,
        FormItemDialogComponent,
        FormItemStructureDialogComponent,
        VFznDocumentTypeCsc1340selectionDialogComponent,
        DynamicHeaderCsc1390selectionDialogComponent,
        ProgrammeCostTypeCsc1400selectionDialogComponent,
        ProjectSroPersonnelCsc1430selectionDialogComponent,
        DocumentTypeCsc1480selectionDialogComponent,
        DocumentGroupCsc1520selectionDialogComponent,
        GanttChartDetailDialogComponent,
        ReportTemplateCsc1540selectionDialogComponent,
        ProgrammeDocumentTypeCsc1550selectionDialogComponent,
        DynamicDataDictUpdateDialogComponent,
        CustomClassificationDetailCsc2170selectionDialogComponent,
        SroCsc1122selectionDialogComponent,
        SroCsc1177selectionDialogComponent,
        SroDynamicSelectionDialogComponent,
        FznTransactionHeaderCsc8920selectionDialogComponent,
        ProjectHeaderCsc1251selectionCustomDialogComponent,
        ProjectReportPeriodMonthSelectionCsc1240selectionDialogComponent,
        CustomClassificationDetailCscdynamicDialogComponent,
        YfrBudgetBalanceDialogComponent,
        ProjectSroPersonnelBudgetDialogComponent
    ]

})
export class BoDialogsModule {
}
