import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {District} from '../../../../../../model/district';
import {DistrictService} from '../../../../../../service/district.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-district-csc5460selection-dialog',
    templateUrl: './district-csc5460selection-dialog.component.html',
    styleUrls: ['./district-csc5460selection-dialog.component.css']
})
export class DistrictCsc5460selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public districts: District[];
    @Output() onSelected = new EventEmitter<District>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'dstExtRegno': null, 'dstName': null};


    @ViewChild('dt1', { static: false }) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'district';

    constructor(private _districtService: DistrictService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getDistricts();
    }


    selectDst(obj: any) {
        this.selectDstCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getDistricts(): void {
        this.getDistrictsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getDistrictsCustom() {
        this._districtService
            .getDistricts()
            .subscribe(districts => this.districts = districts, error => this.errorMessage = <any> error);
    }

    selectDstCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
