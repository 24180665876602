import {AfterViewChecked, OnInit, Component, EventEmitter, Output, ViewChild, OnDestroy, Inject} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {InteractionObject} from '../../shared/interaction-object';
import {ChangePasswordService} from '../../bo/service/change-password.service';
import {MessageService} from '../../core/message.service';
import {Message} from 'primeng/components/common/api';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';

@Component({
    selector: 'app-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit, OnDestroy {

    public newpassword: string;
    public confirmnewpassword: string;
    public errorMessage: string;
    private token;
    private marker: boolean;
    msgs: Message[] = [];


    /* constructor(private _changePasswordService: ChangePasswordService, private _messageService: MessageService,
                 private activatedRoute: ActivatedRoute, private _translateService: TranslateService,
                 private router: Router) {
         this.onInitCustom();

     }*/
    constructor(private _changePasswordService: ChangePasswordService, private _messageService: MessageService,
                private activatedRoute: ActivatedRoute, private _translateService: TranslateService,
                private router: Router, @Inject(DOCUMENT) private document: Document) {
        this.document.body.classList.add('login-body');
        this.onInitCustom();

    }

    // custom-constructor-end


    ngOnInit(): void {

    }


    ngOnDestroy() {
        this.onDestroyCustom();
    }


    onInitCustom() {
        this.activatedRoute.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    onDestroyCustom() {

    }


    checkPasswordMatch(password1: string, password2: string): boolean {
        if (password1 === password2) {
            return true;
        } else {
            this.errorMessage = 'Password not matched';
            this._messageService.PasswordNotMatchingMsg();
            return false;
        }
    }

    setNewPassword() {

        if (this.checkPasswordMatch(this.newpassword, this.confirmnewpassword)) {
            this._changePasswordService.saveNewPassword(this.token, this.newpassword)
                .subscribe(res => {
                        this._messageService.showSuccessSaveNewPasswordMsg();
                        setTimeout(() => {
                            this.router.navigate(['login']);
                        }, 1500);

                    },
                    error => {
                        this.errorMessage = error;
                        if (this.errorMessage.includes('You cannot use your old password')) {
                            this._translateService
                                .get('CANNOT_USE_OLD_PASSWORD').subscribe((res: string) => {
                                    this.errorMessage = res.toString();
                                }
                            );
                            this._messageService.showFailMsgText(this.errorMessage);
                        } else if (this.errorMessage.includes('token has been expired')) {
                            this._translateService
                                .get('EXPIRED_TOKEN').subscribe((res: string) => {
                                    this.errorMessage = res.toString();
                                }
                            );
                            this._messageService.showFailMsgText(this.errorMessage);
                        } else if (this.errorMessage.includes('PASSWORD_USED_ALREADY')) {
                            this._translateService
                                .get('PASSWORD_USED_ALREADY').subscribe((res: string) => {
                                    this.errorMessage = res.toString();
                                }
                            );
                            this._messageService.showFailMsgText(this.errorMessage);
                        } else if (this.errorMessage.includes('Password contains block from username')) {
                            this._translateService
                                .get('CANNOT_USE_BLOCK_PASSWORD').subscribe((res: string) => {
                                    this.errorMessage = res.toString();
                                }
                            );
                            this._messageService.showFailMsgText(this.errorMessage);
                        } else {
                            this._messageService.showFailMsgText(this.errorMessage);

                        }
                    });


        } else {
            console.log('error');
        }

    }


}
