import {Directive, HostListener, ElementRef, OnInit} from "@angular/core";
import {MyCurrencyPipe} from './my-currency.pipe';


@Directive({selector: "[myCurrencyFormatter]"})
export class MyCurrencyFormatterDirective implements OnInit {

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef,
              private currencyPipe: MyCurrencyPipe) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {

    console.log(' KORAKKKKKKKKK 1 myCurrencyFormatter');

    this.el.value = this.currencyPipe.transform(this.el.value);
  }

  @HostListener("focus", ["$event.target.value"])
  onFocus(value) {
    console.log(' focus MyCurrencyFormatterDirective MyCurrencyFormatterDirective MyCurrencyFormatterDirective MyCurrencyFormatterDirective')
    this.el.value = this.currencyPipe.parse(value); // opossite of transform
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    console.log('  blur MyCurrencyFormatterDirective MyCurrencyFormatterDirective MyCurrencyFormatterDirective MyCurrencyFormatterDirective')

    this.el.value = this.currencyPipe.transform(value);
  }

}
