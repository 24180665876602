import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {Sro} from '../model/sro';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {VSroList} from "../model/v-sro-list";
import {VSroDialogList} from "../model/v-sro-dialog-list";


@Injectable()
export class SroService {

    private baseApiUrl = globals.BASE_API_URL;
    private srosUrl = this.baseApiUrl + 'sros';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getSros(): Observable<Sro[]> {
        return this.httpClient.get<ResponseWrapper>(this.srosUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getSrosDynamicDialog(sqlQuery): Observable<VSroDialogList[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.srosUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    getVSros(): Observable<VSroList[]> {
        return this.httpClient.get<ResponseWrapper>(this.srosUrl + '/vsros').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getSro(id: number): Observable<Sro> {
        return this.httpClient.get<ResponseWrapper>(`${this.srosUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.srosUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(sro: Sro): Observable<Sro> {
        return this.httpClient
            .post<ResponseWrapper>(this.srosUrl, JSON.stringify(sro)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(sro: Sro): Observable<Sro> {
        const url = `${this.srosUrl}/${sro.sroId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(sro)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.srosUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(sro) {

        if (sro.sroSysCreateDate) {
            sro.sroSysCreateDate = new Date(sro.sroSysCreateDate);
        }
        if (sro.sroSysSyncTst) {
            sro.sroSysSyncTst = new Date(sro.sroSysSyncTst);
        }
        if (sro.sroSysUpdateDate) {
            sro.sroSysUpdateDate = new Date(sro.sroSysUpdateDate);
        }
        if (sro.sroAccreditationActDate) {
            sro.sroAccreditationActDate = new Date(sro.sroAccreditationActDate);
        }
        return sro;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }

    getVSroList(request) {
        return this.httpClient.post<ResponseWrapper>(this.srosUrl + '/LazyLoad', JSON.stringify(request)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    // custom-start

    getSroByPjhId(pjhId: number): Observable<Sro[]> {
        const url = `${this.srosUrl}/byPjhId/${pjhId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getSroByPjhIdAndFthId(pjhId: number, fthId: number): Observable<VSroDialogList[]> {
        const url = `${this.srosUrl}/byPjhIdAndFthId/${pjhId}/${fthId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



