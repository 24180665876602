import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HomeLayoutComponent} from './home-layout-component/home-layout.component';
import {AuthService} from '../core/auth.service';
import {interval, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Subscription} from 'rxjs';
import {BoInteractionService} from '../shared/bo-interaction.service';
import {MessageService} from '../core/message.service';
import {Router} from '@angular/router';
import {InfoService} from '../core/info.service';
import {OrganizationUnitService} from '../bo/service/organization-unit.service';
import {TskNotification} from '../bo/model/tsk-notification';
import {TskNotificationService} from '../bo/service/tsk-notification.service';
import {ActivitiService} from '../bo/service/activiti.service';
import {ActiveProcessInfoService} from '../bo/service/active-process-info.service';
import {TskNotificationObjectList} from '../bo/model/tsk-notification-object-list';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="topbar clearfix">
            <div class="topbar-right" style="background:#108bd7; border-radius: 3px">

                <!--Fond za nauku -logo-->
                <a href="#">
                    <img border="0" src="assets/layout/images/FZN-logo.png" height="55" *ngIf="showDefault">
                    <img border="0" src="assets/layout/images/FZN-logo.png" height="50" *ngIf="!showDefault">
                </a>

                <!--Strelica pored logoa-->
                <a *ngIf="true" id="menu-button" href="#" (click)="app.onMenuButtonClick($event)"
                   [ngClass]="{'menu-button-rotate': app.rotateMenuButton}" style="color:#043858;">
                    <i class="fa fa-angle-left"></i>
                </a>

                <app-info [style.display]="showDefault?'none':null"
                          style="position: absolute; left: 280px; font-weight: bolder;">
                </app-info>

                <!-- <a *ngIf="false" id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)"><i class="fa fa-bars"></i></a>-->

                <ul class="topbar-items fadeInDown" [ngClass]="{'topbar-items-visible': app.topbarMenuActive}">

                    <!--Profil-->
                    <li #profile *ngIf="app.profileMode==='top'||app.isHorizontal()"
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">
                        <a href="#" (click)="app.onTopbarItemClick($event,profile)">
                            <table>
                                <tr>
                                    <td><span style="color: white">{{user ? user.accNameSurname : ''}}</span></td>
                                </tr>
                                <tr>
                                    <td><span
                                            style="color: white">{{organizationUnit ? organizationUnit.oruFullname : ''}}</span>
                                    </td>
                                </tr>
                            </table>
                        </a>
                        <ul class="layout-menu fadeInDown">
                            <!-- profile start -->
                            <li role="menuitem">
                                <a href="#/user-administration/bo-csc4180main">
                                    <i class="fa fa-fw fa-user"></i>
                                    <span>{{'PROFILE_MENU_BUTTON_PROFILE' | translate}}</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="showLogoutDlg()">
                                    <i class="fa fa-fw fa-sign-out"></i>
                                    <span>{{'PROFILE_MENU_BUTTON_LOGOUT' | translate}}</span>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li style="padding-top: 6px;" #settings
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === settings}">
                        <!--<a href="#" (click)="app.onTopbarItemClick($event,settings)">-->
                        <!--<i class="topbar-icon fa fa-fw fa-cog"></i>-->
                        <!--<span class="topbar-item-name">Settings</span>-->
                        <!--</a>-->
                        <ul class="layout-menu fadeInDown">
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="fa fa-fw fa-paint-brush"></i>
                                    <span>Change Theme</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="fa fa-fw fa-star-o"></i>
                                    <span>Favorites</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="fa fa-fw fa-lock"></i>
                                    <span>Lock Screen</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="fa fa-fw fa-picture-o"></i>
                                    <span>Wallpaper</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    
                    <!--Prikazivanje notifikacija po organizacionoj jedinici, ukoliko ima takvih notifikacija, a ukoliko nema, ne prikazuje se ni zvonce za ove notifikacije-->
                    <li style="padding-top: 6px;" #notifications *ngIf="caseActivitysListNoDocument!=null && numCasActivitiesNotSeen !== 0"
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                        <a href="#" (click)="app.onTopbarItemClick($event,notifications)">
                            <i style="font-weight: normal;" class=" topbar-icon animated swing fa fa-fw fa-bell"></i>
                            <span *ngIf="caseActivitysListNoDocument!=null && numCasActivitiesNotSeen !== 0"
                                  class="topbar-badge animated rubberBand">
                                {{ numCasActivitiesNotSeen }}
                            </span>
                            <span class="topbar-item-name">Notifications</span>
                        </a>
                        <ul class="layout-menu fadeInDown" style="width: 400px">
                            <div *ngIf="caseActivitysListNoDocument !=null && caseActivitysListNoDocument.length>0">
                                <p-dataScroller [value]="caseActivitysListNoDocument" [rows]="rowNumber"
                                                *ngIf="activeWithDocument"
                                                scrollHeight="500px" [inline]="true" class="menuMotificationScroller"
                                                [loader]="loadButton2" id="msgScroll">
                                    <ng-template let-caseActivity pTemplate="item">
                                        <div class="ui-grid ui-grid-responsive ui-fluid"
                                             style="font-size:16px;padding:2px;border-bottom:1px solid #D5D5D5;">
                                            <div class="ui-grid-col-12">
                                                <div class="ui-grid-row" style="height: 100%;width: 100%">
                                                    <div class="ui-g-12">
                                                        <p style="height: 100%; width: 100%; font-size: 14px; margin-bottom: 8px;margin-left: 50px"
                                                           (click)="openSelectedbyGthId(caseActivity)">{{caseActivity.tnoDescription}}</p>
                                                    </div>
                                                    <div class="ui-g-1" *ngIf="!caseActivity.tnoSeen"
                                                         style="margin: auto;">
                                                        <i class="fa fa-circle" style="color: #0D4173"
                                                           (click)="markOneAsSeen(caseActivity)"></i>
                                                    </div>
                                                </div>
                                                <span style="font-size: 12px; margin-right: 5px;">
                                                        <span style="margin-right: 3px;" class="fa fa-clock-o"></span>
                                                    {{caseActivity.tnoDate | date: 'dd.MM.yyyy HH:mm'}}
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p-footer>
                                        <button #loadButton2 type="text" onclick="event.stopPropagation()"
                                                (click)="loadMoreNotificationNoDocument()" icon="fa fa-refresh" pButton
                                                label="Учитај још"></button>
                                    </p-footer>
                                </p-dataScroller>
                            </div>
                            <div style="text-align:center"
                                 *ngIf="caseActivitysListWithDocument !=null && caseActivitysListWithDocument.length<1">
                                <span>{{'NO_NOTIFICATIONS' | translate}}</span>
                            </div>
                        </ul>
                    </li>

                    <!--Prikazivanje notifikacija po ulogovanom account-u, ukoliko ima takvih, a ukoliko nema, zvonce ce se i dalje videti ali je prazno-->
                    <!--Kod fzn aplikacije, dogovor je da se notifikacije prikazuju po account-u, tako da necemo ni imati notifikacije po org.jedinicama ni po roli-->
                    <li style="padding-top: 6px;" #notificationsPersonal
                        [ngClass]="{'active-top-menu':app.activeTopbarItem === notificationsPersonal}">
                        <a href="#" (click)="app.onTopbarItemClick($event,notificationsPersonal)">
                            <i style="font-weight: normal;" class=" topbar-icon animated swing fa fa-fw fa-bell"></i>
                            <span *ngIf="caseActivitysPersonalListNoDocument!=null && numCasActivitiesPersonalNotSeen !== 0"
                                  class="topbar-badge animated rubberBand">
                                {{ numCasActivitiesPersonalNotSeen }}
                            </span>
                            <span class="topbar-item-name">Notifications personal</span>
                        </a>
                        <ul class="layout-menu fadeInDown" style="width: 400px">
                            <div *ngIf="caseActivitysPersonalListNoDocument !=null && caseActivitysPersonalListNoDocument.length>0">
                                <p-dataScroller [value]="caseActivitysPersonalListNoDocument" [rows]="rowNumber"
                                                *ngIf="activePersonalWithDocument"
                                                scrollHeight="500px" [inline]="true" class="menuMotificationScroller"
                                                [loader]="loadButton2" id="msgScroll">
                                    <ng-template let-caseActivity pTemplate="item">
                                        <div class="ui-grid ui-grid-responsive ui-fluid"
                                             style="font-size:16px;padding:2px;border-bottom:1px solid #D5D5D5;">
                                            <div class="ui-grid-col-12">
                                                <div class="ui-grid-row" style="height: 100%;width: 100%">
                                                    <div class="ui-g-12">
                                                        <p style="height: 100%; width: 100%; font-size: 14px; margin-bottom: 8px;margin-left: 50px"
                                                           (click)="openSelectedbyGthId(caseActivity)">{{caseActivity.tnoDescription}}</p>
                                                    </div>
                                                    <div class="ui-g-1" *ngIf="!caseActivity.tnoSeen"
                                                         style="margin: auto;">
                                                        <i class="fa fa-circle" style="color: #0D4173"
                                                           (click)="markOneAsSeen(caseActivity)"></i>
                                                    </div>
                                                </div>
                                                <span style="font-size: 12px; margin-right: 5px;">
                                                        <span style="margin-right: 3px;" class="fa fa-clock-o"></span>
                                                    {{caseActivity.tnoDate | date: 'dd.MM.yyyy HH:mm'}}
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p-footer>
                                        <button #loadButton2 type="text" onclick="event.stopPropagation()"
                                                (click)="loadMorePersonalNotificationNoDocument()" icon="fa fa-refresh" pButton
                                                label="Учитај још"></button>
                                    </p-footer>
                                </p-dataScroller>
                            </div>
                            <div style="text-align:center"
                                 *ngIf="caseActivitysPersonalListWithDocument !=null && caseActivitysPersonalListWithDocument.length<1">
                                <span>{{'NO_NOTIFICATIONS' | translate}}</span>
                            </div>
                        </ul>
                    </li>

                    <!-- Prikazivanje notifikacija po roli, ukoliko ima takvih notifikacija, a ukoliko nema, ne prikazuje se ni zvonce za ove notifikacije-->
                    <li style="padding-top: 6px;" #notificationsRole *ngIf="caseActivitysRoleListNoDocument!=null && numCasActivitiesRoleNotSeen !== 0"
                         [ngClass]="{'active-top-menu':app.activeTopbarItem === notificationsRole}">
                        <a href="#" (click)="app.onTopbarItemClick($event,notificationsRole)">
                            <i style="font-weight: normal;" class=" topbar-icon animated swing fa fa-fw fa-bell"></i>
                            <span *ngIf="caseActivitysRoleListNoDocument!=null && numCasActivitiesRoleNotSeen !== 0"
                                  class="topbar-badge animated rubberBand">{{ numCasActivitiesRoleNotSeen }}</span>
                            <span class="topbar-item-name">Messages</span>
                        </a>
                        <ul class="layout-menu fadeInDown" style="width: 400px">
                            <div *ngIf="caseActivitysRoleListNoDocument !=null && caseActivitysRoleListNoDocument.length>0">
                                <p-dataScroller [value]="caseActivitysRoleListNoDocument" [rows]="rowNumber"
                                                *ngIf="activeRoleWithDocument"
                                                scrollHeight="500px" [inline]="true" class="menuMotificationScroller"
                                                [loader]="loadButton3" id="msgScroll">
                                    <ng-template let-caseActivity pTemplate="item">
                                        <div class="ui-grid ui-grid-responsive ui-fluid"
                                             style="font-size:16px;padding:2px;border-bottom:1px solid #D5D5D5;">
                                            <div class="ui-grid-col-12">
                                                <div class="ui-grid-row" style="height: 100%;width: 100%">
                                                    <div class="ui-g-12">
                                                        <p style="height: 100%; width: 100%; font-size: 14px; margin-bottom: 8px;margin-left: 50px"
                                                           (click)="openSelectedbyGthId(caseActivity)">{{caseActivity.tnoDescription}}</p>
                                                    </div>
                                                    <div class="ui-g-1" *ngIf="!caseActivity.tnoSeen"
                                                         style="margin: auto;">
                                                        <i class="fa fa-circle" style="color: #0D4173"
                                                           (click)="markOneAsSeen(caseActivity)"></i>
                                                    </div>
                                                </div>
                                                <span style="font-size: 12px; margin-right: 5px;">
                                                    <span style="margin-right: 3px;" class="fa fa-clock-o"></span>
                                                    {{caseActivity.tnoDate | date: 'dd.MM.yyyy HH:mm'}}
                                                </span>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <p-footer>
                                        <button #loadButton3 type="text" onclick="event.stopPropagation()"
                                                (click)="loadMoreNotificationNoDocumentForRole()" icon="fa fa-refresh"
                                                pButton label="Учитај још"></button>
                                    </p-footer>
                                </p-dataScroller>
                            </div>
                            <div style="text-align:center"
                                 *ngIf="caseActivitysRoleListWithDocument !=null && caseActivitysRoleListWithDocument.length<1">
                                <span>{{'NO_NOTIFICATIONS' | translate}}</span>
                            </div>
                        </ul>
                    </li>
                </ul>

            </div>
        </div>

        <p-dialog draggable="false" [draggable]="true" [resizable]="false" [(visible)]="displayLogoutDlg"
                  [modal]="true"
                  appendTo="body">
            <div>{{'LOGOUT_WARNING' | translate}}<br/>
                <button pButton type="button" id="btn-logout-app-topbar" (click)="logout()"
                        [label]="'BTN_YES' | translate"></button>
                <button pButton type="button" (click)="cancelLogout()" [label]="'BTN_NO' | translate"></button>
            </div>
        </p-dialog>

        <p-dialog draggable="false" [draggable]="true" [resizable]="false" [(visible)]="displayWarnDlg" [modal]="true"
                  appendTo="body" header="Обавештење">
            <div>Немате право приступа овом предмету јер вам је истекла улога или је деактивирана. Потребно је да се
                обратите вашем ИТ
                администратору<br/>
            </div>
        </p-dialog>

    `
})
export class AppTopBarComponent implements OnInit, OnDestroy {
    appTitle: string;
    user: any;
    organizationUnit: any;
    public selectedObj: any;
    public selectedMode: string;
    apiId: string;

    rowNumber = 3;
    rowNumberSeen: number;
    rowPersonalNumber = 3;
    rowPersonalNumberSeen: number;

    caseActivitysListWithDocument: TskNotification[];
    caseActivitysListNoDocument: TskNotification[];
    activeWithDocument = true;
    activeNoDocument = true;
    numCasActivitiesNotSeen: number;

    caseActivitysPersonalListWithDocument: TskNotification[];
    caseActivitysPersonalListNoDocument: TskNotification[];
    activePersonalWithDocument = true;
    activePersonalNoDocument = true;
    numCasActivitiesPersonalNotSeen: number;

    caseActivitysRoleListWithDocument: TskNotification[];
    caseActivitysRoleListNoDocument: TskNotification[];
    activeRoleWithDocument = true;
    activeRoleNoDocument = true;
    numCasActivitiesRoleNotSeen: number;

    private objectWithDocumentSeen: TskNotificationObjectList = new TskNotificationObjectList();
    private objectNoDocumentSeen: TskNotificationObjectList = new TskNotificationObjectList();
    private objectPersonalNoDocumentSeen: TskNotificationObjectList = new TskNotificationObjectList();
    private objectRoleNoDocumentSeen: TskNotificationObjectList = new TskNotificationObjectList();

    errorMessage: string;
    ntfNotSeenStyle = {'background-color': 'lightgrey', color: 'black', padding: '2px', cursor: 'pointer'};
    ntfSeenStyle = {padding: '2px', cursor: 'pointer'};

    sub1: Subscription;
    sub2: Subscription;
    sub3: Subscription;
    sub4: Subscription;
    sub5: Subscription;

    // 01.03.2020 Nenad ovo je period osvjezavanja notifikacije
    // kada se ode u metod gdje je primjenjen vidi se poziv dva metoda za same notifikacije
    // komponenta za osvjezavanje je pending-interceptor.ts
    // u toj komponenti se nalazi "exclude" poziva putanja da se ne vidi "loading screen"
    notificationRefreshInterval = interval(60000);
    notificationSubscribtion: Subscription;
    formMap: any;
    displayWarnDlg = false;
    displayLogoutDlg = false;
    public showDefault = true;

    constructor(public app: HomeLayoutComponent,
                private tskNotificationService: TskNotificationService,
                private interactionService: BoInteractionService,
                private authService: AuthService,
                private activitiService: ActivitiService,
                private messageService: MessageService,
                private activeProcessInfoService: ActiveProcessInfoService,
                private router: Router,
                private infoService: InfoService,
                private organizatonUnitService: OrganizationUnitService) {

        this.appTitle = environment.PROJECT.toLocaleUpperCase();
        this.rowNumberSeen = this.rowNumber;

        this.sub1 = this.authService.organizationUnitChanged$.subscribe(res => {
            this.organizationUnit = res;
            this.getNotificationsForUserAndOrgUnit();
        });

        this.sub2 = this.authService.userLoaded$.subscribe(res => {
            this.getUser();
            this.getNotificationsForUserAndOrgUnit();
        });

        this.sub3 = this.authService.organizationUnitChanged$.subscribe(res => {
            this.authService.getFormsForUser().subscribe(f1 => {
                    this.formMap = f1;
                },
                err => {
                    console.log(err);
                }
            );
        });

        this.sub4 = this.infoService.infoAnnounced$.subscribe(
            m => {
                if (m) {
                    this.showDefault = false;
                } else {
                    this.showDefault = true;
                }
            });

        this.sub5 = this.router.events.subscribe((val) => {
            console.log('route: ' + val);
            this.showDefault = true;
        });

        this.getUser();
        this.getNotificationsForUserAndOrgUnit();
    }

    getUser() {
        this.authService.getUser().subscribe(
            rs => {
                this.user = rs;
                if (this.user != null) {
                    this.organizatonUnitService.getOrganizationUnit(this.user.organizationUnit.oruId).subscribe(res => {
                        this.organizationUnit = res;
                    });
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    logout() {
        console.log('menu logout');
        this.authService.logoutLog(this.user.accName).subscribe(
            res => {
                if (this.notificationSubscribtion) {
                    this.notificationSubscribtion.unsubscribe();
                }
                this.authService.logout();
            },
            err => {
                if (this.notificationSubscribtion) {
                    this.notificationSubscribtion.unsubscribe();
                }
                this.authService.logout();
            }
        );
    }

    ngOnInit() {
        this.authService.getFormsForUser().subscribe(f1 => {
                this.formMap = f1;
            },
            err => {
                console.log(err);
            });
    }

    showLogoutDlg() {
        this.displayLogoutDlg = true;
    }

    cancelLogout() {
        this.displayLogoutDlg = false;
    }

    profileView() {
        this.router.navigate(['user-administration/bo-csc4180main']);
    }

    markOneAsSeen(tskNotification: TskNotification) {
        this.tskNotificationService.markSeenCaseActivity(tskNotification).subscribe(
            res => {
                // this.getCaseActivityNotifications(this.organizationUnit.oruId);
                this.getPersonalCaseActivityNotifications(this.user.accId);
                //  this.getCaseActivityNotificationsForRole(this.user.accRole);

                if (tskNotification != null && tskNotification.tnoCasProcessInstanceId != null) {
                    this.activitiService.findApiIdByApiProcessInstanceId(tskNotification.tnoCasProcessInstanceId).subscribe(
                        res2 => {
                            this.apiId = res2;
                            this.activeProcessInfoService.checkTaskRolePermissionTsk(this.organizationUnit.oruId, this.user.accId, this.apiId).subscribe(
                                ress => {
                                    if (ress) {
                                        this.selectedMode = 'startTask';
                                        this.router.navigate(['case-management/bo-csc20000main', this.apiId]);
                                    } else {
                                        this.displayWarnDlg = true;
                                    }
                                }
                            );
                        },
                        error => {
                            this.errorMessage = error as any;
                            // debugger;
                            this.messageService.showFailMsg(error);
                        });
                }
            }
        );
    }

    getCaseActivityNotifications(oruId: number) {
        this.activeWithDocument = false;
        this.activeNoDocument = false;
        this.tskNotificationService
            .findNonSeenForOrgUnit(oruId)
            .subscribe(caseActivitys => {
                if (caseActivitys != null) {
                    this.caseActivitysListWithDocument = caseActivitys;
                    this.caseActivitysListNoDocument = caseActivitys;
                } else {
                    this.caseActivitysListWithDocument = [];
                    this.caseActivitysListNoDocument = [];
                }
                this.numCasActivitiesNotSeen = this.caseActivitysListNoDocument.filter(el => !el.tnoSeen).length;
                this.activeWithDocument = true;
                this.activeNoDocument = true;
            });
    }

    getPersonalCaseActivityNotifications(accId: number) {
        this.activePersonalWithDocument = false;
        this.activePersonalNoDocument = false;
        this.tskNotificationService
            .findNonSeenForUser(accId)
            .subscribe(caseActivitys => {
                if (caseActivitys != null) {
                    this.caseActivitysPersonalListWithDocument = caseActivitys;
                    this.caseActivitysPersonalListNoDocument = caseActivitys;
                } else {
                    this.caseActivitysPersonalListWithDocument = [];
                    this.caseActivitysPersonalListNoDocument = [];
                }
                this.numCasActivitiesPersonalNotSeen = this.caseActivitysPersonalListNoDocument.filter(el => !el.tnoSeen).length;
                this.activePersonalWithDocument = true;
                this.activePersonalNoDocument = true;
            });
    }

    getCaseActivityNotificationsForRole(role: string) {
        this.activeRoleWithDocument = false;
        this.activeRoleNoDocument = false;
        this.tskNotificationService
            .findNonSeenForRole(role)
            .subscribe(caseActivitys => {
                if (caseActivitys != null) {
                    this.caseActivitysRoleListWithDocument = caseActivitys;
                    this.caseActivitysRoleListNoDocument = caseActivitys;
                } else {
                    this.caseActivitysRoleListWithDocument = [];
                    this.caseActivitysRoleListNoDocument = [];
                }
                this.numCasActivitiesRoleNotSeen = this.caseActivitysRoleListNoDocument.filter(el => !el.tnoSeen).length;
                this.activeRoleWithDocument = true;
                this.activeRoleNoDocument = true;
            });
    }

    public loadMoreNotificationNoDocument() {
        let number: number = null;
        if (this.objectNoDocumentSeen.listTskNotificationBO != null) {
            number = this.rowNumber + this.objectNoDocumentSeen.listTskNotificationBO.length;
        } else {
            number = this.rowNumberSeen + Math.min(this.rowNumber, this.caseActivitysListNoDocument.length - this.rowNumber);
        }
        this.rowNumberSeen = number;
        this.objectNoDocumentSeen.listTskNotificationBO = this.caseActivitysListNoDocument.slice(0, number);
    }

    public loadMorePersonalNotificationNoDocument() {
        let number: number = null;
        if (this.objectPersonalNoDocumentSeen.listTskNotificationBO != null) {
            number = this.rowPersonalNumber + this.objectPersonalNoDocumentSeen.listTskNotificationBO.length;
        } else {
            number = this.rowPersonalNumberSeen + Math.min(this.rowPersonalNumber, this.caseActivitysPersonalListNoDocument.length - this.rowPersonalNumber);
        }
        this.rowPersonalNumberSeen = number;
        this.objectPersonalNoDocumentSeen.listTskNotificationBO = this.caseActivitysPersonalListNoDocument.slice(0, number);
    }

    public loadMoreNotificationNoDocumentForRole() {
        let number: number = null;
        if (this.objectRoleNoDocumentSeen.listTskNotificationBO != null) {
            number = this.rowNumber + this.objectRoleNoDocumentSeen.listTskNotificationBO.length;
        } else {
            number = this.rowNumberSeen + Math.min(this.rowNumber, this.caseActivitysRoleListNoDocument.length - this.rowNumber);
        }
        this.rowNumberSeen = number;
        this.objectRoleNoDocumentSeen.listTskNotificationBO = this.caseActivitysRoleListNoDocument.slice(0, number);
    }


    showHelpDeskDetail() {
        // this.helpDeskDlg.showDlg();
    }

    // Ova metoda ce povlaciti notifikacije samo po user-u, tj. account-u
    getNotificationsForUserAndOrgUnit() {
        this.authService.getUser().subscribe(
            rs => {
                this.user = rs;
                if (this.user != null) {
                    this.organizatonUnitService.getOrganizationUnit(this.user.organizationUnit.oruId).subscribe(res => {
                        this.organizationUnit = res;
                        //   this.getCaseActivityNotifications(this.organizationUnit.oruId);
                    });
                }

                this.getPersonalCaseActivityNotifications(this.user.accId);
                // this.getCaseActivityNotificationsForRole(this.user.accRole);


                if (this.notificationSubscribtion) {
                    this.notificationSubscribtion.unsubscribe();
                }

                this.notificationSubscribtion = this.notificationRefreshInterval.subscribe(() => {
                    // this.getCaseActivityNotifications(this.organizationUnit.oruId);
                    this.getPersonalCaseActivityNotifications(this.user.accId);
                    // this.getCaseActivityNotificationsForRole(this.user.accRole);
                });
            }, err => {
                    console.log(err);
            });
    }

    ngOnDestroy() {
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        if (this.sub2) {
            this.sub2.unsubscribe();
        }
        if (this.sub3) {
            this.sub3.unsubscribe();
        }
        if (this.sub4) {
            this.sub4.unsubscribe();
        }
        if (this.sub5) {
            this.sub5.unsubscribe();
        }
        if (this.notificationSubscribtion) {
            this.notificationSubscribtion.unsubscribe();
        }
    }

    // svrha ovog zahteva je otvaranje forme direktno iz izabrane notifikacije
    // privremeno zakucano da klikom na izabranu notifikaciju se ide na putanju fin/bo-change-approp-csc3810main/notificationGthId
    // ova putanja je dodata u fin-routing.module.ts sa dodatkom parametra notificationGthId koji se salje na listu u komponenti
    // posto postoji 6 razlicitih strana da bi znali koju main stranu odnosno pripadajucu listu otvaramo
    // bice potrebno dodati jos jedno polje u tsk_notification tabeli da bi prilikom kreiranja notifikacije
    // upisali i taj string koji ce biti sama putanja
    // polje za putanju je dodato tnoUrl
    // dodat parametar tno_mode za otvaranje notifikacije u view ili edit modu
    openSelectedbyGthId(tskNotification: TskNotification) {
        this.markOneAsSeen(tskNotification);
        this.selectedMode = 'startTask';
        if (tskNotification.tnoMode != null) {
            if (tskNotification.tnoMode != null) {
                this.router.navigate([tskNotification.tnoUrl + '/' + tskNotification.tnoFthId + '/' + tskNotification.tnoMode]);
            } else {
                this.router.navigate([tskNotification.tnoUrl + '/' + tskNotification.tnoFthId]);
            }
        } else {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigateByUrl(tskNotification.tnoUrl).then(() => {
                this.router.onSameUrlNavigation = 'ignore';
            });
        }
    }

}
