import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProjectSroPersonnel} from '../model/project-sro-personnel';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {ProjectSro} from '../model/project-sro';
import {ProjectSroPersonnelBudget} from "../model/project-sro-personnel-budget";


@Injectable()
export class ProjectSroPersonnelService {

    private baseApiUrl = globals.BASE_API_URL;
    private projectSroPersonnelsUrl = this.baseApiUrl + 'projectSroPersonnels';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProjectSroPersonnels(): Observable<ProjectSroPersonnel[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectSroPersonnelsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnel(id: number): Observable<ProjectSroPersonnel> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectSroPersonnelsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.projectSroPersonnelsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(psp: ProjectSroPersonnel): Observable<ProjectSroPersonnel> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectSroPersonnelsUrl, JSON.stringify(psp)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(projectSroPersonnel: ProjectSroPersonnel): Observable<ProjectSroPersonnel> {
        const url = `${this.projectSroPersonnelsUrl}/${projectSroPersonnel.pspId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(projectSroPersonnel)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.projectSroPersonnelsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(projectSroPersonnel) {

        if (projectSroPersonnel.pspSysUpdateDate) {
            projectSroPersonnel.pspSysUpdateDate = new Date(projectSroPersonnel.pspSysUpdateDate);
        }
        if (projectSroPersonnel.pspSysCreateDate) {
            projectSroPersonnel.pspSysCreateDate = new Date(projectSroPersonnel.pspSysCreateDate);
        }
        if (projectSroPersonnel.pspSysSyncTst) {
            projectSroPersonnel.pspSysSyncTst = new Date(projectSroPersonnel.pspSysSyncTst);
        }
        if (projectSroPersonnel.pspTitleStartDate) {
            projectSroPersonnel.pspTitleStartDate = new Date(projectSroPersonnel.pspTitleStartDate);
        }
        if (projectSroPersonnel.pspTitleEndDate) {
            projectSroPersonnel.pspTitleEndDate = new Date(projectSroPersonnel.pspTitleEndDate);
        }
        if (projectSroPersonnel.pspEmploymentStartDate) {
            projectSroPersonnel.pspEmploymentStartDate = new Date(projectSroPersonnel.pspEmploymentStartDate);
        }
        if (projectSroPersonnel.pspEmploymentEndDate) {
            projectSroPersonnel.pspEmploymentEndDate = new Date(projectSroPersonnel.pspEmploymentEndDate);
        }
        return projectSroPersonnel;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    getProjectSroPersonnelsByProjectSroId(projectSroId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/byProjectSroId/${projectSroId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelByProjectHeaderIdAndPspType(projectHeaderId: number, pspType: string): Observable<ProjectSroPersonnel> {
        const url = `${this.projectSroPersonnelsUrl}/ByProjectHeaderIdAndPspType/${projectHeaderId}/${pspType}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelsByProjectHeaderId(projectHeaderId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/AllByProjectHeaderId/${projectHeaderId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelsByProjectHeaderIdAndSroIdAndFthIdAndPdfId(pjhId: number, sroId: number, fthId: number, pdfId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/AllByProjectHeaderIdAndSroIdAndFthIdAndPdfId/${pjhId}/${sroId}/${fthId}/${pdfId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelsByProjectHeaderIdAndFthIdAndPdfId(pjhId: number, fthId: number, pdfId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/AllByProjectHeaderIdAndFthIdAndPdfId/${pjhId}/${fthId}/${pdfId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    projectSroPersonnelsByFthIdAndPspTypePi(fthId: number, pspType: string): Observable<ProjectSroPersonnel> {
        const url = `${this.projectSroPersonnelsUrl}ByFthIdAndPspTypePi/${fthId}/${pspType}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    showDlgByProjectHeaderIdAndSroId(projectHeaderId: number, sroId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/AllByProjectHeaderIdAndSroId/${projectHeaderId}/${sroId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelAllByFthId(fthId: number): Observable<ProjectSroPersonnel[]> {
        const url = `${this.projectSroPersonnelsUrl}/AllByFthId/${fthId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroPersonnelBudgetByFthIdAndYfrFthId(fthId: number, yfrFthId: number): Observable<ProjectSroPersonnelBudget[]> {
        const url = `${this.projectSroPersonnelsUrl}/BudgetByFthIdAndYfrFthId/${fthId}/${yfrFthId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



