import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProjectHeader} from '../../../../../../model/project-header';
import {ProjectHeaderService} from '../../../../../../service/project-header.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {AuthService} from '../../../../../../../core/auth.service';
import {VFznTransactionHeader} from '../../../../../../model/v-fzn-transaction-header';
import {VFznTransactionHeaderService} from '../../../../../../service/v-fzn-transaction-header.service';
import {FznTransactionHeader} from '../../../../../../model/fzn-transaction-header';

@Component({
    selector: 'app-fzn-transaction-header-csc8920selection-dialog',
    templateUrl: './fzn-transaction-header-csc8920selection-dialog.component.html',
    styleUrls: ['./fzn-transaction-header-csc8920selection-dialog.component.css']
})
export class FznTransactionHeaderCsc8920selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public vFznTransactionHeaders: VFznTransactionHeader[];
    lazyLoadParams: any;
    totalRecords: number;
    @Output() onSelected = new EventEmitter<FznTransactionHeader>();
    public errorMessage: string;
    visible = false;

    loggedAccId: number;

    tfFilterMap = {
        'prhAcronym': null,
        'pjhAcronym': null,
        'fdtCode': null,
        'fthDocNo': null
    };

    exportCols = ['prhAcronym', 'pjhAcronym', 'fdtCode', 'fthDocNo'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'v-fzn-transaction-header';

    user: any;

    roles: any;

    constructor(private vFznTransactionHeaderService: VFznTransactionHeaderService,
                private auth: AuthService) {
        super();
        this.roles = this.auth.getRoles();
    }

    // custom-constructor-end


    ngOnInit(): void {
    }


    selectFth(obj: any) {
        this.selectFthCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        // this.getProjectHeaders();
        this.getVFznTransactionHeaders();
    }

    getProjectHeaders(): void {
        this.getProjectHeadersCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProjectHeadersCustom() {
        // this.projectHeaderService
        //     .getProjectHeaders()
        //     .subscribe(projectHeaders => {
        //         this.projectHeaders = projectHeaders;
        //         this.projectHeaders.sort((pjh1, pjh2) => {
        //             return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //         });
        //         this.visible = true;
        //     }, error => this.errorMessage = <any> error);

        // this.projectHeaderService
        //     .getProjectHeadersByAccId(this.user.accId)
        //     .subscribe(projectHeaders => {
        //         this.projectHeaders = projectHeaders;
        //         this.projectHeaders.sort((pjh1, pjh2) => {
        //             return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //         });
        //         this.visible = true;
        //     }, error => this.errorMessage = <any> error);

        // 04.04.2022 Nenad: dodat metod provijere prijavljenje uloge korisnika
        // ako je uloga RUKOVODILAC_PROJEKTA onda vidi svoje projekte samo
        // ako je uloga FZN_KONTROLOR on vidi sve
        // if (this.roles.includes('ROLE_RUKOVODILAC_PROJEKTA') && !this.roles.includes('ROLE_FZN_KONTROLOR')) {
        //
        //     // 11.03.2022 Nenad: dodat novi metod za popunu dijaloga
        //     // ovo je bilo originalno ranije uradjeno, ali je izvrsena dorada 04.04.2022
        //     this.auth.getUser().subscribe(
        //         rs => {
        //             this.user = rs;
        //             this.projectHeaderService
        //                 .getProjectHeadersByAccId(this.user.accId)
        //                 .subscribe(projectHeaders => {
        //                     this.projectHeaders = projectHeaders;
        //                     this.projectHeaders.sort((pjh1, pjh2) => {
        //                         return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //                     });
        //                     this.visible = true;
        //                 }, error => this.errorMessage = <any> error);
        //
        //         }, err => {
        //             console.log(err);
        //         }
        //     );
        // }
        // // 04.04.2022 Nenad: ovo je dodato za ulogu FZN_KONTROLOR
        // if ((this.roles.includes('ROLE_FZN_KONTROLOR') || this.roles.includes('ROLE_ADMIN')) && !this.roles.includes('ROLE_RUKOVODILAC_PROJEKTA')) {
        //     this.projectHeaderService
        //         .getProjectHeadersAllCustom()
        //         .subscribe(projectHeaders => {
        //             this.projectHeaders = projectHeaders;
        //             this.projectHeaders.sort((pjh1, pjh2) => {
        //                 return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //             });
        //             this.visible = true;
        //         }, error => this.errorMessage = <any> error);
        // }

    }

    selectFthCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    getVFznTransactionHeaders(): void {
        this.getVFznTransactionHeadersCustom();
        this.visible = true;
    }

    getVFznTransactionHeadersCustom(showSuccessMsg?: boolean) {
        if (this.lazyLoadParams) {
            // this.fillLazyFilters(); // ovo generalno ne treba ovde da pozivamo, samo u izuzetnim situacijama
            this.vFznTransactionHeaderService.getVFznTransactionHeaderLazy(this.lazyLoadParams).subscribe(
                res => {
                    this.vFznTransactionHeaders = res.value;
                    this.totalRecords = res.totalRecords;
                }, error => this.errorMessage = <any>error);
        }
    }

    fillLazyFilters() {
        if (this.lazyLoadParams) {
            this.lazyLoadParams.filters = [];
            const z1 = this.tfFilterMap;
            const z2 = this.dateFilterMap;
            const z3 = this.booleanFilterMap;
            const z4 = this.tfDateFilterMap;
            // 14.03.2022 Nenad: dodat komentar na ovaj dan
            // dodat const z6 za multiselect padajuci meni koji je dodat na kolonu Status
            // da bi multiselect radio, ovaj z6 i njegova pripadajuca funkcija su dodate
            // i na baseList komponenti
            // s tim sto je dodat z6 i u metod clearFilters(inace ce biti greska)
            // i u metod filterField
            // sustina je: da bi multiselect radio, potrebno je dodati i u baseList i ovdje u komponentu
            // jer se tabela isto tako filtira metodom fiterTable koji poziva ove override metode iz komponente
            const z6 = this.multiselectMap;
            const dt1 = this.dataTable;
            const f1 = this.lazyLoadParams.filters;
            console.log(z1);
            if (z1) {
                Object.keys(z1).forEach(function (key) {
                    console.log(key, z1[key]);
                    if (z1[key] && z1[key].toLowerCase().includes(' or ') && z1[key].length > 4) {
                        const l2 = z1[key].toLowerCase().split(' or ');
                        f1.push({fieldName: key, inValue: l2});
                    } else if (z1[key] && z1[key].startsWith('=')) {
                        const val1 = z1[key].replace('=', '').trim();
                        f1.push({fieldName: key, filterValue: z1[key]});
                    } else {
                        f1.push({fieldName: key, filterValue: z1[key]});
                    }
                });
            }

            if (z4) {
                Object.keys(z4).forEach(function (key) {
                    if (z4[key]) {

                        if (z2[key]) {
                            z2[key].from = null;
                            z2[key].to = null;

                            // Nina - novi date filter
                            z2[key].matchMode = null;
                            z2[key].numOfDateInputs = 0;
                        }
                    }
                    const v = z4[key];
                    console.log('z444444444444 dateEqulas ', v);
                    f1.push({fieldName: key, filterRangeDateFrom: v});
                });
            }

            if (z2) {
                Object.keys(z2).forEach(function (key) {
                    if (z2[key] && z2[key].matchMode &&
                        (z2[key].numOfDateInputs === 0 ||
                            (z2[key].numOfDateInputs === 1 && z2[key].from) ||
                            z2[key].numOfDateInputs === 2 && (z2[key].from || z2[key].to))
                    ) {
                        const l2 = [z2[key].from, z2[key].to];
                        f1.push({
                            fieldName: key,
                            filterRangeDateFrom: z2[key].from,
                            filterRangeDateTo: z2[key].to,
                            filterMatchMode: z2[key].matchMode
                        });
                    }
                });
            }

            if (z3) {
                console.log(z3);
                Object.keys(z3).forEach(function (key) {
                    if (z3[key]) {
                        const v1 = z3[key].toLowerCase();
                        console.log('v1', v1);
                        const v = (v1 === 'да') ? true : (v1 === 'не' ? false : undefined);
                        console.log('v', v);
                        f1.push({fieldName: key, filterValue: v});
                    }
                });
            }
            if (z6) {
                Object.keys(z6).forEach(function (key) {
                    let v = z6[key];
                    console.log('z6666666 innnn ', v);
                    if (v) {
                        // dt1.filters[key] = {value: v, matchMode: 'in'};
                        f1.push({'fieldName': key, 'inValue': v});
                    }

                });
            }
            console.log('ovdeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
            debugger;
            // KLIJENT ZAHTEVAO DA 'SVI KORISNICI VIDE SVE'
            if (this.roles === 'ROLE_RUKOVODILAC_PROJEKTA') {
                f1.push({fieldName: 'pspAccId', filterValue: this.loggedAccId});
                f1.push({fieldName: 'staCode', filterMatchMode: 'notEquals', filterValue: 'STA_STORNIRAN'});
                f1.push({fieldName: 'fdtCode', filterMatchMode: 'notEquals', filterValue: 'PAYMENT_INFO'});
            }
            // if (this.roles === 'ROLE_FZN_KONTROLOR') {
            //     f1.push({fieldName: 'pjhRespPersonAccId', filterValue: this.loggedAccId});
            // } else if (this.roles === 'ROLE_RUKOVODILAC_PROJEKTA') {
            //     f1.push({fieldName: 'pspAccId', filterValue: this.loggedAccId});
            // }
            console.log('fillLazyFilters', f1);
        }
    }

    clearFiltersForEmptyFields() {
        if (this.lazyLoadParams && this.lazyLoadParams.filters) {
            for (const item of this.lazyLoadParams.filters) {
                if (item.filterValue === '') {
                    item.filterValue = null;
                }
            }
        }
    }

    loadLazy($event) {
        console.log('loadLazy ', $event);
        console.log($event.filters);
        this.fillLazyFilters();
        this.clearFiltersForEmptyFields();

        const offset = $event.first;
        const limit = $event.rows;
        let sorts;
        const sortField = $event.sortField;
        const sortOrder = $event.sortOrder;
        if (sortField) {
            const s1 = {fieldName: sortField, asc: (sortOrder === 1 ? true : false)};
            sorts = [s1];
        } else {
            sorts = [{fieldName: 'fthId', asc: false}];
        }

        let lazyLoadGlobalFilter;
        const globalFilter = $event.globalFilter;
        if (globalFilter !== null) {
            lazyLoadGlobalFilter = {
                filterValue: globalFilter.toLowerCase().trim(),
                filterMatchMode: 'contains',
                isGlobal: true
            };
        }

        if (this.lazyLoadParams === null || this.lazyLoadParams === undefined) {
            this.auth.getUser().subscribe(
                rs => {
                    this.user = rs;
                    this.loggedAccId = this.user.accId;
                    this.lazyLoadParams = {
                        offset,
                        limit,
                        // KLIJENT ZAHTEVAO DA 'SVI KORISNICI IZ FZN-A VIDE SVE'
                        filters: this.roles === 'ROLE_RUKOVODILAC_PROJEKTA' ? [{
                            fieldName: 'pspAccId',
                            filterValue: this.loggedAccId,
                            filterMatchMode: 'equals'
                        }, {
                            fieldName: 'staCode',
                            filterValue: 'STA_STORNIRAN',
                            filterMatchMode: 'notEquals'
                        }, {
                            fieldName: 'fdtCode',
                            filterValue: 'PAYMENT_INFO',
                            filterMatchMode: 'notEquals'
                        }] : [],
                        // filters: this.roles === 'ROLE_FZN_KONTROLOR' ? [{
                        //     fieldName: 'pjhRespPersonAccId',
                        //     filterValue: this.loggedAccId,
                        //     filterMatchMode: 'equals'
                        // }] : (this.roles === 'ROLE_RUKOVODILAC_PROJEKTA' ? [{
                        //     fieldName: 'pspAccId',
                        //     filterValue: this.loggedAccId,
                        //     filterMatchMode: 'equals'
                        // }] : []),
                        sorts
                    };

                    // NE DODAJEM GLOBALNI FILTER MEDJU FILTERE JER JE PRVO UCITAVANJE

                    this.getVFznTransactionHeadersCustom();
                }, err => {
                    console.log(err);
                });
        } else {
            this.lazyLoadParams = {
                offset,
                limit,
                filters: this.lazyLoadParams ? this.lazyLoadParams.filters : [],
                sorts
            };

            if (lazyLoadGlobalFilter) {
                this.lazyLoadParams.filters.push(lazyLoadGlobalFilter);
            }
            this.getVFznTransactionHeadersCustom();
        }
    }

    // custom-end

}
