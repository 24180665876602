import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProgrammeCostType} from '../../../../../../model/programme-cost-type';
import {ProgrammeCostTypeService} from '../../../../../../service/programme-cost-type.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-programme-cost-type-csc1400selection-dialog',
    templateUrl: './programme-cost-type-csc1400selection-dialog.component.html',
    styleUrls: ['./programme-cost-type-csc1400selection-dialog.component.css']
})
export class ProgrammeCostTypeCsc1400selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    @Input('prhId') prhId: number;

    public selectedObj: any;
    public programmeCostTypes: ProgrammeCostType[];
    @Output() onSelected = new EventEmitter<ProgrammeCostType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'pctOrder': null, 'costType.ctpName': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'programme-cost-type';

    constructor(private programmeCostTypeService: ProgrammeCostTypeService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
    }


    selectPct(obj: any) {
        this.selectPctCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.getProgrammeCostTypes();
    }

    showDlgDynamic(sqlQuery) {
        this.getProgrammeCostTypeDynamicDialog(sqlQuery);
        this.clearFilters();
    }

    getProgrammeCostTypeDynamicDialog(sqlQuery) {
        this.programmeCostTypeService
            .getProgrammeCostTypesDynamicDialog(sqlQuery)
            .subscribe(programmeCostTypes => {
                this.programmeCostTypes = programmeCostTypes;
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    getProgrammeCostTypes(): void {
        this.getProgrammeCostTypesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProgrammeCostTypesCustom() {
        debugger;
        console.log(this.prhId);
        this.programmeCostTypeService
            .getProgrammeCostTypesByProgrammeId(this.prhId)
            .subscribe(programmeCostTypes => {
                this.programmeCostTypes = programmeCostTypes;
                this.visible = true;
            }, error => this.errorMessage = <any> error);

        // this.programmeCostTypeService
        //     .getProgrammeCostTypes()
        //     .subscribe(programmeCostTypes => {
        //         this.programmeCostTypes = programmeCostTypes;
        //         this.visible = true;
        //     }, error => this.errorMessage = <any> error);
    }

    selectPctCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
