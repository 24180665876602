import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProgrammeCostType} from '../model/programme-cost-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ProgrammeCostTypeService {

    private baseApiUrl = globals.BASE_API_URL;
    private programmeCostTypesUrl = this.baseApiUrl + 'programmeCostTypes';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProgrammeCostTypes(): Observable<ProgrammeCostType[]> {
        return this.httpClient.get<ResponseWrapper>(this.programmeCostTypesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProgrammeCostType(id: number): Observable<ProgrammeCostType> {
        return this.httpClient.get<ResponseWrapper>(`${this.programmeCostTypesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.programmeCostTypesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(pct: ProgrammeCostType): Observable<ProgrammeCostType> {
        return this.httpClient
            .post<ResponseWrapper>(this.programmeCostTypesUrl, JSON.stringify(pct)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(programmeCostType: ProgrammeCostType): Observable<ProgrammeCostType> {
        const url = `${this.programmeCostTypesUrl}/${programmeCostType.pctId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(programmeCostType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.programmeCostTypesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(programmeCostType) {

        if (programmeCostType.pctSysCreateDate) {
            programmeCostType.pctSysCreateDate = new Date(programmeCostType.pctSysCreateDate);
        }
        if (programmeCostType.pctSysSyncTst) {
            programmeCostType.pctSysSyncTst = new Date(programmeCostType.pctSysSyncTst);
        }
        if (programmeCostType.pctSysUpdateDate) {
            programmeCostType.pctSysUpdateDate = new Date(programmeCostType.pctSysUpdateDate);
        }
        return programmeCostType;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getProgrammeCostTypesByProgrammeId(programmeHeaderId: number): Observable<ProgrammeCostType[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.programmeCostTypesUrl}/getByProgrammeHeaderId/${programmeHeaderId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

    getProgrammeCostTypesDynamicDialog(sqlQuery): Observable<ProgrammeCostType[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.programmeCostTypesUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    // custom-end

}



