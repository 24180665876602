import {BrowserModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeLayoutComponent} from './layout/home-layout-component/home-layout.component';
import {LoginLayoutComponent} from './layout/login-layout-component/login-layout.component';
import {LoginCertComponent} from './login/loginCert/loginCert.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login/login.component';
import {EmailVerificationComponent} from './login/change-password-email-verification/email-verification.component';
import {NewPasswordComponent} from './login/change-password-input-new-password/new-password.component';
import {PageNotFoundComponent} from './layout/page-not-found-component/page-not-found.component';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthService} from './core/auth.service';
import {AccountRoleService} from './bo/service/account-role.service';
import {MessageService} from './core/message.service';
import {AppFooterComponent} from './layout/app.footer.component';
import {AppMenuComponent, AppSubMenuComponent} from './layout/app.menu.component';
import {AppProfileComponent} from './layout/app.profile.component';
import {AppTopBarComponent} from './layout/app.topbar.component';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {MessageComponent} from './message/message.component';
import {FormsModule} from '@angular/forms';
import {MessagesModule} from 'primeng/messages';
import {SystemPropertyService} from './bo/service/system-property.service';
import {AuthGuard} from './auth-guards/auth-guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseListComponent} from './bo/base/base-list/base-list.component';
import {BaseDetailComponent} from './bo/base/base-detail/base-detail.component';
import {HighlightDirective} from './shared/highlight.directive';
import {LoginCertNoticeComponent} from './login/login-cert-notice/login-cert-notice.component';
import {InfoComponent} from './info/info.component';
import {BaseLazyListComponent} from './bo/base/base-lazy-list/base-lazy-list.component';
import {TooltipModule} from 'primeng/tooltip';
import {InfoService} from './core/info.service';
import {httpInterceptorProviders} from './http-interceptor';
import {PendingRequestService} from './core/pending-request.service';
import {RnValidatorDirective} from './bo/validator/rn-validator.directive';
import {VatValidatorDirective} from './bo/validator/vat-validator.directive';
import {JmbgValidatorDirective} from './bo/validator/jmbg-validator.directive';
import {TekucaGodinaValidatorDirective} from './bo/validator/tekuca-godina-validator.directive';
import {AppInjector} from './core/app-injector.service';
import {ExcelExportService} from './core/excel-export.service';
import {CommonCodeService} from './core/common-code.service';
import {environment} from '../environments/environment';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {ReportService} from './shared/report.service';
import {DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SpinnerComponent} from './layout/spinner-component/spinner.component';
import {BlockUIModule} from 'primeng/blockui';
import {SharedModule} from './shared/shared.module';
import {BoInteractionService} from './shared/bo-interaction.service';
import {OrganizationUnitService} from './bo/service/organization-unit.service';
import {BrowserStorageService} from 'angular-browser-storage';
import {FinModule} from './bo/ui/fin/fin.module';
import {PersistanceService} from './core/persistance.service';
import {Privileges} from './core/privileges.service';
import {DocumentTypeService} from './bo/service/document-type.service';
import {TskNotificationService} from './bo/service/tsk-notification.service';
import {SroService} from './bo/service/sro.service';
import {CostTypeService} from './bo/service/cost-type.service';
import localeSr from '@angular/common/locales/sr-Latn';
import {CommonCodeSelectionDialogComponent} from './bo/ui/bo-dialogs/common-code-selection-dialog/common-code-selection-dialog.component';
import {SroCsc1100selectionDialogComponent} from './bo/ui/bo-dialogs/sro/c/sc/sro-csc1100selection-dialog/sro-csc1100selection-dialog.component';
import {ProjectReportPeriodQuartalSelectionDialogComponent} from "./bo/ui/bo-dialogs/project-report-period-quartal-selection-dialog/project-report-period-quartal-selection-dialog.component";
import {ActivitiService} from './bo/service/activiti.service';
import {ActiveProcessInfoService} from './bo/service/active-process-info.service';
import {ProjectStructureElementSelectionDialogComponent} from "./bo/ui/bo-dialogs/project-structure-element-selection-dialog/project-structure-element-selection-dialog.component";
import {ProgrammeCostTypeCsc1400selectionDialogComponent} from "./bo/ui/bo-dialogs/programme-cost-type/c/sc/programme-cost-type-csc1400selection-dialog/programme-cost-type-csc1400selection-dialog.component";
import {Ng4LoadingSpinnerModule, Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import {ChangePasswordService} from './bo/service/change-password.service';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {SroDynamicSelectionDialogComponent} from "./bo/ui/bo-dialogs/sro-dynamic-selection-dialog/sro-dynamic-selection-dialog.component";
import {PageRibbonComponent} from './layout/ribbon-component/ribbon.component';
import {RibbonService} from './shared/ribbon.service';
import {CtpSelectionDialogComponent} from "./bo/ui/bo-dialogs/ctp-selection-dialog/ctp-selection-dialog.component";
import {CtpDynamicSelectionDialogComponent} from "./bo/ui/bo-dialogs/ctp-dynamic-selection-dialog/ctp-dynamic-selection-dialog.component";
import {CustomClassificationDetailCsc2190selectionDialogComponent} from "./bo/ui/bo-dialogs/classification-detail/c/sc/custom-classsification-detail-csc2190selection-dialog/custom-classification-detail-csc2190selection-dialog.component";
import {CustomClassificationDetailCscdynamicDialogComponent} from "./bo/ui/bo-dialogs/classification-detail/c/sc/custom-classification-detail-cscdynamic-dialog/custom-classification-detail-cscdynamic-dialog.component";
import {YfrBudgetBalanceDialogComponent} from "./bo/ui/bo-dialogs/yfr-budget-balance-dialog/yfr-budget-balance-dialog.component";

registerLocaleData(localeSr, 'sr');

@NgModule({
    declarations: [
        AppComponent,
        HomeLayoutComponent,
        LoginLayoutComponent, LoginCertComponent,
        HomeComponent,
        LoginComponent,

        EmailVerificationComponent,
        NewPasswordComponent,
        PageNotFoundComponent,

        AppFooterComponent, AppMenuComponent, AppProfileComponent, AppTopBarComponent, AppSubMenuComponent,

        MessageComponent,
        BaseListComponent, BaseDetailComponent, BaseLazyListComponent,
        HighlightDirective,
        LoginCertNoticeComponent,
        InfoComponent,
        RnValidatorDirective,
        VatValidatorDirective,
        TekucaGodinaValidatorDirective,
        SpinnerComponent,
        ChangePasswordComponent, PageRibbonComponent
    ],
    entryComponents: [
        CommonCodeSelectionDialogComponent,
        SroCsc1100selectionDialogComponent,
        ProjectReportPeriodQuartalSelectionDialogComponent,
        ProjectStructureElementSelectionDialogComponent,
        ProgrammeCostTypeCsc1400selectionDialogComponent,
        SroDynamicSelectionDialogComponent,
        CtpSelectionDialogComponent,
        CtpDynamicSelectionDialogComponent,
        CustomClassificationDetailCscdynamicDialogComponent,
        YfrBudgetBalanceDialogComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        // ngx-translate and the loader module
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        ScrollPanelModule,
        TooltipModule,
        MessagesModule,
        ReactiveFormsModule,
        BlockUIModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        // ngx-translate and the loader module
        HttpClientModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FinModule,
        Ng4LoadingSpinnerModule
    ],
    providers: [
        /*{
          provide: LocationStrategy,
          useClass: HashLocationStrategy
        },*/
        AuthService,
        AccountRoleService,
        MessageService,
        ReportService,
        SystemPropertyService,
        AuthGuard,
        InfoService,
        ExcelExportService,
        CommonCodeService,
        PendingRequestService,
        BoInteractionService,
        httpInterceptorProviders,
        OrganizationUnitService,
        BrowserStorageService,
        PersistanceService,
        Privileges,
        TskNotificationService,
        SroService,
        DatePipe,
        CostTypeService,
        ActivitiService,
        ActiveProcessInfoService,
        Ng4LoadingSpinnerService,
        ChangePasswordService,
        RibbonService
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        AppInjector.setInjector(injector);
    }
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
        {prefix: './assets/i18n/', suffix: '-gen.json'}
    ]);
}
