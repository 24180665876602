import {NgModule} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';
import {FileUploadModule} from 'primeng/primeng';
import {BsSaveDlgComponent} from './bs-save-dlg/bs-save-dlg.component';
import {BsEditorComponent} from '../fin/bs-report-editor/bs-report-editor.component';
import {AsynchronouslyInitialisedComponent} from './async-initialised-component/async-initialised-component.component';


// import { RSBDecimalPipe } from '../../../shared/custom-pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FileUploadModule,

  ],
  declarations: [
      BsSaveDlgComponent,
      BsEditorComponent,
      AsynchronouslyInitialisedComponent
    // RSBDecimalPipe
  ],
  exports: [
      BsSaveDlgComponent,
      BsEditorComponent,
      AsynchronouslyInitialisedComponent
  ],
  providers: []
})
export class BoSharedModule {

}


