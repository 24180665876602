import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {DocumentType} from '../../../../../../model/document-type';
import {DocumentTypeService} from '../../../../../../service/document-type.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-document-type-csc1480selection-dialog',
    templateUrl: './document-type-csc1480selection-dialog.component.html',
    styleUrls: ['./document-type-csc1480selection-dialog.component.css']
})
export class DocumentTypeCsc1480selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public documentTypes: DocumentType[];
    @Output() onSelected = new EventEmitter<DocumentType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'dctCode': null, 'dctDescription': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'document-type';

    constructor(private documentTypeService: DocumentTypeService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getDocumentTypes();
    }


    selectDct(obj: any) {
        this.selectDctCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    getDocumentTypes(): void {
        this.getDocumentTypesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getDocumentTypesCustom() {
        this.documentTypeService
            .getDocumentTypes()
            .subscribe(documentTypes => this.documentTypes = documentTypes, error => this.errorMessage = <any> error);
    }

    selectDctCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
