
//export const BASE_API_URL = 'http://localhost:8080/baseproject/api';

export const sr = {
  firstDayOfWeek: 1,
  dayNames: [ 'nedelja','ponedeljak','utorak','sreda','četvrtak','petak','subota'],
  dayNamesShort: [ 'ned','pon','uto','sre','čet','pet','sub' ],
  dayNamesMin: [ 'NE','PO','UT','SR','ČE','PE','SU' ],
  monthNames: [ 'januar','februar','mart','april','maj','jun','jul','avgust','septembar','oktobar','novembar','decembar' ],
  monthNamesShort: [ 'jan','feb','mar','apr','maj','jun','jul','avg','sep','okt','nov','dec' ]
};

export const sr_cyr = {
  firstDayOfWeek: 1,
  dayNames: [ 'недеља','понедељак','уторак','среда','четвртак','петак','субота'],
  dayNamesShort: [ 'нед','пон','уто','сре','чет','пет','суб' ],
  dayNamesMin: [ 'НЕ','ПО','УТ','СР','ЧЕ','ПЕ','СУ' ],
  monthNames: [ 'јануар','фебруар','март','април','мај','јун','јул','август','септембар','октобар','новембар','децембар' ],
  monthNamesShort: [ 'јан','феб','мар','апр','мај','јун','јул','авг','сеп','окт','нов','дец' ]
};

export const en = {
  firstDayOfWeek: 0,
  dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
  monthNames: [ "January","February","March","April","May","June","July","August","September","October","November","December" ],
  monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
  today: 'Today',
  clear: 'Clear'
};
