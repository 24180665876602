import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProjectSro} from '../model/project-sro';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ProjectSroService {

    private baseApiUrl = globals.BASE_API_URL;
    private projectSrosUrl = this.baseApiUrl + 'projectSros';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProjectSros(): Observable<ProjectSro[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectSrosUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSro(id: number): Observable<ProjectSro> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectSrosUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.projectSrosUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(pjs: ProjectSro): Observable<ProjectSro> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectSrosUrl, JSON.stringify(pjs)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(projectSro: ProjectSro): Observable<ProjectSro> {
        const url = `${this.projectSrosUrl}/${projectSro.pjsId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(projectSro)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.projectSrosUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(projectSro) {

        if (projectSro.pjsSysSyncTst) {
            projectSro.pjsSysSyncTst = new Date(projectSro.pjsSysSyncTst);
        }
        if (projectSro.pjsSysUpdateDate) {
            projectSro.pjsSysUpdateDate = new Date(projectSro.pjsSysUpdateDate);
        }
        if (projectSro.pjsSysCreateDate) {
            projectSro.pjsSysCreateDate = new Date(projectSro.pjsSysCreateDate);
        }
        return projectSro;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getProjectSrosByProjectHeaderId(pjh: number): Observable<ProjectSro[]> {
        const url = `${this.projectSrosUrl}/byProjectHeaderId/${pjh}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectSroByProjectHeaderIdAndPjsType(projectHeaderId: number, pjsType: string): Observable<ProjectSro> {
        const url = `${this.projectSrosUrl}/ByProjectHeaderIdAndPjsType/${projectHeaderId}/${pjsType}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getCountProjectSroByProjectHeaderId(projectHeaderId: number): Observable<number> {
        const url = `${this.projectSrosUrl}/CountByProjectHeaderId/${projectHeaderId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    // custom-end

}



