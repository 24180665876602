import {Component, Input, OnInit, EventEmitter, ViewChild} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {MenuItem} from 'primeng/primeng';
import {HomeLayoutComponent} from './home-layout-component/home-layout.component';
import {environment} from '../../environments/environment';
import {AuthService} from '../core/auth.service';
import {Subscription} from 'rxjs/internal/Subscription';
import * as globals from '../globals/globals';
import appMenu from '../../app-menu.json';
// import appMenuDev from '../../app-menu-dev.json';
import {RibbonService} from '../shared/ribbon.service';

@Component({
    selector: 'app-menu',
    template: `
        <ul app-submenu [item]="model" root="true" class="layout-menu layout-main-menu clearfix"
            [reset]="reset" visible="true" parentActive="true"></ul>
    `
})
export class AppMenuComponent implements OnInit {

    @Input() reset: boolean;

    model: any[];

    theme = 'blue';

    layout = 'blue';

    version = 'v3';
    public token: string;

    userName: string;
    // model_SR_CYR: any[];
    // model_IPARD_SR_CYR: any[];
    // model_EINSPECTOR_SR_CYR: any[];
    // model_EN: any[];
    // model_IPARD_EN: any[];
    // model_EINSPECTOR_EN: any[];
    appTitle: string;

    formMap: any;

    sub1: Subscription;
    profile: string;

    constructor(public app: HomeLayoutComponent, private authService: AuthService, private ribbonService: RibbonService) {
        this.appTitle = environment.PROJECT.toLocaleUpperCase();
        this.userName = this.authService.getUsername();
        this.authService.findActiveProfile().subscribe(resProf => {
            this.profile = resProf;
        });

        this.sub1 = this.authService.organizationUnitChanged$.subscribe(res => {
            this.authService.getFormsForUser().subscribe(f1 => {
                this.formMap = f1;
                console.log('init menu modelllllllllll');
                this.initModel();
            });
        });
    }


    ngOnInit() {

        this.authService.getFormsForUser().subscribe(f1 => {
                this.formMap = f1;
                // Nenad: 30.09.2020 privremeno postavljeno na csc200main, prekopirano sa iparda
                // bice postavljena nova putanja za GIR
                console.log(this.formMap, this.formMap['/case-management/bo-csc2000main']);

                this.initModel();
            },
            err => {
                console.log(err);
            }
        );
    }

    // Nenad 30.09.2020 kompletan initModel je prekopiran sa iparda
    // putanje se moraju mijenjati za potrebe GIR-a
    initModel() {
        //   console.log(' TEST TEST');
        //   console.log(this.formMap);
        if (this.formMap && Object.keys(this.formMap).length > 0) {
            /* razdvajanje okruzenja
            this.ribbonService.getActiveProfiles().subscribe(
                activeProfiles => {
                    if (activeProfiles.some(profile => profile === 'dev') || activeProfiles.some(profile => profile === 'sreids') || activeProfiles.some(profile => profile === 'test')) {
                        // deep copy of an array
                        this.model = JSON.parse(JSON.stringify(appMenuDev));

                        this.model.map(item => {
                            item.visible = this.formMap[item.formMapKey] == true;
                            if (item.items) {
                                item.items.map(childItem => {
                                    childItem.visible = this.formMap[childItem.formMapKey] == true;
                                    return childItem;
                                });
                            }
                            return item;
                        });
                    } else {
                        // deep copy of an array
                        this.model = JSON.parse(JSON.stringify(appMenu));

                        this.model.map(item => {
                            item.visible = this.formMap[item.formMapKey] == true;
                            if (item.items) {
                                item.items.map(childItem => {
                                    childItem.visible = this.formMap[childItem.formMapKey] == true;
                                    return childItem;
                                });
                            }
                            return item;
                        });
                    }
                }
            );
            */
            // deep copy of an array
            this.model = JSON.parse(JSON.stringify(appMenu));

            this.model.map(item => {
                item.visible = this.formMap[item.formMapKey] == true;
                if (item.items) {
                    item.items.map(childItem => {
                        childItem.visible = this.formMap[childItem.formMapKey] == true;
                        return childItem;
                    });
                }
                return item;
            });

            // this.model = [
            //     /*  {
            //          label: 'Matični podaci',
            //          routerLink: ['/misc/bo-csc1080mmain'],
            //          visible: true
            //      }, */
            //
            //
            //     {
            //         label: 'Управљање корисницима',
            //         routerLink: ['/user-administration/bo-aus1010main'],
            //         visible: this.formMap['/user-administration/bo-aus1010main'] == true,
            //     },
            //     {
            //         label: 'Управљање групама корисника и овлашћењима улоге',
            //         routerLink: ['/user-administration/bo-gbo1890main'],
            //         visible: this.formMap['/role'] == true,
            //     },
            //     {
            //         label: 'Управљање каталозима',
            //         visible: this.formMap['/upravljanje-nalozima'] == true,
            //         items: [
            //
            //             {
            //                 label: 'Типови каталога',
            //                 routerLink: ['/misc/bo-csc1580main'],
            //                 visible: this.formMap['/misc/bo-csc1580main'] == true,
            //             },
            //
            //
            //             {
            //                 label: 'Каталози',
            //                 routerLink: ['/misc/bo-csc1620main'],
            //                 visible: this.formMap['/misc/bo-csc1620main'] == true,
            //             }
            //         ]
            //     },
            //
            //     {
            //         label: 'Извештавање',
            //         url: ['http://sreids:8082/reportserver-ee/reportserver/scriptAccess?id=16151&args=' + this.userName + '&exception=true'],
            //         visible: this.formMap['/izvestavanje'] == true,
            //         target: '_blank'
            //     },
            //     {
            //         label: 'Размена података',
            //         visible: this.formMap['/razmena-podataka'] == true,
            //         items: [
            //             {
            //                 label: 'Сервиси',
            //                 routerLink: ['/misc/bo-csc1660main'],
            //                 visible: this.formMap['/misc/bo-csc1660main'] == true,
            //             },
            //             {
            //                 label: 'Лог извршења сервиса',
            //                 routerLink: ['/misc/bo-csc1690main'],
            //                 visible: this.formMap['/misc/bo-csc1690main'] == true,
            //
            //             }
            //         ]
            //     },
            //     {
            //         label: 'Евиденција о приступу систему',
            //         visible: this.formMap['/evidencija_o_pristupu'] == true,
            //         items: [
            //             {
            //                 label: 'Администрирање ревизорксог трага',
            //                 routerLink: ['/user-administration/bo-csc4280main'],
            //                 visible: this.formMap['/user-administration/bo-csc4280main'] == true,
            //
            //             },
            //             {
            //                 label: 'Преглед архиве промена улога на систему',
            //                 routerLink: ['/user-administration/bo-csc6110main'],
            //                 visible: this.formMap['/user-administration/bo-csc6110main'] == true,
            //             },
            //             {
            //                 label: 'Преглед активности над базом',
            //                 routerLink: ['/user-administration/bo-csc6100main'],
            //                 visible: this.formMap['/user-administration/bo-csc6100main'] == true,
            //             },
            //             {
            //                 label: 'Преглед архиве логовања корисника',
            //                 routerLink: ['/user-administration/bo-csc6080main'],
            //                 visible: this.formMap['/user-administration/bo-csc6080main'] == true,
            //             }
            //         ]
            //     },
            //
            //     {
            //         label: 'Матични подаци',
            //         visible: this.formMap['/maticni-podaci'] == true,
            //         items: [
            //             {
            //                 label: 'Извор података',
            //                 routerLink: ['/misc/bo-csc1550main'],
            //                 visible: this.formMap['/misc/bo-csc1550main'] == true,
            //
            //             },
            //             {
            //                 label: 'Организационе јединице',
            //                 routerLink: ['/user-administration/bo-ssy3540main'],
            //                 visible: this.formMap['/user-administration/bo-ssy3540main'] == true,
            //             }
            //         ]
            //     },
            //
            //     {
            //         label: 'Системска администрација ',
            //         visible: this.formMap['/sistemska-administracija'] == true,
            //         items: [
            //             {
            //                 label: 'Системске вредности',
            //                 routerLink: ['/user-administration/bo-ssy4170main'],
            //
            //             },
            //             {
            //                 label: 'Општи шифарник',
            //                 routerLink: ['/misc/bo-ssy1690main'],
            //                 visible: this.formMap['/misc/bo-ssy1690main'] == true,
            //             }
            //         ]
            //     },
            //     {
            //         label: 'Мапирање предмета',
            //         routerLink: ['/misc/bo-csc1720main'],
            //         visible: this.formMap['/misc/bo-csc1720main'] == true,
            //
            //     },
            //
            //     // 26.8.2021 Nenad: dodato rucno privremeno
            //     {
            //         label: 'Контролна табла - листа',
            //         routerLink: ['/misc/bo-csc1760main'],
            //         visible: this.formMap['/misc/bo-csc1760main'] == true,
            //     }
            //
            //     //// ispod treba da se obrise.
            //     /*   {
            //
            //            label: 'Системски подаци',
            //            items: [
            //                {
            //                    label: 'Администрација корисника',
            //                    visible: true,
            //                    items: [
            //                        {
            //                            label: 'Кориснички налози',
            //                            routerLink: ['/user-administration/bo-aus1010main'],
            //                            visible: this.formMap['/user-administration/bo-aus1010main'] == true,
            //                        },
            //                        {
            //                            label: 'Корисничке улоге',
            //                            routerLink: ['/user-administration/bo-gbo1890main'],
            //                            visible: this.formMap['/user-administration/bo-gbo1890main'] == true,
            //                        }
            //                    ]
            //                },
            //                {
            //                    label: 'Ажурирање табела',
            //                    routerLink: ['/case-definition/bo-csc4700main'],
            //                    visible: this.formMap['/case-definition/bo-csc4700main'] == true,
            //                },
            //                {
            //                    label: 'Администрација извештаја/регистара',
            //                    routerLink: ['/reporting/bo-csc5890main'],
            //                    visible: true
            //                    // visible: this.formMap['/case-definition/bo-csc5890main'] == true
            //                },
            //                {
            //                    label: 'Системска администрација',
            //                    visible: true,
            //                    items: [
            //                        {
            //                            label: 'Системске вредности',
            //                            routerLink: ['/user-administration/bo-ssy4170main'],
            //                            visible: this.formMap['/user-administration/bo-ssy4170main'] == true,
            //                        },
            //                        {
            //                            label: 'Администрирање ревизорксог трага',
            //                            routerLink: ['/user-administration/bo-csc4280main'],
            //                            visible: this.formMap['/user-administration/bo-csc4280main'] == true,
            //
            //                        },
            //                        {
            //                            label: 'Преглед активности над базом',
            //                            routerLink: ['/user-administration/bo-csc6100main'],
            //                            visible: this.formMap['/user-administration/bo-csc6100main'] == true,
            //                        }
            //                    ]
            //                }
            //            ]
            //        }
            //        ,
            //        {
            //            label: 'Матични подаци',
            //            items: [
            //                {
            //                    label: 'Подаци предузећа',
            //                    routerLink: ['/misc/bo-csc1080mmain'],
            //                    visible: true
            //                },
            //                {
            //                    label: 'Организационе јединице',
            //                    routerLink: ['/user-administration/bo-ssy3540main'],
            //                    visible: true
            //                }
            //            ]
            //        },
            //        {
            //            label: 'Шифарници',
            //            items: [
            //                /!*{
            //                    label: '\u0420\u0435\u043f\u043e\u0437\u0438\u0442\u043e\u0440\u0438\u0458\u0443\u043c \u043f\u043e\u0441\u043b\u043e\u0432\u043d\u0435 \u043b\u043e\u0433\u0438\u043a\u0435',
            //                    routerLink: ['/misc/bo-csc3650main'],
            //                    visible: this.formMap['/misc/bo-csc3650main'] === true
            //                },*!/
            //                {
            //                    label: 'Општи шифарник',
            //                    routerLink: ['/misc/bo-ssy1690main'],
            //                    visible: true
            //                },
            //                {
            //                    label: 'Статуси',
            //                    routerLink: ['/misc/bo-csc2090main'],
            //                    visible: true
            //                },
            //                /!*
            //                {
            //                    label: 'Organizacione jedinice',
            //                    routerLink: ['/user-administration/bo-ssy3540main'],
            //                    visible: true
            //                },
            //
            //                *!/
            //                {
            //                    label: 'Класификације',
            //                    routerLink: ['/meta-data-registers/bo-csc4090main'],
            //                    visible: true
            //                }
            //            ]
            //        },
            //        {
            //            label: 'Документи',
            //            visible: true,
            //            items: [
            //                {
            //                    label: 'Шаблони докумената',
            //                    routerLink: ['/fin/bo-csc1920main'],
            //                    visible: true
            //                },
            //                {
            //                    label: 'Тип документа',
            //                    routerLink: ['/misc/bo-csc1120main'],
            //                    visible: true
            //                },
            //                {
            //                    label: 'Тип документа - шаблони',
            //                    routerLink: ['/misc/bo-csc1220main'],
            //                    visible: true
            //                },
            //            ]
            //        },
            //        {
            //            label: 'Извештаји',
            //            routerLink: ['/reporting/bo-csc5901main'],
            //        },
            //        {
            //            label: 'Извор података',
            //            routerLink: ['/misc/bo-csc1550main'],
            //            visible: this.formMap['/misc/bo-csc1550main'] == true,
            //
            //        },
            //        {
            //            label: 'Каталози',
            //            routerLink: ['/misc/bo-csc1620main'],
            //            visible: this.formMap['/misc/bo-csc1620main'] == true,
            //        },
            //        {
            //            label: 'Типови каталога',
            //            routerLink: ['/misc/bo-csc1580main'],
            //            visible: this.formMap['/misc/bo-csc1580main'] == true,
            //
            //        },
            //        {
            //            label: 'Размена података',
            //            items: [
            //                {
            //                    label: 'Сервиси',
            //                    routerLink: ['/misc/bo-csc1660main'],
            //                    visible: this.formMap['/misc/bo-csc1660main'] == true,
            //                },
            //                {
            //                    label: 'Лог извршења сервиса',
            //                    routerLink: ['/misc/bo-csc1690main'],
            //                    visible: this.formMap['/misc/bo-csc1690main'] == true,
            //
            //                }
            //            ]
            //        }*/
            // ];
        }
    }


    changeTheme(theme: string) {
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;

        if (this.version === 'v3') {
            themeLink.href = 'assets/theme/theme-' + theme + '.css';
        } else {
            themeLink.href = 'assets/theme/theme-' + theme + '-v4' + '.css';
        }

        this.theme = theme;

    }

    changeLayout(layout: string, special?: boolean) {
        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;

        if (this.version === 'v3') {
            layoutLink.href = 'assets/layout/css/layout-' + layout + '.css';
        } else {
            layoutLink.href = 'assets/layout/css/layout-' + layout + '-v4' + '.css';
        }

        this.layout = layout;

        if (special) {
            this.app.darkMenu = true;
        }
    }

    changeVersion(version: string) {
        const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
        const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;

        if (version === 'v3') {
            this.version = 'v3';
            themeLink.href = 'assets/theme/theme-' + this.theme + '.css';
            layoutLink.href = 'assets/layout/css/layout-' + this.layout + '.css';
        } else {
            themeLink.href = 'assets/theme/theme-' + this.theme + '-v4' + '.css';
            layoutLink.href = 'assets/layout/css/layout-' + this.layout + '-v4' + '.css';
            this.version = '-v4';
        }

    }
}

@Component({
    /* tslint:disable:component-selector */
    selector: '[app-submenu]',
    /* tslint:enable:component-selector */
    template: `
        <ng-template ngFor let-child let-i="index" [ngForOf]="(root ? item : item.items)">
            <li [ngClass]="{'active-menuitem': isActive(i)}" [class]="child.badgeStyleClass"
                *ngIf="child.visible === false ? false : true">
                <a [href]="child.url||'#'" (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)"
                   class="ripplelink" *ngIf="!child.routerLink"
                   [attr.tabindex]="!visible ? '-1' : null" [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down menuitem-toggle-icon" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>

                <a (click)="itemClick($event,child,i)" (mouseenter)="onMouseEnter(i)" class="ripplelink"
                   *ngIf="child.routerLink"
                   [routerLink]="child.routerLink" routerLinkActive="active-menuitem-routerlink"
                   [routerLinkActiveOptions]="{exact: true}" [attr.tabindex]="!visible ? '-1' : null"
                   [attr.target]="child.target">
                    <i [ngClass]="child.icon"></i><span>{{child.label}}</span>
                    <i class="fa fa-fw fa-angle-down menuitem-toggle-icon" *ngIf="child.items"></i>
                    <span class="menuitem-badge" *ngIf="child.badge">{{child.badge}}</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text">{{child.label}}</div>
                </div>
                <div class="submenu-arrow" *ngIf="child.items"></div>
                <ul app-submenu [item]="child" *ngIf="child.items" [visible]="isActive(i)" [reset]="reset"
                    [parentActive]="isActive(i)"
                    [@children]="(app.isSlim()||app.isHorizontal())&&root ? isActive(i) ?
                     'visible' : 'hidden' : isActive(i) ? 'visibleAnimated' : 'hiddenAnimated'"></ul>
            </li>
        </ng-template>
    `,
    animations: [
        trigger('children', [
            state('hiddenAnimated', style({
                height: '0px'
            })),
            state('visibleAnimated', style({
                height: '*'
            })),
            state('visible', style({
                display: 'block'
            })),
            state('hidden', style({
                display: 'none'
            })),
            transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppSubMenuComponent {

    @Input() item: MenuItem;

    @Input() root: boolean;

    @Input() visible: boolean;

    _reset: boolean;

    _parentActive: boolean;

    activeIndex: number;

    constructor(public app: HomeLayoutComponent) {
    }

    itemClick(event: Event, item: MenuItem, index: number) {
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }

        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }

        // activate current item and deactivate active sibling if any
        this.activeIndex = (this.activeIndex === index) ? null : index;

        // execute command
        if (item.command) {
            item.command({originalEvent: event, item});
        }

        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(() => {
                this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }

        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            } else {
                this.app.resetMenu = false;
            }

            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    }

    onMouseEnter(index: number) {
        if (this.root && this.app.menuHoverActive && (this.app.isHorizontal() || this.app.isSlim())
            && !this.app.isMobile() && !this.app.isTablet()) {
            this.activeIndex = index;
        }
    }

    isActive(index: number): boolean {
        return this.activeIndex === index;
    }

    @Input() get reset(): boolean {
        return this._reset;
    }

    set reset(val: boolean) {
        this._reset = val;

        if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
            this.activeIndex = null;
        }
    }

    @Input() get parentActive(): boolean {
        return this._parentActive;
    }

    set parentActive(val: boolean) {
        this._parentActive = val;

        if (!this._parentActive) {
            this.activeIndex = null;
        }
    }
}
