import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ExaminationType} from '../../../../../../model/examination-type';
import {ExaminationTypeService} from '../../../../../../service/examination-type.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {ExaminationTypeList} from '../../../../../../model/examination-type-list';


@Component({
    selector: 'app-examination-type-csc1240selection-dialog',
    templateUrl: './examination-type-csc1240selection-dialog.component.html',
    styleUrls: ['./examination-type-csc1240selection-dialog.component.css']
})
export class ExaminationTypeCsc1240selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public examinationTypes: ExaminationType[];
    @Output() onSelected = new EventEmitter<ExaminationType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'extCode': null, 'extDescription': null, 'ccdCodeExtReason': null, 'extReferralDocNo': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'examination-type';

    public examinationTypesList: ExaminationTypeList[];

    constructor(private examinationTypeService: ExaminationTypeService) {
        super();
    }

    wId: number;


    // custom-constructor-end


    ngOnInit(): void {
        this.getExaminationTypes();
    }


    selectExt(obj: any) {
        this.selectExtCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getExaminationTypes(): void {
        // this.getExaminationTypesCustom();
        this.getExaminationTypesList();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getExaminationTypesCustom() {
        this.examinationTypeService
            .getExaminationTypes()
            .subscribe(examinationTypes => this.examinationTypes = examinationTypes, error => this.errorMessage = <any> error);
    }

    selectExtCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    private getExaminationTypesList() {
        this.examinationTypeService
            .getExaminationTypesList()
            .subscribe(examinationTypes => this.examinationTypesList = examinationTypes, error => this.errorMessage = <any> error);
    }

    private getExaminationTypesListByWpoId() {
        this.examinationTypeService
            .getExaminationTypesByWpoIdList(this.wId)
            .subscribe(examinationTypes => this.examinationTypesList = examinationTypes, error => this.errorMessage = <any> error);
    }

    showDlgByWpoId(id: number) {
        this.wId = id;
        console.log('USAOOOOOOOOOOOOOOOOOOO = ' + this.wId);
        if (this.wId !== null && this.wId !== undefined) {
            this.getExaminationTypesListByWpoId();
        } else {
            this.getExaminationTypesList();
        }
        this.visible = true;
    }

    // custom-end

}
