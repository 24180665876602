import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VNioList} from '../model/v-nio-list';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class VNioListService {

    private baseApiUrl = globals.BASE_API_URL;
    private vNioListUrl = this.baseApiUrl + 'vNioList';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVNioLists(): Observable<VNioList[]> {
        return this.httpClient.get<ResponseWrapper>(this.vNioListUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVNioList(id: number): Observable<VNioList> {
        return this.httpClient.get<ResponseWrapper>(`${this.vNioListUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getVNioListByFthId(id: number): Observable<VNioList[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vNioListUrl}/byFthId/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    loadLazy(request): Observable<any> {
        return this.httpClient.post<ResponseWrapper>(this.baseApiUrl + 'vNioListUrl/lazy', request).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vNioList) {
        return vNioList;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



