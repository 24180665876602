import {Table} from 'primeng/table';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs/index';
import {AfterViewChecked, OnInit, Component, EventEmitter, Output, ViewChild, OnDestroy} from '@angular/core';
import {ChangePasswordService} from '../../bo/service/change-password.service';
import {InteractionObject} from '../../shared/interaction-object';
import {MessageService} from '../../core/message.service';
import {Message} from 'primeng/components/common/api';
import {Ng4LoadingSpinnerService} from 'ng4-loading-spinner';


@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css']
})
export class EmailVerificationComponent implements OnInit, OnDestroy {

  credential: string;
  public errorMessage: string;
  msgs: Message[] = [];


   constructor(private _changePasswordService: ChangePasswordService, private spinnerService: Ng4LoadingSpinnerService,
             private _messageService: MessageService) {
   this.onInitCustom();
   }


  ngOnInit(): void {

  }


  ngOnDestroy() {
    this.onDestroyCustom();
  }


  onInitCustom() {
  }

  onDestroyCustom() {

  }

  reqestNewPassword() {
    this.spinnerService.show();
    this._changePasswordService.checkExistenseAndRequestNewPassword(this.credential, false)
        .subscribe(res => {
              if (res !== null) {
                this._messageService.showSuccessResetPasswordMsg();
              } else {
                this._messageService.showFailResetPasswordMsg();
              }
              this.spinnerService.hide();
            },

            error => {
              this.errorMessage = <any>error;
              console.log('ERRRRRRrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr ', error);
              if (error.error && error.error.error) {
                if (error.error.error === 'ACCOUNT_DISABLED') {
                  this._messageService.showFailMsg('ACCOUNT_DISABLED');
                }
              } else {
                this._messageService.showFailMsgText(this.errorMessage);
              }this.spinnerService.hide();
            });

  }


}
