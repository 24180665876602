import {Injectable} from '@angular/core';
import * as globals from '../../globals/globals';
import {HttpClient} from '@angular/common/http';
import Utils from '../../utils/utils';
import {Observable} from 'rxjs';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import {ProcessDefinitionActiviti} from '../model/process-definition-activiti';

@Injectable()
export class ActivitiService {
  private baseApiUrl = globals.BASE_API_URL;
  private activitiUrl = this.baseApiUrl + 'taskManagement';  // URL to web api

  constructor(private httpClient: HttpClient) {
  }

  findAllDeployedProcessDefinitions(): Observable<ProcessDefinitionActiviti[]> {
    return this.httpClient.get<ResponseWrapper>(this.activitiUrl + '/deployedProcessDefinitions').pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  findApiIdByApiProcessInstanceId(processInstanceId: string): Observable<string> {
    return this.httpClient.get<ResponseWrapper>(`${this.activitiUrl}/ByApiProcessInstanceId/${processInstanceId}`).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }


  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }
}
