import {AuthService} from '../core/auth.service';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    console.log('intercepttttttttttttttttttt');
    const authToken = this.auth.getAuthorizationToken();

    console.log('intercept ' + req.url);
    if (req.url.indexOf('auth') === -1 && authToken && req.url.indexOf('alfrescoUpload') === -1 && req.url.indexOf('ImportXML') === -1 && req.url.indexOf('.json') === -1
      && req.url.indexOf('getCertificate') === -1 && req.url.indexOf('PDFGetSignature') === -1 && req.url.indexOf('mdrUpload') === -1) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken).set('Content-Type', 'application/json').set('Accept', 'application/json').set('Accept-Language', 'sr-cyrl')
      });
      return next.handle(authReq);
    }

    if (req.url.indexOf('alfrescoUpload') !== -1 || req.url.indexOf('ImportXML') !== -1  /*|| req.url.indexOf('mdrUpload') !== 1 */) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
      return next.handle(authReq);
    }
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.


    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
