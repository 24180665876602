import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {DynamicHeader} from '../model/dynamic-header';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class DynamicHeaderService {

    private baseApiUrl = globals.BASE_API_URL;
    private dynamicHeadersUrl = this.baseApiUrl + 'dynamicHeaders';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getDynamicHeaders(): Observable<DynamicHeader[]> {
        return this.httpClient.get<ResponseWrapper>(this.dynamicHeadersUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getDynamicHeader(id: number): Observable<DynamicHeader> {
        return this.httpClient.get<ResponseWrapper>(`${this.dynamicHeadersUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.dynamicHeadersUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(dyh: DynamicHeader): Observable<DynamicHeader> {
        return this.httpClient
            .post<ResponseWrapper>(this.dynamicHeadersUrl, JSON.stringify(dyh)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(dynamicHeader: DynamicHeader): Observable<DynamicHeader> {
        const url = `${this.dynamicHeadersUrl}/${dynamicHeader.dftId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(dynamicHeader)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    copy(dftId: number, dftCode: string): Observable<{} | Object> {
        const url = `${this.dynamicHeadersUrl}/copy/${dftId}/${dftCode}`;
        return this.httpClient
            .post<ResponseWrapper>(url, null).pipe(
                catchError(this.handleError)
            );
    }

    updateTemplate(dynamicHeader: DynamicHeader): Observable<DynamicHeader> {
        debugger;
        const url = `${this.dynamicHeadersUrl}UpdateTemplate/${dynamicHeader.dftId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(dynamicHeader)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.dynamicHeadersUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(dynamicHeader) {

        if (dynamicHeader.dynSysUpdateDate) {
            dynamicHeader.dynSysUpdateDate = new Date(dynamicHeader.dynSysUpdateDate);
        }
        if (dynamicHeader.dynSysSyncTst) {
            dynamicHeader.dynSysSyncTst = new Date(dynamicHeader.dynSysSyncTst);
        }
        if (dynamicHeader.dynSysCreateDate) {
            dynamicHeader.dynSysCreateDate = new Date(dynamicHeader.dynSysCreateDate);
        }
        return dynamicHeader;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getByPdtIdNotInProgrammeDocumentForm(pdtId: number): Observable<DynamicHeader[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.dynamicHeadersUrl}/getByPdtIdNotInProgrammeDocumentForm/${pdtId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



