import {Injectable} from '@angular/core';


import {Router} from '@angular/router';

import * as globals from '../globals/globals';
import {Subject, Subscription} from 'rxjs';
import {Account} from '../shared/account';
import {environment} from '../../environments/environment';
import {LoginDTO} from '../bo/model/sys/login-dto';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {map, catchError, retry, first} from 'rxjs/operators';
import {ResponseWrapper} from '../bo/model/sys/response-wrapper';
import {throwError} from 'rxjs/internal/observable/throwError';
import {timer} from 'rxjs/internal/observable/timer';
import {of} from 'rxjs/internal/observable/of';
import {OrganizationUnit} from '../bo/model/organization-unit';
import {PersistanceService} from './persistance.service';


@Injectable()
export class AuthService {

  public token: string;

  private baseApiUrl = globals.BASE_API_URL;

  // private _loginUrl= this.baseApiUrl + '/auth';

  private loginUrl = this.baseApiUrl + 'auth';  // URL to web api

  private logoutLogUrl = this.baseApiUrl + 'logoutLog';

  private refreshTokenUrl = this.baseApiUrl + 'refreshToken';

  private loggedIn = false;
  private findActiveProfileURL = this.baseApiUrl + 'findActiveProfile';
  private _id;

  private roles: string[];

  private refreshTokenBeforeExp = 30000;

  private userLoadedSource = new Subject<any>();

  userLoaded$ = this.userLoadedSource.asObservable();

  private organizationUnitChangedSource = new Subject<any>();

  organizationUnitChanged$ = this.organizationUnitChangedSource.asObservable();

  // currentUser: any;

  private formForUsers;

  private profile: string;

  private timerSubscription: Subscription;

  organizationUnit: OrganizationUnit;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };


  constructor(private httpClient: HttpClient, private _router: Router, public persister: PersistanceService) {
    /*     if (this.profile == null) {
             this.findActiveProfile().subscribe(resProf => {
                 this.profile = resProf;
             });
         }*/
    this.token = this.getAuthorizationToken();
    console.log('login constructor', this.token);
    if (this.token) {
      console.log('login constructor');
      let expired = this.checkIfTokenExpired(this.token);
      if (expired) {
        this._router.navigate(['login']);
      } else {
        this.setRefreshTokenTimer(this.token);
        this.userLoadedSource.next({'user': this.parseJwt(this.token).sub});
      }
    }


  }

  sendCookies(cookies: string) {
    return this.httpClient.post<ResponseWrapper>(this.loginUrl, JSON.stringify(cookies)).pipe(
        map(rw => {
          return rw.data;
        }),
        retry(3),
        catchError(this.handleError)
    );
  }

  sendCookiesLogin(loginData: LoginDTO): Observable<Account> {
    console.log(this.httpOptions);
    return this.httpClient
        .post<any>(this.loginUrl, loginData, this.httpOptions).pipe(
            map(res => {
              return this.processDataLogin(res);
            }),
            catchError(this.handleError)
        );
  }

  login(loginData: LoginDTO): Observable<Account> {
    console.log('login1112', loginData);
    console.log(this.httpOptions);
    return this.httpClient
        .post<any>(this.loginUrl, loginData, this.httpOptions).pipe(
            map(res => {
              return this.processDataLogin(res);
            }),
            catchError(this.handleError)
        );
  }

  refreshToken(): Observable<any> {
    console.log(this.httpOptions);
    return this.httpClient
        .post<any>(this.refreshTokenUrl, null).pipe(
            map(res => {
              return this.processData(res);
            }),
            retry(3),
            catchError(this.handleError)
        );
  }

  changeOru(obj): Observable<any> {
    console.log(this.httpOptions);
    return this.httpClient
        .post<any>(this.refreshTokenUrl + '?oruId=' + obj.oruId, null).pipe(
            map(res => {
              this.formForUsers = null;
              return this.processDataOru(res, obj);
            }),
            retry(3),
            catchError(this.handleError)
        );
  }

  getUser(): Observable<any> {
    console.log(this.httpOptions);
    return this.httpClient
        .get<any>(this.baseApiUrl + 'accounts?username=' + this.parseJwt(this.token).sub).pipe(
            map(res => {
              return res.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
  }

  findActiveProfile(): Observable<string> {
    return this.httpClient.get<ResponseWrapper>(this.findActiveProfileURL).pipe(
        map(rw => {
          return rw.data;
        }),
        retry(3),
        catchError(this.handleError)
    );
  }

  getOrganizationUnit(): Observable<any> {
    console.log(this.httpOptions);
    console.log(this.parseJwt(this.token));
    console.log(this.parseJwt(this.token).oruId);
    return this.httpClient
        // .get<any>(this.baseApiUrl + 'organizationUnits/' + this.parseJwt(this.token).oruId).pipe(
        // zakucavam da je oruId=1, za pocetak
        .get<any>(this.baseApiUrl + 'organizationUnits/' + 1).pipe(
            map(res => {
              return res.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
  }

  getOruId(): number {
    return this.parseJwt(this.token).oruId;
  }

  getUsername(): string {
    return this.parseJwt(this.token).sub;
  }

  getFormsForUser(): Observable<any> {
    console.log(this.formForUsers);
    // tanja zakomentarisala 26.07.2021.
    /*if (this.formForUsers != null) {
      return of(this.formForUsers);
    }*/
    return this.httpClient
        .get<any>(this.baseApiUrl + 'formsForUser').pipe(
            map(res => {
              this.formForUsers = res.data;
              this.persister.set('gir-forms', this.formForUsers);
              return res.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
  }

  private processData(res) {
    console.log('processData', res);
    this.saveJwt(res.id_token);
    this.userLoadedSource.next({'user': this.parseJwt(res.id_token).sub});
    this.setRefreshTokenTimer(res.id_token);
    this.token = res.id_token;
    // this._router.navigate(['home']);
    return res;
  }

  private processDataOru(res, oru) {
    console.log('processData', res);
    this.saveJwt(res.id_token);
    this.userLoadedSource.next({'user': this.parseJwt(res.id_token).sub});
    this.setRefreshTokenTimer(res.id_token);
    this.token = res.id_token;
    this.organizationUnit = oru;
    this.organizationUnitChangedSource.next(oru);
    // this._router.navigate(['home']);
    return res;
  }

  private processDataLogin(res) {
    console.log('processData', res);
    this.saveJwt(res.id_token);
    this.userLoadedSource.next({'user': this.parseJwt(res.id_token).sub});
    this.setRefreshTokenTimer(res.id_token);
    this.token = res.id_token;
    this._router.navigate(['home']);
    return res;
  }


  private handleError(error: HttpErrorResponse | any) {
    // In a real world app, we might use a remote logging infrastructure

    let errMsg;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errMsg = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      // error.error.message = error.error;
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(
        error);
  }


  saveJwt(jwt) {
    /*console.log('saveJwt ' + jwt);
     console.log(jwt.id_token);*/
    if (jwt) {
      /*sessionStorage.setItem('currentUser', JSON.stringify({user: user, token: jwt}));*/
      sessionStorage.setItem(globals.AUTH_TOKEN_NAME, jwt);

      this.loggedIn = true;
      // console.log('saveJwt ' + this.loggedIn);
    }
    // console.log('saveJwt isLoggedIn ' + this.isLoggedIn());
  }

  /*login(username: string, password: string) {
   if (username == 'admin' && password == 'password') {
   let acc = new Account(1, 'admin', 'password', true);
   this.currentUser = acc;
   this.loggedIn = true;
   this.userLoadedSource.next(acc);
   this._router.navigate(['home']);
   }

   }*/


  /*
   loginSuccess(res: Response) {
   console.log("TOKEN " + res);
   let data = res.json();
   console.log(data);
   this.saveJwt(data);
   this._router.navigate(['home']);
   return data;
   }
   */


  private loginError(error: HttpErrorResponse | any) {
    // In a real world app, we might use a remote logging infrastructure

    let errMsg;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      errMsg = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      error.error.message = error.error;
    }
    // return an ErrorObservable with a user-facing error message
    this._router.navigate(['login']);
    return throwError(
        errMsg);
  }


  logout() {
    // sessionStorage.removeItem(globals.AUTH_TOKEN_NAME);
    /*this.loggedIn = false;
     this.currentUser = null;
     this.userLoadedSource.next(null);
     this._router.navigate(['login']);*/


    this.token = null;
    this.userLoadedSource.next(null);
    sessionStorage.removeItem(globals.AUTH_TOKEN_NAME);
    // var myWindow;
    //
    // this.findActiveProfile().subscribe(resProf => {
    //   this.profile = resProf;
    //   if (this.profile == 'dev' || this.profile == 'sreids') {
    //     myWindow = window.open('http://sreids:8082/reportserver-ee/reportserver/scriptAccess?id=151380');
    //   } else {
    //     myWindow = window.open('http://10.184.83.33:8080/reportserver-ee/reportserver/scriptAccess?id=151380');
    //   }
    //   setTimeout(() => {
    //     myWindow.close();
    //   }, 500);
    // });
    //
    //
    //
    // setTimeout(() => {
    //   myWindow.close();
    // }, 500);
    // /*
    //     myWindow.close();
    // */


    this._router.navigate(['login']);

  }

  logoutLog(username: String): Observable<String> {
    return this.httpClient
        .post<any>(this.logoutLogUrl, username, this.httpOptions).pipe(
            map(res => {
              return 'OK';
            }),
            catchError(this.handleError)
        );
  }


  isLoggedIn() {
    this.token = this.getAuthorizationToken();
    console.log(this.token);
    //  console.log('::::::::::::::::::: CODE:3231');
    // console.log(this.parseJwt(this.token).auth);
    if (this.token) {
      console.log('login constructor');
      let expired = this.checkIfTokenExpired(this.token);
      if (expired) {
        return false;
      } else {
        return true;
      }
    }
  }

  getRoles() {
    return this.parseJwt(this.token).auth;
  }

  getCurrentUserRoles() {
    return this.httpClient
        .get<any>(this.baseApiUrl + 'accounts/currentRoles', this.httpOptions).pipe(
            map(res => {
              return res.data;
            }),
            catchError(this.handleError)
        );
  }


  logoutReportServer() {
    return this.httpClient
        .get<any>('http://sreids:8082/reportserver-ee/reportserver/scriptAccess?id=151380', this.httpOptions).pipe(
            map(res => {
              return res.data;
            }),
            catchError(this.handleError)
        );
  }


  parseJwt(token) {
    let base64Url = token.split('.')[1];
    // let base64 = base64Url.replace('-', '+').replace('_', '/');
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64));
  }

  setRefreshTokenTimer(token) {
    let exp = this.parseJwt(token).exp * 1000;
    let d = new Date();
    var n = d.getTime();
    console.log('exp ' + exp + ' date: ' + new Date(exp));
    console.log('now ' + n + ' date: ' + new Date(n));

    let refreshTime = exp - n - this.refreshTokenBeforeExp;
    console.log('timer ' + refreshTime);
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerSubscription = timer(refreshTime).pipe(first()).subscribe(() => {
      this.refreshToken().subscribe(
          res => {
            console.log('success');
          },
          error2 => {
            console.log(error2);
          }
      );
    });
  }

  checkIfTokenExpired(token): boolean {
    let exp = this.parseJwt(token).exp * 1000;
    let d = new Date();
    var n = d.getTime();
    console.log('checkIfTokenExpired', exp, n);
    if (exp - n < 0) {
      return true;
    } else {
      return false;
    }
  }

  getAuthorizationToken() {
    return sessionStorage.getItem(globals.AUTH_TOKEN_NAME);
  }

  loginDummy() {
    this.userLoadedSource.next({'user': {'username': 'admin', 'password': 'password'}});
  }

  setOrganizationUnit(oru) {
    this.organizationUnitChangedSource.next(oru);
  }

  loginCert(): Observable<Account> {
    return this.httpClient
        .post<any>(this.loginUrl + 'WithCard', this.httpOptions).pipe(
            map(res => {
              return this.processDataLogin(res);
            }),
            catchError(this.handleError)
        );
  }

// https://medium.com/@blacksonic86/authentication-in-angular-2-958052c64492#.ipa2n8y1u

// http://stackoverflow.com/questions/33948709/angular2-detect-variable-change-in-parent-from-centralized-data


}
