import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from "../../../base/base-list/base-list.component";
import {Table} from "primeng/table";
import Utils from "../../../../utils/utils";
import {BaseDetailComponent} from "../../../base/base-detail/base-detail.component";
import {CommonCodeService} from "../../../../core/common-code.service";

@Component({
  selector: 'app-form-item-structure-dialog',
  templateUrl: './form-item-structure-dialog.component.html',
  styleUrls: ['./form-item-structure-dialog.component.css']
})
export class FormItemStructureDialogComponent extends BaseDetailComponent implements OnInit {

  @Output() onSubmited = new EventEmitter<any>();
  @Input('formItems') formItems;

  @ViewChild('xxxForm', {static: false}) form;

  selectedObject:any = {};

  visible: boolean =  false;

  srcComponent;

  constructor(
      private commonCodeService: CommonCodeService
  ) {
    super(commonCodeService);
  }

  ngOnInit() {
  }

  showDlg(obj, srcComponent) {
    this.selectedObject = JSON.parse(obj, Utils.jsonDateParser);
    this.srcComponent = srcComponent;
    this.visible = true;
  }

  onSubmit() {
    this.visible = false;
    this.onSubmited.emit(JSON.stringify(this.selectedObject, Utils.getCircularReplacer()));
  }

  onSubmitedFormStructureItem(obj) {
    const labelWidth = this.selectedObject.labelWidth;
    this.selectedObject = JSON.parse(obj, Utils.jsonDateParser);
    this.selectedObject.labelWidth = labelWidth;
  }

  onHide() {
    // setTimeout(() => this.form.resetForm(), 0);
  }

}
