import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProjectHeader} from '../model/project-header';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ProjectHeaderService {

    private baseApiUrl = globals.BASE_API_URL;
    private projectHeadersUrl = this.baseApiUrl + 'projectHeaders';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProjectHeaders(): Observable<ProjectHeader[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectHeadersUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectHeader(id: number): Observable<ProjectHeader> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectHeadersUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.projectHeadersUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(pjh: ProjectHeader): Observable<ProjectHeader> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectHeadersUrl, JSON.stringify(pjh)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(projectHeader: ProjectHeader): Observable<ProjectHeader> {
        const url = `${this.projectHeadersUrl}/${projectHeader.pjhId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(projectHeader)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.projectHeadersUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(projectHeader) {

        if (projectHeader.pjhStartDate) {
            projectHeader.pjhStartDate = new Date(projectHeader.pjhStartDate);
        }
        if (projectHeader.pjhEndDate) {
            projectHeader.pjhEndDate = new Date(projectHeader.pjhEndDate);
        }
        if (projectHeader.pjhSysSyncTst) {
            projectHeader.pjhSysSyncTst = new Date(projectHeader.pjhSysSyncTst);
        }
        if (projectHeader.pjhSysCreateDate) {
            projectHeader.pjhSysCreateDate = new Date(projectHeader.pjhSysCreateDate);
        }
        if (projectHeader.pjhSysUpdateDate) {
            projectHeader.pjhSysUpdateDate = new Date(projectHeader.pjhSysUpdateDate);
        }
        if (projectHeader.pjhContractSigningDate) {
            projectHeader.pjhContractSigningDate = new Date(projectHeader.pjhContractSigningDate);
        }
        return projectHeader;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    // 11.03.2022 Nenad: popuna dijaloga po acc_id
    getProjectHeadersByAccId(accId: number): Observable<ProjectHeader[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectHeadersUrl + 'ByAccId'}/${accId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // 11.03.2022 Nenad: popuna dijaloga za rolu fzn kontrolor koji vidi sve projekte
    getProjectHeadersAllCustom(): Observable<ProjectHeader[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectHeadersUrl + 'AllCustom'}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // 18.08.2022 Nenad: popuna dijaloga po acc_id i pjh_prh_id
    getProjectHeadersByAccIdAndPjhPrhId(accId: number, pjhPrhId: number): Observable<ProjectHeader[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectHeadersUrl + 'ByAccIdAndPjhPrhId'}/${accId}/${pjhPrhId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // 18.08.2022 Nenad: popuna dijaloga za rolu fzn kontrolor koji vidi sve projekte i pjh_prh_id
    getProjectHeadersAllCustomAndPjhPrhId(pjhPrhId: number): Observable<ProjectHeader[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectHeadersUrl + 'AllCustomAndPjhPrhId'}/${pjhPrhId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



