import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProjectHeader} from '../../../../../../model/project-header';
import {ProjectHeaderService} from '../../../../../../service/project-header.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {AuthService} from '../../../../../../../core/auth.service';
import {ProgrammeHeader} from '../../../../../../model/programme-header';

@Component({
    selector: 'app-project-header-csc1251selection-custom-dialog',
    templateUrl: './project-header-csc1251selection-custom-dialog.component.html',
    styleUrls: ['./project-header-csc1251selection-custom-dialog.component.css']
})
export class ProjectHeaderCsc1251selectionCustomDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public projectHeaders: ProjectHeader[];
    @Output() onSelected = new EventEmitter<ProjectHeader>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'programmeHeader.prhAcronym': null, 'pjhAcronym': null, 'pjhName': null};

    exportCols = ['programmeHeader.prhAcronym', 'pjhAcronym', 'pjhName'];

    @Input('passedPrhId') passedPrhId: number;

    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    public selectedProjectHeader: ProjectHeader;
    passPrhId: number;

    // custom-constructor-start
    private cmpDomainName = 'project-header';

    user: any;

    roles: any;

    constructor(private projectHeaderService: ProjectHeaderService,
                private auth: AuthService) {
        super();
        this.roles = this.auth.getRoles();
    }

    // custom-constructor-end


    ngOnInit(): void {
        // this.getProjectHeaders();
        // this.auth.getUser().subscribe(
        //     res => {
        //         this.user = res;
        //     }
        // );
    }


    selectPjh(obj: any) {
        this.selectPjhCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        debugger;
        this.getProjectHeaders(this.passedPrhId);
    }

    getProjectHeaders(passPrhId: number): void {
        this.getProjectHeadersCustom(passPrhId);
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProjectHeadersCustom(passPrhId: number) {
        // this.projectHeaderService
        //     .getProjectHeaders()
        //     .subscribe(projectHeaders => {
        //         this.projectHeaders = projectHeaders;
        //         this.projectHeaders.sort((pjh1, pjh2) => {
        //             return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //         });
        //         this.visible = true;
        //     }, error => this.errorMessage = <any> error);

        // this.projectHeaderService
        //     .getProjectHeadersByAccId(this.user.accId)
        //     .subscribe(projectHeaders => {
        //         this.projectHeaders = projectHeaders;
        //         this.projectHeaders.sort((pjh1, pjh2) => {
        //             return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
        //         });
        //         this.visible = true;
        //     }, error => this.errorMessage = <any> error);

        // 04.04.2022 Nenad: dodat metod provijere prijavljenje uloge korisnika
        // ako je uloga RUKOVODILAC_PROJEKTA onda vidi svoje projekte samo
        // ako je uloga FZN_KONTROLOR on vidi sve
        if (this.roles.includes('ROLE_RUKOVODILAC_PROJEKTA') && !this.roles.includes('ROLE_FZN_KONTROLOR')) {

            // 11.03.2022 Nenad: dodat novi metod za popunu dijaloga
            // ovo je bilo originalno ranije uradjeno, ali je izvrsena dorada 04.04.2022
            this.auth.getUser().subscribe(
                rs => {
                    this.user = rs;
                    this.projectHeaderService
                        .getProjectHeadersByAccIdAndPjhPrhId(this.user.accId, passPrhId)
                        .subscribe(projectHeaders => {
                            this.projectHeaders = projectHeaders;
                            this.projectHeaders.sort((pjh1, pjh2) => {
                                return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
                            });
                            this.visible = true;
                        }, error => this.errorMessage = <any> error);

                }, err => {
                    console.log(err);
                }
            );
        }
        // 04.04.2022 Nenad: ovo je dodato za ulogu FZN_KONTROLOR
        if ((this.roles.includes('ROLE_FZN_KONTROLOR') || this.roles.includes('ROLE_ADMIN')) && !this.roles.includes('ROLE_RUKOVODILAC_PROJEKTA')) {
            this.projectHeaderService
                .getProjectHeadersAllCustomAndPjhPrhId(passPrhId)
                .subscribe(projectHeaders => {
                    this.projectHeaders = projectHeaders;
                    this.projectHeaders.sort((pjh1, pjh2) => {
                        return pjh1.programmeHeader.prhAcronym.localeCompare(pjh2.programmeHeader.prhAcronym);
                    });
                    this.visible = true;
                }, error => this.errorMessage = <any> error);
        }
        debugger;
    }

    selectPjhCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    // custom-end

}
