import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BaseDetailComponent} from '../../../base/base-detail/base-detail.component';
import {GanttChartDetail} from '../../../model/gantt-chart-detail';
import {ProjectReportPeriod} from '../../../model/project-report-period';
import {GanttChartDetailService} from '../../../service/gantt-chart-detail.service';
import {ProjectReportPeriodService} from '../../../service/project-report-period.service';
import {CommonCodeService} from '../../../../core/common-code.service';
import {MessageService} from '../../../../core/message.service';
import {GanttChartHeader} from '../../../model/gantt-chart-header';
import {ProjectStructureElement} from '../../../model/project-structure-element';
import {InteractionObject} from "../../../../shared/interaction-object";

@Component({
    selector: 'app-gantt-chart-detail-dialog',
    templateUrl: './gantt-chart-detail-dialog.component.html',
    styleUrls: ['./gantt-chart-detail-dialog.component.css']
})
export class GanttChartDetailDialogComponent extends BaseDetailComponent implements OnInit {

    visible = false;

    selectedMode: string;

    selectedGanttChartDetails: GanttChartDetail[];
    selectedGanttChartHeader: GanttChartHeader;
    selectedProjectStructureElement: ProjectStructureElement;
    deletedGcds: number[];

    projectReportPeriods: ProjectReportPeriod[];

    @ViewChild('gcdForm', {static: false}) gcdForm;

    @Output('ganttChartDetailDialogEvent') ganttChartDetailDialogEvent = new EventEmitter<any>();

    constructor(
        private ganttChartDetailService: GanttChartDetailService,
        private projectReportPeriodService: ProjectReportPeriodService,
        private commonCodeService: CommonCodeService,
        private messageService: MessageService
    ) {
        super(commonCodeService);
        this.commonCodeTypes = [];
    }

    ngOnInit() {
    }

    showDlg(
        selectedGanttChartDetails: GanttChartDetail[],
        selectedGanttChartHeader: GanttChartHeader,
        selectedProjectStructureElement: ProjectStructureElement,
        selectedMode: string
    ) {
        this.btnSaveDisabled = false;
        this.deletedGcds = [];
        this.selectedGanttChartDetails = selectedGanttChartDetails;
        this.selectedGanttChartHeader = selectedGanttChartHeader;
        this.selectedProjectStructureElement = selectedProjectStructureElement;
        this.selectedMode = selectedMode;
        this.getProjectReportPeriodsByPrpPjhIdAndPrpType();
    }

    getProjectReportPeriodsByPrpPjhIdAndPrpType() {
        if (this.selectedGanttChartHeader && this.selectedGanttChartHeader.projectHeader) {
            this.projectReportPeriodService.getProjectReportPeriodsByPrpPjhIdAndPrpType(this.selectedGanttChartHeader.projectHeader.pjhId, 'prp_type_gantt')
                .subscribe(
                    projectReportPeriods => {
                        this.projectReportPeriods = projectReportPeriods;
                        this.visible = true;
                    }, error => {
                        this.visible = true;
                        this.errorMessage = <any> error;
                    }
                );
        }
    }

    // onHide() {
    //     setTimeout(() => this.gcdForm.resetForm(), 0);
    // }

    onSubmit() {
        this.btnSaveDisabled = true;
        this.ganttChartDetailService.createUpdateBatch(this.selectedGanttChartDetails, this.deletedGcds)
            .subscribe(
                r => {
                    this.btnSaveDisabled = false;
                    this.visible = false;
                    // this.messageService.showSuccessSaveMsg();
                    this.ganttChartDetailDialogEvent.emit();
                }, error => {
                    this.btnSaveDisabled = false;
                    this.visible = false;
                    this.messageService.showFailMsgText(error);
                }
            );
    }

    selectProjectReportPeriod(projectReportPeriod: ProjectReportPeriod) {
        const ganttChartDetailFound = this.selectedGanttChartDetails.find(gcd => {
           return gcd.projectReportPeriod && gcd.projectReportPeriod.prpId === projectReportPeriod.prpId;
        });

        if (ganttChartDetailFound) {
            this.selectedGanttChartDetails = this.selectedGanttChartDetails.filter(gcd => {
                return gcd.projectReportPeriod && gcd.projectReportPeriod.prpId !== projectReportPeriod.prpId;
            });

            if (ganttChartDetailFound.gcdId) {
                this.deletedGcds.push(ganttChartDetailFound.gcdId);
            }
        } else {
            const newGanttChartDetail = new GanttChartDetail();
            newGanttChartDetail.projectReportPeriod = projectReportPeriod;
            newGanttChartDetail.ganttChartHeader = this.selectedGanttChartHeader;
            newGanttChartDetail.projectStructureElement = this.selectedProjectStructureElement;
            this.selectedGanttChartDetails.push(newGanttChartDetail);
        }
    }

    gcdExists(projectReportPeriod: ProjectReportPeriod) {
        return this.selectedGanttChartDetails.find(gcd => {
            return gcd.projectReportPeriod && gcd.projectReportPeriod.prpId === projectReportPeriod.prpId;
        });
    }

}
