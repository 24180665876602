import {Injectable} from '@angular/core';
import {Subject, Observable, forkJoin} from 'rxjs';
import {Message} from '../shared/message';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';

@Injectable()
export class MessageService {

  // Observable msg sources
  private messageAnnouncedSource = new Subject<Message[]>();
  private messageAnnouncedDialogSource = new Subject<Message[]>();
  // Observable msg streams
  messageAnnounced$ = this.messageAnnouncedSource.asObservable();
  messageAnnouncedDialog$ = this.messageAnnouncedDialogSource.asObservable();

  constructor(private _translateService: TranslateService) {

  }

  addMessages(messages: Message[]) {
    console.log('msg addedddd');
    this.messageAnnouncedSource.next(messages);
  }

  showSuccessStornoMsg() {
    console.log('showSuccessSaveMsg');
    this._translateService.get('MSG_DATA_STORNO_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }




  showSuccessSaveMsg() {
    console.log('showSuccessSaveMsg');
    this._translateService.get('MSG_DATA_SAVED_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'success',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }


  showDeactivateMsg() {
    console.log('showDeactivateMsg');
    this._translateService.get('MSG_DATA_DEACTIVATE_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showSuccessResetPasswordMsg() {
    this._translateService.get('MSG_DATA_RESET_PASSWORD_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showSuccessSetNewPasswordMsg() {
    this._translateService.get('MSG_DATA_SET_NEW_PASSWORD_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showSuccessSaveNewPasswordMsg() {
    this._translateService.get('MSG_DATA_SAVE_NEW_PASSWORD_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showFailResetPasswordMsg() {
    this._translateService.get('MSG_DATA_RESET_PASSWORD_FAIL').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  PasswordNotMatchingMsg() {
    this._translateService.get('PasswordNotMatching').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showAccountDisabledMsg() {
    this._translateService.get('ACCOUNT_DISABLED').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }


  showSuccessMsgLoc(param1: string) {
    this._translateService.get(param1).subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showSuccessMsgDeactivate(param1: string) {
    this._translateService.get(param1).subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showFailSaveMsg() {
    this._translateService.get('DATA_SAVE_FAIL').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showFailMsg(msgParam: string) {
    this._translateService.get(msgParam).subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showSuccessMsg(msgParam: string) {
    const msg = {
      severity: 'success',
      summary: 'ИНФО',
      detail: msgParam
    };
    this.addMessages([msg]);
  }

  showFailMsgText(text: string) {
    this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
      const msg = {
        severity: 'error',
        summary: res1,
        detail: text
      };
      this.addMessages([msg]);
    });
  }

  showFailComplatesProcessDialog(text: string) {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res: string) => {
        const msg = {
          severity: 'error',
          summary: res,
          detail: text
        };
        this.addMessages([msg]);
      });
  }

  showSuccessSendMsg() {
    this._translateService.get('DATA_SEND_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  showFailSendMsg() {
    this._translateService.get('DATA_SEND_FAIL').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessages([msg]);
      });
    });
  }

  /*
   METODE ZA DIALOG:
   */
  addMessagesDilaog(messages: Message[]) {
    this.messageAnnouncedDialogSource.next(messages);
  }


  showSuccessSaveMsgDialog() {
    this._translateService.get('DATA_SAVE_SUCCESS').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
        const msg = {
          severity: 'info',
          summary: res1,
          detail: res
        };
        this.addMessagesDilaog([msg]);
      });
    });
  }

  showFailSaveMsgDialog() {
    this._translateService.get('DATA_SAVE_FAIL').subscribe((res: string) => {
      this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessagesDilaog([msg]);
      });
    });
  }

  showFailMsgDialog(msgParam: string) {
    this._translateService.get(msgParam).subscribe((res: string) => {
      this._translateService.get('ERROR').subscribe((res1: string) => {
        const msg = {
          severity: 'error',
          summary: res1,
          detail: res
        };
        this.addMessagesDilaog([msg]);
      });
    });
  }

  showFailMsgTextDialog(text: string) {
    this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res1: string) => {
      const msg = {
        severity: 'error',
        summary: res1,
        detail: text
      };
      this.addMessagesDilaog([msg]);
    });
  }


  processErrorResponse(response: any, place: string) {
    console.log(' any response' + response);

    const errorMessageOrginal = JSON.parse(response);

    console.log('errorMessageOrginal');
    console.log(errorMessageOrginal);

    if (errorMessageOrginal.errorType) {
      console.log('error type: ' + errorMessageOrginal.errorType);
      if (errorMessageOrginal.errorType == 'GlobalApplicationException') {
        this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res: string) => {
          const msg = {
            severity: 'error',
            summary: res,
            detail: errorMessageOrginal.message
          };
          if (place == 'dialog') {
            this.addMessagesDilaog([msg]);
          } else {
            this.addMessages([msg]);
          }
        });
      }
      if (errorMessageOrginal.errorType == 'Validation exception') {
        const message2 = errorMessageOrginal.message;

//
        let msgs = [];
        let observables = [];
        for (let key in message2) {
          observables.push(this._translateService.get(key.toLowerCase()).pipe(
            map(
              (res: string) => {
                return {'severity': 'error', 'summary': (res ? res : key), 'detail': message2[key]};
              })));
        }
        forkJoin(observables).subscribe(
          msg => {
            msgs.push(msg);
          },
          function () {
            if (place == 'dialog') {
              this.addMessagesDilaog(msgs);
            } else {
              this.addMessages(msgs);
            }
            console.log('Completed');
          }
        );
      }
    } else {

      const message1 = JSON.parse(errorMessageOrginal.message);
      // console.log('message1');
      // console.log(message1);

      if (message1.errorType) {
        console.log('error type: ' + message1.errorType);
        if (message1.errorType == 'GlobalApplicationException') {

          this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res: string) => {
            const msg = {
              severity: 'error',
              summary: res,
              detail: message1.message
            };
            if (place == 'dialog') {
              this.addMessagesDilaog([msg]);
            } else {
              this.addMessages([msg]);
            }
          });
        } else if (message1.errorType == 'Validation exception') {
          const message2 = message1.message;
          let msgs = [];
          let observables = [];
          for (let key in message2) {
            observables.push(this._translateService.get(key.toLowerCase()).pipe(map(
              (res: string) => {
                msgs.push({'severity': 'error', 'summary': (res ? res : key), 'detail': message2[key]});
              })));
          }
          forkJoin(observables).subscribe(
            () => {
              console.log('enddddddddddd');
              if (place == 'dialog') {
                this.addMessagesDilaog(msgs);
              } else {
                this.addMessages(msgs);
              }

            }
          );
        }
      } else {
        this._translateService.get('MSG_SUMMARY_FAIL').subscribe((res: string) => {
          const msg = {
            severity: 'error',
            summary: res,
            detail: message1
          };
          if (place == 'dialog') {
            this.addMessagesDilaog([msg]);
          } else {
            this.addMessages([msg]);
          }
        });
      }
    }
  }

    showSuccessDeleteMsg() {
        console.log('showSuccessDeleteMsg');
        this._translateService.get('MSG_DATA_DELETE_SUCCESS').subscribe((res: string) => {
            this._translateService.get('MSG_SUMMARY_INFO').subscribe((res1: string) => {
                const msg = {
                    severity: 'success',
                    summary: res1,
                    detail: res
                };
                this.addMessages([msg]);
            });
        });
    }

}
