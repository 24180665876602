import { Component, OnInit } from '@angular/core';
import {RibbonService} from '../../shared/ribbon.service';


@Component({
  selector: 'app-page-ribbon',
  template: `<div class="ribbon" *ngIf="displayRibbon"><a href="">{{ribbonProfile}}</a></div>`,
  styleUrls: ['ribbon.component.css']
})
export class PageRibbonComponent implements OnInit {
  displayRibbon: boolean;
  ribbonProfile: string;

  constructor(private _ribbonService: RibbonService) {}

  ngOnInit() {
    this._ribbonService.getActiveProfiles().subscribe(
      activeProfiles => {
        if (activeProfiles.some(profile => profile === 'dev')) {
          this.displayRibbon = true;
          this.ribbonProfile = 'РАЗВОЈ';
        } else if (activeProfiles.some(profile => profile === 'test')) {
          this.displayRibbon = true;
          this.ribbonProfile = 'ТЕСТ';
        } else {
          this.displayRibbon = false;
        }
      }
    );
  }
}
