import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ReportTemplate} from '../../../../../../model/report-template';
import {ReportTemplateService} from '../../../../../../service/report-template.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component'


@Component({
    selector: 'app-report-template-csc1520selection-dialog',
    templateUrl: './report-template-csc1520selection-dialog.component.html',
    styleUrls: ['./report-template-csc1520selection-dialog.component.css']
})
export class ReportTemplateCsc1520selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public reportTemplates: ReportTemplate[];
    @Output() onSelected = new EventEmitter<ReportTemplate>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'rptCode': null, 'rptDescription': null}


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'report-template';

    constructor(private reportTemplateService: ReportTemplateService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getReportTemplates();
    }


    selectRpt(obj: any) {
        this.selectRptCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getReportTemplates(): void {
        this.getReportTemplatesCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getReportTemplatesCustom() {
        this.reportTemplateService
            .getReportTemplates()
            .subscribe(reportTemplates => this.reportTemplates = reportTemplates, error => this.errorMessage = <any>error);
    }

    selectRptCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
