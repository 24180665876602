import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseDetailComponent} from "../../../base/base-detail/base-detail.component";
import {CommonCodeService} from "../../../../core/common-code.service";
import {DynamicDataDictService} from "../../../service/dynamic-data-dict.service";
import {MessageService} from "../../../../core/message.service";

@Component({
    selector: 'app-dynamic-data-dict-update-dialog',
    templateUrl: './dynamic-data-dict-update-dialog.component.html',
    styleUrls: ['./dynamic-data-dict-update-dialog.component.css']
})
export class DynamicDataDictUpdateDialogComponent extends BaseDetailComponent implements OnInit {

    dynamicDataDictTables: string;
    visible: boolean = false;
    @ViewChild('dynamicDataDictUpdateForm', {static: false}) dynamicDataDictUpdateForm;

    constructor(
        private commonCodeService: CommonCodeService,
        private dynamicDataDictService: DynamicDataDictService,
        private messageService: MessageService
    ) {
        super(commonCodeService);
    }

    ngOnInit() {
    }

    showDlg() {
        debugger;
        this.dynamicDataDictTables = '';
        this.visible = true;
        this.refreshForm();
    }

    onSubmit() {
        this.dynamicDataDictService.dynamicDataDictUpdateTables(this.dynamicDataDictTables).subscribe(
            res => {
                this.messageService.showSuccessSaveMsg();
                this.visible = false;
            }, error => {
                this.messageService.showFailMsgText(error);
                this.visible = false;
            }
        );
    }

}
