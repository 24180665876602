import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {GovernmentInstitution} from '../../../../../../model/government-institution';
import {GovernmentInstitutionService} from '../../../../../../service/government-institution.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';

import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-government-institution-csc1830selection-dialog',
    templateUrl: './government-institution-csc1830selection-dialog.component.html',
    styleUrls: ['./government-institution-csc1830selection-dialog.component.css']
})
export class GovernmentInstitutionCsc1830selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public governmentInstitutions: GovernmentInstitution[];
    @Output() onSelected = new EventEmitter<GovernmentInstitution>();
    public errorMessage: string;
    visible = false;



    tfFilterMap = {'goiName': null, 'goiAddress': null, 'goiCode': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'government-institution';
    @Input('goiTypecsc1830selection') goiType: string;

    constructor(private _governmentInstitutionService: GovernmentInstitutionService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getGovernmentInstitutions();
    }


    selectGoi(obj: any) {
        this.selectGoiCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getGovernmentInstitutions(): void {
        this.getGovernmentInstitutionsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getGovernmentInstitutionsCustom() {
        /*
        this._governmentInstitutionService
        .getGovernmentInstitutions()
            .subscribe(governmentInstitutions => this.governmentInstitutions = governmentInstitutions, error => this.errorMessage = <any> error);
        */
        this._governmentInstitutionService
            .getGovernmentInstitutionsByType(this.goiType)
            .subscribe(governmentInstitutions => this.governmentInstitutions = governmentInstitutions, error => this.errorMessage = <any> error);

    }

    selectGoiCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }

    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    // custom-end

}
