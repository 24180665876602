import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import Utils from '../../../../utils/utils';
import {BaseDetailComponent} from '../../../base/base-detail/base-detail.component';
import {CommonCodeService} from '../../../../core/common-code.service';

@Component({
  selector: 'app-dynamic-forms-row-generator-dialog',
  templateUrl: './dynamic-forms-row-generator-dialog.component.html',
  styleUrls: ['./dynamic-forms-row-generator-dialog.component.css']
})
export class DynamicFormsRowGeneratorDialogComponent extends BaseDetailComponent implements OnInit {

  visible: boolean =  false;
  maxColumns: 12;
  columns = [];

  @Output() onSubmited = new EventEmitter<any>();

  columnOptions = [];

  availableColumnOptions = [];

  selectedRow;
  selectedColumn;
  selectedMode;

  constructor(
      private commonCodeService: CommonCodeService
  ) {
    super(commonCodeService);

    for (let i = 3; i <= 12; i++) {
      this.columnOptions.push({
        label: i,
        value: i
      });
    }
  }

  ngOnInit() {
  }

  showDlg(selectedRow, selectedColumn, selectedMode) {
    this.visible = true;
    this.selectedRow = selectedRow;
    this.selectedColumn = JSON.parse(selectedColumn);
    this.selectedMode = selectedMode;

    let columnWidth = 0;

    this.selectedRow.columns.forEach(c => {
      columnWidth += c.width;
    });

    (this.selectedMode === 'edit') && (columnWidth -= this.selectedColumn.width);

    this.availableColumnOptions = this.columnOptions.filter(co => {
      return (co.value + columnWidth <= 12);
    });
  }

  onSubmit() {
    this.visible = false;
    this.onSubmited.emit(JSON.stringify(this.selectedColumn, Utils.getCircularReplacer()));
  }

}
