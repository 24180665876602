import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {CostType} from '../model/cost-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {ProgrammeCostType} from "../model/programme-cost-type";


@Injectable()
export class CostTypeService {

    private baseApiUrl = globals.BASE_API_URL;
    private costTypesUrl = this.baseApiUrl + 'costTypes';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getCostTypes(): Observable<CostType[]> {
        return this.httpClient.get<ResponseWrapper>(this.costTypesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getCostTypesDynamicDialog(sqlQuery): Observable<CostType[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.costTypesUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    getCostType(id: number): Observable<CostType> {
        return this.httpClient.get<ResponseWrapper>(`${this.costTypesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.costTypesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(ctp: CostType): Observable<CostType> {
        return this.httpClient
            .post<ResponseWrapper>(this.costTypesUrl, JSON.stringify(ctp)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(costType: CostType): Observable<CostType> {
        const url = `${this.costTypesUrl}/${costType.ctpId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(costType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.costTypesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(costType) {

        if (costType.ctpSysCreateDate) {
            costType.ctpSysCreateDate = new Date(costType.ctpSysCreateDate);
        }
        if (costType.ctpSysSyncTst) {
            costType.ctpSysSyncTst = new Date(costType.ctpSysSyncTst);
        }
        if (costType.ctpSysUpdateDate) {
            costType.ctpSysUpdateDate = new Date(costType.ctpSysUpdateDate);
        }
        return costType;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



