import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Account} from '../model/account';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {VRoleAccounts} from "../model/v-role-accounts";


@Injectable()
export class AccountService {

    private baseApiUrl = globals.BASE_API_URL;
    private accountsUrl = this.baseApiUrl + 'accounts';  // URL to web api
    private accountsHelpDescUrl = this.baseApiUrl + 'accountsHelpDesc';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getAccounts(): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccount(id: number): Observable<Account> {
        return this.httpClient.get<ResponseWrapper>(`${this.accountsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.accountsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(acc: Account): Observable<Account> {
        return this.httpClient
            .post<ResponseWrapper>(this.accountsUrl, JSON.stringify(acc)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(account: Account): Observable<Account> {
        const url = `${this.accountsUrl}/${account.accId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(account)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.accountsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(account) {

        if (account.accPasswordDate) {
            account.accPasswordDate = new Date(account.accPasswordDate);
        }
        if (account.accSysSyncTst) {
            account.accSysSyncTst = new Date(account.accSysSyncTst);
        }
        if (account.accSysCreateDate) {
            account.accSysCreateDate = new Date(account.accSysCreateDate);
        }
        if (account.accTokenExp) {
            account.accTokenExp = new Date(account.accTokenExp);
        }
        if (account.accSysUpdateDate) {
            account.accSysUpdateDate = new Date(account.accSysUpdateDate);
        }
        return account;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    private updatePsw = this.accountsUrl + 'UpdatePassword';

    updatePassword(account: Account, oldPassword: string, newPassword: string): Observable<Account> {
        const url = `${this.updatePsw}/${account.accId}` + '?old_password=' + oldPassword + '&new_password=' + newPassword;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(account)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    private updatePswExp = this.accountsUrl + 'UpdatePasswordExp';

    updatePasswordOnExp(account: Account, newPassword: string): Observable<Account> {
        const url = `${this.updatePswExp}/${account.accId}` + '?new_password=' + newPassword;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(account)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    getManagerByOruId(oruId: number): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + 'FindManagersByOruId/' + oruId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getUnderOruId(oruId: number): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + 'FindUnderOruId/' + oruId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsByRolId(rolId: number): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '?rolId=' + rolId).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsByWpoId(wpoId: number): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '?wpoId=' + wpoId).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsByRolName(rolName: string): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + 'ByRolName?rolName=' + rolName).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsCaseCountByRolId(rolId: number): Observable<any[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '/caseCount?rolId=' + rolId).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsMsrPclCaseCountByAccId(accId): Observable<any[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '/msrPclCaseCount?accId=' + accId).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsKnlmCaseCountByAccId(accId): Observable<any[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '/knlmCaseCount?accId=' + accId).pipe(
            map(rw => {

                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsHelpDesc(): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsHelpDescUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountForReassignment(accId: number, rolId: number): Observable<Account[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + 'ForReassignment?accId=' + accId + '&rolId=' + rolId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsByRoleName(roleName: string): Observable<Account[]> {
        const url = `${this.accountsUrl}/byRoleName/${roleName}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getAccountsByRoleId(rolId: number): Observable<VRoleAccounts[]> {
        return this.httpClient.get<ResponseWrapper>(this.accountsUrl + '/ByRoleId?rolId=' + rolId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    // custom-end

}



