import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProjectReportPeriod} from '../model/project-report-period';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {Sro} from "../model/sro";


@Injectable()
export class ProjectReportPeriodService {

    private baseApiUrl = globals.BASE_API_URL;
    private projectReportPeriodsUrl = this.baseApiUrl + 'projectReportPeriods';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProjectReportPeriods(): Observable<ProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectReportPeriodsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriod(id: number): Observable<ProjectReportPeriod> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectReportPeriodsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.projectReportPeriodsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(prp: ProjectReportPeriod): Observable<ProjectReportPeriod> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectReportPeriodsUrl, JSON.stringify(prp)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(projectReportPeriod: ProjectReportPeriod): Observable<ProjectReportPeriod> {
        const url = `${this.projectReportPeriodsUrl}/${projectReportPeriod.prpId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(projectReportPeriod)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.projectReportPeriodsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(projectReportPeriod) {

        if (projectReportPeriod.prpEndDate) {
            projectReportPeriod.prpEndDate = new Date(projectReportPeriod.prpEndDate);
        }
        if (projectReportPeriod.prpSysSyncTst) {
            projectReportPeriod.prpSysSyncTst = new Date(projectReportPeriod.prpSysSyncTst);
        }
        if (projectReportPeriod.prpSysCreateDate) {
            projectReportPeriod.prpSysCreateDate = new Date(projectReportPeriod.prpSysCreateDate);
        }
        if (projectReportPeriod.prpSysUpdateDate) {
            projectReportPeriod.prpSysUpdateDate = new Date(projectReportPeriod.prpSysUpdateDate);
        }
        if (projectReportPeriod.prpStartDate) {
            projectReportPeriod.prpStartDate = new Date(projectReportPeriod.prpStartDate);
        }
        if (projectReportPeriod.prpDeadline) {
            projectReportPeriod.prpDeadline = new Date(projectReportPeriod.prpDeadline);
        }
        return projectReportPeriod;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getProjectReportPeriodsByPrpPjhIdAndPrpType(prpPjhId: number, prpType: string): Observable<ProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectReportPeriodsUrl}ByPrpPjhIdAndPrpType/${prpPjhId}/${prpType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriodsMaxByPrpPjhIdAndPrpType(prpPjhId: number, prpType: string): Observable<ProjectReportPeriod> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectReportPeriodsUrl}MaxByPrpPjhIdAndPrpType/${prpPjhId}/${prpType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectReportPeriodsQuartalsDynamicDialog(sqlQuery): Observable<ProjectReportPeriod[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectReportPeriodsUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    getProjectReportPeriodsByPrpType(prpType: string): Observable<ProjectReportPeriod[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectReportPeriodsUrl}ByPrpType/${prpType}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



