import * as globals from '../globals/globals';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import Utils from '../utils/utils';
import {ResponseWrapper} from '../bo/model/sys/response-wrapper';
import {Injectable} from '@angular/core';

@Injectable()
export class RibbonService {
  private ribbonUrl =  globals.BASE_API_URL + 'ribbon';

  constructor(private httpClient: HttpClient) {
  }

  getActiveProfiles(): Observable<string[]> {

    return this.httpClient.get<ResponseWrapper>(this.ribbonUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }
}

