import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {OrganizationUnit} from '../../../../../../model/organization-unit';
import {OrganizationUnitService} from '../../../../../../service/organization-unit.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-organization-unit-gbo2650selection-dialog',
    templateUrl: './organization-unit-gbo2650selection-dialog.component.html',
    styleUrls: ['./organization-unit-gbo2650selection-dialog.component.css']
})
export class OrganizationUnitGbo2650selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public organizationUnits: OrganizationUnit[];
    @Output() onSelected = new EventEmitter<OrganizationUnit>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'oruCode': null, 'oruFullname': null};


    @ViewChild('dt1', {static: false}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start

    @Input()
    private _oruLevel = -1;
    private cmpDomainName = 'organization-unit';

    constructor(private _organizationUnitService: OrganizationUnitService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getOrganizationUnits();
    }


    selectOru(obj: any) {
        this.selectOruCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getOrganizationUnits(): void {
        this.getOrganizationUnitsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getOrganizationUnitsCustom() {
        if (this.oruLevel == -1) {
            this._organizationUnitService
                .getOrganizationUnits()
                .subscribe(organizationUnits => this.organizationUnits = organizationUnits, error => this.errorMessage = <any> error);
        }
    }

    selectOruCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    get oruLevel(): number {
        return this._oruLevel;
    }

    @Input('oruLevel')
    set oruLevel(value: number) {
        console.log('setoruLevel', value);
        this._oruLevel = value;
        if (this.oruLevel != -1) {
            this._organizationUnitService.getOrganizationUnitsByCurrentOruId().subscribe(
                res => {
                    this.organizationUnits = res;
                },
                err => {
                    console.log(err);
                }
            );
        }
    }


    // custom-end

}
