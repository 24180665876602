import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ClassificationDetail} from '../../../../../../model/classification-detail';
import {ClassificationDetailService} from '../../../../../../service/classification-detail.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {WindowRef} from '../../../../../../../core/window-ref';



@Component({
    selector: 'app-classification-detail-csc1410selection-dialog-universal',
    templateUrl: './classification-detail-csc1410selection-dialog-universal.component.html',
    styleUrls: ['./classification-detail-csc1410selection-dialog-universal.component.css']
})
export class ClassificationDetailCsc1410selectionDialogUniversalComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public classificationDetails: ClassificationDetail[];
    @Output() onSelected = new EventEmitter<ClassificationDetail>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'cldCode': null, 'cldFullname': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'classification-detail';

    windowWrap: any;

    @ViewChild('dlg1', {static: false}) public dlg1: any;

    constructor(private classificationDetailService: ClassificationDetailService, private winRef: WindowRef) {
        super();
        this.windowWrap = winRef.nativeWindow;
    }


    // custom-constructor-end


    ngOnInit(): void {
       // this.getClassificationDetails();
    }


    selectCld(obj: any) {
        this.selectCldCustom(obj);
    }

    showDlg(cltId: number) {
        this.visible = true;
        this.getClassificationDetails(cltId);
    }

    getClassificationDetails(cltId: number): void {
        this.getClassificationDetailsCustom(cltId);
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getClassificationDetailsCustom(cltId: number) {
        this.classificationDetailService
            .getClassificationDetailsByCltId(cltId)
            .subscribe(classificationDetails => this.classificationDetails = classificationDetails,
                error => this.errorMessage = <any> error);
    }

    selectCldCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    showDlgAllCldLevels(cltId: number) {
        this.clearFilters();
        this.visible = true;
        this.getClassificationDetailsByCldCltIdAllCldLevels(cltId);
    }

    getClassificationDetailsByCldCltIdAllCldLevels(cltId: number) {
        this.classificationDetailService
            .getClassificationDetailsByCltIdAllCldLeveles(cltId)
            .subscribe(classificationDetails => {
                this.classificationDetails = classificationDetails;
                this.windowWrap.setTimeout(this.dlg1.center.bind(this.dlg1), 0);
                },
                error => this.errorMessage = <any> error);
    }

    // onShow() {
    //     console.log('onShow');
    //     //this.windowWrap.setTimeout(dlg1.center.bind(dlg1), 0)
    // }

    // custom-end

}
