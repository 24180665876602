import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VDocumentType} from '../model/v-document-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {VDocumentTypeList} from '../model/v-document-type-list';


@Injectable()
export class VDocumentTypeListService {

    private baseApiUrl = globals.BASE_API_URL;
    private vDocumentTypeListUrl = this.baseApiUrl + 'vDocumentTypeList';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVDocumentTypeList(): Observable<VDocumentTypeList[]> {
        return this.httpClient.get<ResponseWrapper>(this.vDocumentTypeListUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVDocumentTypeListId(id: number): Observable<VDocumentTypeList> {
        return this.httpClient.get<ResponseWrapper>(`${this.vDocumentTypeListUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }
/*
    delete(id: number): Observable<{} | Object> {
        const url = `${this.vDocumentTypeListUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }
*/
    create(dct: VDocumentTypeList): Observable<VDocumentTypeList> {
        return this.httpClient
            .post<ResponseWrapper>(this.vDocumentTypeListUrl, JSON.stringify(dct)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(vDocumentTypeList: VDocumentTypeList): Observable<VDocumentTypeList> {
        const url = `${this.vDocumentTypeListUrl}/${vDocumentTypeList.dctId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(vDocumentTypeList)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.vDocumentTypeListUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vDocumentTypeList) {

        return vDocumentTypeList;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



