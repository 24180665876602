import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {FznTransactionDetail} from '../model/fzn-transaction-detail';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {FznTransactionHeader} from "../model/fzn-transaction-header";
import {ClassificationDetail} from "../model/classification-detail";
import {YfrBudgetBalance} from "../model/yfr-budget-balance";


@Injectable()
export class FznTransactionDetailService {

    private baseApiUrl = globals.BASE_API_URL;
    private fznTransactionDetailsUrl = this.baseApiUrl + 'fznTransactionDetails';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getFznTransactionDetails(): Observable<FznTransactionDetail[]> {
        return this.httpClient.get<ResponseWrapper>(this.fznTransactionDetailsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getFznTransactionDetail(id: number): Observable<FznTransactionDetail> {
        return this.httpClient.get<ResponseWrapper>(`${this.fznTransactionDetailsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.fznTransactionDetailsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(ftd: FznTransactionDetail): Observable<FznTransactionDetail> {
        return this.httpClient
            .post<ResponseWrapper>(this.fznTransactionDetailsUrl, JSON.stringify(ftd)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(fznTransactionDetail: FznTransactionDetail): Observable<FznTransactionDetail> {
        const url = `${this.fznTransactionDetailsUrl}/${fznTransactionDetail.ftdId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(fznTransactionDetail)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.fznTransactionDetailsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(fznTransactionDetail) {

        if (fznTransactionDetail.ftdBankStatementDate) {
            fznTransactionDetail.ftdBankStatementDate = new Date(fznTransactionDetail.ftdBankStatementDate);
        }
        if (fznTransactionDetail.ftdSysUpdateDate) {
            fznTransactionDetail.ftdSysUpdateDate = new Date(fznTransactionDetail.ftdSysUpdateDate);
        }
        if (fznTransactionDetail.ftdAmountM2Date) {
            fznTransactionDetail.ftdAmountM2Date = new Date(fznTransactionDetail.ftdAmountM2Date);
        }
        if (fznTransactionDetail.ftdAmountM3Date) {
            fznTransactionDetail.ftdAmountM3Date = new Date(fznTransactionDetail.ftdAmountM3Date);
        }
        if (fznTransactionDetail.ftdEndDate) {
            fznTransactionDetail.ftdEndDate = new Date(fznTransactionDetail.ftdEndDate);
        }
        if (fznTransactionDetail.ftdSysSyncTst) {
            fznTransactionDetail.ftdSysSyncTst = new Date(fznTransactionDetail.ftdSysSyncTst);
        }
        if (fznTransactionDetail.ftdSysCreateDate) {
            fznTransactionDetail.ftdSysCreateDate = new Date(fznTransactionDetail.ftdSysCreateDate);
        }
        if (fznTransactionDetail.ftdAmountM1Date) {
            fznTransactionDetail.ftdAmountM1Date = new Date(fznTransactionDetail.ftdAmountM1Date);
        }
        if (fznTransactionDetail.ftdStartDate) {
            fznTransactionDetail.ftdStartDate = new Date(fznTransactionDetail.ftdStartDate);
        }
        if (fznTransactionDetail.ftdBankStatementDateM1) {
            fznTransactionDetail.ftdBankStatementDateM1 = new Date(fznTransactionDetail.ftdBankStatementDateM1);
        }
        if (fznTransactionDetail.ftdBankStatementDateM2) {
            fznTransactionDetail.ftdBankStatementDateM2 = new Date(fznTransactionDetail.ftdBankStatementDateM2);
        }
        if (fznTransactionDetail.ftdBankStatementDateM3) {
            fznTransactionDetail.ftdBankStatementDateM3 = new Date(fznTransactionDetail.ftdBankStatementDateM3);
        }
        if (fznTransactionDetail.ftdJsonData) {
            fznTransactionDetail.ftdJsonData = JSON.parse(JSON.stringify(fznTransactionDetail.ftdJsonData, Utils.getCircularReplacer()), Utils.jsonDateParser);
        }

        return fznTransactionDetail;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getFznTransactionDetailByFthIdAndPdfId(ftdFthId: number, ftdPdfId): Observable<FznTransactionDetail[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.fznTransactionDetailsUrl}ByFthIdAndPdfId/${ftdFthId}/${ftdPdfId}`).pipe(
            map(rw => {
                return rw.data.map(d => {
                    return this.processData(d);
                });
            }),
            catchError(this.handleError)
        );
    }

    copy(ftdId: number): Observable<FznTransactionDetail> {
        const url = `${this.fznTransactionDetailsUrl}/copy/${ftdId}`;
        return this.httpClient.post<ResponseWrapper>(url, null).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    updateStaticForm(fznTransactionDetail: FznTransactionDetail): Observable<FznTransactionDetail> {
        const url = `${this.fznTransactionDetailsUrl}/updateStatic/${fznTransactionDetail.ftdId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(fznTransactionDetail)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    getFznTransactionDetailsLazyLoad(request) {
        return this.httpClient.post<ResponseWrapper>(this.fznTransactionDetailsUrl + '/LazyLoad', JSON.stringify(request)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getFznTransactionDetailsExportDynamicLazy(request): Observable<FznTransactionDetail[]> {
        return this.httpClient.post<ResponseWrapper>(this.fznTransactionDetailsUrl + '/exportDynamicLazy', JSON.stringify(request)).pipe(
            map(rw => {
                return rw.data.map(d => {
                    return this.processData(d);
                });
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    propagateJsonFields(propagateToJsonTables: string): Observable<{} | Object> {
        let url = `${this.fznTransactionDetailsUrl}/propagateJsonFields`;
        if (propagateToJsonTables) {
            url += `/${propagateToJsonTables}`;
        }
        return this.httpClient.post(url, null).pipe(
            catchError(this.handleError)
        );
    }

    propagateJsonFieldsByDocType(ftdCode: string, prhId: number): Observable<{} | Object> {
        let url = `${this.fznTransactionDetailsUrl}/propagateJsonFieldsByDocType`;
        if (ftdCode) {
            url += `/${ftdCode}`;
        }
        if (prhId) {
            url += `/${prhId}`;
        }
        return this.httpClient.post(url, null).pipe(
            catchError(this.handleError)
        );
    }

    createPaymentInfoDocument(prhId: number, paymentInfoQuarter: number): Observable<{} | Object> {
        let url = `${this.fznTransactionDetailsUrl}/createPaymentInfoDocument`;
        if (prhId) {
            url += `/${prhId}`;
        }
        if (paymentInfoQuarter) {
            url += `/${paymentInfoQuarter}`;
        }
        return this.httpClient.post(url, null).pipe(
            catchError(this.handleError)
        );
    }

    isPodnesiDisabled(fthId: number, pdtId: number): Observable<boolean> {
        return this.httpClient.get<ResponseWrapper>(`${this.fznTransactionDetailsUrl}/isPodnesiDisabled/${fthId}/${pdtId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }

    isPodnesiDisabledForAfr(fthId: number): Observable<boolean> {
        return this.httpClient.get<ResponseWrapper>(`${this.fznTransactionDetailsUrl}/isPodnesiDisabledForAfr/${fthId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            catchError(this.handleError)
        );
    }


    getYfrBudgetBalanceDynamicDialog(sqlQuery): Observable<YfrBudgetBalance[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.fznTransactionDetailsUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    // custom-end


    getYfrBudgetBalanceByFthIdAncCtpIdAndSroId(fthId: number, ctpId: number, sroId: number): Observable<YfrBudgetBalance[]> {
        const url = `${this.fznTransactionDetailsUrl}/YfrBudgetBalanceByFthIdAncCtpIdAndSroId/${fthId}/${ctpId}/${sroId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }


    getYfrBudgetBalanceByFthIdAncCtpId(fthId: number, ctpId: number): Observable<YfrBudgetBalance[]> {
        const url = `${this.fznTransactionDetailsUrl}/YfrBudgetBalanceByFthIdAncCtpId/${fthId}/${ctpId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getYfrBudgetBalanceByFthIdAndFtdId(fthId: number, ftdId: number): Observable<YfrBudgetBalance> {
        const url = `${this.fznTransactionDetailsUrl}/YfrBudgetBalanceByFthIdAndFtdId/${fthId}/${ftdId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }
    getYfrBudgetBalanceByFthIdAndPspIdAndFtdId(fthId: number, pspId: number, ftdId: number): Observable<YfrBudgetBalance> {
        const url = `${this.fznTransactionDetailsUrl}/YfrBudgetBalanceByFthIdAndPspIdAndFtdId/${fthId}/${pspId}/${ftdId}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

}



