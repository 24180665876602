import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Account} from "../../../model/account";
import {Table} from "primeng/table";
import {AccountService} from "../../../service/account.service";
import {BaseListComponent} from "../../../base/base-list/base-list.component";
import {ClassificationDetail} from "../../../model/classification-detail";
import {ClassificationDetailService} from "../../../service/classification-detail.service";

@Component({
  selector: 'app-pjh-cld-selection-dialog',
  templateUrl: './pjh-cld-selection-dialog.component.html',
  styleUrls: ['./pjh-cld-selection-dialog.component.css']
})
export class PjhCldSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  public selectedObj: any;
  public classificationDetails: ClassificationDetail[];
  @Output() onSelected = new EventEmitter<ClassificationDetail>();
  public errorMessage: string;
  visible = false;

  tfFilterMap = {
    'cldLevel0Name': null,
    'cldLevel1Name': null,
    'cldLevel2Name': null
  };

  exportCols = ['cldLevel0Name', 'cldLevel1Name', 'cldLevel2Name'];

  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  // custom-constructor-start
  private cmpDomainName = 'pjh-classification-detail';

  constructor(
      private classificationDetailService: ClassificationDetailService
  ) {
    super();
  }

  // custom-constructor-end


  ngOnInit(): void {
  }

  selectCld(obj: any) {
    this.selectCldCustom(obj);
  }

  showDlg() {
    this.clearFilters();
    this.getPjhClassificationDetails();
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  // custom-start

  getPjhClassificationDetails() {
    this.classificationDetailService.getClassificationDetailsByCltIdLevel2(1001).subscribe(
      classificationDetails => {
        this.classificationDetails = classificationDetails;
        this.classificationDetails.sort((cld1, cld2) => {
          return cld1.cldLevel0Name.localeCompare(cld2.cldLevel0Name);
        });
        this.visible = true;
      }, error => this.errorMessage = <any>error
    );
  }

  selectCldCustom(obj: any) {
    this.selectedObj = obj;
    this.visible = false;
    this.onSelected.emit(this.selectedObj);
  }

  onDestroyCustom() {
  }


  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }

  // custom-end

}
