import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseDetailComponent} from '../../../base/base-detail/base-detail.component';
import {CommonCodeService} from '../../../../core/common-code.service';
import Utils from '../../../../utils/utils';
import DynamicDialogComponents from '../../../../dynamic-dialog-components';

@Component({
  selector: 'app-form-item-dialog',
  templateUrl: './form-item-dialog.component.html',
  styleUrls: ['./form-item-dialog.component.css']
})
export class FormItemDialogComponent extends BaseDetailComponent implements OnInit {

  visible: boolean =  false;
  @Output() onSubmited = new EventEmitter<any>();

  @ViewChild('formItemForm', {static: false}) formItemForm;

  selectedMode;
  selectedFormItem;

  dynamicDialogComponents: any;
  dlgParametersOptions: any;

  constructor(
      private commonCodeService: CommonCodeService
  ) {
    super(commonCodeService);
    this.dynamicDialogComponents = [];
    this.dynamicDialogComponents.push({label: '---', value: null});
    DynamicDialogComponents.forEach(ddc => {
      this.dynamicDialogComponents.push({
        label: ddc.title,
        value: ddc.id
      });
    });
    this.commonCodeTypes = ['form_item'];
  }

  ngOnInit() {
    this.getCommonCodes();
  }

  showDlg(selectedFormItem, selectedMode) {
    this.selectedFormItem = JSON.parse(selectedFormItem);
    this.selectedMode = selectedMode;
    this.getDlgParamaterOptions();
    this.visible = true;
    this.refreshForm();
  }

  onSubmit() {

    if (this.selectedFormItem.formType !== 'form_item_dropdown') {
      delete this.selectedFormItem.selectedCommonCodeType;
    }
    if (this.selectedFormItem.formType !== 'form_item_dialog') {
      delete this.selectedFormItem.selectedDialog;
      delete this.selectedFormItem.selectedDialogParameter;
      delete this.selectedFormItem.selectedDlgPrimaryKey;
      delete this.selectedFormItem.selectedNgModelParam;
    }
    if (this.selectedFormItem.formType === 'form_item_dialog') {
      const dlgComponent = DynamicDialogComponents.find(ddc => ddc.id === this.selectedFormItem.selectedDialog);
      if (dlgComponent.primaryKey) {
        this.selectedFormItem.selectedDlgPrimaryKey = dlgComponent.primaryKey;
      }
      if (dlgComponent.ngModelParam) {
        this.selectedFormItem.selectedNgModelParam = dlgComponent.ngModelParam;
      }
    }
    if (this.selectedFormItem.formType !== 'form_item_integer' && this.selectedFormItem.formType !== 'form_item_decimal') {
      delete this.selectedFormItem.total;
    }

    this.onSubmited.emit(JSON.stringify(this.selectedFormItem, Utils.getCircularReplacer()));
    this.visible = false;
  }

  onHide() {
    // setTimeout(() => this.formItemForm.resetForm(), 0);
  }

  onSelectedCommonCodeType(obj) {
    this.selectedFormItem.selectedCommonCodeType = obj.ccdType;
  }

  onSelectedDynamicDataDict(obj) {
    this.selectedFormItem.dynamicDataDict = obj;

    if (this.selectedMode === 'new') {
      this.selectedFormItem.label = this.selectedFormItem.dynamicDataDict.dddColDescription;

      if (this.selectedFormItem.dynamicDataDict.dddDataType === 'character varying') {
        this.selectedFormItem.formType = 'form_item_text';
      }
      if (this.selectedFormItem.dynamicDataDict.dddDataType === 'date') {
        this.selectedFormItem.formType = 'form_item_date';
      }
      if (this.selectedFormItem.dynamicDataDict.dddDataType === 'bigint') {
        this.selectedFormItem.formType = 'form_item_integer';
      }
      if (this.selectedFormItem.dynamicDataDict.dddDataType === 'numeric') {
        this.selectedFormItem.formType = 'form_item_decimal';
      }

      this.getDlgParamaterOptions();
    }
  }

  getDlgParamaterOptions() {
    this.dlgParametersOptions = [
      {
        label: '---',
        value: null
      }
    ];

    try {
      const dlgComponent = DynamicDialogComponents.find(ddc => ddc.id === this.selectedFormItem.selectedDialog);
      const dlgComponentsParameters = dlgComponent.parameters.filter(dcp => dcp.targetTable === this.selectedFormItem.dynamicDataDict.dddTableName);

      dlgComponentsParameters.forEach(dcp => {
        this.dlgParametersOptions.push({
          label: dcp.label,
          value: dcp
        });
      });
    } catch (error) {

    }

    debugger;

    if (!this.dlgParametersOptions.find(dpo => dpo.value && this.selectedFormItem.selectedDialogParameter && dpo.value.id === this.selectedFormItem.selectedDialogParameter.id)) {
      debugger;
      this.selectedFormItem.selectedDialogParameter = null;
    }

    return this.dlgParametersOptions;
  }

}
