
import {throwError as observableThrowError, Observable, Subscription} from 'rxjs';
import {Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild} from '@angular/core';
import {MessageService} from '../../../../../../../core/message.service';
import {ReportTemplate} from '../../../../../../model/report-template';
import {ReportTemplateService} from '../../../../../../service/report-template.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
import {BoInteractionService} from '../../../../../../../shared/bo-interaction.service';
import {ReportService} from '../../../../../../../shared/report.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {CommonCodeService} from '../../../../../../../core/common-code.service';
import {SelectItem} from 'primeng/components/common/selectitem';
import * as calendarTranslation from '../../../../../../../globals/calendar-translation';
import {environment} from '../../../../../../../../environments/environment';
import {BaseDetailComponent} from '../../../../../../base/base-detail/base-detail.component';
import {Status} from '../../../../../../model/status';
import {Account} from '../../../../../../model/account';
import {CommonCode} from '../../../../../../../core/common-code';
import {catchError, map} from 'rxjs/operators';


@Component({
  selector: 'app-report-template-csc2770detail',
  templateUrl: 'report-template-csc2770detail.component.html',
  styleUrls: ['report-template-csc2770detail.component.css']
})
export class ReportTemplateCsc2770detailComponent extends BaseDetailComponent implements OnInit, OnDestroy {

  public selectedReportTemplate: ReportTemplate;
  /*
  public mode: string;
  public active: boolean;
  public errorMessage: string;
  */

  // btnSaveDisabled = false;

  /*
  commonCodeTypes: string[];
  commonCodes:any = {};
  */

  lang: any;

  private subscription1: Subscription;

  @Output() reportTemplateCsc2770detailEvent = new EventEmitter();


  @ViewChild('rptForm' , {static: true}) form;
  /*
  private formChanged = false;
  private formChangeTracking = false;
  private formChangeSub: Subscription;
  */

  // custom-constructor-start


  private subscription2: Subscription;


  constructor(private reportTemplateService: ReportTemplateService,
              private messageService: MessageService,
              private interactionService: BoInteractionService,
              commonCodeService: CommonCodeService,
              private reportService: ReportService) {
    super(commonCodeService);
    this.commonCodeTypes = ['rpt_category'];
    this.lang = calendarTranslation.sr_cyr;
    this.subscription1 = this.interactionService.boEdit$.subscribe(
      r => {
        if (r.className === 'report-template') {
          if (environment.SHOW_CONSOLE_MSG) {
            console.log('subscribeeeeeeeee report-template ' + r);
          }
          this.selectedReportTemplate = r.object;
          this.mode = r.actionType;
          this.refreshForm();
        }
      }
    );
    this.subscription2 = this.interactionService.boSaved$.subscribe(r => {
      if (r.className === 'report-template') {
        this.selectedReportTemplate = r.object;
        this.refreshForm();
      }
    });
    this.onInitCustom();
  }


  // custom-constructor-end

  ngOnInit() {
    super.ngOnInit();
    this.ngOnInitCustom();
  }

  /*
  getCommonCodes() {
	if(environment.SHOW_CONSOLE_MSG) {
		console.log(this.commonCodeTypes);
	}
    this.commonCodeTypes.map(t => this._commonCodeService.findByType(t).subscribe(
      result => {
        this.commonCodes[t] = result;
      }));
  }
  */

  onSubmit() {
    this.onSubmitCustom();
  }

  /*
  isFormValid() {
    return this.form.valid;
  }

  isFormChanged() {
	if(environment.SHOW_CONSOLE_MSG) {
		console.log('isFormChanged: ' + this.formChanged);
	}
    return this.formChanged;
  }
  */

  /*
  refreshForm() {
	if(environment.SHOW_CONSOLE_MSG) {
		console.log('refreshForm ' + this.formChangeTracking);
	}
    if (this.formChangeTracking) {
      if (this.formChangeSub) {
        this.deactivateChangeTracking();
      }
    }
    this.active = false;
    setTimeout(() => {
        this.active = true;
        this.formChanged = false;
        if (this.formChangeTracking) {
          setTimeout(() => {
            this.activateChangeTracking();
          }, 500);
        }

      },
      0); */

  /*
    setTimeout(() => {

    if (this.subValidationErrors) {
      this.subValidationErrors.unsubscribe();
    }
    this.subValidationErrors = this.form.control.statusChanges.subscribe(
      r => {
        if(r == 'INVALID') {
          console.log('errors subscribe');
          console.log(r);

          console.log(this.form.controls);

          Object.keys(this.form.controls).forEach(key => {
            console.log(key);
            const controlErrors: ValidationErrors = this.form.controls[key].errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
              });
            }
          });
        }
      }
    );
  }, 500);
    */

  /* } */

  refreshAndDeactivateTracking(): Observable<boolean> {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('refreshAndDeactivateTracking');
    }
    return this.reportTemplateService.getReportTemplate(this.selectedReportTemplate.rptId).pipe(
      map(
      res => {
        this.selectedReportTemplate = res;
        this.deactivateChangeTracking();
        this.formChanged = false;
        if (environment.SHOW_CONSOLE_MSG) {
          console.log('refresh endddd');
        }
        return true;

      }
    ),
  catchError(err => {
      return observableThrowError(err);
    })
    );
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  /*
  activateChangeTracking() {
	if(environment.SHOW_CONSOLE_MSG) {
		console.log('activateChangeTracking');
	}
    if (!this.formChangeSub) {
      this.formChangeSub = this.form.control.valueChanges
        .subscribe(values => {
			if(environment.SHOW_CONSOLE_MSG) {
				console.log('form changeddddddddd');
				console.log(values);
			}
            this.formChanged = true;
          }
        );
    }
  }

  deactivateChangeTracking() {
    try {
		if(environment.SHOW_CONSOLE_MSG) {
			console.log('deactivateChangeTracking start');
	    }
      if (this.formChangeSub) {
		if(environment.SHOW_CONSOLE_MSG) {
			console.log('deactivateChangeTracking start');
		}
        this.formChangeSub.unsubscribe();
        this.formChangeSub = null;
		if(environment.SHOW_CONSOLE_MSG) {
			console.log('deactivateChangeTracking unsubscribed');
		}
      }
	  if(environment.SHOW_CONSOLE_MSG) {
		console.log('deactivateChangeTracking end');
	  }
    } catch (err) {
		if(environment.SHOW_CONSOLE_MSG) {
			console.log(err);
		}
    }
  }
  */


  // custom-start


  onInitCustom() {

  }

  ngOnInitCustom() {

  }

  onSubmitCustom() {
    this.btnSaveDisabled = true;
    if (this.mode === 'new') {
      this.reportTemplateService.create(this.selectedReportTemplate)
        .subscribe(res => {
            this.selectedReportTemplate = res;
            this.mode = 'edit';
            this.btnSaveDisabled = false;
            let io = new InteractionObject('save', this.selectedReportTemplate, 'report-template');
            // this._interactionService.setBoSaved(io);
            this.reportTemplateCsc2770detailEvent.emit(io);
            setTimeout(() => {
              this.formChanged = false;
            }, 500);
            this.messageService.showSuccessSaveMsg();
          },
          error => {
            this.errorMessage = <any> error;
            this.btnSaveDisabled = false;
            this.messageService.showFailMsgText(this.errorMessage);
          });
    } else {
      this.reportTemplateService.update(this.selectedReportTemplate)
        .subscribe(res => {
            this.selectedReportTemplate = res;
            this.btnSaveDisabled = false;
            let io = new InteractionObject('save', this.selectedReportTemplate, 'report-template');
            // this._interactionService.setBoSaved(io);
            this.reportTemplateCsc2770detailEvent.emit(io);
            setTimeout(() => {
              this.formChanged = false;
            }, 500);
            this.messageService.showSuccessSaveMsg();
          },
          error => {
            this.errorMessage = <any> error;
            this.btnSaveDisabled = false;
            this.messageService.showFailMsgText(this.errorMessage);
          });
    }
  }

  onDestroyCustom() {
    this.subscription1.unsubscribe();
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }


  // custom-end

  /* custom-gen-methods
ngOnInitCustom
onSubmitCustom
onDestroyCustom
onInitCustom
custom-gen-methods */

}
