import { Subject } from 'rxjs';
import { Component } from '@angular/core';

@Component({
    selector: 'app-async-initialised-component',
    template:`<ng-content></ng-content>`
})
export class AsynchronouslyInitialisedComponent {
  loadedState: Subject<boolean> = new Subject<boolean>();
  loadedState$ = this.loadedState.asObservable();
  constructor() {
  }
  protected componentLoaded() {
    this.loadedState.next(true);
    this.loadedState.complete();
  }
}