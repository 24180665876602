import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {ProjectReportPeriod} from '../../../model/project-report-period';
import {ProjectStructureElement} from '../../../model/project-structure-element';
import {Table} from 'primeng/table';
import {ProjectStructureElementService} from '../../../service/project-structure-element.service';

@Component({
  selector: 'app-project-structure-element-selection-dialog',
  templateUrl: './project-structure-element-selection-dialog.component.html',
  styleUrls: ['./project-structure-element-selection-dialog.component.css']
})
export class ProjectStructureElementSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  public selectedObj: any;
  public projectStructureElements: ProjectStructureElement[];
  @Output() onSelected = new EventEmitter<ProjectReportPeriod>();
  public errorMessage: string;
  visible = false;

  tfFilterMap = {'pseCode': null, 'label': null};

  exportCols = ['pseCode', 'label'];

  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  private cmpDomainName = 'project-report-period-selection';

  private title = 'project_structure_element_dlg_label';

  constructor(private projectStructureElementService: ProjectStructureElementService) {
    super();
  }

  ngOnInit() {
  }

  selectPse(obj: any) {
    this.selectPseCustom(obj);
  }

  showDlg() {
    this.getProjectStructureElements();
    this.clearFilters();
  }

  showDlgDynamic(sqlQuery) {
    if (sqlQuery.includes('PSE_TYPE_MILESTONE')) {
      this.title = 'project_structure_element_dlg_label_milestone';
    }
    this.getProjectStructureElementsDynamicDialog(sqlQuery);
    this.clearFilters();
  }

  getProjectStructureElements() {
    this.projectStructureElementService
        .getProjectStructureElements()
        .subscribe(projectStructureElements => {
          this.projectStructureElements = projectStructureElements;
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  getProjectStructureElementsDynamicDialog(sqlQuery) {
    this.projectStructureElementService
        .getProjectStructureElementsDynamicDialog(sqlQuery)
        .subscribe(projectStructureElements => {
          this.projectStructureElements = projectStructureElements;
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  selectPseCustom(obj: any) {
    this.selectedObj = obj;
    this.visible = false;
    this.onSelected.emit(this.selectedObj);
  }

  onDestroyCustom() {
  }

  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }

}
