import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {YfrBudgetBalance} from '../../../model/yfr-budget-balance';
import {Table} from 'primeng/table';
import {FznTransactionDetailService} from '../../../service/fzn-transaction-detail.service';

@Component({
    selector: 'app-yfr-budget-balance-dialog',
    templateUrl: './yfr-budget-balance-dialog.component.html',
    styleUrls: ['./yfr-budget-balance-dialog.component.css']
})
export class YfrBudgetBalanceDialogComponent extends BaseListComponent implements OnInit, OnDestroy {


    public selectedObj: any;
    public yfrBudgetBalance: YfrBudgetBalance[];
    @Output() onSelected = new EventEmitter<YfrBudgetBalance>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {
        tblSroCode: null,
        tblBudgetTotal: null,
        tblBudgetBalance: null,
        tblFtdFtdCostName: null,
        tblFtdFtdDescription: null
    };

    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;
    // custom-constructor-start
    private cmpDomainName = 'yfr_budget_balance';


    exportCols = ['tblSroCode', 'tblFtdFtdCostName', 'tblFtdFtdDescription', 'tblBudgetTotal', 'tblBudgetBalance'];

    constructor(
        private fznTransactionDetailService: FznTransactionDetailService
    ) {
        super();
    }


    selectYFR(obj: any) {
        this.selectYFRCustom(obj);
    }

    private selectYFRCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    onDestroyCustom() {
    }

    showDlgByFthIdAndSroIdAndCtpId(fthId: number, ctpId: number, sroId: number) {
        debugger;
        this.clearFilters();
        if (fthId !== null && fthId !== undefined &&
            ctpId !== null && ctpId !== undefined) {
            if (sroId !== null && sroId !== undefined) {
                this.fznTransactionDetailService.getYfrBudgetBalanceByFthIdAncCtpIdAndSroId(fthId, ctpId, sroId).subscribe(
                    res => {
                        this.yfrBudgetBalance = res;
                        this.visible = true;
                    }, error => this.errorMessage = <any>error
                );
            } else {
                this.fznTransactionDetailService.getYfrBudgetBalanceByFthIdAncCtpId(fthId, ctpId).subscribe(
                    res => {
                        this.yfrBudgetBalance = res;
                        this.visible = true;
                    }, error => this.errorMessage = <any>error
                );
            }
        } else {
            console.log(' ERROR:00241');
        }
    }

    showDlgDynamic(sqlQuery) {
        this.getYFRDynamicDialog(sqlQuery);
        this.clearFilters();
    }


    getYFRDynamicDialog(sqlQuery) {
        this.fznTransactionDetailService
            .getYfrBudgetBalanceDynamicDialog(sqlQuery)
            .subscribe(yfrBudgetBalance => {
                this.yfrBudgetBalance = yfrBudgetBalance;
                debugger;
                this.visible = true;
            }, error => this.errorMessage = <any>error);
    }

    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


}
