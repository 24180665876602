import {
    Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit, OnDestroy,
    ViewChild, Input
} from '@angular/core';
import {Router} from '@angular/router';
declare var Quill: any;

import {Span} from './span';
import {SelectItem} from 'primeng/api';
import {TableBlot} from './table-blot';
import {TableCellBlot} from './table-cell-blot';
import {PageBreak} from './page-break';
import {ConditionalPageBreak} from './conditional-page-break';
import {ReportTemplateMapperService} from '../../../service/report-template-mapper.service';


var Parchment = Quill.import('parchment');
var Block = Quill.import('blots/block');


const IdAttribute = new Parchment.Attributor('id', 'id', {
    scope: TableBlot
});


export default IdAttribute;

@Component({
    selector: 'app-bs-editor',
    templateUrl: 'bs-report-editor.component.html',
    styleUrls: ['bs-report-editor.component.css']
})

export class BsEditorComponent implements OnInit, AfterViewInit {

    quill: any;

    private _editorText: string;

    text: string;

    selectedMacrocode: string;

    macrocodeList: SelectItem[];

    disable = false;

    isReadOnly = false;

    constructor(private reportTempalteMapperService: ReportTemplateMapperService) {
        this.reportTempalteMapperService.findAllMacroCodes().subscribe(
            res => {

                this.macrocodeList = res.sort().reduce(
                    function(pV, cV, cI) {
                        if (cV.lastIndexOf('.') !== (cV.length - 1)) {
                            pV.push({
                                label: cV,
                                value: cV
                            });
                        }
                        return pV;
                    }, [{'label': '---', 'value': null}]
                );
            }, error => console.log(error));

    }

    ngOnInit() {
        console.log('on initttttttttttt bs-editor');
    }

    ngAfterViewInit() {
        Span.blotName = 'span';
        Span.tagName = 'SPAN';
        Span.className = 'complex';

        TableBlot.blotName = 'div';
        TableBlot.tagName = 'DIV';
        TableBlot.className = 'table-blot';

        PageBreak.blotName = 'pagebreak';
        PageBreak.tagName = 'div';
        PageBreak.className = 'break';

        ConditionalPageBreak.blotName = 'conditionalpagebreak';
        ConditionalPageBreak.tagName = 'div';
        ConditionalPageBreak.className = 'conditional-break';

        /* TableCellBlot.blotName = 'td';
         TableCellBlot.tagName = 'td';*/
        // TableCellBlot.className = 'table-blot';


        console.log('table-blot', TableBlot);
        Quill.register(Span, true);
        Quill.register(TableBlot, true);
        Quill.register(PageBreak, true);
        Quill.register(ConditionalPageBreak, true);
        // Quill.register(TableCellBlot, true);

        Quill.register({
            'attributors/attribute/id': IdAttribute
        }, true);

        Quill.register({
            'formats/id': IdAttribute,
        }, true);

        var Font = Quill.import('formats/font');
        Font.whitelist = ['roboto', 'arial', 'calibri', 'timesnewroman'];
        Quill.register(Font, true);

        var Indent = Quill.import('formats/indent');
        Indent.whitelist = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        /*var fontSizeStyle = Quill.import('attributors/style/size');
         fontSizeStyle.whitelist = ['24px', '48px', '100px', '200px'];
         Quill.register(fontSizeStyle, true);*/
        // https://quilljs.com/guides/how-to-customize-quill/#content-and-formatting

        var SizeAttributor = Quill.import('attributors/class/size');
        SizeAttributor.whitelist = [
            '8pt', '9pt', '10pt', '11pt', '12pt', '13pt', '14pt', '15pt', '16pt', '17pt', '18pt'
        ];
        Quill.register(SizeAttributor, true);

        /*var Size = Quill.import('attributors/style/size');
         // fontSizeStyle.whitelist = ['small', false, 'large', 'huge'];
         Size.whitelist = ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt'];
         Quill.register(Size, true);*/

        this.quill = new Quill('#editor', {
            modules: {
                toolbar: {
                    container: '.toolbar'
                    ,
                    handlers: {
                        'span': this.handler,
                        'pagebreak': this.pageBreakHandler,
                        'conditionalpagebreak': this.conditionalPageBreakHandler
                    }
                }
            },
            theme: 'snow'
        });

        this.quill.keyboard.addBinding({key: ' '}, function(range) {
            var selection = this.quill.getSelection();
            this.quill.insertText(selection, '\u00A0');
        });

        // this._editorText = 'abcdefghijkl';
        if (this._editorText) {
            this.quill.clipboard.dangerouslyPasteHTML(this._editorText);
        }

        /*
         setTimeout(()=>{
         console.log(this.elementRef.nativeElement.getElementsByClassName('break'));
         let l1 = this.elementRef.nativeElement.getElementsByClassName('break');

         console.log(l1, l1.length);
         for (let i = 0; i < l1.length; i++) {
         console.log(i);
         l1[i].addEventListener('click', function(event){
         console.log(event.target);
         event.target.remove();

         }
         );
         }}, 1000);
         */

    }

    @Input()
    set editorText(editorText: string) {
        this._editorText = editorText;
        if (this.quill && this._editorText !== 'undefined' && this._editorText !== null) {
            this.quill.clipboard.dangerouslyPasteHTML(this._editorText);
        }
    }

    get editorText(): string {
        return this.quill.root.innerHTML;
    }

    @Input() set disabled(disable: boolean) {
        if (this.quill) {
            this.disable = disable;
            if (disable) {
                this.quill.disable();
            } else {
                this.quill.enable();
            }
        }
    }

    @Input() set readOnly(readOnly: boolean) {
        if (this.quill) {
            if (readOnly) {
                document.getElementsByClassName('ql-editor')[0].setAttribute('contenteditable', 'false');
            } else {
                document.getElementsByClassName('ql-editor')[0].setAttribute('contenteditable', 'true');
            }
        }
    }

    handler() {
        console.log('handler');
        let text = document.getElementById('input1').getElementsByTagName('input')[0].getAttribute('aria-label');
        // let text = (<HTMLInputElement>document.getElementById('input1')).value;
        console.log('text', text);
        var complexSpan = document.getElementById('complextype').firstElementChild.cloneNode(true);
        // var divSpan = document.getElementById('table-blot-parent').firstElementChild.cloneNode(true);
        console.log(complexSpan);
        var selection = this.quill.getSelection();
        this.quill.insertEmbed(selection.index, 'span', complexSpan);
        // this.quill.insertEmbed(selection.index, 'div', divSpan);
        // console.log('getSelection ', this.quill.getSelection());
        this.quill.setSelection(selection.index + text.length, 0);
    }

    pageBreakHandler() {
        console.log('PAGE BREAK HANDLER');
        const range = this.quill.getSelection(true);
        this.quill.insertEmbed(range.index + 1, 'pagebreak', true, Quill.sources.USER);
    }

    conditionalPageBreakHandler() {
        console.log('CONDITIONAL PAGE BREAK HANDLER');
        const range = this.quill.getSelection(true);
        this.quill.insertEmbed(range.index + 1, 'conditionalpagebreak', true, Quill.sources.USER);
    }


}
