import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BaseDetailComponent} from '../../../base/base-detail/base-detail.component';
import {ProjectStructureElement} from '../../../model/project-structure-element';
import {BoInteractionService} from '../../../../shared/bo-interaction.service';
import {MessageService} from '../../../../core/message.service';
import {CommonCodeService} from '../../../../core/common-code.service';
import Utils from "../../../../utils/utils";

@Component({
    selector: 'app-project-structure-element-dialog',
    templateUrl: './project-structure-element-dialog.component.html',
    styleUrls: ['./project-structure-element-dialog.component.css']
})
export class ProjectStructureElementDialogComponent extends BaseDetailComponent implements OnInit {

    visible = false;
    // @ViewChild('pseForm', {static: true}) form;

    selectedMode: string;

    public selectedProjectStructureElement: ProjectStructureElement;

    @Output() onSelected = new EventEmitter<any>();

    @ViewChild('pseForm', {static: false}) pseForm;

    allowedPseTypes;

    private cmpDomainName = 'project-structure-element-dialog';

    constructor(
        private interactionService: BoInteractionService,
        private messageService: MessageService,
        private commonCodeService: CommonCodeService,
    ) {
        super(commonCodeService);
        this.commonCodeTypes = ['pse_type'];
    }

    ngOnInit() {
        this.getCommonCodes();
        this.selectedProjectStructureElement = new ProjectStructureElement();
    }

    showDlg(
        obj: any
    ) {
        this.btnSaveDisabled = false;
        this.selectedMode = obj.selectedMode;
        this.selectedProjectStructureElement = obj.selectedProjectStructureElement;

        debugger;

        this.allowedPseTypes = this.commonCodes['pse_type'].filter(ccd => {
            return obj.allowedPseTypes.includes(ccd.value);
        });

        if (this.selectedMode === 'new') {
            this.selectedProjectStructureElement.pseType = this.allowedPseTypes[0].value;
        }

        this.visible = true;
    }

    onSubmit() {
        this.btnSaveDisabled = true;

        const emitObj = {
            selectedMode: this.selectedMode,
            selectedProjectStructureElement: JSON.parse(JSON.stringify(this.selectedProjectStructureElement, Utils.getCircularReplacer()))
        };

        this.onSelected.emit(emitObj);

        this.visible = false;
    }


    getCommonCodes() {
        this.commonCodeTypes.map(t => this.commonCodeService.findByType(t).subscribe(
            result => {
                this.commonCodes[t] = result;
            }
        ));
    }

    onHide() {
        setTimeout(() => this.pseForm.resetForm(), 0);
    }


}
