import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CommonCodeService} from '../../../core/common-code.service';
import * as calendarTranslation from '../../../globals/calendar-translation';

@Component({
  selector: 'app-base-detail',
  templateUrl: './base-detail.component.html',
  styleUrls: ['./base-detail.component.css']
})
export class BaseDetailComponent implements OnInit {

  public mode: string;
  public active: boolean;
  public errorMessage: string;

  btnSaveDisabled = false;
  readonlyCondition = false;

  commonCodeTypes: string[];
  commonCodes: any = {};

  protected formChanged = false;
  protected formChangeTracking = false;

  protected formChangeSub: Subscription;

  yearRange = '2010:2030';

  form;

  displaySaveDlg = false;

  saveDlgEnabled = environment.SAVE_DLG_ENABLED;

  lang: any;

  protected _commonCodeService: CommonCodeService;

  constructor(_commonCodeService: CommonCodeService) {
    this._commonCodeService = _commonCodeService;

    this.lang = calendarTranslation.sr_cyr;
  }

  ngOnInit() {
    this.getCommonCodes();
  }

  getCommonCodes() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log(this.commonCodeTypes);
    }
    this.commonCodeTypes.map(t => this._commonCodeService.findByType(t).subscribe(
      result => {
        this.commonCodes[t] = result;
      }));
  }

  isFormValid() {
    return this.form.valid;
  }

  isFormChanged() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('isFormChanged: ' + this.formChanged);
    }
    return this.formChanged;
  }

  refreshForm() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('refreshForm ' + this.formChangeTracking);
    }
    if (this.formChangeTracking) {
      if (this.formChangeSub) {
        this.deactivateChangeTracking();
      }
    }
    this.active = false;
    setTimeout(() => {
        this.active = true;
        this.formChanged = false;
        if (this.formChangeTracking) {
          setTimeout(() => {
            this.activateChangeTracking();
          }, 500);
        }

      },
      0);
  }

  activateChangeTracking() {
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('activateChangeTracking');
    }
    if (!this.formChangeSub) {
      this.formChangeSub = this.form.control.valueChanges
        .subscribe(values => {
            if (environment.SHOW_CONSOLE_MSG) {
              console.log('form changeddddddddd');
              console.log(values);
            }
            this.formChanged = true;
          }
        );
    }
  }

  deactivateChangeTracking() {
    try {
      if (environment.SHOW_CONSOLE_MSG) {
        console.log('deactivateChangeTracking start');
      }
      if (this.formChangeSub) {
        if (environment.SHOW_CONSOLE_MSG) {
          console.log('deactivateChangeTracking start');
        }
        this.formChangeSub.unsubscribe();
        this.formChangeSub = null;
        if (environment.SHOW_CONSOLE_MSG) {
          console.log('deactivateChangeTracking unsubscribed');
        }
      }
      if (environment.SHOW_CONSOLE_MSG) {
        console.log('deactivateChangeTracking end');
      }
    } catch (err) {
      if (environment.SHOW_CONSOLE_MSG) {
        console.log(err);
      }
    }
  }

  handleEnterKeyPress(event) {
    const tagName = event.target.tagName.toLowerCase();
    if (tagName !== 'textarea') {
      return false;
    }
  }

}
