import {Component, OnInit, Input, Output, OnDestroy, EventEmitter, AfterViewChecked, ViewChild} from '@angular/core';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
import {BoInteractionService} from '../../../../../../../shared/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../../../../environments/environment';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {ReportTemplateList} from '../../../../../../model/report-template-list';
import {ReportTemplateService} from '../../../../../../service/report-template.service';
import {Privileges} from '../../../../../../../core/privileges.service';
import {ReportTemplate} from '../../../../../../model/report-template';


@Component({
    selector: 'app-report-template-list-csc2760list',
    templateUrl: './report-template-list-csc2760list.component.html',
    styleUrls: ['./report-template-list-csc2760list.component.css']
})
export class ReportTemplateListCsc2760listComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewChecked {

    public reportTemplateLists: ReportTemplateList[];

    public selectedObj: any;
    public selectedMode: string;
    public errorMessage: string;

    public displayDeleteDlg = false;

    private subscription1: Subscription;
    private subscription2: Subscription;

    public editPrivilege = false;
    public createPrivilege = false;

    @Output() reportTemplateListCsc2760listEvent = new EventEmitter();


    tfFilterMap = {rptCode: null, rptDescription: null, locCategory: null, rptVersion: null, staDescription: null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    exportCols = ['rptCode', 'rptDescription', 'locCategory', 'rptVersion', 'staDescription'];

    // custom-constructor-start


    private cmpDomainName = 'report-template';

    constructor(private reportTemplateService: ReportTemplateService,
                private privileges: Privileges,
                private tabControllerService: TabControllerService,
                private interactionService: BoInteractionService) {
        super();
        this.subscription1 = this.interactionService.boSaved$.subscribe(
            r => {
                if (r.className === this.cmpDomainName) {
                    if (environment.SHOW_CONSOLE_MSG) {
                        console.log('subscribeeeeeeeee saveeeee ');
                    }
                    this.getReportTemplateLists();
                }
            }
        );

        this.subscription2 = this.interactionService.boDeleted$.subscribe(
            r => {
                if (r.className === this.cmpDomainName) {
                    if (environment.SHOW_CONSOLE_MSG) {
                        console.log('subscribeeeeeeeee deleteeeee ');
                    }
                    this.getReportTemplateLists();
                }
            }
        );
        this.onInitCustom();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.isCreate();
        this.isEdit();
        this.getReportTemplateLists();
    }


    getReportTemplateLists(): void {
        this.getReportTemplateListsCustom();


    }


    view(obj: ReportTemplateList) {
        this.viewCustom(obj);

    }

    edit(obj: any) {
        this.editCustom(obj);

    }

    showDeleteDlg(obj: ReportTemplateList) {
        this.selectedObj = obj;
        this.displayDeleteDlg = true;
    }

    delete() {
        this.deleteCustom();

    }

    create() {
        this.createCustom();

    }

    cancelDelete() {
        this.displayDeleteDlg = false;
    }


    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    viewCustom(obj: any) {
        this.selectedObj = obj;
        this.selectedMode = 'view';
        this.reportTemplateService.getReportTemplate(this.selectedObj.rptId).subscribe(
            obj => {
                this.selectedObj = obj;
                const io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
                this.reportTemplateListCsc2760listEvent.emit(io);
            }
        );
    }

    editCustom(obj: any) {
        this.selectedObj = obj;
        this.selectedMode = 'edit';
        this.reportTemplateService.getReportTemplate(this.selectedObj.rptId).subscribe(
            obj => {
                this.selectedObj = obj;
                const io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
                this.reportTemplateListCsc2760listEvent.emit(io);
            }
        );
    }

    deleteCustom() {
        this.tabControllerService.setTabIndex(0);
        this.tabControllerService.showTab001(false);
        this.selectedMode = 'delete';
        this.reportTemplateService.delete(this.selectedObj.rptId).subscribe(
            obj => {
                this.displayDeleteDlg = false;
                const io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
                this.interactionService.setBoDeleted(io);
                this.selectedObj = null;
                this.reportTemplateListCsc2760listEvent.emit(io);
            }
        );
    }

    createCustom() {
        this.selectedObj = new ReportTemplate();
        this.selectedObj.rptVersion = 0;
        this.selectedMode = 'new';
        const io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
        this.reportTemplateListCsc2760listEvent.emit(io);
    }

    getReportTemplateListsCustom() {
        this.reportTemplateService
            .getReportTemplateLists()
            .subscribe(reportTemplateLists => this.reportTemplateLists = reportTemplateLists, error => this.errorMessage = error as any);
    }

    onInitCustom() {
    }


    onDestroyCustom() {
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }
        if (this.subscription2) {
            this.subscription2.unsubscribe();
        }
    }


    createNewVersion(rptId) {
        this.reportTemplateService.createNewVersion(rptId).subscribe(
            r => {
                this.getReportTemplateLists();
            },
            err => {
                console.log(err);
            }
        );
    }

    duplicate(rptId) {
        this.reportTemplateService.duplicate(rptId).subscribe(
            r => {
                this.getReportTemplateLists();
            },
            err => {
                console.log(err);
            }
        );
    }

    isCreate() {
        this.createPrivilege = this.privileges.create();
    }

    isEdit() {
        this.editPrivilege = this.privileges.update();
    }


    // custom-end

    /* custom-gen-methods
  customDelete
  customCreate
  customEdit
  customView
  onInitCustom
  getReportTemplateListsCustom()
  custom-gen-methods */

}
