import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ClassificationDetail} from '../../../../../../model/classification-detail';
import {ClassificationDetailService} from '../../../../../../service/classification-detail.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-custom-classification-detail-cscdynamic-dialog',
    templateUrl: './custom-classification-detail-cscdynamic-dialog.component.html',
    styleUrls: ['./custom-classification-detail-cscdynamic-dialog.component.css']
})
export class CustomClassificationDetailCscdynamicDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public classificationDetails: ClassificationDetail[];
    @Output() onSelected = new EventEmitter<ClassificationDetail>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'cldCode': null, 'cldShortName': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'classification-detail';

    // polje koje prima vrijednost poslatu sa detail forme
    // i koje se salje kao parametar u servis tj kontroler na backend
    cltId: number;

    constructor(private classificationDetailService: ClassificationDetailService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
       // this.getClassificationDetails();
    }


    selectCld(obj: any) {
        this.selectCldCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getClassificationDetails(): void {
        this.getClassificationDetailsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    // poziv dijaloga sa filterom gdje se salje parametar cltId sa detail forme
    // pogledati komentar na @Input - u
    // NAPOMENA: ovaj dijalog je kopiran jer se otvara samo iz polja Sifra kvalifikacije
    // i puni listu bez uslova cld_level = 0, pogledati komentare na be u kontroleru, servisu, repositoriju
    getClassificationDetailsCustom() {
        this.classificationDetailService
            .getClassificationDetailsByCltIdWithoutCldLevel(this.cltId)
            .subscribe(classificationDetails => this.classificationDetails = classificationDetails, error => this.errorMessage = <any> error);
    }

    selectCldCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    showDlgDynamic(sqlQuery) {
        debugger;
        this.getClassificationDetailDynamicDialog(sqlQuery);
        this.clearFilters();
    }


    getClassificationDetailDynamicDialog(sqlQuery) {
        debugger;
        this.classificationDetailService
            .getClassificationDetailDynamicDialog(sqlQuery)
            .subscribe(classificationDetails => {
                debugger;
                this.classificationDetails = classificationDetails;
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }


    // custom-end

}
