import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Account} from '../../../../../../model/account';
import {AccountService} from '../../../../../../service/account.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-account-csc1550selection-dialog',
    templateUrl: './account-csc1550selection-dialog.component.html',
    styleUrls: ['./account-csc1550selection-dialog.component.css']
})
export class AccountCsc1550selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public accounts: Account[];
    @Output() onSelected = new EventEmitter<Account>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'accNameSurname': null, 'accWorkplace': null}


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'account';

    constructor(private accountService: AccountService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getAccounts();
    }


    selectAcc(obj: any) {
        this.selectAccCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    getAccounts(): void {
        this.getAccountsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getAccountsCustom() {
        this.accountService
            .getAccounts()
            .subscribe(accounts => this.accounts = accounts, error => this.errorMessage = <any>error);
    }

    selectAccCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
