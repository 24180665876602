import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProgrammeHeader} from '../../../../../../model/programme-header';
import {ProgrammeHeaderService} from '../../../../../../service/programme-header.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-programme-header-csc1030selection-dialog',
    templateUrl: './programme-header-csc1030selection-dialog.component.html',
    styleUrls: ['./programme-header-csc1030selection-dialog.component.css']
})
export class ProgrammeHeaderCsc1030selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public programmeHeaders: ProgrammeHeader[];
    @Output() onSelected = new EventEmitter<ProgrammeHeader>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {
        'prhAcronym': null,
        'prhName': null,
    };

    exportCols = ['prhAcronym', 'prhName'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'programme-header';

    constructor(private programmeHeaderService: ProgrammeHeaderService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        //  this.getProgrammeHeaders();
    }


    selectPrh(obj: any) {
        this.selectPrhCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.getProgrammeHeaders();
    }

    getProgrammeHeaders(): void {
        this.getProgrammeHeadersCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getProgrammeHeadersCustomByPrhLevel(prhLevel: string) {
        this.programmeHeaderService
            .getProgrammeHeadersByPrhLevel(prhLevel)
            .subscribe(
                programmeHeaders => {
                    this.programmeHeaders = programmeHeaders;
                    this.visible = true;
                }, error => this.errorMessage = <any>error
            );
    }

    getProgrammeHeadersCustom() {
        this.programmeHeaderService
            .getProgrammeHeaders()
            .subscribe(
                programmeHeaders => {
                    debugger;
                    this.programmeHeaders = programmeHeaders.filter(prh => {
                        return !prh.prhHasSubprogrammes;
                    });
                    this.programmeHeaders.sort((prh1, prh2) => {
                        return prh1.prhAcronym.localeCompare(prh2.prhAcronym);
                    });
                    this.visible = true;
                }, error => this.errorMessage = <any>error
            );
    }

    selectPrhCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    showDlgByPrhLevel(prhLevel: string) {
        this.clearFilters();
        this.getProgrammeHeadersCustomByPrhLevel(prhLevel);
    }

    // custom-end

}
