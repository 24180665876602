import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ClassificationDetail} from '../../../../../../model/classification-detail';
import {ClassificationDetailService} from '../../../../../../service/classification-detail.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-classification-detail-csc1410selection-dialog',
    templateUrl: './classification-detail-csc1410selection-dialog.component.html',
    styleUrls: ['./classification-detail-csc1410selection-dialog.component.css']
})
export class ClassificationDetailCsc1410selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public classificationDetails: ClassificationDetail[];
    @Output() onSelected = new EventEmitter<ClassificationDetail>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'cldCode': null, 'cldFullname': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'classification-detail';

    constructor(private _classificationDetailService: ClassificationDetailService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getClassificationDetails();
    }


    selectCld(obj: any) {
        this.selectCldCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getClassificationDetails(): void {
        this.getClassificationDetailsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getClassificationDetailsCustom() {
        this._classificationDetailService
            .getClassificationDetailsByCltId(1001)
            .subscribe(classificationDetails => this.classificationDetails = classificationDetails,
                error => this.errorMessage = <any> error);
    }

    selectCldCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
