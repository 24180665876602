import {Injectable} from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot, CanActivateChild
} from '@angular/router';

import {environment} from '../../environments/environment';
import {AuthService} from '../core/auth.service';
import {SystemPropertyService} from '../bo/service/system-property.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router, private _systemPropertyService: SystemPropertyService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('auth guard CAN ACTIVATE!!!!!');
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('auth guard CAN ACTIVATE!!!!!');
    }

    return this.canActivateComponent();
  }

  canActivateChild() {
    console.log('auth guard CAN ACTIVATE CHILD!!!!!');
    if (environment.SHOW_CONSOLE_MSG) {
      console.log('auth guard CAN ACTIVATE CHILD!!!!!');
    }
    return this.canActivateComponent();
  }

/*  canActivateComponent() {
    console.log('auth guard CAN ACTIVATE COMPONENT!!!!!');
    if (this.authService.isLoggedIn()) {
      // console.log('is logged in ? true');
      console.log('AUTENTICATED!!!!!');
      return true;
    }
    // console.log('is logged in ? false');
    this._systemPropertyService.isCertLogin().subscribe(res => {
        res ? this.router.navigate(['loginCert']) : this.router.navigate(['login']);
      });

    return false;
  }*/


  canActivateComponent() {
    if (this.authService.isLoggedIn()) {
      // console.log('is logged in ? true');
      return true;
    }
    // console.log('is logged in ? false');
    this.router.navigate(['login']);
    return false;
  }

  // http://stackoverflow.com/questions/38402776/angular2-routing-canactivate-and-authguard-jwt-with-user-role-parameter
}
