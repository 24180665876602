import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CommonCodeService} from '../../../core/common-code.service';
import {OverlayPanel} from 'primeng/overlaypanel';
import Utils from '../../../utils/utils';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as calendarTranslation from '../../../globals/calendar-translation';
import {AppInjector} from '../../../core/app-injector.service';
import {ExcelExportService} from '../../../core/excel-export.service';
import {DatePipe} from '@angular/common';
import {FilterUtils} from 'primeng/api';

@Component({
    selector: 'app-base-list',
    templateUrl: './base-list.component.html',
    styleUrls: ['./base-list.component.css']
})
export class BaseListComponent implements OnInit, AfterViewChecked {

    tfFilterStartWithMap;

    dateFilterMap;

    tfDateFilterMap;

    tfFilterMap;

    booleanFilterMap;

    multiselectMap;

    selectedFilterField: string;

    commonCodeDateFilter: any[] = [{}];
    dataTable: any;

    exportCols;

    private _excelExportService: any;
    private _commonCodeService: any;
    private _datePipe: any;

    // lang: any;
    lang = calendarTranslation.sr_cyr;

    yearRange = '2010:2030';

    constructor() {
        const injector = AppInjector.getInjector();
        console.log(injector);
        this._excelExportService = injector.get(ExcelExportService);
        this._commonCodeService = injector.get(CommonCodeService);
        this._datePipe = injector.get(DatePipe);

        this.populateCommonCodeDateFilter();
    }


    ngOnInit() {
    }

    public getToday(): Date {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        return today;
    }

    public getMonday(date: Date) {
        const day = date.getDay();
        const diff = date.getDate() - day + (day == 0 ? -6:1);

        const retDate = new Date(date.setDate(diff));

        return retDate;
    }

    public getSunday(date: Date) {
        const sunday = this.getMonday(date);
        sunday.setDate(sunday.getDate() + 6);

        return sunday;
    }

    // Neka polja u bazi su tipa timestamp, pa kako bi filteri bili pravilno primenjeni,
    // setujemo sate, minute, sekunde i milisekunde na nulu
    private transformDate(dateStr) {
        const date = new Date(dateStr);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    ngAfterViewChecked() {
        // Add custom dataTable filter constraints
        if (this.dataTable !== undefined) {

            FilterUtils['date_filter_eq'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                // const valDate = new Date(value);
                return valDate.getTime() === filter[0].getTime();
            };

            FilterUtils['date_filter_neq'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return valDate.getTime() !== filter[0].getTime();
            };

            FilterUtils['date_filter_ls'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return valDate.getTime() < filter[0].getTime();
            };

            FilterUtils['date_filter_gt'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return valDate.getTime() > filter[0].getTime();
            };

            FilterUtils['date_filter_lse'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return valDate.getTime() <= filter[0].getTime();
            };

            FilterUtils['date_filter_gte'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return valDate.getTime() >= filter[0].getTime();
            };

            FilterUtils['date_filter_today'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getTime() === today.getTime();
            };

            FilterUtils['date_filter_yesterday'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                today.setDate(today.getDate() - 1);
                return valDate.getTime() === today.getTime();
            };

            FilterUtils['date_filter_from_to'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                return (filter[0] ? valDate.getTime() >= filter[0].getTime() : true) &&
                    (filter[1] ? valDate.getTime() <= filter[1].getTime() : true);
            };

            FilterUtils['date_filter_from_today'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getTime() >= today.getTime();
            };

            FilterUtils['date_filter_till_today'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getTime() < today.getTime();
            };

            FilterUtils['date_filter_current_month'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getMonth() === today.getMonth();
            };

            FilterUtils['date_filter_last_month'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getMonth() === today.getMonth() - 1;
            };

            FilterUtils['date_filter_current_year'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getFullYear() === today.getFullYear();
            };

            FilterUtils['date_filter_last_year'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                return valDate.getFullYear() === today.getFullYear() - 1;
            };

            FilterUtils['date_filter_current_week'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                const monday = this.getMonday(today);
                const sunday = this.getSunday(today);
                return valDate.getTime() >= monday.getTime() && valDate.getTime() <= sunday.getTime();
            };

            FilterUtils['date_filter_last_week'] = (value, filter): boolean => {
                const valDate = this.transformDate(value);
                const today = this.getToday();
                today.setDate(today.getDate() - 7);
                const monday = this.getMonday(today);
                const sunday = this.getSunday(today);
                return valDate.getTime() >= monday.getTime() && valDate.getTime() <= sunday.getTime();
            };

            // const customFilterConstraints = this.dataTable.filterConstraints;
            // customFilterConstraints['between'] = this.between; // between filter functionality
            // customFilterConstraints['customInContains'] = this.customInContains;
            // customFilterConstraints['dateEquals'] = this.dateEquals;
            // customFilterConstraints['booleanEquals'] = this.booleanEquals;
            //  this.dataTable.filterConstraints = customFilterConstraints;

            FilterUtils['between'] = this.between;
            FilterUtils['customInContains'] = this.customInContains;
            FilterUtils['dateEquals'] = this.dateEquals;
            FilterUtils['booleanEquals'] = this.booleanEquals;

        }
    }

    showFilterPopUp(event, overlaypanel: OverlayPanel, fieldName) {
        this.selectedFilterField = fieldName;
        debugger;
        overlaypanel.toggle(event);
        event.stopPropagation();
    }

    between = function (v1, [f1, f2]) {
        console.log('between');
        console.log(v1);
        console.log(f1);
        console.log(f2);

        if (v1 == null && f1 == null && f2 == null) {
            return true;
        } else if (v1 == null) {
            return false;
        }

        // let v2 = Utils.stringToDate(v1);
        let v2 = new Date(v1);
        if ((f1 && v2 < f1.getTime()) || (f2 && v2 > f2.getTime())) {
            return false;
        } else {
            return true;
        }
    };

    customInContains = function (v1, f) {
        // console.log('customInContains');
        // console.log(v1);
        // console.log(f);

        if (!f) {
            return true;
        }
        for (var i = 0; i < f.length; ++i) {
            if (v1.toLowerCase().includes(f[i])) {
                return true;
            }
        }
        return false;
    };

    dateEquals = function (v1, f) {
        // console.log('customInContains');
        // console.log(v1);
        // console.log(f);

        if (!f) {
            return true;
        }
        if (!v1) {
            return false;
        }
        let d1 = new Date(v1);
        if (d1.getFullYear() == f.getFullYear() && d1.getMonth() == f.getMonth() && d1.getDate() == f.getDate()) {
            return true;
        }
        return false;
    };

    dateEqualsLater = function (v1, f) {
        // console.log('customInContains');
        // console.log(v1);
        // console.log(f);

        if (!f) {
            return true;
        }
        if (!v1) {
            return false;
        }
        let d1 = new Date(v1);

        if (d1.getFullYear() > f.getFullYear()) {
            return true;
        } else if (d1.getFullYear() == f.getFullYear()) {
            if (d1.getMonth() > f.getMonth()) {
                return true;
            } else if (d1.getMonth() == f.getMonth()) {
                if (d1.getDate() >= f.getDate()) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    };

    booleanEquals = function (v1, f) {
        // console.log('customInContains');
        // console.log(v1);
        // console.log(f);

        if (v1 == undefined) {
            return false;
        }

        if (f == v1) {
            return true;
        }
        return false;
    };

    onSelectCalendar(dt: any) {
        this.tfDateFilterMap[this.selectedFilterField] = null;
        // dt.filter([this.dateFilterMap[this.selectedFilterField].from, this.dateFilterMap[this.selectedFilterField].to], this.selectedFilterField, 'between');
    }

    onChangeCalendar(dt: any) {
        this.tfDateFilterMap[this.selectedFilterField] = null;
        // dt.filter([this.dateFilterMap[this.selectedFilterField].from, this.dateFilterMap[this.selectedFilterField].to], this.selectedFilterField, 'between');
    }

    cancelFilterDateFrom(dt: any) {
        this.dateFilterMap[this.selectedFilterField].from = null;
        dt.filter([this.dateFilterMap[this.selectedFilterField].from, this.dateFilterMap[this.selectedFilterField].to], this.selectedFilterField, 'between');
    }

    cancelFilterDateTo(dt: any) {
        this.dateFilterMap[this.selectedFilterField].to = null;
        dt.filter([this.dateFilterMap[this.selectedFilterField].from, this.dateFilterMap[this.selectedFilterField].to], this.selectedFilterField, 'between');
    }

    clearFilters() {
        console.log(this.dataTable);
        console.log(this.tfFilterMap);

        let z1 = this.tfFilterMap;
        if (z1) {
            Object.keys(z1).forEach(function (key) {
                console.log(key, z1[key]);
                z1[key] = null;
            });
        }
        let z2 = this.dateFilterMap;
        if (z2) {
            Object.keys(z2).forEach(function (key) {
                z2[key].from = null;
                z2[key].to = null;

                //Nina - novi date filter
                z2[key].matchMode = null;
                z2[key].numOfDateInputs = 0;
            });
        }
        let z3 = this.booleanFilterMap;
        if (z3) {
            Object.keys(z3).forEach(function (key) {
                console.log(key, z3[key]);
                z3[key] = null;
            });
        }

        let z4 = this.tfDateFilterMap;
        if (z4) {
            Object.keys(z4).forEach(function (key) {
                console.log(key, z4[key]);
                z4[key] = null;
            });
        }

        let z5 = this.tfFilterStartWithMap;
        if (z5) {
            Object.keys(z5).forEach(function (key) {
                console.log(key, z5[key]);
                z5[key] = null;
            });
        }
        let z6 = this.multiselectMap;
        if (z6) {
            Object.keys(z6).forEach(function (key) {
                console.log(key, z6[key]);
                z6[key] = null;
            });
        }
        this.dataTable.reset();
        /*console.log(this.dataTable.el);
        console.log(this.dataTable.el.nativeElement.getElementsByTagName('input'));*/


        /*let l1 = this.dataTable.el.nativeElement.getElementsByTagName('input');
        for (var i = 0; i < l1.length; i++) {
          l1[i].value = null;
        }
        this.dataTable.reset();*/
    }

    filterTable() {

        let z1 = this.tfFilterMap;
        let z2 = this.dateFilterMap;
        let z3 = this.booleanFilterMap;
        let z4 = this.tfDateFilterMap;
        let z5 = this.tfFilterStartWithMap;
        let z6 = this.multiselectMap;
        let dt1 = this.dataTable;
        dt1.filters = {};


        // console.log(z1);
        // if (z1) {
        //     Object.keys(z1).forEach(function(key) {
        //         console.log(key, z1[key]);
        //         if (z1[key] && z1[key].toLowerCase().includes(' or ') && z1[key].length > 4) {
        //             let l2 = z1[key].toLowerCase().split(' or ');
        //             dt1.filters[key] = {value: l2, matchMode: 'customInContains'};
        //         } else if (z1[key] && z1[key].startsWith('>')) {
        //             let val1 = z1[key].replace('>', '').trim();
        //             dt1.filters[key] = {value: val1, matchMode: 'gt'};
        //         } else if (z1[key] && z1[key].startsWith('<')) {
        //             let val1 = z1[key].replace('<', '').trim();
        //             dt1.filters[key] = {value: val1, matchMode: 'lt'};
        //         } else if (z1[key] && z1[key].startsWith('=')) {
        //             let val1 = z1[key].replace('=', '').trim();
        //             dt1.filters[key] = {value: val1, matchMode: 'equals'};
        //         } else {
        //             dt1.filters[key] = {value: z1[key], matchMode: 'contains'};
        //         }
        //     });
        // }

        console.log(z1);
        if (z1) {
            Object.keys(z1).forEach(function (key) {
                console.log(key, z1[key]);
                if (z1[key] && z1[key].toLowerCase().includes(' or ') && z1[key].length > 4) {
                    let l2 = z1[key].toLowerCase().split(' or ');
                    dt1.filters[key] = {value: l2, matchMode: 'customInContains'};
                } else if (z1[key] && z1[key].startsWith('>')) {
                    let val1 = z1[key].replace('>', '').trim();
                    dt1.filters[key] = {value: val1, matchMode: 'gt'};
                } else if (z1[key] && z1[key].startsWith('<')) {
                    let val1 = z1[key].replace('<', '').trim();
                    dt1.filters[key] = {value: val1, matchMode: 'lt'};
                } else if (z1[key] && z1[key].startsWith('=')) {
                    let val1 = z1[key].replace('=', '').trim();
                    dt1.filters[key] = {value: val1, matchMode: 'equals'};
                } else {
                    dt1.filters[key] = {value: z1[key] ? z1[key].trim() : z1[key], matchMode: 'contains'};
                }
            });
        }

        // if (z4) {
        //     Object.keys(z4).forEach(function(key) {
        //         if (z4[key]) {
        //
        //             if (z2[key]) {
        //                 z2[key].from = null;
        //                 z2[key].to = null;
        //             }
        //         }
        //         let v = z4[key];
        //         console.log('z444444444444 dateEqulas ', v);
        //         dt1.filters[key] = {value: v, matchMode: 'dateEquals'};
        //
        //     });
        // }

        if (z4) {
            Object.keys(z4).forEach(function (key) {
                if (z4[key]) {

                    if (z2[key]) {
                        z2[key].from = null;
                        z2[key].to = null;

                        z2[key].matchMode = null;
                        z2[key].numOfDateInputs = 0;
                    }

                    let v = z4[key];
                    console.log('z444444444444 dateEqulas ', v);
                    dt1.filters[key] = {value: [v, null], matchMode: 'date_filter_eq'};
                }
                // let v = z4[key];
                // console.log('z444444444444 dateEqulas ', v);
                // dt1.filters[key] = {value: v, matchMode: 'dateEquals'};
            });
        }

        if (z6) {
            Object.keys(z6).forEach(function (key) {
                if (z6[key]) {
                    debugger;
                    const v = z6[key];
                    console.log('z6666666 innnn ', v);
                    dt1.filters[key] = {value: v, matchMode: 'in'};
                }

            });
        }

        // if (z2) {
        //     Object.keys(z2).forEach(function(key) {
        //         if (z2[key] && (z2[key].from || z2[key].to)) {
        //             let l2 = [z2[key].from, z2[key].to];
        //             dt1.filters[key] = {value: l2, matchMode: 'between'};
        //         }
        //     });
        // }

        if (z2) {
            // Object.keys(z2).forEach(function (key) {
            //   if (z2[key] && (z2[key].from || z2[key].to)) {
            //     let l2 = [z2[key].from, z2[key].to];
            //     dt1.filters[key] = {value: l2, matchMode: 'between'};
            //   }
            // });
            Object.keys(z2).forEach(function (key) {
                if (z2[key] && z2[key].matchMode &&
                    (z2[key].numOfDateInputs === 0 ||
                        (z2[key].numOfDateInputs === 1 && z2[key].from) ||
                        z2[key].numOfDateInputs === 2 && (z2[key].from || z2[key].to))
                ) {
                    dt1.filters[key] = {
                        value: [z2[key].from, z2[key].to],
                        matchMode: z2[key].matchMode
                    };
                    // f1.push({
                    //   fieldName: key,
                    //   filterRangeDateFrom: z2[key].from,
                    //   filterRangeDateTo: z2[key].to,
                    //   filterMatchMode: z2[key].matchMode
                    // });
                }
            });
        }

        if (z5) {
            Object.keys(z5).forEach(function (key) {
                console.log(key);
                console.log(z5);
                dt1.filters[key] = {value: z5[key], matchMode: 'startsWith'};
            });
        }

        if (z3) {
            console.log(z3);
            Object.keys(z3).forEach(function (key) {
                if (z3[key]) {
                    let v1 = z3[key].toLowerCase();
                    console.log('v1', v1);
                    let v = (v1 == 'да' || v1 == 'da') ? true : (v1 == 'не' || v1 == 'ne' ? false : undefined);
                    console.log('v', v);
                    dt1.filters[key] = {value: v, matchMode: 'equals'};
                } else {
                    dt1.filters[key] = { value: null, matchMode: 'contains' };
                }
            });
        }

        console.log(dt1.filters);
        this.dataTable._filter();
    }

    filterByField(value, filterField) {
        if (this.booleanFilterMap && this.booleanFilterMap[filterField]) {
            let v1 = this.booleanFilterMap[filterField].toLowerCase();
            value = v1 === 'da' ? 'true' : (v1 == 'ne' ? 'false' : '');
            // console.log(v);
            // this.dataTable.filters[filterField] = {value: v, matchMode: 'equals'};
        }

        if (this.multiselectMap && this.multiselectMap[filterField]) {
            let v1 = this.multiselectMap[filterField];
            this.dataTable.filter(v1, filterField, 'in');
            return;
        }

        if (value && value.toLowerCase().includes(' or ') && value.length > 4) {
            let l2 = value.toLowerCase().split(' or ');
            this.dataTable.filter(l2, filterField, 'customInContains');
        } else if (value && value.startsWith('>')) {
            let val1 = value.replace('>', '').trim();
            this.dataTable.filter(val1, filterField, 'gt');
        } else if (value && value.startsWith('<')) {
            let val1 = value.replace('<', '').trim();
            this.dataTable.filter(val1, filterField, 'lt');
        } else if (value && value.includes('=')) {
            let val1 = value.replace('=', '').trim();
            this.dataTable.filter(val1, filterField, 'equals');
        } else {
            this.dataTable.filter(value, filterField, 'contains');
        }
    }

    onTableCellLinkClick(initValue, cmpName) {
        console.log('onTableLinkClick', initValue);
    }

    exportToExcel() {
        let r = this.dataTable._value;
        /*console.log('111', this.dataTable.headerTemplate);
        console.log('222', this.dataTable.domHandler);
        console.log('333', this.dataTable.el.nativeElement.getElementsByClassName('ui-sortable-column'));*/
        if (this.dataTable.filteredValue) {
            r = this.dataTable.filteredValue;
        } else if (this.dataTable._selection) {
            r = this.dataTable._selection;
        }

        this.exportAsExcelFile(r, 'export', null, null);
    }

    public exportAsExcelFile(json: any[], excelFileName: string, headerParam, labelParam): void {
        let header = headerParam || [];
        let headerLabels = labelParam || [];
        let cols = this.dataTable.el.nativeElement.getElementsByClassName('ui-sortable-column');
        console.log('cols', this.dataTable);
        console.log('cols', cols);
        if (!headerParam) {
            // header.push(el1.getAttribute("ng-reflect-field"));
            header = this.exportCols || this.dataTable.globalFilterFields;
        }

        console.log('header1', header);
        if (!labelParam) {
            for (var i = 0; i < cols.length; i++) {
                let el1 = cols.item(i);
                headerLabels.push(el1.innerText);
                /*console.log(el1.innerText);
               console.log(el1.getAttribute("ng-reflect-field"));*/
            }
        }

        function subset(obj, propList) {
            return propList.reduce(function (newObj, prop) {
                if (obj.hasOwnProperty(prop)) {
                    newObj[prop] = obj[prop];
                } else {
                    let propList = prop.split('.');
                    let v1;
                    /*for (i = 0; i < propList.length; i++) {
                      console.log('ffff ', v1, propList[i]);
                      v1 = obj[propList[i]];
                      console.log('zzzz ', v1, propList[i]);
                    }*/
                    if (propList) {
                        if (propList.length === 2) {
                            if (obj[propList[0]]) {
                                v1 = obj[propList[0]][propList[1]];
                                console.log(v1);
                            }
                        }
                        if (propList.length === 3) {
                            if (obj[propList[0]] && obj[propList[0]][propList[1]]) {
                                v1 = obj[propList[0]][propList[1]][propList[2]];
                                console.log(v1);
                            }
                        }
                        if (propList.length === 4) {
                            if (obj[propList[0]] && obj[propList[0]][propList[1]] && obj[propList[0]][propList[1]][propList[2]]) {
                                v1 = obj[propList[0]][propList[1]][propList[2]][propList[3]];
                                console.log(v1);
                            }
                        }
                    }
                    if (v1 instanceof Date) {
                        v1 = v1.toString();
                    }
                    let prop1 = prop.replace(/\./g, '');
                    newObj[prop1] = v1;
                    // console.log('newObj', newObj);
                }
                return newObj;
            }, {});
        }

        let propTypes = [];

        if (this.tfDateFilterMap) {
            propTypes = header.map(r => {
                return this.tfDateFilterMap.hasOwnProperty(r) ? 'Date' : 'string';
            });
        }
        console.log('propTypes', propTypes);


        function getArray(result, el) {
            result.push(subset(el, header));
            return result;
        }

        let json1 = json.reduce(getArray, []);

        // console.log('header2', header);
        header = header.map(h1 => {
            return h1.replace(/\./g, '');
        });
        // console.log('header3', header);

        var ws = XLSX.utils.json_to_sheet(json1, {header: header});

        var range = XLSX.utils.decode_range(ws['!ref']);
        console.log('range', range);
        for (var C = range.s.c; C <= range.e.c; ++C) {
            var address = XLSX.utils.encode_col(C) + '1'; // <-- first row, column number C
            if (!ws[address]) {
                continue;
            }
            ws[address].s = {fill: {fgColor: {rgb: '9ab9ea'}}};
            console.log(C, header[C]);
            if (ws[address].v === header[C]) {
                ws[address].v = headerLabels[C];
            }
        }

        /* add to workbook */
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        console.log(wb);
        console.log(wb.Sheets.Sheet1.B2);
        console.log('propTypes', propTypes);
        for (i = 0; i < propTypes.length; i++) {
            if (propTypes[i] === 'Date') {
                let ch1 = String.fromCharCode(65 + i);
                for (var n1 = 2; n1 <= range.e.r + 1; n1++) {
                    let p1 = ch1 + n1;
                    console.log(p1);
                    if (wb.Sheets.Sheet1[p1].v === null) {
                        wb.Sheets.Sheet1[p1].v = undefined;
                    }
                    if (wb.Sheets.Sheet1[p1].v) {
                        wb.Sheets.Sheet1[p1].v = new Date(wb.Sheets.Sheet1[p1].v);
                    }
                    wb.Sheets.Sheet1[p1].t = 'd';
                    wb.Sheets.Sheet1[p1].z = 'dd.mm.yyyy';
                   //  wb.Sheets.Sheet1[p1].w = '19.02.2014';
                    // XLSX.utils.format_cell(wb.Sheets.Sheet1[p1], null, {dateNF:'dd.MM.yyyy'});
                }
            }
        }

        /* write workbook (use type 'binary') */
        var wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i !== s.length; ++i) {
                view[i] = s.charCodeAt(i) & 0xFF;
            }
            return buf;
        }

        FileSaver.saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), excelFileName + '.xlsx');
    }

    public exportLazyToExcel(table: string, lazyLoadParams: any): void {
        // let table = 'v_calc_fin_transaction_case_header';
        let header = [];
        let headerLabels = [];
        let cols = this.dataTable.el.nativeElement.getElementsByClassName('ui-sortable-column');
        console.log('cols', this.dataTable);
        console.log('cols', cols);
        // header.push(el1.getAttribute("ng-reflect-field"));
        header = this.exportCols || this.dataTable.globalFilterFields;

        console.log('header1', header);
        for (let i = 0; i < cols.length; i++) {
            let el1 = cols.item(i);
            headerLabels.push(el1.innerText);
            /*console.log(el1.innerText);
            console.log(el1.getAttribute("ng-reflect-field"));*/
        }
        let props = {};


        for (var i = 0; i < header.length; i++) {
            props[header[i]] = (this.tfDateFilterMap && this.tfDateFilterMap.hasOwnProperty(header[i])) ? 'Date' : 'string';
        }

        console.log(table);
        console.log(lazyLoadParams);
        console.log(headerLabels);
        // debugger;
        let columns1 = [];
        for (i = 0; i < headerLabels.length; i++) {
            let a1 = headerLabels[i];
            let b1 = {};
            b1['headerFieldDb'] = Object.keys(props)[i];
            b1['headerLabel'] = headerLabels[i];
            columns1.push(b1);
        }

        debugger;

        this._excelExportService.exportXLS({
            'table': table
            , 'props': props
            ,
            'filters': lazyLoadParams.filters, 'sorts': lazyLoadParams.sorts
            // , 'columns': headerLabels
            , 'columns': columns1
            , 'isDistinct': false
        }).subscribe(
            res => {
                console.log('success');
                if (res.size === 0) {
                    //  this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                } else {
                    // console.log(res);
                    // debugger;
                    FileSaver.saveAs(res, 'export' + '.' + 'xlsx');
                }
            },
            err => {
                console.log(err);
            }
        );
    }

    onChangeDateFilterType() {
        switch (this.dateFilterMap[this.selectedFilterField].matchMode) {
            case 'date_filter_from_to':
                this.dateFilterMap[this.selectedFilterField].numOfDateInputs = 2;
                break;
            case 'date_filter_today':
            case 'date_filter_yesterday':
            case 'date_filter_from_today':
            case 'date_filter_till_today':
            case 'date_filter_last_year':
            case 'date_filter_current_year':
            case 'date_filter_current_month':
            case 'date_filter_last_month':
            case 'date_filter_current_week':
            case 'date_filter_last_week':
            case null:
                this.dateFilterMap[this.selectedFilterField].numOfDateInputs = 0;
                break;
            default:
                this.dateFilterMap[this.selectedFilterField].numOfDateInputs = 1;
                break;
        }

        this.dateFilterMap[this.selectedFilterField].from = null;
        this.dateFilterMap[this.selectedFilterField].to = null;
        this.tfDateFilterMap[this.selectedFilterField] = null;
    }

    dateFilterActive(key: string) {
        if (this.dateFilterMap[key] && this.dateFilterMap[key].matchMode !== null &&
            (this.dateFilterMap[key].numOfDateInputs === 0 ||
                (this.dateFilterMap[key].numOfDateInputs === 1 && this.dateFilterMap[key].from) ||
                (this.dateFilterMap[key].numOfDateInputs === 2 && (this.dateFilterMap[key].from || this.dateFilterMap[key].to)))
        ) {
            return true;
        }
        return false;
    }

    dateFilterTooltip(key: string) {
        let tooltip = '';
        if (this.dateFilterMap[key] && this.dateFilterMap[key].matchMode !== null) {
            const ccdLabel = this.commonCodeDateFilter.find((ccd) => {
                return ccd.value === this.dateFilterMap[key].matchMode;
            }).label;

            if (this.dateFilterMap[key].numOfDateInputs === 0) {
                tooltip = ccdLabel;
            }
            if (this.dateFilterMap[key].numOfDateInputs === 1 && this.dateFilterMap[key].from) {
                tooltip = ccdLabel + ' ' + this._datePipe.transform(this.dateFilterMap[key].from, 'dd.MM.yyyy');
            }
            if (this.dateFilterMap[key].numOfDateInputs === 2 && (this.dateFilterMap[key].from || this.dateFilterMap[key].to)) {
                tooltip = ccdLabel + ': ' +
                    (this.dateFilterMap[key].from ? this._datePipe.transform(this.dateFilterMap[key].from, 'dd.MM.yyyy') : '')
                    + ' - ' +
                    (this.dateFilterMap[key].to ? this._datePipe.transform(this.dateFilterMap[key].to, 'dd.MM.yyyy') : '');
            }
        }
        return tooltip;
    }

    clearDateFilters(key: string) {
        this.dateFilterMap[key].from = null;
        this.dateFilterMap[key].to = null;
        this.dateFilterMap[key].matchMode = null;
        this.dateFilterMap[key].numOfDateInputs = 0;

        this.filterTable();
    }

    populateCommonCodeDateFilter() {
        this.commonCodeDateFilter = [{label: '---', value: null}];
        this._commonCodeService.getCommonCodesByCcdType('date_filter').subscribe(
            res => {
                res.sort((a, b) => a.ccdSortSeq - b.ccdSortSeq);
                res.map(item => {
                    this.commonCodeDateFilter.push({
                        label: item.ccdDefaultValue,
                        value: item.ccdCode
                    });
                });
            }
        );
    }

}
