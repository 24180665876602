import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CommonCodeService} from '../../../core/common-code.service';
// import {LazyLoadEvent} from 'primeng/primeng';
import {FilterVM} from '../../model/sys/filter-vm';
import {SortVM} from '../../model/sys/sort-vm';
import {LazyLoadRequestVM} from '../../model/sys/lazy-load-request-vm';
import {BaseListComponent} from '../base-list/base-list.component';

@Component({
  selector: 'app-base-lazy-list',
  templateUrl: './base-lazy-list.component.html',
  styleUrls: ['./base-lazy-list.component.css']
})
export class BaseLazyListComponent extends BaseListComponent {

  totalRecords: number;

  limit = 10;

  /*extractLazyLoadRequestParams(event: LazyLoadEvent) {
    let requestParams = new LazyLoadRequestVM();
    requestParams.limit = event.rows;
    requestParams.offset = event.first;
    let sorts = [];
    if (event.sortField) {
      let sort1 = new SortVM();
      sort1.fieldName = event.sortField;
      sort1.asc = event.sortOrder == 1 ? true : false;
      sorts.push(sort1);
    }
    requestParams.sorts = sorts;
    requestParams.filters = [];
    Object.keys(event.filters).map(k => {
      let f1 = new FilterVM();
      f1.fieldName = k;
      f1.filterMatchMode = event.filters[k].matchMode;
      if (this.dateFilterMap[k]) {
        f1.filterRangeDateFrom = this.dateFilterMap[k].from;
        f1.filterRangeDateTo = this.dateFilterMap[k].to;
      } else {
        f1.filterValue = event.filters[k].value;
      }
      requestParams.filters.push(f1);
    });
    return requestParams;
  }*/

}
