import {AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Directive} from '@angular/core';

// validation function
function validateRNFactory(): ValidatorFn {
  return (cc: AbstractControl) => {
    console.log('validate RN');
    const DIGIT_REGEXP = /^[0-9]*$/;

    let isValid = false;
    
    if (isValid) {
      return null;
    } else {
      return {
        badRN: {
          valid: false
        }
      };
    }
  };
}

@Directive({
  selector: '[appValidateRN][ngModel], [appValidateRN][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: RnValidatorDirective, multi: true}
  ]
})
export class RnValidatorDirective implements Validator {
  validator: Function;

  constructor() {
    this.validator = validateRNFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

}

