import {Injectable} from '@angular/core';
/*import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';*/
import {Observable} from 'rxjs/index';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import * as globals from '../globals/globals';
import Utils from '../utils/utils';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelExportService {

  private baseApiUrl = globals.BASE_API_URL;

  constructor(private httpClient: HttpClient) { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    /*const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet', worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);*/
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    /*const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);*/
  }

  exportXLSReport(req): Observable<Blob>  {
    return this.httpClient.post(this.baseApiUrl + 'lazyReport', JSON.stringify(req), {responseType: 'blob'}).pipe(
      map(this.extractBlob),
      catchError(this.handleError));
  }

  exportXLS(req): Observable<Blob>  {
    return this.httpClient.post(this.baseApiUrl + 'lazyExport', JSON.stringify(req), {responseType: 'blob'}).pipe(
      map(this.extractBlob),
      catchError(this.handleError));
  }

  extractBlob(res: Blob) {
    return res;
    /*console.log(res);
    debugger;
    return new Blob([res.blob()], {type: 'application/vnd.ms-excel'});*/
  }

  private handleError(error: Response | any) {
    return Utils.handleError(error);
  }

}
