import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {ProgrammeHeader} from '../../../model/programme-header';
import {CommonCode} from '../../../../core/common-code';
import {Table} from "primeng/table";
import {CommonCodeService} from "../../../../core/common-code.service";

@Component({
    selector: 'app-common-code-selection-dialog',
    templateUrl: './common-code-selection-dialog.component.html',
    styleUrls: ['./common-code-selection-dialog.component.css']
})
export class CommonCodeSelectionDialogComponent extends BaseListComponent implements OnInit {

    public selectedObj: any;
    public commonCodeTypes: any[];
    visible = false;
    @Output() onSelected = new EventEmitter<ProgrammeHeader>();
    public errorMessage: string;

    @ViewChild('dt1', {static: true}) dataTable: Table;

    tfFilterMap = {
        'ccdCode': null
    };

    exportCols = ['ccdType'];

    private cmpDomainName = 'common-code-selection-dialog';

    constructor(
        private commonCodeService: CommonCodeService
    ) {
        super();
    }

    ngOnInit(): void {
    }

    selectCommonCode(obj: any) {
        this.selectCommonCodeCustom(obj);
    }

    showDlg() {
        this.getCommonCodesByType();

        // CLEAR-OVANJE SVIH FILTERA
        this.clearFilters();

    }

    getCommonCodesByType(): void {
        this.getCommonCodesByTypeCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    onDestroyCustom() {
    }

    getCommonCodesByTypeCustom() {
        this.commonCodeService
            .getCommonCodesDistinct()
            .subscribe(
                commonCodeTypes => {
                    this.commonCodeTypes = commonCodeTypes.map(ccd => {
                        return {
                            ccdType: ccd
                        }
                    });
                    this.visible = true;
                }, err => {
                    this.errorMessage = <any> err;
                }
            );
    }

    selectCommonCodeCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

}
