import {Component, OnInit, Input, Output, OnDestroy, EventEmitter, AfterViewChecked, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {Table} from 'primeng/table';
import {BoInteractionService} from '../../../../../../shared/bo-interaction.service';

import {InteractionObject} from '../../../../../../shared/interaction-object';
import {MessageService} from '../../../../../../core/message.service';
import {ReportTemplateService} from '../../../../../service/report-template.service';
import {ReportTemplate} from '../../../../../model/report-template';


@Component({
    selector: 'app-report-template-editor',
    templateUrl: './report-template-editor.component.html',
    styleUrls: ['./report-template-editor.component.css']
})
export class ReportTemplateEditorComponent implements OnDestroy {

    editorText: string;

    private subscription1: Subscription;
    private subscription2: Subscription;

    private reportTemplate: ReportTemplate;

    @Input()
    private fieldName;

    _visibleContent = false;

    @Output() reportTemplateEditorEvent = new EventEmitter();

    constructor(private interactionService: BoInteractionService,
                private reportTemplateService: ReportTemplateService,
                private messageService: MessageService) {
        this.subscription1 = this.interactionService.boEdit$.subscribe(r => {
            if (r.className === 'report-template') {
                this.reportTemplate = r.object;
                if (this.reportTemplate) {

                    this.editorText = this.reportTemplate.rptHeader;
                }
            }
        });

        this.subscription2 = this.interactionService.boSaved$.subscribe(r => {
            if (r.className === 'report-template') {
                this.reportTemplate = r.object;
                if (this.reportTemplate) {
                    this.editorText = this.reportTemplate.rptHeader;
                }
            }
        });
    }

    save(editorText) {
        console.log(editorText);
        this.reportTemplate.rptHeader = editorText;
        this.reportTemplateService.update(this.reportTemplate).subscribe(
            r => {
                this.reportTemplate = r;
                let io = new InteractionObject('save', this.reportTemplate, 'report-template');
                this.reportTemplateEditorEvent.emit(io);
                this.messageService.showSuccessSaveMsg();
            },
            error => {
            }
        );
    }


    get visibleContent() {
        return this._visibleContent;
    }

    @Input('visibleContent')
    set visibleContent(value) {
        this._visibleContent = value;
    }

    ngOnDestroy() {
        if (this.subscription1) {
            this.subscription1.unsubscribe();
        }
        if (this.subscription2) {
            this.subscription2.unsubscribe();
        }
    }


}
