import {Component, OnInit, Input, Output, OnDestroy, EventEmitter, AfterViewChecked, ViewChild} from '@angular/core';
 import {ReportTemplate} from '../../../../../../model/report-template';
 import {ReportTemplateService} from '../../../../../../service/report-template.service';
 import {TabControllerService} from '../../../../../../../core/tab-controller.service';
 import {BoInteractionService} from '../../../../../../../shared/bo-interaction.service';
 import {InteractionObject} from '../../../../../../../shared/interaction-object';
 import {Subscription} from 'rxjs';
 import {environment} from '../../../../../../../../environments/environment';
 import {Table} from 'primeng/table';
 import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
  selector: 'app-report-template-csc2760list',
  templateUrl: './report-template-csc2760list.component.html',
  styleUrls: ['./report-template-csc2760list.component.css']
})
export class ReportTemplateCsc2760listComponent extends BaseListComponent implements OnInit, OnDestroy, AfterViewChecked {

  public reportTemplates: ReportTemplate[];

  public selectedObj: any;
  public selectedMode: string;
  public errorMessage: string;

  public displayDeleteDlg = false;

  private subscription1: Subscription;
  private subscription2: Subscription;

  @Output() reportTemplateCsc2760listEvent = new EventEmitter();





  tfFilterMap = {'rptCode' : null,'rptDescription' : null,'rptCategory' : null,'rptVersion' : null,'status.staDescription' : null};



  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  exportCols = ['rptCode','rptDescription','rptCategory','rptVersion','status.staDescription'];

  // custom-constructor-start




  private cmpDomainName = 'report-template';

 constructor(private _reportTemplateService: ReportTemplateService, private _tabControllerService: TabControllerService, private _interactionService: BoInteractionService) {
super();
this.subscription1 = this._interactionService.boSaved$.subscribe(
r => {
if(r.className == this.cmpDomainName) {
if(environment.SHOW_CONSOLE_MSG) {
console.log('subscribeeeeeeeee saveeeee ');
}
this.getReportTemplates();
}
}
);

this.subscription2 = this._interactionService.boDeleted$.subscribe(
r => {
if(r.className == this.cmpDomainName) {
if(environment.SHOW_CONSOLE_MSG) {
console.log('subscribeeeeeeeee deleteeeee ');
}
this.getReportTemplates();
}
}
);
this.onInitCustom();
}








  // custom-constructor-end


  ngOnInit(): void {
    this.getReportTemplates();
  }





  getReportTemplates(): void {
	this.getReportTemplatesCustom();

  }




  view(obj: ReportTemplate) {
	this.viewCustom(obj);
	console.log(obj);

  }

  edit(obj: any) {
	this.editCustom(obj);

  }

  showDeleteDlg(obj: ReportTemplate) {
    this.selectedObj = obj;
    this.displayDeleteDlg = true;
  }

  delete() {
	this.deleteCustom();

  }

  create() {
	this.createCustom();

  }

  cancelDelete() {
    this.displayDeleteDlg = false;
  }



  ngOnDestroy() {
	this.onDestroyCustom();
  }

  // custom-start





 viewCustom(obj: any) {
this.selectedObj = obj;
this.selectedMode = 'view';
this._reportTemplateService.getReportTemplate(this.selectedObj.rptId).subscribe(
 obj => {
 this.selectedObj = obj;
 let io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
 this.reportTemplateCsc2760listEvent.emit(io);
}
 );
}
editCustom(obj: any) {
this.selectedObj = obj;
this.selectedMode = 'edit';
this._reportTemplateService.getReportTemplate(this.selectedObj.rptId).subscribe(
 obj => {
 this.selectedObj = obj;
 let io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
 this.reportTemplateCsc2760listEvent.emit(io);
}
 );
}
deleteCustom() {
this._tabControllerService.setTabIndex(0);
this._tabControllerService.showTab001(false);
this.selectedMode = 'delete';
this._reportTemplateService.delete(this.selectedObj.rptId).subscribe(
 obj => {
 this.displayDeleteDlg = false;
 let io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
 this._interactionService.setBoDeleted(io);
 this.selectedObj = null;
 this.reportTemplateCsc2760listEvent.emit(io);
}
 );
}
createCustom() {
this.selectedObj =  new ReportTemplate();
  this.selectedObj.rptVersion = 0;
this.selectedMode = 'new';
let io = new InteractionObject(this.selectedMode, this.selectedObj , this.cmpDomainName);
 this.reportTemplateCsc2760listEvent.emit(io);
}

getReportTemplatesCustom() {
this._reportTemplateService
.getReportTemplates()
.subscribe(reportTemplates => this.reportTemplates = reportTemplates,error =>  this.errorMessage = <any>error);}
onInitCustom() {
}


onDestroyCustom() {
 if(this.subscription1) {
 this.subscription1.unsubscribe();
 }
 if(this.subscription1) {
 this.subscription2.unsubscribe();
 }
}


createNewVersion(rptId) {
  this._reportTemplateService.createNewVersion(rptId).subscribe(
    r => {
      this.getReportTemplates();
    },
    err => {
      console.log(err);
    }
  );
}

  duplicate(rptId) {
    this._reportTemplateService.duplicate(rptId).subscribe(
      r => {
        this.getReportTemplates();
      },
      err => {
        console.log(err);
      }
    );
  }









  // custom-end

  /* custom-gen-methods
customDelete
customCreate
getReportTemplatesCustom()
customEdit
customView
onInitCustom
custom-gen-methods */

}
