import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
// tslint:disable-next-line:max-line-length
import {ReportTemplateCsc1920mainComponent} from './bo-main/report_template/c/sc/report-template-csc1920main/report-template-csc1920main.component';

// ${import}

const appRoutes: Routes = [
    {
        path: 'bo-csc1920main',
        component: ReportTemplateCsc1920mainComponent
    }
// ${route}
];

@NgModule({
    imports: [RouterModule.forChild(appRoutes)],
    exports: [RouterModule]
})
export class FinRoutingModule {
}

