import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VNioList} from '../model/v-nio-list';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {VCostTypeList} from '../model/v-cost-type-list';


@Injectable()
export class VCostTypeListService {

    private baseApiUrl = globals.BASE_API_URL;
    private vCostTypeListUrl = this.baseApiUrl + 'vCostTypeList';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVCostTypeLists(): Observable<VCostTypeList[]> {
        return this.httpClient.get<ResponseWrapper>(this.vCostTypeListUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVCostTypeList(id: number): Observable<VCostTypeList> {
        return this.httpClient.get<ResponseWrapper>(`${this.vCostTypeListUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getVCostTypeListByFthId(id: number): Observable<VCostTypeList[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.vCostTypeListUrl}/byFthId/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    loadLazy(request): Observable<any> {
        return this.httpClient.post<ResponseWrapper>(this.baseApiUrl + 'vCostTypeListUrl/lazy', request).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vCostTypeList) {
        return vCostTypeList;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



