import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../core/message.service';
import {Subscription} from 'rxjs';
import {Message} from '../shared/message';
import {Message as PngMessage} from 'primeng/components/common/api';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscription1: Subscription;
  timerSubscription: Subscription;

  msgs: PngMessage[] = [];

  ngOnInit() {

  }

  constructor(private messageService: MessageService, private router: Router) {
    this.subscription = messageService.messageAnnouncedDialog$.subscribe(
      m => {
        // if (this.timerSubscription != null) {
        //   this.timerSubscription.unsubscribe();
        // }
        this.clear();
        this.showMsgs(m);
        // console.log('SET TIMER');
        /*this.timerSubscription = Observable.timer(5000).first().subscribe(() => {
          // console.log('timerrrrrrrrrrrr clear');
          this.clear();
        });*/
      });

    this.subscription1 = router.events.subscribe((val) => {
      if (environment.SHOW_CONSOLE_MSG) {
        console.log('route: ' + val);
      }
      this.clear();
      /*if (event instanceof NavigationEnd) {
       console.log('NavigationEnd:', event);
       this.clear();
       }*/

    });

  }

  show() {
    // this.msgs.push({severity:'info', summary: this.summary, detail:'PrimeNG rocks'});
    // this.msgs.push({severity:'info', summary: this.summary, detail:'PrimeNG rocks'});
  }

  showMsgs(messages: Message[]) {
    for (let i = 0; i < messages.length; i++) {
      // console.log('counter: ' + messages[i].severity);
      this.msgs.push({severity: messages[i].severity, summary: messages[i].summary, detail: messages[i].detail});
    }

  }

  hide() {
    this.msgs = [];
  }

  clear() {
    this.msgs = [];
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}
