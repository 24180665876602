import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../core/auth.service';
import {LoginDTO} from '../../bo/model/sys/login-dto';
import {MessageService} from '../../core/message.service';
import {SystemPropertyService} from '../../bo/service/system-property.service';

@Component({
  selector: 'app-login-cert',
  templateUrl: 'loginCert.component.html',
  styleUrls: ['loginCert.component.css'],
})

export class LoginCertComponent implements OnInit {
  public account: LoginDTO;
  public errorMessage: string;

  /*
  constructor(private _authService: LoginService, private _globalEventsManager: GlobalEventsManager) {
  } */

  constructor(private _authService: AuthService, private _router: Router, private _messageService: MessageService,
              private _systemPropertyService: SystemPropertyService) {
    this._systemPropertyService.isCertLogin().subscribe(res => {
        !res ? this._router.navigate(['login']) : this.login();
      });
  }

  ngOnInit() {
    /*this._authService.logout();*/
    this.account = new LoginDTO;
  }

  login() {
    console.log('loginCERT');
    this._authService.loginCert().subscribe(
      res => {
        console.log('success');
      },
      err => {
        console.log(err);
        if (err.error.error != 'ERROR LOGIN') {
          this._router.navigate(['loginCertNotice']);
        }
      }
    );
  }


}
