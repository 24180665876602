import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DynamicDataDict} from '../../../model/dynamic-data-dict';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../base/base-list/base-list.component';
import {CommonCodeService} from '../../../../core/common-code.service';
import {DynamicDataDictService} from '../../../service/dynamic-data-dict.service';

@Component({
  selector: 'app-dynamic-data-dict-selection-dialog',
  templateUrl: './dynamic-data-dict-selection-dialog.component.html',
  styleUrls: ['./dynamic-data-dict-selection-dialog.component.css']
})
export class DynamicDataDictSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  @Output() onSelected = new EventEmitter<DynamicDataDict>();
  public selectedObj: any;
  public dynamicDataDicts: DynamicDataDict[];
  visible = false;
  public errorMessage: string;

  @ViewChild('dt1', {static: true}) dataTable: Table;

  tfFilterMap = {
    'dddTableName': null,
    'dddColumnName': null,
    'dddDataType': null,
    'dddColDescription': null
  };

  exportCols = ['dddTableName', 'dddColumnName', 'dddDataType', 'dddColDescription'];

  private cmpDomainName = 'dynamic-data-dict-selection-dialog';

  constructor(
      private commonCodeService: CommonCodeService,
      private dynamicDataDictService: DynamicDataDictService
  ) {
      super();
  }

  ngOnInit(): void {
    this.getDynamicDataDicts();
  }

  selectDynamicDictData(obj: any) {
    this.selectDynamicDictDataCustom(obj);
  }

  showDlg() {
    this.visible = true;

    // CLEAR-OVANJE SVIH FILTERA
    this.clearFilters();

  }

  getDynamicDataDicts(): void {
    this.getDynamicDataDictsCustom();
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  onDestroyCustom() {
  }

  getDynamicDataDictsCustom() {
    this.dynamicDataDictService.getDynamicDataDicts().subscribe(
      dynamicDataDicts => {
        this.dynamicDataDicts = dynamicDataDicts;
      }, error => {
        this.errorMessage = <any> error;
        }
    );
  }

  selectDynamicDictDataCustom(obj: any) {
    this.selectedObj = obj;
    this.visible = false;
    this.onSelected.emit(this.selectedObj);
  }

  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }

}
