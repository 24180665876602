import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {MeasuringPoint} from '../../../../../../model/measuring-point';
import {MeasuringPointService} from '../../../../../../service/measuring-point.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-measuring-point-csc2010selection-dialog',
    templateUrl: './measuring-point-csc2010selection-dialog.component.html',
    styleUrls: ['./measuring-point-csc2010selection-dialog.component.css']
})
export class MeasuringPointCsc2010selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public measuringPoints: MeasuringPoint[];
    @Output() onSelected = new EventEmitter<MeasuringPoint>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'mspCode': null, 'mspDescription': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'measuring-point';

    constructor(private measuringPointService: MeasuringPointService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getMeasuringPoints();
    }


    selectMsp(obj: any) {
        this.selectMspCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getMeasuringPoints(): void {
        this.getMeasuringPointsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getMeasuringPointsCustom() {
        this.measuringPointService
            .getMeasuringPoints()
            .subscribe(measuringPoints => this.measuringPoints = measuringPoints, error => this.errorMessage = <any> error);
    }

    selectMspCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
