import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProgrammeHeader} from '../model/programme-header';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class ProgrammeHeaderService {

    private baseApiUrl = globals.BASE_API_URL;
    private programmeHeadersUrl = this.baseApiUrl + 'programmeHeaders';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProgrammeHeaders(): Observable<ProgrammeHeader[]> {
        return this.httpClient.get<ResponseWrapper>(this.programmeHeadersUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProgrammeHeadersByPrhLevel(prhLevel: string): Observable<ProgrammeHeader[]> {
        const url = `${this.programmeHeadersUrl}/byPrhLevel/${prhLevel}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProgrammeHeader(id: number): Observable<ProgrammeHeader> {
        return this.httpClient.get<ResponseWrapper>(`${this.programmeHeadersUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.programmeHeadersUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(prh: ProgrammeHeader): Observable<ProgrammeHeader> {
        return this.httpClient
            .post<ResponseWrapper>(this.programmeHeadersUrl, JSON.stringify(prh)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(programmeHeader: ProgrammeHeader): Observable<ProgrammeHeader> {
        const url = `${this.programmeHeadersUrl}/${programmeHeader.prhId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(programmeHeader)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.programmeHeadersUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(programmeHeader) {

        if (programmeHeader.prhStartDate) {
            programmeHeader.prhStartDate = new Date(programmeHeader.prhStartDate);
        }
        if (programmeHeader.prhSysCreateDate) {
            programmeHeader.prhSysCreateDate = new Date(programmeHeader.prhSysCreateDate);
        }
        if (programmeHeader.prhEndDate) {
            programmeHeader.prhEndDate = new Date(programmeHeader.prhEndDate);
        }
        if (programmeHeader.prhSysSyncTst) {
            programmeHeader.prhSysSyncTst = new Date(programmeHeader.prhSysSyncTst);
        }
        if (programmeHeader.prhSysUpdateDate) {
            programmeHeader.prhSysUpdateDate = new Date(programmeHeader.prhSysUpdateDate);
        }
        return programmeHeader;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



