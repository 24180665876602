import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProjectSroPersonnel} from '../../../../../../model/project-sro-personnel';
import {ProjectSroPersonnelService} from '../../../../../../service/project-sro-personnel.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-project-sro-personnel-csc1430selection-dialog',
    templateUrl: './project-sro-personnel-csc1430selection-dialog.component.html',
    styleUrls: ['./project-sro-personnel-csc1430selection-dialog.component.css']
})
export class ProjectSroPersonnelCsc1430selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public projectSroPersonnels: ProjectSroPersonnel[];
    @Output() onSelected = new EventEmitter<ProjectSroPersonnel>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'pspCode': null, 'pspNameSurname': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'project-sro-personnel';

    constructor(private projectSroPersonnelService: ProjectSroPersonnelService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
       // this.getProjectSroPersonnels();
    }


    selectPsp(obj: any) {
        this.selectPspCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    showDlgByProjectHeaderId(projectHeaderId: number) {
        this.getProjectSroPersonnelsByProjectHeaderId(projectHeaderId);
    }

    showDlgByProjectHeaderIdAndFthIdAndPdfId(pjhId: number, fthId: number, pdfId: number) {
        this.getProjectSroPersonnelsByProjectHeaderIdAndFthIdAndPdfId(pjhId, fthId, pdfId);
    }

    showDlgByProjectHeaderIdAndSroIdAndFthIdAndPdfId(pjhId: number, sroId: number, fthId: number, pdfId: number) {
        this.getProjectSroPersonnelsByProjectHeaderIdAndSroIdAndFthIdAndPdfId(pjhId, sroId, fthId, pdfId);
    }

    showDlgAllByFthId(fthId: number) {
        this.getProjectSroPersonnelAllByFthId(fthId);
    }

    getProjectSroPersonnels(): void {
        this.getProjectSroPersonnelsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProjectSroPersonnelsCustom() {
        this.projectSroPersonnelService
            .getProjectSroPersonnels()
            .subscribe(projectSroPersonnels => this.projectSroPersonnels = projectSroPersonnels, error => this.errorMessage = <any> error);
    }

    getProjectSroPersonnelsByProjectHeaderId(projectHeaderId: number) {
        this.projectSroPersonnelService
            .getProjectSroPersonnelsByProjectHeaderId(projectHeaderId)
            .subscribe(projectSroPersonnels => {
                this.projectSroPersonnels = projectSroPersonnels;
                this.visible = true;
                }, error => this.errorMessage = <any> error);
    }

    getProjectSroPersonnelsByProjectHeaderIdAndSroIdAndFthIdAndPdfId(pjhId: number, sroId: number, fthId: number, pdfId: number) {
        this.projectSroPersonnelService.getProjectSroPersonnelsByProjectHeaderIdAndSroIdAndFthIdAndPdfId(pjhId, sroId, fthId, pdfId)
            .subscribe(projectSroPersonnels => {
            this.projectSroPersonnels = projectSroPersonnels;
            this.visible = true;
        }, error => this.errorMessage = <any>error);
    }

    getProjectSroPersonnelsByProjectHeaderIdAndFthIdAndPdfId(pjhId: number, fthId: number, pdfId: number) {
        this.projectSroPersonnelService.getProjectSroPersonnelsByProjectHeaderIdAndFthIdAndPdfId(pjhId, fthId, pdfId)
            .subscribe(projectSroPersonnels => {
                this.projectSroPersonnels = projectSroPersonnels;
                this.visible = true;
            }, error => this.errorMessage = <any>error);
    }

    getProjectSroPersonnelAllByFthId(fthId: number) {
        this.projectSroPersonnelService.getProjectSroPersonnelAllByFthId(fthId)
            .subscribe(projectSroPersonnels => {
                this.projectSroPersonnels = projectSroPersonnels;
                this.visible = true;
            }, error => this.errorMessage = <any>error);
    }


    selectPspCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    showDlgByProjectHeaderIdAndSroId(projectHeaderId: number, sroId: number) {
        debugger;
        this.projectSroPersonnelService.showDlgByProjectHeaderIdAndSroId(projectHeaderId, sroId)
            .subscribe(projectSroPersonnels => {
                this.projectSroPersonnels = projectSroPersonnels;
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    // custom-end

}
