import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {TskNotification} from '../model/tsk-notification';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {TskNotificationList} from '../model/sys/tsk-notification-list';

// import {TskNotificationObjectList} from '../model/tsk-notification-object-list';


@Injectable()
export class TskNotificationService {

    private baseApiUrl = globals.BASE_API_URL;
    private tskNotificationsUrl = this.baseApiUrl + 'tskNotifications';  // URL to web api
    private tskNotificationsListUrl = this.baseApiUrl + 'tskNotificationsList';

    constructor(private httpClient: HttpClient) {
    }

    getTskNotifications(): Observable<TskNotification[]> {
        return this.httpClient.get<ResponseWrapper>(this.tskNotificationsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getTskNotification(id: number): Observable<TskNotification> {
        return this.httpClient.get<ResponseWrapper>(`${this.tskNotificationsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getTskNotificationsList(): Observable<TskNotificationList[]> {
        return this.httpClient.get<ResponseWrapper>(this.tskNotificationsListUrl).pipe(
            map(rw => {
                console.log('TSK NOT LIST SERVICE', rw.data);
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.tskNotificationsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(tno: TskNotification): Observable<TskNotification> {
        console.log('CREATE', tno);
        return this.httpClient
            .post<ResponseWrapper>(this.tskNotificationsUrl, JSON.stringify(tno)).pipe(
                map(rw => {
                    console.log('CREATE RAW DATA', rw);
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    // kreiranje notifikacije sa parametrima
    // koji se salju kroz path i body koji je za sad null
    createNotification(oruId: number, description: string, title: string, accId: number, url: string, mode: string, fthId: number): Observable<any> {
        const path = `${this.tskNotificationsUrl}CreateNotification?oruId=${oruId}&description=${description}&title=${title}&accId=${accId}&url=${url}&mode=${mode}&fthId=${fthId}`;
        return this.httpClient.post<ResponseWrapper>(path, {oruId, description, title, accId, url, mode, fthId}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }


    // ovaj metod trenutno nije implementiran
    // na be u application-dev.properties su kucani parametri defult mail setovanja koji je kopiran sa
    // pelagusa, te se mora prilagoditi gir-u
    sendMail(oruId: number, description: string, title: string): Observable<any> {
        const path = `${this.tskNotificationsUrl}SendMail?oruId=${oruId}&description=${description}&title=${title}`;
        return this.httpClient.post<ResponseWrapper>(path, null).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }


    update(tskNotification: TskNotification): Observable<TskNotification> {
        const url = `${this.tskNotificationsUrl}/${tskNotification.tnoId}`;
        console.log('UPDATE', tskNotification);
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(tskNotification)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.tskNotificationsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    loadLazyList(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.tskNotificationsListUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(tskNotification) {

        if (tskNotification.tnoAlertDate) {
            tskNotification.tnoAlertDate = new Date(tskNotification.tnoAlertDate);
        }
        if (tskNotification.tnoDate) {
            tskNotification.tnoDate = new Date(tskNotification.tnoDate);
        }
        if (tskNotification.tnoDueDate) {
            tskNotification.tnoDueDate = new Date(tskNotification.tnoDueDate);
        }
        if (tskNotification.tnoSysCreateDate) {
            tskNotification.tnoSysCreateDate = new Date(tskNotification.tnoSysCreateDate);
        }
        if (tskNotification.tnoSysSyncTst) {
            tskNotification.tnoSysSyncTst = new Date(tskNotification.tnoSysSyncTst);
        }
        if (tskNotification.tnoSysUpdateDate) {
            tskNotification.tnoSysUpdateDate = new Date(tskNotification.tnoSysUpdateDate);
        }

        // if (tskNotification.tnoPriority) {
        //     tskNotification.tnoPriority = tskNotification.tnoPriority.toString();
        // }

        console.log('PROCESS DATA', tskNotification);

        return tskNotification;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    markSeenCaseActivities(objectNoDocumentSeen: TskNotification[]): Observable<void> {
        const url = `${this.tskNotificationsUrl}/markSeen`;
        return this.httpClient
            .post<ResponseWrapper>(url, JSON.stringify(objectNoDocumentSeen)).pipe(
                map(rw => {
                }),
                catchError(this.handleError)
            );
    }

    markSeenCaseActivity(objectNoDocumentSeen: TskNotification): Observable<void> {
        const url = `${this.tskNotificationsUrl}/markOneSeen`;
        return this.httpClient
            .post<ResponseWrapper>(url, JSON.stringify(objectNoDocumentSeen)).pipe(
                map(rw => {
                }),
                catchError(this.handleError)
            );
    }


    findNonSeenForUser(accId: number): Observable<TskNotification[]> {
        const url = `${this.tskNotificationsUrl}NonSeen`;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('accId', accId + '');

        return this.httpClient.get<ResponseWrapper>(url, {params: httpParams}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );

    }

    findNonSeenForOrgUnit(oruId: number): Observable<TskNotification[]> {
        const url = `${this.tskNotificationsUrl}NonSeen`;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('oruId', oruId + '');

        return this.httpClient.get<ResponseWrapper>(url, {params: httpParams}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );

    }

    sendMailToAccEmail(accEmail: string, description: string, title: string): Observable<any> {
        const path = `${this.tskNotificationsUrl}SendMailToAccEmail?accEmail=${accEmail}&description=${description}&title=${title}`;
        return this.httpClient.post<ResponseWrapper>(path, null).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    findNonSeenForRole(role: string): Observable<TskNotification[]> {
        const url = `${this.tskNotificationsUrl}NonSeen`;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('role', role + '');

        return this.httpClient.get<ResponseWrapper>(url, {params: httpParams}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );

    }

    // 01.03.2022 Nenad: dodat metod ucitavanja liste notifikacija na pocetni ekran aplikacije
    // po parametru prijavljenog korisnika, sortirano prvo po neprociatnim notifikacijama i potom datumu
    findNotificationsNonSeenForUser(accId: number): Observable<TskNotification[]> {
        const url = `${this.tskNotificationsUrl}NotificationsNonSeen`;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('accId', accId + '');

        return this.httpClient.get<ResponseWrapper>(url, {params: httpParams}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // 01.03.2022 Nenad: dodat metod ucitavanja jos 10 notifikacija na pocetni ekran aplikacije
    // po parametru prijavljenog korisnika i velicini liste, sortirano prvo po neprociatnim notifikacijama i potom datumu
    findNextTenNotificationsNonSeenForUser(accId: number, page: number): Observable<TskNotification[]> {
        const url = `${this.tskNotificationsUrl}NextTenNotificationsNonSeen`;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('accId', accId + '');
        httpParams = httpParams.append('page', page + '');

        return this.httpClient.get<ResponseWrapper>(url, {params: httpParams}).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



