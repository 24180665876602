import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login/login.component';
import {EmailVerificationComponent} from './login/change-password-email-verification/email-verification.component';
import {NewPasswordComponent} from './login/change-password-input-new-password/new-password.component';
import {LoginCertComponent} from './login/loginCert/loginCert.component';
import {LoginLayoutComponent} from './layout/login-layout-component/login-layout.component';
import {PageNotFoundComponent} from './layout/page-not-found-component/page-not-found.component';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './auth-guards/auth-guard';
import {HomeLayoutComponent} from './layout/home-layout-component/home-layout.component';
import {UserAdministrationModule} from './bo/ui/user-administration/user-administration.module';


const routes: Routes = [

  {
    path: '',                       // {1}
    component: HomeLayoutComponent,
    canActivateChild: [AuthGuard],       // {2}
    children: [
      {
        path: '',
        component: HomeComponent   // {3}
      },
      {
        path: 'home',
        component: HomeComponent   // {3}
      },
      { path: 'user-administration', loadChildren: () => import('./bo/ui/user-administration/user-administration.module').then(m => m.UserAdministrationModule) },
      { path: 'case-definition', loadChildren: './bo/ui/case-definition/case-definition.module#CaseDefinitionModule' },
      { path: 'meta-data-registers', loadChildren: './bo/ui/mdr/mdr.module#MdrModule' },
      { path: 'misc', loadChildren: () => import('./bo/ui/misc/misc.module').then(m => m.MiscModule)},
      { path: 'fin', loadChildren: () => import('./bo/ui/fin/fin.module').then(m => m.FinModule)},
      { path: 'reporting', loadChildren: () => import('./bo/ui/reporting/reporting.module').then(m => m.ReportingModule)},
      { path: 'form', loadChildren: './bo/ui/form/form.module#FormModule'}
    ]
  },


  {
    path: '',
    component: LoginLayoutComponent, // {4}
    children: [
      {
        path: 'login',
        component: LoginComponent   // {5}
      },
      {
        path: 'email-verification',
        component: EmailVerificationComponent
      },
      {
        path: 'new-password',
        component: NewPasswordComponent
      },
      {
        path: 'loginCert',
        component: LoginCertComponent
      }
    ]
  }
  ,
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
