import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ExaminationType} from '../model/examination-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {ExaminationTypeList} from '../model/examination-type-list';


@Injectable()
export class ExaminationTypeService {

    private baseApiUrl = globals.BASE_API_URL;
    private examinationTypesUrl = this.baseApiUrl + 'examinationTypes';  // URL to web api
    private examinationTypesUrlList = this.baseApiUrl + 'examinationTypesList';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getExaminationTypes(): Observable<ExaminationType[]> {
        return this.httpClient.get<ResponseWrapper>(this.examinationTypesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getExaminationType(id: number): Observable<ExaminationType> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.examinationTypesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(ext: ExaminationType): Observable<ExaminationType> {
        return this.httpClient
            .post<ResponseWrapper>(this.examinationTypesUrl, JSON.stringify(ext)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(examinationType: ExaminationType): Observable<ExaminationType> {
        const url = `${this.examinationTypesUrl}/${examinationType.extId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(examinationType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.examinationTypesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(examinationType) {

        if (examinationType.extSysCreateDate) {
            examinationType.extSysCreateDate = new Date(examinationType.extSysCreateDate);
        }
        if (examinationType.extSysSyncTst) {
            examinationType.extSysSyncTst = new Date(examinationType.extSysSyncTst);
        }
        if (examinationType.extSysUpdateDate) {
            examinationType.extSysUpdateDate = new Date(examinationType.extSysUpdateDate);
        }
        return examinationType;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    // poziv putanje za popunu liste iz ExaminationTypeList modela jer se kolona ext_reason
    // puni za poziv common_code-a
    getExaminationTypesList(): Observable<ExaminationTypeList[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrlList}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getExaminationTypesByWpoIdList(wpoId: number): Observable<ExaminationTypeList[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrlList}/byWpoId/${wpoId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getExaminationTypeByWorker(wrkId: number): Observable<ExaminationType[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrl}/byWorkerId/${wrkId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getExaminationTypeByWpoId(wpoId: number): Observable<ExaminationType[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrl}/byWpoId/${wpoId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getExaminationTypeByExtReason(extReason: string): Observable<ExaminationType[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.examinationTypesUrl}/byExtReason/${extReason}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



