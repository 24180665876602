import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { District } from '../model/district';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';


@Injectable()
export class DistrictService {

  private baseApiUrl = globals.BASE_API_URL;
  private districtsUrl = this.baseApiUrl + 'districts';  // URL to web api

  constructor(private httpClient: HttpClient) { }

  getDistricts(): Observable<District[]> {
	return this.httpClient.get<ResponseWrapper>(this.districtsUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getDistrict(id: number): Observable<District> {
    return this.httpClient.get<ResponseWrapper>(`${this.districtsUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.districtsUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  create(dst: District): Observable<District> {
	return this.httpClient
      .post<ResponseWrapper>(this.districtsUrl, JSON.stringify(dst)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(district: District): Observable<District> {
    const url = `${this.districtsUrl}/${district.dstId}`;
	return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(district)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.districtsUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(district) {

 if(district.dstSysCreateDate) {
district.dstSysCreateDate = new Date(district.dstSysCreateDate);
}
 if(district.dstSysSyncTst) {
district.dstSysSyncTst = new Date(district.dstSysSyncTst);
}
 if(district.dstSysUpdateDate) {
district.dstSysUpdateDate = new Date(district.dstSysUpdateDate);
}
    return district;
  }

  private handleError (error: Response | any) {
     return Utils.handleError(error);
  }


  // custom-start




  // findAllByOrganizationUnit(oruId: number): Observable<District[]> {
  //   let httpParams = new HttpParams();
  //   httpParams = httpParams.append('oruId', oruId.toString());
  //
  //   return this.httpClient.get<ResponseWrapper>(this.districtsUrl + 'ByOrganizationUnit', {params: httpParams}).pipe(
  //     map(rw => {
  //       return rw.data;
  //     }),
  //     retry(3),
  //     catchError(this.handleError)
  //   );
  // }

  // custom-end

}



