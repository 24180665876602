import {Injectable} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {Message} from '../shared/message';
import {TranslateService} from '@ngx-translate/core';
@Injectable()
export class InfoService {

  // Observable msg sources
  private infoAnnouncedSource = new Subject<string>();
  // Observable msg streams
  infoAnnounced$ = this.infoAnnouncedSource.asObservable();

  constructor(private _translateService: TranslateService) {

  }

  addInfo(info: string) {
    this.infoAnnouncedSource.next(info);
  }






}
