import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/internal/Subscription';
import {AuthService} from '../core/auth.service';
import {OrganizationUnit} from '../bo/model/organization-unit';
import {AccountRoleService} from '../bo/service/account-role.service';
import {AccountRole} from '../bo/model/account-role';
import {LazyLoadRequestVM} from '../bo/model/sys/lazy-load-request-vm';
import {MessageService} from '../core/message.service';
import {PersistanceService} from '../core/persistance.service';
import {TskNotification} from '../bo/model/tsk-notification';
import {TskNotificationService} from '../bo/service/tsk-notification.service';
import {TskNotificationObjectList} from '../bo/model/tsk-notification-object-list';
import {interval} from 'rxjs';


@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

    public errorMessage;
    public tabIndex = 0;

    fieldId1 = '805403-805415-805428-797530';
    fieldId2 = '805405-805417-797531';
    // fieldId3 = 797532;
    fieldId4 = '805420-797533';
    fieldId5 = '797535';

    countByCtvId1: number;
    countByCtvId2: number;
    countByCtvId3: number;
    countByCtvId4: number;
    countByCtvId5: number;

    notActiveBenfeiciariesCount: number;

    public selectedObj: any;
    public selectedMode: string;
    private sub1: Subscription;
    private sub2: Subscription;
    isObjectStatusVisible: boolean;
    @Output() activeProcessInfoCsc3460listEvent = new EventEmitter();

    organizationUnit: any;
    formMap: any;
    routesLoaded = false;

    caseActivitysPersonalListWithDocument: TskNotification[];
    caseActivitysPersonalListNoDocument: TskNotification[];
    activePersonalWithDocument = true;
    activePersonalNoDocument = true;
    numCasActivitiesPersonalNotSeen: number;
    user: any;

    notificationListSize: number;
    // 01.03.2020 Nenad ovo je period osvjezavanja notifikacije
    // kada se ode u metod gdje je primjenjen vidi se poziv dva metoda za same notifikacije
    // komponenta za osvjezavanje je pending-interceptor.ts
    // u toj komponenti se nalazi "exclude" poziva putanja da se ne vidi "loading screen"
    notificationRefreshInterval = interval(60000);
    notificationSubscribtion: Subscription;

    constructor(
        private router: Router,
        private tskNotificationService: TskNotificationService,
        private authService: AuthService,
        private accountRoleService: AccountRoleService,
        private messageService: MessageService,
        public persister: PersistanceService) {

        // OVO NIKAKO NE TREBA RADITI!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        /*this.getCountByCtvId1(this.fieldId1);
        this.getCountByCtvId2(this.fieldId2);
        this.getCountByCtvId3(this.fieldId3);
        this.getCountByCtvId4(this.fieldId4);
        this.getCountByCtvId5(this.fieldId5);*/


        /*this.sub1 = this._authService.organizationUnitChanged$.subscribe(res => {
          /!*this.getCountByCtvId1(this.fieldId1);
          this.getCountByCtvId2(this.fieldId2);
          this.getCountByCtvId3(this.fieldId3);
          this.getCountByCtvId4(this.fieldId4);
          this.getCountByCtvId5(this.fieldId5);*!/
          this.getCountByCldId(this.fieldId1, 1);
          this.getCountByCldId(this.fieldId2, 2);
          // this.getCountByCldId(this.fieldId3, 3);
          this.getCountByCldId(this.fieldId4, 4);
          this.getCountByCldId(this.fieldId5, 5);
          this.getLoggedInUserRoles();
        });*/

        this.sub1 = this.authService.organizationUnitChanged$.subscribe(res => {
            this.organizationUnit = res;
            this.authService.getUser().subscribe(
                rs => {
                    let obj = this.authService.getRoles();
                    if (obj !== null) {
                        this.persister.set('gir-roles', ' ');
                    }
                });
        });
        this.sub2 = this.authService.userLoaded$.subscribe(res => {
            this.getUser();
            this.refreshPersonalNotificationList();
        });
        // 01.03.2022 Nenad: poziv metode getUser koja vraca accId prijavljenog korisnika
        // koji nam treba za ucitavanje liste obavijestenja na pocetni ekran
        this.getUser();
        this.refreshPersonalNotificationList();

        // Ovo treba dodati verovatno... (Tanja 02.10.2020.)
        /* this.sub2 = this.noticesService.orgChange.subscribe(
             res => this.getLast5Notices()
         );*/
    }

    getClassificationDetails() {


    }


    // loadBylistBycldLevel0Id(id: number){
    //   this.activitiProcessInfoService.findBylistBycldLevel0Id(id).subscribe(
    //     res => {
    //       console.log(res);
    //       this.selectedObj = res;
    //       const io = new InteractionObject('edit', this.selectedObj, 'active-process-info');
    //       this.activeProcessInfoCsc3460listEvent.emit(io);
    //        this.router.navigate(['bo/bo-csc3620main']);
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
    // }


    getLoggedInUserRoles() {
        this.authService.getUser().subscribe(res => {
            this.accountRoleService.getAccountRolesByAccountIdAndLoggedUserOru(res.accId).subscribe(
                roles => {
                    this.isObjectStatusVisible = this.checkForRequiredRoleForObjectStatusBox(roles);
                }
            );
        });
    }


    checkForRequiredRoleForObjectStatusBox(roles: AccountRole[]): boolean {
        return roles.some(accRole => accRole.role.rolName === 'ИНСПЕКТОР-АКТИВИРАЊЕ ОБЈЕКТА ИН');
    }


    /*getCountByCldId(ids: string, field) {
      this.activitiProcessInfoService.countByCldId(ids).subscribe(
        res => {
          if(field == 1) {
            this.countByCtvId1 = res;
          }
          if(field == 2) {
            this.countByCtvId2 = res;
          }
          if(field == 3) {
            this.countByCtvId3 = res;
          }
          if(field == 4) {
            this.countByCtvId4 = res;
          }
          if(field == 5) {
            this.countByCtvId5 = res;
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  */
    /*///ovo poziva podatke iz activitiProcessInfo i poziva proceduru za brojac u zavisnosti od id
    getCountByCtvId1(id: number) {
      this.activitiProcessInfoService.countByCtvId1(id).subscribe(
        res => {
          this.countByCtvId1 = res;
        },
        err => {
          console.log(err);
        }
      );
    }

    getCountByCtvId2(id: number) {
      this.activitiProcessInfoService.countByCtvId2(id).subscribe(
        res => {
          this.countByCtvId2 = res;
        },
        err => {
          console.log(err);
        }
      );
    }

    getCountByCtvId3(id: number) {
      this.activitiProcessInfoService.countByCtvId3(id).subscribe(
        res => {
          this.countByCtvId3 = res;
        },
        err => {
          console.log(err);
        }
      );
    }

    getCountByCtvId4(id: number) {
      this.activitiProcessInfoService.countByCtvId4(id).subscribe(
        res => {
          this.countByCtvId4 = res;
        },
        err => {
          console.log(err);
        }
      );
    }

    getCountByCtvId5(id: number) {
      this.activitiProcessInfoService.countByCtvId5(id).subscribe(
        res => {
          this.countByCtvId5 = res;
        },
        err => {
          console.log(err);
        }
      );
    }*/

    /* countBeneficiariesWithNotActiveStatus() {
       const staCode = 'NEAKTIVAN';

       this._beneficiaryService.countBeneficiaries(staCode).subscribe(
         count => {
           this.notActiveBenfeiciariesCount = count;
         },
         error => {
           this._messageService.showFailMsgText(error);
         }
       );
     }*/

    ngOnInit(): void {
        /* console.log('on initttttttttttttttt');
         /!*this.getCountByCtvId1(this.fieldId1);
         this.getCountByCtvId2(this.fieldId2);
         this.getCountByCtvId3(this.fieldId3);
         this.getCountByCtvId4(this.fieldId4);
         this.getCountByCtvId5(this.fieldId5);*!/

         this.getCountByCldId(this.fieldId1, 1);
         this.getCountByCldId(this.fieldId2, 2);
         // this.getCountByCldId(this.fieldId3, 3);
         this.getCountByCldId(this.fieldId4, 4);
         this.getCountByCldId(this.fieldId5, 5);
         this.getLoggedInUserRoles();
         /!*this.countBeneficiariesWithNotActiveStatus();*!/

         // console.log("HOME COMPONENT");
         // this.activitiProcessInfoService.countByCtvId(10165).subscribe(
         //   res => {
         //     this.countByCtvId1 = res;
         //   },
         //   err => {
         //     console.log(err);
         //   }
         // );*/
    }

    // this.activitiProcessInfoService.getActiveProcessInfosByCldId(id).subscribe(
    //   res => {
    //     this.selectedObj = res;
    //     console.log('TEST');
    //
    //     console.log(id);
    //     this.router.navigate(['case-management/bo-csc3620main', id]);
    //     // let io = new InteractionObject(this.selectedMode, this.selectedObj, this.cmpDomainName);
    //     // this.activeProcessInfoCsc3460listEvent.emit(io);
    //   },
    //   err => {
    //     console.log(err);
    //   }
    // );

    ngOnDestroy() {
        if (this.sub1) {
            this.sub1.unsubscribe();
        }
        if (this.sub2) {
            this.sub2.unsubscribe();
        }
    }

    getActiveRoutes() {

        // Moguce da i ovo treba otkomentarisati (Tanja 02.10.2020.)
        /*  this.persister.getObserver('ortCode').subscribe((key) => {
            this.ortCode = this.persister.get(key);
          });*/
        this.persister.getObserver('gir-forms').subscribe((key) => {
            this.formMap = this.persister.get(key);
            this.routesLoaded = true;
        });

        //   this.persister.trigger('ortCode');
        this.persister.trigger('gir-forms');
    }

    getUser() {
        this.authService.getUser().subscribe(
            rs => {
                this.user = rs;
                this.getPersonalCaseActivityNotifications(this.user.accId);
            },
            err => {
                console.log(err);
            }
        );
    }

    // 01.03.2022 Nenad: ucitavanje liste notifikacija po korisniku sortirano prvo po neprocitanim a onda datumu
    getPersonalCaseActivityNotifications(accId: number) {
        this.activePersonalWithDocument = false;
        this.activePersonalNoDocument = false;
        this.tskNotificationService
            .findNotificationsNonSeenForUser(accId)
            .subscribe(caseActivitys => {
                if (caseActivitys != null) {
                    this.caseActivitysPersonalListWithDocument = caseActivitys;
                    this.caseActivitysPersonalListNoDocument = caseActivitys;
                    this.notificationListSize = this.caseActivitysPersonalListNoDocument.length;
                } else {
                    this.caseActivitysPersonalListWithDocument = [];
                    this.caseActivitysPersonalListNoDocument = [];
                }
                this.numCasActivitiesPersonalNotSeen = this.caseActivitysPersonalListNoDocument.filter(el => !el.tnoSeen).length;
                this.activePersonalWithDocument = true;
                this.activePersonalNoDocument = true;
            });
    }

    // 01.03.2022 Nenad: metod azuriranja notifikacije na procitano
    markOneAsSeen(tskNotification: TskNotification) {
        this.tskNotificationService.markSeenCaseActivity(tskNotification).subscribe(
            res => {
                this.getPersonalCaseActivityNotifications(this.user.accId);
            }
        );
    }

    // 01.03.2022 Nenad: prekopirao sam ispod kod sa app.topbar komponente, ko god je ovo zakomentarisao svaka cast!!!
    //
    // svrha ovog zahteva je otvaranje forme direktno iz izabrane notifikacije
    // privremeno zakucano da klikom na izabranu notifikaciju se ide na putanju fin/bo-change-approp-csc3810main/notificationGthId
    // ova putanja je dodata u fin-routing.module.ts sa dodatkom parametra notificationGthId koji se salje na listu u komponenti
    // posto postoji 6 razlicitih strana da bi znali koju main stranu odnosno pripadajucu listu otvaramo
    // bice potrebno dodati jos jedno polje u tsk_notification tabeli da bi prilikom kreiranja notifikacije
    // upisali i taj string koji ce biti sama putanja
    // polje za putanju je dodato tnoUrl
    // dodat parametar tno_mode za otvaranje notifikacije u view ili edit modu
    openSelectedbyGthId(tskNotification: TskNotification) {
        this.markOneAsSeen(tskNotification);
        this.selectedMode = 'startTask';
        if (tskNotification.tnoMode != null) {
            if (tskNotification.tnoMode != null) {
                // this.router.navigate([tskNotification.tnoUrl + '/' + tskNotification.tnoFthId + '/' + 'tskNotification.tnoMode']);
                // 01.03.2022 Nenad: zakucano da se otvaranjem notifikacije iz liste ide na 'edit' izabranog objekta
                this.router.navigate([tskNotification.tnoUrl + '/' + tskNotification.tnoFthId + '/' + 'edit']);
            } else {
                this.router.navigate([tskNotification.tnoUrl + '/' + tskNotification.tnoFthId]);
            }
        } else {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigateByUrl(tskNotification.tnoUrl).then(() => {
                this.router.onSameUrlNavigation = 'ignore';
            });
        }
    }

    // 01.03.2022 Nenad: ucitavanje jos 10 notifikacija na prethodno otvorenu listu notifikacija
    loadNextTenPersonalNotifications() {
        this.activePersonalWithDocument = false;
        this.activePersonalNoDocument = false;
        this.tskNotificationService
            .findNextTenNotificationsNonSeenForUser(this.user.accId, this.notificationListSize)
            .subscribe(caseActivitys => {
                if (caseActivitys != null) {
                    this.caseActivitysPersonalListWithDocument = caseActivitys;
                    this.caseActivitysPersonalListNoDocument = caseActivitys;
                } else {
                    this.caseActivitysPersonalListWithDocument = [];
                    this.caseActivitysPersonalListNoDocument = [];
                }
                this.numCasActivitiesPersonalNotSeen = this.caseActivitysPersonalListNoDocument.filter(el => !el.tnoSeen).length;
                this.activePersonalWithDocument = true;
                this.activePersonalNoDocument = true;
            });
    }

    // 02.03.2022 Nenad: osvjezavanje liste obavijestenja na pocetnom ekranu
    refreshPersonalNotificationList() {
        if (this.notificationSubscribtion) {
            this.notificationSubscribtion.unsubscribe();
        }
        this.notificationSubscribtion = this.notificationRefreshInterval.subscribe(() => {
            this.getPersonalCaseActivityNotifications(this.user.accId);
        });
    }
}
