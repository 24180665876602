import { Injectable }    from '@angular/core';
import { Observable } from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators'; 
import { MeasuringPoint } from '../model/measuring-point';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class MeasuringPointService {

  private baseApiUrl = globals.BASE_API_URL;
  private measuringPointsUrl = this.baseApiUrl + 'measuringPoints';  // URL to web api

  constructor(private httpClient: HttpClient) { }

  getMeasuringPoints(): Observable<MeasuringPoint[]> {
	return this.httpClient.get<ResponseWrapper>(this.measuringPointsUrl).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  getMeasuringPoint(id: number): Observable<MeasuringPoint> {
    return this.httpClient.get<ResponseWrapper>(`${this.measuringPointsUrl}/${id}`).pipe(
      map(rw => {
        return this.processData(rw.data);
      }),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<{} | Object> {
    const url = `${this.measuringPointsUrl}/${id}`;
    return this.httpClient.delete(url).pipe(
      catchError(this.handleError)
    );
  }

  create(msp: MeasuringPoint): Observable<MeasuringPoint> {
	return this.httpClient
      .post<ResponseWrapper>(this.measuringPointsUrl, JSON.stringify(msp)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }

  update(measuringPoint: MeasuringPoint): Observable<MeasuringPoint> {
    const url = `${this.measuringPointsUrl}/${measuringPoint.mspId}`;
	return this.httpClient
      .put<ResponseWrapper>(url, JSON.stringify(measuringPoint)).pipe(
        map(rw => {
          return this.processData(rw.data);
        }),
        catchError(this.handleError)
      );
  }
  
  loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
    return this.httpClient.post<ResponseWrapper>(this.measuringPointsUrl + '/lazy', JSON.stringify(req)).pipe(
      map(rw => {
        return rw.data;
      }),
      retry(3),
      catchError(this.handleError)
    );
  }

  private processData(measuringPoint) {
    
 if(measuringPoint.mspSysUpdateDate) {
measuringPoint.mspSysUpdateDate = new Date(measuringPoint.mspSysUpdateDate);
}
 if(measuringPoint.mspSysCreateDate) {
measuringPoint.mspSysCreateDate = new Date(measuringPoint.mspSysCreateDate);
}
    return measuringPoint;
  }

  private handleError (error: Response | any) {
     return Utils.handleError(error);
  }
  
  
  // custom-start
  
  
  
  // custom-end
  
}



