
import {throwError as observableThrowError, Observable, Subscription} from 'rxjs';
import {Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild} from '@angular/core';
import {catchError, map} from 'rxjs/operators';


@Component({
  selector: 'app-bs-save-dlg',
  templateUrl: 'bs-save-dlg.component.html',
  styleUrls: ['bs-save-dlg.component.css']
})
export class BsSaveDlgComponent {

  displaySaveDlg = false;

  @Output() bsSaveDlgEvent = new EventEmitter();

  save() {
    this.bsSaveDlgEvent.emit();
    this.displaySaveDlg = false;
  }

  cancel() {
    this.displaySaveDlg = false;
  }

  show() {
    this.displaySaveDlg = true;
  }

  hide() {
    this.displaySaveDlg = false;
  }
}
