import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BaseListComponent} from "../../../base/base-list/base-list.component";
import {VProjectReportPeriod} from "../../../model/v-project-report-period";
import {ProjectReportPeriod} from "../../../model/project-report-period";
import {Table} from "primeng/table";
import {VProjectReportPeriodService} from "../../../service/v-project-report-period.service";
import {ProjectReportPeriodService} from "../../../service/project-report-period.service";

@Component({
  selector: 'app-project-report-period-quartal-selection-dialog',
  templateUrl: './project-report-period-quartal-selection-dialog.component.html',
  styleUrls: ['./project-report-period-quartal-selection-dialog.component.css']
})
export class ProjectReportPeriodQuartalSelectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

  public selectedObj: any;
  public projectReportPeriods: ProjectReportPeriod[];
  @Output() onSelected = new EventEmitter<ProjectReportPeriod>();
  public errorMessage: string;
  visible = false;

  tfFilterMap = {'prpCode': null};

  exportCols = ['prpCode'];

  @ViewChild('dt1', {static: true}) dataTable: Table;

  selectedFilterField: string;

  // custom-constructor-start
  private cmpDomainName = 'project-report-period-quartal';

  private title = 'Project_report_period_csc1240selection_dialog_Label';

  constructor(private projectReportPeriodService: ProjectReportPeriodService) {
    super();
  }

  ngOnInit(): void {
  }

  selectPrp(obj: any) {
    this.selectPrpCustom(obj);
  }

  showDlg() {
    this.getProjectReportPeriods();
    this.clearFilters();
  }

  showDlgDynamic(sqlQuery) {
    if (sqlQuery.includes('prp_type_gantt')) {
      this.title = 'project_report_period_csc1240selection_dialog_Label_delivery_month';
    }
    this.getProjectReportPeriodsQuartalsDynamicDialog(sqlQuery);
    this.clearFilters();
  }

  getProjectReportPeriods() {
    this.projectReportPeriodService
        .getProjectReportPeriodsByPrpType('prp_type_quarterly')
        .subscribe(projectReportPeriods => {
          debugger;
          this.projectReportPeriods = projectReportPeriods;
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  getProjectReportPeriodsQuartalsDynamicDialog(sqlQuery) {
    this.projectReportPeriodService
        .getProjectReportPeriodsQuartalsDynamicDialog(sqlQuery)
        .subscribe(projectReportPeriods => {
          debugger;
          this.projectReportPeriods = projectReportPeriods;
          this.visible = true;
        }, error => this.errorMessage = <any> error);
  }

  ngOnDestroy() {
    this.onDestroyCustom();
  }

  selectPrpCustom(obj: any) {
    this.selectedObj = obj;
    this.visible = false;
    this.onSelected.emit(this.selectedObj);
  }

  onDestroyCustom() {
  }

  removeSelected() {
    this.selectedObj = null;
    this.onSelected.emit(this.selectedObj);
  }

}
