import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {DocumentGroup} from '../../../../../../model/document-group';
import {DocumentGroupService} from '../../../../../../service/document-group.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';

@Component({
    selector: 'app-document-group-csc1520selection-dialog',
    templateUrl: './document-group-csc1520selection-dialog.component.html',
    styleUrls: ['./document-group-csc1520selection-dialog.component.css']
})
export class DocumentGroupCsc1520selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public documentGroups: DocumentGroup[];
    @Output() onSelected = new EventEmitter<DocumentGroup>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'dgrCode': null, 'dgrName': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'document-group';

    constructor(private documentGroupService: DocumentGroupService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
        this.getDocumentGroups();
    }


    selectDgr(obj: any) {
        this.selectDgrCustom(obj);
    }

    showDlg() {
        this.visible = true;
    }

    getDocumentGroups(): void {
        this.getDocumentGroupsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getDocumentGroupsCustom() {
        this.documentGroupService
            .getDocumentGroups()
            .subscribe(documentGroups => this.documentGroups = documentGroups, error => this.errorMessage = <any> error);
    }

    selectDgrCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
