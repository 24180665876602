import {throwError as observableThrowError, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';


import * as FileSaver from 'file-saver';


import * as globals from '../globals/globals';
import {MessageService} from '../core/message.service';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import Utils from '../utils/utils';

@Injectable()
export class ReportService {

    // private baseApiUrl = environment.BASE_API_URL;
    private baseApiUrl = globals.BASE_API_URL;
    private reportsUrl = this.baseApiUrl + 'reports';  // URL to web api
    private reportsAlfrescoUrl = this.baseApiUrl + 'reportsAndAlfresco';

    private reportAnnouncedSource = new Subject<any>();
    reportAnnounced$ = this.reportAnnouncedSource.asObservable();

    constructor(private http: HttpClient, private _messageService: MessageService) {
    }


    downloadReport(rptCode, outputFileName, format, requestParams) {
        if (environment.SHOW_CONSOLE_MSG) {
            console.log('downloadDocument code: ' + rptCode + ' outputFileName: ' + outputFileName + ' requestParams: ' + requestParams);
        }
        this.getReport(rptCode, outputFileName, format, requestParams).subscribe(
            res => {
                if (res.size == 0) {
                    this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                } else {
                    FileSaver.saveAs(res, outputFileName + '.' + format);
                }
            },
            err => {
                //  this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                if (environment.SHOW_CONSOLE_MSG) {
                    console.log(err);
                }
                this._messageService.showFailMsgText(err);
            }
        );
    }

    downloadReportWithAlfresco(rptCode, outputFileName, format, requestParams) {
        if (environment.SHOW_CONSOLE_MSG) {
            console.log('downloadDocument code: ' + rptCode + ' outputFileName: ' + outputFileName + ' requestParams: ' + requestParams);
        }
        this.getReportWithAlfresco(rptCode, outputFileName, format, requestParams).subscribe(
            res => {
                if (res.size == 0) {
                    this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                } else {
                    FileSaver.saveAs(res, outputFileName + '.' + format);
                }
            },
            err => {
                //  this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                if (environment.SHOW_CONSOLE_MSG) {
                    console.log(err);
                }
                this._messageService.showFailMsgText(err);
            }
        );
    }

    uploaddReportOnAlfresco(rptCode, outputFileName, format, requestParams) {
        if (environment.SHOW_CONSOLE_MSG) {
            console.log('downloadDocument code: ' + rptCode + ' outputFileName: ' + outputFileName + ' requestParams: ' + requestParams);
        }
        this.getReportWithAlfresco(rptCode, outputFileName, format, requestParams).subscribe(
            res => {
                if (res.size == 0) {
                    this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                }  // else {
                   // FileSaver.saveAs(res, outputFileName + '.' + format);
                   // }

            },
            err => {
                //  this._messageService.showFailMsgText('MSG_FAIL_DOWNLOAD_FILE');
                if (environment.SHOW_CONSOLE_MSG) {
                    console.log(err);
                }
                this._messageService.showFailMsgText(err);
            }
        );
    }

    showReportDialog(rptCode, outputFileName, requestParams) {
        console.log('rpt addedddd');
        let rpt = {'rptCode': rptCode, 'requestParams': requestParams, 'outputFileName': outputFileName};
        this.reportAnnouncedSource.next(rpt);
    }


    getReport(rptCode, outputFileName, format, requestParams): Observable<Blob> {
        if (!format) {
            format = 'pdf';
        }
        if (!outputFileName) {
            outputFileName = 'output.pdf';
        }
        let suffix = '?format=' + format + '&rptCode=' + rptCode + '&outputFileName=' + outputFileName;
        if (requestParams) {
            for (var key in requestParams) {
                if (requestParams.hasOwnProperty(key)) {
                    suffix += '&' + key + '=' + requestParams[key];
                }
            }
        }
        let url = this.reportsUrl + suffix;
        console.log('url: ' + url);
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map(this.extractBlob),
            catchError(this.handleError))
            ;
    }

    getReportWithAlfresco(rptCode, outputFileName, format, requestParams): Observable<Blob> {
        if (!format) {
            format = 'pdf';
        }
        if (!outputFileName) {
            outputFileName = 'output.pdf';
        }
        let suffix = '?format=' + format + '&rptCode=' + rptCode + '&outputFileName=' + outputFileName;
        if (requestParams) {
            for (var key in requestParams) {
                if (requestParams.hasOwnProperty(key)) {
                    suffix += '&' + key + '=' + requestParams[key];
                }
            }
        }
        let url = this.reportsAlfrescoUrl + suffix;
        console.log('url: ' + url);
        return this.http.get(url, {responseType: 'blob'}).pipe(
            map(this.extractBlob),
            catchError(this.handleError))
            ;
    }

    extractBlob(res) {
        debugger;
        // posto u res vec vraca Blob ne mora se praviti novi Blob koji onda baca gresku
        return res;
        // return new Blob([res.blob()], {type: 'application/pdf'});
    }


    private handleError(error: Response | any) {
        /*// In a real world app, we might use a remote logging infrastructure
        let errMsg: string;
        if (error instanceof Response) {
          const body = error.json() || '';
          const err = body.error || JSON.stringify(body);
          // errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
          errMsg = body.message;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);*/
        return Utils.handleError(error);
    }

    getHttpHeader() {
        let headers = new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem(globals.AUTH_TOKEN_NAME)
        });
        return headers;
    }

}



