import {AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';
import {Directive} from '@angular/core';

// validation function
function validateTEKUCA_GODINAFactory(): ValidatorFn {
  return (cc: AbstractControl) => {
    console.log('validate TEKUCA_GODINA');
    const DIGIT_REGEXP = /^[0-9]*$/;

    let isValid = false;
    
    if (isValid) {
      return null;
    } else {
      return {
        badTEKUCA_GODINA: {
          valid: false
        }
      };
    }
  };
}

@Directive({
  selector: '[appValidateTEKUCA_GODINA][ngModel], [appValidateTEKUCA_GODINA][formControl]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: TekucaGodinaValidatorDirective, multi: true}
  ]
})
export class TekucaGodinaValidatorDirective implements Validator {
  validator: Function;

  constructor() {
    this.validator = validateTEKUCA_GODINAFactory();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }

}

