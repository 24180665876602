import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {VFznDocumentType} from '../model/v-fzn-document-type';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class VFznDocumentTypeService {

    private baseApiUrl = globals.BASE_API_URL;
    private vFznDocumentTypesUrl = this.baseApiUrl + 'vFznDocumentTypes';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getVFznDocumentTypes(): Observable<VFznDocumentType[]> {
        return this.httpClient.get<ResponseWrapper>(this.vFznDocumentTypesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getVFznDocumentType(id: number): Observable<VFznDocumentType> {
        return this.httpClient.get<ResponseWrapper>(`${this.vFznDocumentTypesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.vFznDocumentTypesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(fdt: VFznDocumentType): Observable<VFznDocumentType> {
        return this.httpClient
            .post<ResponseWrapper>(this.vFznDocumentTypesUrl, JSON.stringify(fdt)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(vFznDocumentType: VFznDocumentType): Observable<VFznDocumentType> {
        const url = `${this.vFznDocumentTypesUrl}/${vFznDocumentType.fdtId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(vFznDocumentType)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.vFznDocumentTypesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(vFznDocumentType) {

        if (vFznDocumentType.fdtSysCreateDate) {
            vFznDocumentType.fdtSysCreateDate = new Date(vFznDocumentType.fdtSysCreateDate);
        }
        if (vFznDocumentType.fdtSysUpdateDate) {
            vFznDocumentType.fdtSysUpdateDate = new Date(vFznDocumentType.fdtSysUpdateDate);
        }
        if (vFznDocumentType.fdtSysSyncTst) {
            vFznDocumentType.fdtSysSyncTst = new Date(vFznDocumentType.fdtSysSyncTst);
        }
        return vFznDocumentType;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // custom-end

}



