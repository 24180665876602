import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {SystemProperty} from '../model/system-property';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';


@Injectable()
export class SystemPropertyService {

    private baseApiUrl = globals.BASE_API_URL;
    private systemPropertysUrl = this.baseApiUrl + 'systemPropertys';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getSystemPropertys(): Observable<SystemProperty[]> {
        return this.httpClient.get<ResponseWrapper>(this.systemPropertysUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getSystemProperty(id: number): Observable<SystemProperty> {
        return this.httpClient.get<ResponseWrapper>(`${this.systemPropertysUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    isCertLogin(): Observable<boolean> {
        return this.httpClient.post<ResponseWrapper>(this.baseApiUrl + 'systemPropertysIsCertLogin', null).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.systemPropertysUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(spr: SystemProperty): Observable<SystemProperty> {
        return this.httpClient
            .post<ResponseWrapper>(this.systemPropertysUrl, JSON.stringify(spr)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(systemProperty: SystemProperty): Observable<SystemProperty> {
        const url = `${this.systemPropertysUrl}/${systemProperty.sprId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(systemProperty)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.systemPropertysUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(systemProperty) {

        if (systemProperty.sprSysCreateDate) {
            systemProperty.sprSysCreateDate = new Date(systemProperty.sprSysCreateDate);
        }
        if (systemProperty.sprSysUpdateDate) {
            systemProperty.sprSysUpdateDate = new Date(systemProperty.sprSysUpdateDate);
        }
        if (systemProperty.sprSysSyncTst) {
            systemProperty.sprSysSyncTst = new Date(systemProperty.sprSysSyncTst);
        }
        return systemProperty;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getSystemPropertyValueBySprName(sprName: string): Observable<number> {
        const url = `${this.systemPropertysUrl}/BySprName/${sprName}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }
    getSystemPropertyValueBySprNameInDecimal(sprName: string): Observable<number> {
        const url = `${this.systemPropertysUrl}/BySprNameInDecimal/${sprName}`;
        return this.httpClient.get<ResponseWrapper>(url).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



