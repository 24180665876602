import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Sro} from '../../../../../../model/sro';
import {SroService} from '../../../../../../service/sro.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {VNioListService} from '../../../../../../service/v-nio-list.service';
import {VNioList} from '../../../../../../model/v-nio-list';

@Component({
    selector: 'app-sro-csc1122selection-dialog',
    templateUrl: './sro-csc1122selection-dialog.component.html',
    styleUrls: ['./sro-csc1122selection-dialog.component.css']
})
export class SroCsc1122selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public sros: VNioList[];

    // @Input('fthId') fthId: number;

    @Output() onSelected = new EventEmitter<VNioList>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'sroCode': null, 'sroName': null};

    exportCols = ['sroCode', 'sroName'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'sro';

    constructor(private sroService: VNioListService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
    }


    selectSro(obj: any) {
        this.selectSroCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    showDlgByFthId(fthId: number) {
        this.clearFilters();
        this.getSrosByFthId(fthId);
    }

    getSrosByFthId(fthId: number) {
        this.sroService.getVNioListByFthId(fthId).subscribe(
            res => {
                this.sros = res;
                this.visible = true;
            },error => this.errorMessage = <any> error
        );
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    selectSroCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
