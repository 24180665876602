import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {ProjectStructureElement} from '../model/project-structure-element';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {ProjectReportPeriod} from "../model/project-report-period";


@Injectable()
export class ProjectStructureElementService {

    private baseApiUrl = globals.BASE_API_URL;
    private projectStructureElementsUrl = this.baseApiUrl + 'projectStructureElements';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getProjectStructureElements(): Observable<ProjectStructureElement[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectStructureElementsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectStructureElement(id: number): Observable<ProjectStructureElement> {
        return this.httpClient.get<ResponseWrapper>(`${this.projectStructureElementsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.projectStructureElementsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(pse: ProjectStructureElement): Observable<ProjectStructureElement> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectStructureElementsUrl, JSON.stringify(pse)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    saveProjectStructure(pses: ProjectStructureElement[], pjhId: number, deletedNodes: number[]): Observable<ProjectStructureElement[]> {
        let url = this.projectStructureElementsUrl + `Save/${pjhId}`;
        if (deletedNodes.length > 0) {
            url += `/${deletedNodes}`;
        }
        return this.httpClient
            .post<ResponseWrapper>(url,
                JSON.stringify(pses, Utils.getCircularReplacer())).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(projectStructureElement: ProjectStructureElement): Observable<ProjectStructureElement> {
        const url = `${this.projectStructureElementsUrl}/${projectStructureElement.pseId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(projectStructureElement, Utils.getCircularReplacer())).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.projectStructureElementsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(projectStructureElement) {

        if (projectStructureElement.pseSysUpdateDate) {
            projectStructureElement.pseSysUpdateDate = new Date(projectStructureElement.pseSysUpdateDate);
        }
        if (projectStructureElement.pseSysSyncTst) {
            projectStructureElement.pseSysSyncTst = new Date(projectStructureElement.pseSysSyncTst);
        }
        if (projectStructureElement.pseSysCreateDate) {
            projectStructureElement.pseSysCreateDate = new Date(projectStructureElement.pseSysCreateDate);
        }
        return projectStructureElement;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    getProjectStructureElementsRootsByPsePjhId(psePjhId: number): Observable<ProjectStructureElement[]> {
        return this.httpClient.get<ResponseWrapper>(this.projectStructureElementsUrl + `RootsByPsePjhId/${psePjhId}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getProjectStructureElementsDynamicDialog(sqlQuery): Observable<ProjectStructureElement[]> {
        return this.httpClient
            .post<ResponseWrapper>(this.projectStructureElementsUrl + '/dynamicDialogs', sqlQuery).pipe(
                map(rw => {
                    return rw.data;
                }),
                catchError(this.handleError)
            );
    }

    // custom-end

}



