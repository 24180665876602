import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProgrammeDocumentType} from '../../../../../../model/programme-document-type';
import {ProgrammeDocumentTypeService} from '../../../../../../service/programme-document-type.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {VProgrammeDocumentType} from '../../../../../../model/v-programme-document-type';
import {VProgrammeDocumentTypeService} from '../../../../../../service/v-programme-document-type.service';
import {AuthService} from '../../../../../../../core/auth.service';

@Component({
    selector: 'app-programme-document-type-csc1550selection-dialog',
    templateUrl: './programme-document-type-csc1550selection-dialog.component.html',
    styleUrls: ['./programme-document-type-csc1550selection-dialog.component.css']
})
export class ProgrammeDocumentTypeCsc1550selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public vProgrammeDocumentTypes: VProgrammeDocumentType[];
    @Output() onSelected = new EventEmitter<VProgrammeDocumentType>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'pdtOrder': null, 'fdtCode': null, 'fdtName': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start

    roles: any;
    private cmpDomainName = 'programme-document-type';

    constructor(private vProgrammeDocumentTypeService: VProgrammeDocumentTypeService,
                private authService: AuthService) {
        super();
        this.roles = this.authService.getRoles();
    }

    // custom-constructor-end


    ngOnInit(): void {
        //  this.getProgrammeDocumentTypes();
    }


    selectPdt(obj: any) {
        this.selectPdtCustom(obj);
    }

    showDlg(pjhId: number) {
        this.getProgrammeDocumentTypes(pjhId);
    }

    getProgrammeDocumentTypes(pjh: number): void {
        this.getProgrammeDocumentTypesCustom(pjh);
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProgrammeDocumentTypesCustom(pjhId: number) {

        this.vProgrammeDocumentTypeService
            .getProgrammeDocumentTypesByPjhId(pjhId)
            .subscribe(vProgrammeDocumentTypes => {
                this.vProgrammeDocumentTypes = vProgrammeDocumentTypes;
                if (this.roles.includes('ROLE_RUKOVODILAC_PROJEKTA')) {
                    this.vProgrammeDocumentTypes = this.vProgrammeDocumentTypes.filter(data => data.fdtViewRukovodilac === true);
                } else if (this.roles.includes('ROLE_FZN_KONTROLOR')) {
                    this.vProgrammeDocumentTypes = this.vProgrammeDocumentTypes.filter(data => data.fdtViewFond === true);
                }
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    selectPdtCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
