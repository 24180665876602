import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError, retry} from 'rxjs/operators';
import {Item} from '../model/item';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import Utils from '../../utils/utils';
import {CharacteristicsList} from "../model/sys/characteristics-list";
import {ItemList} from "../model/item-list";
import {Characteristics} from '../model/characteristics';


@Injectable()
export class ItemService {

    private baseApiUrl = globals.BASE_API_URL;
    private itemsUrl = this.baseApiUrl + 'items';  // URL to web api
    private itemsUrlList = this.baseApiUrl + 'itemsList';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getItems(): Observable<Item[]> {
        return this.httpClient.get<ResponseWrapper>(this.itemsUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getItem(id: number): Observable<Item> {
        return this.httpClient.get<ResponseWrapper>(`${this.itemsUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.itemsUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(itm: Item): Observable<Item> {
        return this.httpClient
            .post<ResponseWrapper>(this.itemsUrl, JSON.stringify(itm)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(item: Item): Observable<Item> {
        const url = `${this.itemsUrl}/${item.itmId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(item)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.itemsUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(item) {

        if (item.itmSysSyncTst) {
            item.itmSysSyncTst = new Date(item.itmSysSyncTst);
        }
        if (item.itmSysUpdateDate) {
            item.itmSysUpdateDate = new Date(item.itmSysUpdateDate);
        }
        if (item.itmSysCreateDate) {
            item.itmSysCreateDate = new Date(item.itmSysCreateDate);
        }
        return item;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start

    // metod punjenja liste sa common_code vezom na polju itm_dangerous_goods
    getItemsList(): Observable<ItemList[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.itemsUrlList}`).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getItemsForTrainingDetailItemDlg(trdId: number): Observable<Item[]> {
        return this.httpClient.get<ResponseWrapper>(`${this.itemsUrlList}/ForTrainingDetailItemDlg/${trdId}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    // custom-end

}



