import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ActiveProcessInfo} from '../model/active-process-info';
import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';


@Injectable()
export class ActiveProcessInfoService {

    private baseApiUrl = globals.BASE_API_URL;
    private activeProcessInfosUrl = this.baseApiUrl + 'activeProcessInfos';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getActiveProcessInfos(): Observable<ActiveProcessInfo[]> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getActiveProcessInfo(id: number): Observable<ActiveProcessInfo> {
        return this.httpClient.get<ResponseWrapper>(`${this.activeProcessInfosUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    getActiveProcessInfoFindByProcessInstanceId(id: number): Observable<ActiveProcessInfo> {
        return this.httpClient.get<ResponseWrapper>(`${this.activeProcessInfosUrl + 'FindByProcessInstanceId'}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.activeProcessInfosUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(api: ActiveProcessInfo): Observable<ActiveProcessInfo> {
        return this.httpClient
            .post<ResponseWrapper>(this.activeProcessInfosUrl, JSON.stringify(api)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(activeProcessInfo: ActiveProcessInfo): Observable<ActiveProcessInfo> {
        const url = `${this.activeProcessInfosUrl}/${activeProcessInfo.apiId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(activeProcessInfo)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.activeProcessInfosUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(activeProcessInfo) {

        if (activeProcessInfo.apiCreateTime) {
            activeProcessInfo.apiCreateTime = new Date(activeProcessInfo.apiCreateTime);
        }
        if (activeProcessInfo.ctvDateFrom) {
            activeProcessInfo.ctvDateFrom = new Date(activeProcessInfo.ctvDateFrom);
        }
        if (activeProcessInfo.ctvDateTo) {
            activeProcessInfo.ctvDateTo = new Date(activeProcessInfo.ctvDateTo);
        }
        return activeProcessInfo;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    // findBylistBycldLevel0Id(Id): Observable<number> {
    //   return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/cldLevel0Id?cldLevel0Id=' + Id).pipe(
    //     map(rw => {
    //       return rw.data;
    //     }),
    //     retry(3),
    //     catchError(this.handleError)
    //   );
    // }


    countByCtvId(ctvId: number): Observable<number> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?ctvId=' + ctvId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    countByMsrId(ctvId: number): Observable<number> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?msrId=' + ctvId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // metod za prikazivanje broja predmeta samo za internu reviziju, za oruId = 943
    countByOruId(): Observable<number> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/countByOruId').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    // POSTOVANE KOLEGE MOLIM VAS NEMOJTE PISATI OVAKVE GLUPOSTI!!!!!!!!!!!!!!! HVALA!
    /*countByCtvId1(Id): Observable<number> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?Id=' + Id).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }





     countByCtvId2(Id): Observable<number> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?Id=' + Id).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }

     countByCtvId3(Id): Observable<number> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?Id=' + Id).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }

     countByCtvId4(Id): Observable<number> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?Id=' + Id).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }

     countByCtvId5(Id): Observable<number> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/count?Id=' + Id).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }*/


    /*getActiveProcessInfosByCldId(ctvId): Observable<ActiveProcessInfo[]> {
     return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/userAndOruAndCtvId?ctvId=' + ctvId).pipe(
     map(rw => {
     return rw.data;
     }),
     retry(3),
     catchError(this.handleError)
     );
     }*/

    getActiveProcessInfosByMsrId(ctvId): Observable<ActiveProcessInfo[]> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/userAndOruAndMsrId?msrId=' + ctvId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getActiveProcessInfosForUserAndOru(): Observable<any[]> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/userAndOru').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getActiveProcessInfosForUser(accName: string): Observable<ActiveProcessInfo[]> {
        const params = new HttpParams().set('accName', accName);
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/user', {params: params}).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    getSlassificationDetails(): Observable<string> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    checkTaskRolePermission(oruId: number, accId: number, apiId: number, apiRoles: string): Observable<boolean> {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/checkTaskRolePermission?oruId=' + oruId + '&accId=' + accId + '&apiId=' + apiId + '&apiRoles=' + apiRoles).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    checkTaskRolePermissionTsk(oruId: any, accId: number | null, apiId: string) {
        return this.httpClient.get<ResponseWrapper>(this.activeProcessInfosUrl + '/checkTaskRolePermissionTsk?oruId=' + oruId + '&accId=' + accId + '&apiId=' + apiId).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }


    // custom-end
}
