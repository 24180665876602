import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {ProjectReportPeriod} from '../../../../../../model/project-report-period';
import {ProjectReportPeriodService} from '../../../../../../service/project-report-period.service';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';
import {VProjectReportPeriod} from '../../../../../../model/v-project-report-period';
import {VProjectReportPeriodService} from '../../../../../../service/v-project-report-period.service';

@Component({
    selector: 'app-project-report-period-csc1240selection-dialog',
    templateUrl: './project-report-period-csc1240selection-dialog.component.html',
    styleUrls: ['./project-report-period-csc1240selection-dialog.component.css']
})
export class ProjectReportPeriodCsc1240selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    @Input('pjhId') pjhId: number;

    public selectedObj: any;
    public vProjectReportPeriods: VProjectReportPeriod[];
    @Output() onSelected = new EventEmitter<ProjectReportPeriod>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'ccdPrpType': null, 'prpCode': null};

    exportCols = ['ccdPrpType', 'prpCode'];


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'project-report-period';

    // 03.03.2022 Nenad: parametar periodType koji dolazi sa 1210detail komponente
    // jer nam treba string za filter liste dijaloga
    @Input('periodType') periodType: string;

    constructor(private vProjectReportPeriodService: VProjectReportPeriodService) {
        super();
    }

    // custom-constructor-end


    ngOnInit(): void {
    }


    selectPrp(obj: any) {
        this.selectPrpCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.getProjectReportPeriods();
    }

    showDlgCopyQarQfr(fthPjhId: number, fthFtdId: number) {
        this.clearFilters();
        this.getProjectReportPeriodsCopyQfrQarByPjhIdAndFdtId(fthPjhId, fthFtdId);
    }

    getProjectReportPeriods(): void {
        this.getProjectReportPeriodsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start

    getProjectReportPeriodsCopyQfrQarByPjhIdAndFdtId(fthPjhId: number, fthFdtId: number) {
        this.vProjectReportPeriodService
            .getProjectReportPeriodsCopyQfrQarByPjhIdAndFdtId(fthPjhId, fthFdtId)
            .subscribe(projectReportPeriods => {
                this.vProjectReportPeriods = projectReportPeriods;
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    getProjectReportPeriodsCustom() {

        debugger;

        this.vProjectReportPeriodService
            .getVProjectReportPeriodsByProjectHeaderId(this.pjhId)
            .subscribe(projectReportPeriods => {
                this.vProjectReportPeriods = projectReportPeriods;
                this.vProjectReportPeriods.sort((prp1, prp2) => {
                    return prp1.prpType.localeCompare(prp2.prpType);
                });
                // 03.03.2022 Nenad: lista na dijalogu se filterise odabirom tipa dokumenta
                // ovaj parametar periodType dolazi sa 1210detail komponente
                this.vProjectReportPeriods = this.vProjectReportPeriods.filter(value => value.ccdPrpType === this.periodType);

                this.visible = true;
            }, error => this.errorMessage = <any> error);

        // this.vProjectReportPeriodService
        //     .getVProjectReportPeriods()
        //     .subscribe(projectReportPeriods => {
        //         this.vProjectReportPeriods = projectReportPeriods;
        //         this.vProjectReportPeriods.sort((prp1, prp2) => {
        //             return prp1.prpType.localeCompare(prp2.prpType);
        //         });
        //     }, error => this.errorMessage = <any> error);
    }

    selectPrpCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }

    // showDlgByPrpPjhIdAndPrpType(prpPjhId: number, prpType: string) {
    //    this.clearFilters();
    //    this.getProjectReportPeriodsByPrpPjhIdAndPrpType(prpPjhId, prpType);
    // }

    showDlgByPrpPjhIdAndPrpType() {
        this.clearFilters();
        this.getProjectReportPeriodsByPrpPjhIdAndPrpType(this.pjhId, this.periodType);
    }

    getProjectReportPeriodsByPrpPjhIdAndPrpType(prpPjhId: number, prpType: string) {
        this.vProjectReportPeriodService.getProjectReportPeriodsByPrpPjhIdAndPrpType(prpPjhId, prpType)
            .subscribe(projectReportPeriods => {
                this.vProjectReportPeriods = projectReportPeriods;
                this.visible = true;
            }, error => this.errorMessage = <any> error);
    }

    // custom-end

}
