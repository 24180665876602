import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import * as globals from '../../globals/globals';
import {LazyLoadRequestVM} from '../model/sys/lazy-load-request-vm';
import {LazyLoadResultVM} from '../model/sys/lazy-load-result-vm';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ResponseWrapper} from '../model/sys/response-wrapper';
import {map, catchError, retry} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {ReportTemplateList} from '../model/report-template-list';
import {ReportTemplate} from '../model/report-template';


@Injectable()
export class ReportTemplateService {

    private baseApiUrl = globals.BASE_API_URL;
    private reportTemplatesUrl = this.baseApiUrl + 'reportTemplates';  // URL to web api

    constructor(private httpClient: HttpClient) {
    }

    getReportTemplates(): Observable<ReportTemplate[]> {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplatesUrl).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    getReportTemplate(id: number): Observable<ReportTemplate> {
        return this.httpClient.get<ResponseWrapper>(`${this.reportTemplatesUrl}/${id}`).pipe(
            map(rw => {
                return this.processData(rw.data);
            }),
            catchError(this.handleError)
        );
    }

    delete(id: number): Observable<{} | Object> {
        const url = `${this.reportTemplatesUrl}/${id}`;
        return this.httpClient.delete(url).pipe(
            catchError(this.handleError)
        );
    }

    create(rpt: ReportTemplate): Observable<ReportTemplate> {
        return this.httpClient
            .post<ResponseWrapper>(this.reportTemplatesUrl, JSON.stringify(rpt)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }

    update(reportTemplate: ReportTemplate): Observable<ReportTemplate> {
        const url = `${this.reportTemplatesUrl}/${reportTemplate.rptId}`;
        return this.httpClient
            .put<ResponseWrapper>(url, JSON.stringify(reportTemplate)).pipe(
                map(rw => {
                    return this.processData(rw.data);
                }),
                catchError(this.handleError)
            );
    }


    loadLazy(req: LazyLoadRequestVM): Observable<LazyLoadResultVM> {
        return this.httpClient.post<ResponseWrapper>(this.reportTemplatesUrl + '/lazy', JSON.stringify(req)).pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    private processData(reportTemplate) {
        if (reportTemplate.rptSysCreateDate) {
            reportTemplate.rptSysCreateDate = new Date(reportTemplate.rptSysCreateDate);
        }
        if (reportTemplate.rptSysSyncTst) {
            reportTemplate.rptSysSyncTst = new Date(reportTemplate.rptSysSyncTst);
        }
        if (reportTemplate.rptSysUpdateDate) {
            reportTemplate.rptSysUpdateDate = new Date(reportTemplate.rptSysUpdateDate);
        }
        return reportTemplate;
    }

    private handleError(error: Response | any) {
        return Utils.handleError(error);
    }


    // custom-start


    getReportTemplateLists(): Observable<ReportTemplateList[]> {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplatesUrl + 'List').pipe(
            map(rw => {
                return rw.data;
            }),
            retry(3),
            catchError(this.handleError)
        );
    }

    duplicate(rptId) {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplatesUrl + '/' + rptId + '/duplicate').pipe(
            map(rw => {
                return rw.data;
            }),
            // retry(3),
            catchError(this.handleError)
        );
    }

    createNewVersion(rptId) {
        return this.httpClient.get<ResponseWrapper>(this.reportTemplatesUrl + '/' + rptId + '/createNewVersion').pipe(
            map(rw => {
                return rw.data;
            }),
            // retry(3),
            catchError(this.handleError)
        );
    }

    // custom-end

}



