import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../core/message.service';
import {Subscription} from 'rxjs';
import {Message} from '../shared/message';
import {Message as PngMessage} from 'primeng/components/common/api';
import {NavigationEnd, Router} from '@angular/router';
// import {Observable} from 'rxjs/Rx';
import {InfoService} from '../core/info.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscription1: Subscription;
  timerSubscription: Subscription;

  info: string;

  ngOnInit() {

  }

  constructor(private messageService: InfoService, private router: Router) {
    this.subscription = messageService.infoAnnounced$.subscribe(
      m => {
        if (this.timerSubscription != null) {
          this.timerSubscription.unsubscribe();
        }
        this.clear();
        console.log('showwwwwww msg');
        this.info = m;
        /*this.timerSubscription = Observable.timer(20000).first().subscribe(() => {
          this.clear();
        });*/
      });

    this.subscription1 = router.events.subscribe((val) => {
      console.log('route: ' + val);
      this.clear();

    });

  }




  clear() {
    this.info = null;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

}
