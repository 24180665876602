import {Component, OnInit, Input, Output, EventEmitter, AfterViewChecked, ViewChild, OnDestroy} from '@angular/core';
import {Item} from '../../../../../../model/item';
import {ItemService} from '../../../../../../service/item.service';
import {TabControllerService} from '../../../../../../../core/tab-controller.service';
// import {BoInteractionService} from '../../../../../../../shared/service/bo-interaction.service';
import {InteractionObject} from '../../../../../../../shared/interaction-object';
import {Table} from 'primeng/table';
import {BaseListComponent} from '../../../../../../base/base-list/base-list.component';


@Component({
    selector: 'app-item-csc1790selection-dialog',
    templateUrl: './item-csc1790selection-dialog.component.html',
    styleUrls: ['./item-csc1790selection-dialog.component.css']
})
export class ItemCsc1790selectionDialogComponent extends BaseListComponent implements OnInit, OnDestroy {

    public selectedObj: any;
    public items: Item[];
    @Output() onSelected = new EventEmitter<Item>();
    public errorMessage: string;
    visible = false;


    tfFilterMap = {'itmChemicalName': null, 'itmName': null};


    @ViewChild('dt1', {static: true}) dataTable: Table;

    selectedFilterField: string;

    // custom-constructor-start
    private cmpDomainName = 'item';

    constructor(private itemService: ItemService) {
        super();
    }


    // custom-constructor-end


    ngOnInit(): void {
        this.getItems();
    }


    selectItm(obj: any) {
        this.selectItmCustom(obj);
    }

    showDlg() {
        this.clearFilters();
        this.visible = true;
    }

    getItems(): void {
        this.getItemsCustom();
    }

    ngOnDestroy() {
        this.onDestroyCustom();
    }

    // custom-start


    getItemsCustom() {
        this.itemService
            .getItems()
            .subscribe(items => this.items = items, error => this.errorMessage = <any> error);
    }

    selectItmCustom(obj: any) {
        this.selectedObj = obj;
        this.visible = false;
        this.onSelected.emit(this.selectedObj);
    }

    onDestroyCustom() {
    }


    removeSelected() {
        this.selectedObj = null;
        this.onSelected.emit(this.selectedObj);
    }


    // custom-end

}
